.payment_section {
    .white-bg {
        padding: 30px;
        @include media767 {
            padding: 20px;
        }
        .payment_method {
            .payment_detail {
                width: 50%;
                padding: 0 15px;
                @include media767 {
                    width: 100%;
                }
                .title-name {
                    h2 {
                        @include defaultFontStyle(500, 26, 1.4, $black-color);
                        margin-bottom: 6px;
                        @include media575 {
                            @include defaultFontStyle(500, 20, 1.4, $black-color);
                        }
                        @include media480 {
                            @include defaultFontStyle(500, 18, 1.4, $black-color);
                        }
                    }
                }
                .payment_form {
                    .check_input {
                        &.row {
                            .check_inputbox {
                                width: 50%;
                                padding: 0 15px;
                                @include media575 {
                                    width: 100%;
                                }
                            }
                        }
                    }
                    .payment_symbols {
                        display: flex;
                        justify-content: space-evenly;
                        margin-top: 40px;
                        flex-wrap: wrap;
                        @include media767 {
                            margin-top: 20px;
                        }
                        img {
                            margin-bottom: 10px;
                            width: 60px;
                            height: 40px;
                            @include media480 {
                                width: 50px;
                            }
                        }
                    }
                    .billing_addrss {
                        margin-top: 31px;
                        @include media767 {
                            margin-top: 20px;
                        }
                        h3 {
                            @include defaultFontStyle(500, 22, 1.4, $black-color);
                            margin-bottom: 10px;
                            @include media575 {
                                @include defaultFontStyle(500, 20, 1.4, $black-color);
                            }
                            @include media480 {
                                @include defaultFontStyle(500, 16, 1.4, $black-color);
                            }
                        }
                    }
                    .select_box {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .select_box_inner {
                            width: 33.33%;
                            padding: 0 15px;
                            @include media1199 {
                                width: 50%;
                            }
                            @include media575 {
                                width: 100%;
                            }
                        }
                    }
                    .card_add {
                        padding-top: 20px;
                        button {
                            margin-top: 10px;
                            padding: 8px 10px;
                            font-size: 15px;
                        }
                        .radiobox {
                            position: relative;
                            display: flex;
                            align-items: center;
                            font-size: 18px;
                            margin-bottom: 10px;
                            input {
                                position: absolute;
                                opacity: 0;
                                left: 0;
                                right: 0;
                                top: 0;
                                height: 100%;
                                width: 100%;
                                z-index: 1;
                                cursor: pointer;
                            }
                            span {
                                position: relative;
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                                transition: all 0.5s ease 0s;
                                background-color: $gray-btn;
                                margin-right: 20px;
                            }
                            span:after {
                                content: "";
                                position: absolute;
                                width: 16px;
                                height: 8px;
                                border: 3px solid $light-color;
                                border-top: 0;
                                border-right: 0;
                                transform: rotate(-40deg) scale(0.6);
                                top: 30%;
                                left: 7px;
                                transition: all 0.5s ease 0s;
                                opacity: 0;
                            }
                            input:checked ~ span::after {
                                transform: rotate(-40deg) scale(1);
                                opacity: 1;
                            }
                            input:checked ~ span {
                                background: $blue;
                            }
                        }
                    }
                }
            }
            .payment_amount {
                width: 50%;
                padding: 0 15px;
                @include media767 {
                    width: 100%;
                    margin-top: 30px;
                }
                .form_content {
                    background: $menu-bg;
                    position: relative;
                    margin: auto;
                    &:before {
                        content: "";
                        background: url("../../assets/images/payment/whiteborder.png");
                        position: absolute;
                        width: 100%;
                        height: 19px;
                        background-repeat: no-repeat;
                        top: -11px;
                    }
                    &:after {
                        content: "";
                        background: url("../../assets/images/payment/whiteborder.png");
                        position: absolute;
                        width: 100%;
                        height: 19px;
                        background-repeat: no-repeat;
                        bottom: -9px;
                    }
                    .table_content_main {
                        padding: 30px 15px 30px 15px;
                        .table_header {
                            background: $blue;
                            padding: 25px;
                            color: $white;
                            display: flex;
                            align-items: center;
                            border-radius: 5px;
                            @include media1199 {
                                padding: 25px 10px;
                                display: block;
                                text-align: center;
                            }
                            h4 {
                                @include defaultFontStyle(500, 22, 1.2, $white);
                                margin: 0;
                                @include media991 {
                                    @include defaultFontStyle(500, 18, 1.2, $white);
                                }
                                @include media480 {
                                    @include defaultFontStyle(500, 16, 1.2, $white);
                                }
                                &.price-right {
                                    margin-left: auto;
                                    @include defaultFontStyle(500, 26, 1.4, $white);
                                    @include media575 {
                                        @include defaultFontStyle(500, 18, 1.4, $white);
                                    }
                                    @include media480 {
                                        @include defaultFontStyle(500, 16, 1.4, $white);
                                    }
                                    span {
                                        @include defaultFontStyle(400, 18, 1.4, $white);
                                        @include media575 {
                                            @include defaultFontStyle(400, 14, 1.4, $white);
                                        }
                                    }
                                }
                            }
                        }
                        .table_content {
                            padding: 40px 20px 20px;
                            @include media1199 {
                                padding: 20px 0 0;
                            }
                            table {
                                width: 100%;
                                border-collapse: collapse;
                                th {
                                    @include defaultFontStyle(500, 20, 1.4, $black-color);
                                    @include media480 {
                                        @include defaultFontStyle(500, 16, 1.4, $black-color);
                                    }
                                    padding: 7px;
                                    &:first-child {
                                        text-align: left;
                                    }
                                    &:last-child {
                                        text-align: right;
                                    }
                                }
                                td {
                                    @include defaultFontStyle(400, 18, 1.4, $light-black);
                                    @include media480 {
                                        @include defaultFontStyle(500, 15, 1.4, $light-black);
                                    }
                                    padding: 7px;
                                    &:first-child {
                                        text-align: left;
                                    }
                                    &:last-child {
                                        text-align: right;
                                    }
                                    &.TotalAmount {
                                        @include defaultFontStyle(500, 20, 1.4, $black-color);
                                        border-top: 1px dashed $light-black;
                                        padding-top: 13px;
                                        @include media480 {
                                            @include defaultFontStyle(500, 15, 1.4, $black-color);
                                        }
                                    }
                                }
                                tr {
                                    &:nth-child(3) {
                                        td {
                                            padding-bottom: 13px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .purchase_policy {
                        width: 90%;
                        margin: 110px auto 0;
                        @include media767 {
                            margin-top: 65px;
                            width: 100%;
                        }
                        .form-group-checkbox {
                            label {
                                @include media1199 {
                                    @include defaultFontStyle(500, 14, 1.6, $black-color);
                                }
                                @include media767 {
                                    @include defaultFontStyle(500, 13, 1.8, $black-color);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.payment-container {
    max-width: 900px;
    margin: 0 auto;
    .payment-thanq {
        padding: 20px 30px;
        background-color: #fff;
        margin: 60px 0;

        .thanq-title {
            text-align: center;
            h4 {
                @include defaultFontStyle(700, 24, 1.2, $green-color);
                padding-bottom: 20px;
            }
        }
    }
}
