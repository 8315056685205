.coachdashboard {
  padding-top: 20px;
  padding-bottom: 20px;
}
.event-dashboard {
  .event-roster-select {
    .custom-select {
      background: url("../../assets/images/social/down_arrow.svg") no-repeat 99% center #fff;
    }
  }
  .dashbord-center {
    margin-left: 0 !important;
  }
  .common-div {
    @include media1199 {
      width: 100% !important;
      margin-left: 0 !important;
    }
  }
}
.event-dashboard {
  .event-roster-select {
    .custom-select {
      background: url("../../assets/images/social/down_arrow.svg") no-repeat 99% center #fff;
    }
  }
  .dashbord-center {
    margin-left: 0 !important;
  }
  .common-div {
    @include media1199 {
      width: 100% !important;
      margin-left: 0 !important;
    }
  }
}
.common-dashbord {
  display: flex;
  align-content: center;
  @include media1199 {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .common-div {
    background-color: $white;
    padding: 18px 20px;
    width: 300px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 10px 5px -7px rgba(0, 0, 0, 0.09);
    text-align: center;
    h3 {
      font-size: 18px;
      font-weight: 600;
    }
    @include media1429 {
      width: 250px;
    }
    @include media1199 {
      width: 46%;
      margin: 0 20px;
      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-left: 0;
      }
    }
    @include media575 {
      margin: 0px 0 20px;
      width: 100%;
    }
    .facalitiy_logo {
      max-width: 250px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      border-radius: 5px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .team-name {
      background-color: var(--green-color-light);
      padding: 10px;
      width: 100%;
      h3 {
        @include defaultFontStyle(400, 18, 1.2, $white);
        text-transform: capitalize;
      }
    }
    .team_logo {
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 15px auto 10px;
      border-radius: 100%;
      border: 1px solid $gray-btn;
      > img {
        border-radius: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &.commissioner_team {
        object-fit: contain;
      }
    }
    h4 {
      @include defaultFontStyle(700, 16, 1.4, $black-color);
      text-align: center;
    }
    .p-black {
      margin: 5px 0 10px;
      position: relative;
      padding-bottom: 15px;
      width: 100%;
      text-align: center;
      &:before {
        position: absolute;
        left: 0;
        right: 0;
        content: "";
        height: 3px;
        background-color: var(--green-color);
        width: 28px;
        bottom: 0;
        margin: 0 auto;
      }
      span {
        color: $light-black;
      }
    }
    .p-black.befor {
      padding-bottom: 0px;
      margin: 15px 0;
      &:before {
        display: none;
      }
    }
    .dash_social {
      display: flex;
      align-items: center;
      margin: 10px 0 15px;
      .social_but {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-size: 18px;
        margin: 0 5px;
        border-radius: 100%;
        &:hover {
          background: $light-black;
          color: $white;
        }
      }
    }
    .form-group {
      margin: 15px 0;
      width: 100%;
      position: relative;
      &:before {
        content: attr(data-left);
        display: inline-block;
        text-align: left;
        position: absolute;
        left: 10px;
        line-height: 50px;
        color: $light-black;
        font-size: 15px;
      }
      &:after {
        content: attr(data-right);
        display: inline-block;
        text-align: left;
        position: absolute;
        right: 10px;
        color: $light-black;
        line-height: 50px;
        font-size: 15px;
      }
      .form-control,
      .form-control:active,
      .form-control:focus {
        text-align: center;
        color: $black-color;
        height: 50px;
        color: $black-color;
        font-size: 14px;
        &::-webkit-input-placeholder {
          /* Edge */
          color: $black-color;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $black-color;
        }

        &::placeholder {
          color: $black-color;
        }
        .team-name {
          background-color: $green-btn-light;
          padding: 10px;
          width: 100%;
          h3 {
            @include defaultFontStyle(400, 18, 1.2, $light-color);
            text-transform: capitalize;
          }
        }
        .facalitiy_logo {
          max-width: 250px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
          border-radius: 5px;
          > img {
            width: 100%;
            height: 100%;
          }
        }

        .team_logo {
          width: 80px;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 15px auto 10px;
          border-radius: 100%;
          border: 1px solid $gray-btn;
          > img {
            border-radius: 100%;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          &.commissioner_team {
            object-fit: contain;
          }
        }
      }
    }
    .btn-group {
      display: flex;
      width: 100%;
      justify-content: center;
      a {
        margin-right: 12px;
        padding: 13px 5px;
        width: 50%;
        &:last-child {
          margin-right: 0;
        }
        i {
          margin-right: 8px;
        }
      }
    }
  }
  .dashbord-center {
    flex: auto;
    margin: 0 20px;
    width: calc(100% - 620px);
    @include media1429 {
      width: calc(100% - 520px);
    }
    @include media1199 {
      width: 100%;
      order: 1;
      margin: 0;
      margin-top: 20px;
    }
    @include media575 {
      margin-top: 0;
    }
    .slider-common {
      overflow: hidden;
      background: $white;
      border-radius: 5px;
      box-shadow: 0px 10px 5px -7px rgba(0, 0, 0, 0.09);
      .item {
        height: 200px;
        > img {
          border-radius: 5px;
          width: 100%;
          height: 100%;
          display: inline-block;
          object-fit: cover;
        }
        p {
          margin-bottom: 0;
          text-align: center;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .slick-prev {
        left: -20px;
        z-index: 1;
        opacity: 0;
        background: $light-black;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        text-align: center;
        &:before {
          content: "\f053";
          font-size: 12px;
          font-family: "Font Awesome 5 Free";
          color: $white;
          opacity: 1;
          line-height: 21px;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
        }
        &:hover {
          background-color: var(--green-color);
        }
      }
      .slick-next {
        right: -20px;
        z-index: 1;
        opacity: 0;
        background: $light-black;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        text-align: center;
        &:before {
          content: "\f054";
          font-family: "Font Awesome 5 Free";
          font-size: 12px;
          color: $white;
          opacity: 1;
          line-height: 20px;
          position: absolute;
          top: 1px;
          left: 3px;
          right: 0;
        }
        &:hover {
          background-color: var(--green-color);
        }
      }
      &:hover {
        .slick-next {
          right: 15px;
          opacity: 1;
        }
        .slick-prev {
          left: 15px;
          opacity: 1;
        }
      }
    }
    .upload_file {
      display: flex;
      align-content: center;
      margin: 20px -10px 0;
      @include media575 {
        flex-wrap: wrap;
        justify-content: center;
        margin: 0;
      }
      .upload_inner {
        padding: 30px 10px;
        background: $white;
        border-radius: 5px;
        display: flex;
        align-content: center;
        margin: 0 10px;
        width: calc(100% / 3);
        box-shadow: 0px 10px 5px -7px rgba(0, 0, 0, 0.09);
        flex-direction: column;
        justify-content: center;
        text-align: center;
        transition: auto;
        @include media1429 {
          padding: 10px 10px;
        }
        @include media575 {
          padding: 30px;
          width: 100%;
          margin: 20px auto 0;
        }
        .upload_img {
          margin: 0 auto 30px;
          width: 70px;
          height: 70px;
          display: flex;
          align-content: center;
          justify-self: center;
          background: var(--green-color-extra-light);
          border-radius: 100%;
          transition: all 0.5s ease 0s;
          padding: 18px;
          .img {
            width: 100%;
            height: 100%;
          }
          .deactive {
            display: none;
            transition: all 0.5s ease 0s;
          }
        }
        h5 {
          @include defaultFontStyle(600, 18, 1.4, $black-color);
        }
        &:hover {
          box-shadow: 0px 0px 2px 2px var(--green-color-shadow);
          .upload_img {
            background-color: var(--green-color-light);
            .deactive {
              display: block;
            }
            .active {
              display: none;
            }
          }
        }
        &.store-padding {
          padding: 45px 10px;
          @include media1199 {
            padding: 10px;
          }
        }
      }
      &.subcoach-access {
        .upload_inner {
          width: calc(100% / 2);
        }
      }
    }
  }
}
.roster-event-tab {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  @include media575 {
    display: block;
  }
  h3 {
    margin: 10px 0;
  }
  .roster-event-tab-info {
    display: flex;
    @include media575 {
      display: block;
    }
    .list-group {
      margin-right: 15px;
      flex-wrap: nowrap;
      @include media575 {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      .list-group-item {
        padding: 10px 20px;
      }
    }
  }
}
.products {
  padding: 0px 0 20px;
  .white-bg {
    padding: 20px;
    @include media767 {
      padding-bottom: 10px;
    }
    overflow: hidden;
    .produc-common {
      @include media767 {
        padding-bottom: 20px;
      }
      .slick-list {
        margin: 20px -15px 0;
        .slick-track {
          margin-left: unset;
        }
        @include media480 {
          margin-top: 15px;
        }
        .slick-slide {
          .item {
            .items {
              margin: 0 5px;
              padding: 20px;
              border: 1px solid $gray-light;
              display: flex;
              align-content: center;
              border-radius: 5px;
              width: 340px;
              @include media1429 {
                width: 318px;
              }
              @include media575 {
                padding: 15px;
                flex-direction: column;
                width: 300px;
              }
              @include media480 {
                width: 240px;
              }
              @include media350 {
                width: 210px;
              }
              .team_img {
                position: relative;
                width: 150px;
                height: 150px;
                display: flex;
                align-content: center;
                justify-content: center;
                margin: 0 auto;
                border-radius: 5px;
                @include media575 {
                  width: 100%;
                  height: 100%;
                }
                > img {
                  border-radius: 5px;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
                span {
                  position: absolute;
                  bottom: 10px;
                  left: 0;
                  right: 0;
                  @include defaultFontStyle(600, 16, 1.2, $white);
                  text-align: center;
                  display: none;
                  @include media767 {
                    font-size: 16px;
                  }
                }
              }
              .team_dec {
                margin-left: 25px;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                @include media1429 {
                  margin-left: 15px;
                }
                @include media575 {
                  margin: 15px 0 0;
                  flex: 0;
                }
                .team_name {
                  display: flex;
                  align-content: center;
                  justify-content: space-between;
                  // margin-bottom: 15px;
                  @include media480 {
                    flex-direction: column;
                  }
                  @include media575 {
                    margin-bottom: 15px;
                  }
                  h6 {
                    position: relative;
                    padding-bottom: 10px;
                    @include defaultFontStyle(600, 22, 1.2, $black-color);
                    @include media767 {
                      font-size: 18px;
                    }
                    &:before {
                      position: absolute;
                      left: 0;
                      right: auto;
                      content: "";
                      height: 3px;
                      background-color: var(--green-color-light);
                      width: 28px;
                      bottom: 0;
                    }
                  }
                }
                .manage {
                  padding: 10px;
                  margin: 10px 0;
                  border-radius: 5px;
                  display: inline-block;
                  vertical-align: middle;
                  background: $gray-btn;
                  color: $black-color;
                  text-align: center;
                  @include defaultFontStyle(500, 13, 1.2, $black-color);
                  @include media1429 {
                    padding: 8px 5px;
                  }
                  @include media767 {
                    padding: 8px;
                  }
                  @include media480 {
                    margin-top: 15px;
                  }
                }
                span {
                  @include defaultFontStyle(400, 16, 1.2, $light-black);
                  display: block;
                  margin: 22px 0;
                  @include media480 {
                    margin: 15px 0;
                    font-size: 15px;
                  }
                }
              }
            }
          }
        }
      }
      .slick-prev {
        left: -40px;
        z-index: 1;
        opacity: 0;
        background: $light-black;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        text-align: center;
        &:before {
          content: "\f053";
          font-size: 12px;
          font-family: "Font Awesome 5 Free";
          color: $white;
          opacity: 1;
          line-height: 21px;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
        }
        &:hover {
          background-color: var(--green-color);
        }
      }
      .slick-next {
        right: -40px;
        z-index: 1;
        opacity: 0;
        background: $light-black;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        text-align: center;
        &:before {
          content: "\f054";
          font-family: "Font Awesome 5 Free";
          font-size: 12px;
          color: $white;
          opacity: 1;
          line-height: 20px;
          position: absolute;
          top: 1px;
          left: 3px;
          right: 0;
        }
        &:hover {
          background-color: var(--green-color);
        }
      }
      &:hover {
        .slick-next {
          opacity: 1;
          right: -8px;
        }
        .slick-prev {
          opacity: 1;
          left: -8px;
        }
      }
    }
    .table {
      margin-top: 20px;
      @include media480 {
        margin-top: 15px;
      }
      tr {
        border-bottom: 1px solid $gray-btn;
        @include defaultFontStyle(500, 14, 1.2, $black-color);
        td {
          @include defaultFontStyle(500, 14, 1.2, $light-black);
        }
        th,
        td {
          padding: 20px 0;
        }
        &:last-child {
          border-bottom: none;
          th,
          td {
            padding-bottom: 0;
            @include media767 {
              padding: 20px 0;
            }
          }
        }
        &:first-child {
          th,
          td {
            padding-top: 0;
          }
        }
        th {
          @include media767 {
            min-width: 300px;
          }
        }
        td {
          @include media767 {
            min-width: 180px;
            text-align: center;
          }
        }
      }
      .payment_link {
        text-align: right;
        a {
          color: $text-red;
          &:hover {
            color: $black-color;
          }
        }
      }
      .status_link {
        text-align: right;
        a {
          margin: 5px;
        }
      }
    }
  }
}

.social_but:disabled,
.social_but:focus:disabled,
.social_but:active:disabled,
.social_but:visited:disabled,
.social_but[disabled],
.social_but:focus[disabled],
.social_but:active[disabled],
.social_but:visited[disabled] {
  background: $gray-text !important;
  color: $white !important;
  box-shadow: none !important;
  cursor: not-allowed;
}

.status_link {
  text-align: right;
  a {
    margin: 5px;
  }
}
