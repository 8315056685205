.main-ranking {
    // margin-top: 180px;
    background-color: $white-light;
    // padding-top: 60px;
    // @include media1366 {
    //     margin-top: 127px;
    //     padding-top: 30px;
    // }
    // @include media991 {
    //     margin-top: 110px;
    // }
    // @include customMedia(380) {
    //     margin-top: 102px;
    // }
    .info-tooltip {
        width: 13px !important;
        height: 13px !important;
        margin: 0;
        margin-left: 5px;
        cursor: pointer;
    }
}
.main-leaderboards {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    @include media991 {
        display: block;
    }
    .player-ranking {
        width: 27%;
        @include media991 {
            width: 100%;
            margin-bottom: 50px;
        }
        .section_title {
            font-size: 24px;
        }
        .white-bg {
            border-radius: 10px;
            box-shadow: unset;
        }
        .player-search {
            position: relative;
            input {
                width: 100%;
                border: transparent;
                padding: 19px 24px;
                border-radius: 10px;
            }
            .search-icon {
                position: absolute;
                top: 0;
                right: 0;
                padding: 13px;
                transform: translate(-8px, 7px);
                border-radius: 10px;
                background-color: var(--green-color-light);
                cursor: pointer;
            }
        }
        select {
            border-radius: 10px;
        }
    }
    .mainBoard {
        width: 46%;
        margin: 0 30px;
        background-color: $white;
        border-radius: 10px;
        @include media991 {
            width: 100%;
            margin: 0 0 50px 0;
        }
    }
    .leaderboards-team-ranking {
        width: 27%;

        .leaderboards-team {
            background-color: $white;
            border-radius: 10px;
        }
        @include media991 {
            width: 100%;
            margin-bottom: 50px;
        }
        .custom-select {
            border-radius: 10px;
        }
    }
    .custom-player-search {
        .ranking-select {
            > div {
                border: 1px solid #e5e9f2;
                border-radius: 10px;
            }
        }
    }
}
.list-group-scroll {
    .list-group {
        display: flex;
        // flex-wrap: wrap;
        width: 100%;
        list-style: none;
        overflow-x: auto;
        .list-group-item {
            flex: 1;
            padding: 10px 5px;
            border: 1px solid $gray-light;
            @include defaultFontStyle(500, 16, 1.2, $black-color);
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            white-space: nowrap;
            &:first-child {
                border-radius: 10px 0 0 10px;
            }
            &:last-child {
                border-radius: 0 10px 10px 0;
                // border-right: 1px solid $gray-light;
            }
            &.active {
                background-color: var(--green-color-light);
                color: $white;
            }
            &.list-group-item-one {
                border-radius: 10px;
            }
        }
        .disabled {
            pointer-events: none;
            background-color: lightgray !important;
            color: #6c7b95 !important;
        }
    }
    ::-webkit-scrollbar-track {
        background-color: $light-color;
        border-radius: 5px;
    }
    ::-webkit-scrollbar {
        height: 3px;
        border-radius: 5px;
        background-color: $light-color;
    }
    ::-webkit-scrollbar-thumb {
        background-color: $light-black;
        border: 2px solid $light-black;
        border-radius: 5px;
        &:hover {
            background-color: $light-black;
            border: 2px solid $light-black;
        }
    }
}
.player-ranking-year {
    border: 1px solid #e0dcde9e;
    box-sizing: border-box;
    border-radius: 10px;
    .ranking-year {
        background: #e0dcdeab;
        text-align: center;
        @include defaultFontStyle(bold, 16, 1.2, $black-light);
        padding: 10px;
        border-radius: 10px 10px 0 0;
    }
    .player-list {
        padding: 20px 20px 10px;
        @include customMedia(1024) {
            padding: 10px;
        }
        .player-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 12px;
            &:last-child {
                border-bottom: 1px solid #e0dcde9e;
                padding-bottom: 20px;
            }
            .player-name {
                @include defaultFontStyle(500, 16, 1.2, $light-black);
                display: flex;
                align-items: center;
                cursor: pointer;
                @include customMedia(1024) {
                    font-size: 14px;
                }
                img {
                    width: 36px;
                    height: 36px;
                    border-radius: 100%;
                    margin-right: 14px;
                    object-fit: cover;
                    @include customMedia(1024) {
                        width: 30px;
                        height: 30px;
                        margin-right: 10px;
                    }
                }
            }
            .player-rank {
                @include defaultFontStyle(500, 16, 1.2, $black-light);
                @include customMedia(1024) {
                    font-size: 14px;
                }
            }
        }
    }
    .more-info {
        @include defaultFontStyle(bold, 16, 1.2, var(--green-color-light));
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}
// mainBoard
.mainboard-group {
    padding: 0 20px;
    border-bottom: 1px solid #e0dcde9e;
    .list-group-scroll {
        .custom-select {
            border-radius: 10px;
            height: 38px;
        }
        &:first-child {
            .list-group-item {
                font-size: 18px;
            }
        }
        &:nth-child(2) {
            .list-group-item {
                font-size: 14px;
            }
        }
        &:nth-child(3) {
            .list-group-item {
                font-size: 14px;
            }
        }
        .list-group {
            &:first-child {
                // border-bottom: 1px solid #e0dcde9e;
                // padding-bottom: 20px;
            }
        }
    }
}
.mainBoard-list {
    padding: 20px;
    .mainBoard-item {
        padding: 10px;
        background: $white-light;
        border-radius: 10px;
        position: relative;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
        .Board-info {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            @include media575 {
                display: block;
            }
            .mainboard-img {
                width: 60%;
                display: flex;
                align-items: center;
                padding-right: 15px;
                border-right: 1px solid #e0dcde9e;
                @include media575 {
                    border-right: 0;
                    width: 100%;
                }
                .img {
                    width: 100px;
                    height: 100px;
                    border-radius: 10px;
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                        object-fit: cover;
                    }
                }
                .name {
                    width: calc(100% - 100px);
                    margin-left: 14px;
                    cursor: pointer;
                    p {
                        @include defaultFontStyle(500, 18, 1.3, $black-light);
                        margin: 0;
                        padding-bottom: 12px;
                    }
                    .time {
                        max-width: fit-content;
                        background: $white;
                        border-radius: 10px;
                        @include defaultFontStyle(500, 14, 1.2, $light-black);
                        padding: 8px 15px;
                        @include media991 {
                            font-size: 11px;
                            padding: 8px 5px;
                        }
                    }
                }
            }
            .mainboard-info {
                width: 40%;
                padding: 20px 0px 20px 15px;
                @include media575 {
                    width: 100%;
                }
                p {
                    position: relative;
                    padding-right: 10px;
                    margin-bottom: 17px;
                    padding-left: 26px;
                    @include defaultFontStyle(400, 14, 1.2, $light-black);
                    &:last-child {
                        margin-bottom: 0;
                        cursor: pointer;
                    }
                    &.date-icon::before {
                        position: absolute;
                        content: "";
                        background: url(../../assets/images/pw-ranking/date-icon.svg) no-repeat;
                        width: 15px;
                        height: 15px;
                        background-size: cover;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    &.light-date-icon::before {
                        background: url(../../assets/images/pw-ranking/date-icon-light.svg) no-repeat;
                    }
                    &.location-icon::before {
                        position: absolute;
                        content: "";
                        background: url(../../assets/images/pw-ranking/event.svg) no-repeat;
                        width: 15px;
                        height: 13px;
                        background-size: cover;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    &.light-location-icon::before {
                        background: url(../../assets/images/pw-ranking/event_greenlight.svg) no-repeat;
                    }
                }
            }
        }
        .winner-img {
            position: absolute;
            top: 0;
            right: 0;
            display: none;
        }
    }
    .lock_view {
        .logo_lock {
            height: 270px;
        }
        .lock_img {
            bottom: 3%;
        }
    }
}
.mainBoard {
    .see-more-btn {
        max-width: 410px;
        width: 100%;
        margin: auto;
        .btn {
            margin: 10px 0 30px;
        }
    }
}
// leaderboards-team-ranking
.leaderboards-team-ranking {
    .section_title {
        font-size: 24px;
    }
    .team-ranking-list {
        .team-ranking-item {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include defaultFontStyle(500, 16, 1.2, $light-black);
            border-top: 1px solid #e0dcde9e;
            padding: 12px 30px;
            @include customMedia(1024) {
                padding: 12px 20px;
                font-size: 14px;
            }
            &:last-child {
                border-bottom: 1px solid #e0dcde9e;
            }
            .team-rank {
                color: $black-light;
            }
        }
    }
    .see-more-btn {
        .btn {
            margin: 30px 0 30px;
        }
    }
}
.see-more-btn {
    padding: 0 20px;
    .btn {
        width: 100%;
        background: var(--green-color-light);
        border: 2px solid var(--green-color-light);
        position: relative;
        border-radius: 10px;
        &:hover {
            background: $white;
            color: var(--green-color-light);
        }
    }
    .lock-icon {
        position: absolute;
        right: 15px;
        top: 6px;
        @include media767 {
            top: 3px;
        }
    }
}
.lock-icon {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    background: var(--green-color-light);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    &.bg-grey {
        background: #878b7e;
    }
}

// Team Ranking
.team-ranking {
    display: flex;
    flex-wrap: wrap;
    background: $white;
    border-radius: 0px 0px 10px 10px;
    .section_title {
        @include defaultFontStyle(700, 24, 1.4, $black);
    }
    .team-rankings-info {
        width: 35%;
        border-right: 1px solid #f0eeef;
        padding: 30px 20px;
        @include media767 {
            width: 100%;
            border-right: 0px solid #f0eeef;
        }
        .national {
            margin: 20px 0 15px;
            .custom-select {
                background-color: #f0eeefeb;
                border-radius: 10px;
                height: 50px;
            }
        }
    }
    .national-rankings-info {
        width: 65%;
        padding: 30px 20px;
        @include media767 {
            width: 100%;
            padding: 0 30px 20px;
        }
        .ranking-logo {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-left: -15px;
            margin-right: -15px;
            .national-rank-item {
                padding: 15px;
                .national-rank-img {
                    background: $white-light;
                    border-radius: 10px;
                    width: 120px;
                    height: 120px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    span {
                        position: absolute;
                        background: var(--green-color-light);
                        border-radius: 0px 11px 0px 10px;
                        width: 40px;
                        height: 40px;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        bottom: 0;
                        left: 0;
                        @include defaultFontStyle(700, 18, 1.2, $white);
                    }
                }
            }
        }
    }
}
.ranking-table {
    padding: 30px 0 0;
    .table {
        @include media767 {
            white-space: nowrap;
        }
        thead {
            background-color: #dce3eb;
            th {
                opacity: 0.5;
                @include defaultFontStyle(500, 14, 1.2, $black);
                padding: 21px 30px;
                border: 1px solid #f6f9fe;
                min-width: 160px;
                &:first-child {
                    border-radius: 8px 0 0 0;
                }
                &:last-child {
                    border-radius: 0 8px 0 0;
                }
                &:nth-child(2) {
                    min-width: 260px;
                    max-width: 260px;
                }
            }
        }
        tbody {
            background-color: $white;
            td {
                @include defaultFontStyle(500, 14, 1.2, $light-black);
                padding: 15px 30px;
                border: 1px solid #eef1f7;
                &:first-child {
                    color: #6c7b95;
                }
            }
            .team-name {
                @include defaultFontStyle(500, 16, 1.2, $black);
                span {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    cursor: pointer;
                    img {
                        width: 36px;
                        height: 36px;
                        border-radius: 100%;
                        margin: 0 14px 0 0;
                    }
                }
            }
            .player-info {
                font-weight: normal;
                line-height: 1.4;
                color: $light-black;
            }
            .show-more {
                color: $green-btn-light;
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
    .lock_view {
        border-radius: 0;
    }
}
.ranking-banner {
    background: rgba(0, 0, 0, 0.77);
    border-radius: 0px 0px 10px 10px;
    text-align: center;
    position: relative;
    .ranking-banner-logo {
        padding: 30px;
        p {
            @include defaultFontStyle(500, 16, 1.2, $white);
            padding: 20px 0;
            margin: 0;
        }
        .ranking-btn {
            max-width: 410px;
            width: 100%;
        }
    }
    .lock-icon {
        position: absolute;
        width: 35px;
        height: 35px;
        border-radius: 100%;
        background: #96cf04;
        bottom: 25px;
        right: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        @include media675 {
            position: unset;
            margin: 12px auto 0;
        }
    }
}
.player-ranking-section {
    .ranking-table {
        .table {
            tbody {
                td {
                    color: $black;
                }
                .player-info {
                    color: $light-black;
                }
            }
        }
        .lock_view {
            border-radius: 0;
        }
    }
}

// Team Profile

.team-profile-section {
    padding: 60px 0 35px;
    @include media1366 {
        padding: 30px 0 35px;
    }
    .team-profile-details {
        background: $white;
        border-radius: 5px;
        padding: 27px 25px 25px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include media991 {
            padding: 10px;
        }
        h3 {
            @include defaultFontStyle(bold, 20, 1.4, $black-light);
            padding-bottom: 10px;
            text-align: center;
        }
        .team-profile-item {
            border-right: 1px solid #e0dcde;
            width: 25%;
            padding: 0 20px;
            @include media1429 {
                width: 50%;
                margin-bottom: 30px;
                &:nth-child(2) {
                    border-right: 0;
                }
            }
            @include media767 {
                width: 100%;
                border-right: 0;
                padding: 0;
            }
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                border-right: 0;
                padding-right: 0;
            }
            .team_claim_btn {
                max-width: 236px;
                /* width: 75%; */
                font-weight: 500;
                width: 100%;
                margin-top: 10px;
                cursor: pointer;
                padding: 8px 13px;
                margin: 10px auto 0;
                display: block;
                font-size: 14px;
            }
        }

        .team-info-blog {
            .team-contact {
                background: $white-light;
                border: 1px solid #6c7b9533;
                box-sizing: border-box;
                border-radius: 5px;
                .team-info {
                    background: var(--green-color-light);
                    border-radius: 5px 5px 0px 0px;
                    padding: 20px 30px;
                    display: flex;
                    align-items: center;
                    .team-logo {
                        width: 72px;
                        height: 70px;
                    }
                    .team-title {
                        width: calc(100% - 70px);
                        border-left: 1px solid $white;
                        padding-left: 20px;
                        margin-left: 20px;
                        p {
                            @include defaultFontStyle(bold, 20, 1.4, $white);
                            margin: 0;
                            max-width: 150px;
                        }
                    }
                }
                .contact-id {
                    padding: 30px 15px;
                    .contact-input {
                        margin-bottom: 10px;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        label {
                            @include defaultFontStyle(500, 16, 1.2, $black-light);
                            padding-right: 15px;
                            // width: 150px;
                        }
                        .form-group {
                            margin-bottom: 0;
                            .form-control {
                                height: 35px;
                                color: $light-black;
                            }
                        }
                    }
                }
            }
        }

        .upcoming-schedule-event {
            .event_box {
                padding: 5px;
                h3 {
                    padding: 0;
                    text-align: left;
                }
                .event_card {
                    margin-bottom: 0;
                }
            }
            .slick-prev {
                left: 0;
                z-index: 1;
                opacity: 0;
                background: $light-black;
                width: 20px;
                height: 20px;
                border-radius: 100%;
                text-align: center;
                &:before {
                    content: "\f053";
                    font-size: 12px;
                    font-family: "Font Awesome 5 Free";
                    color: $white;
                    opacity: 1;
                    line-height: 21px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                }
                &:hover {
                    background-color: var(--green-color);
                }
            }
            .slick-next {
                right: 0;
                z-index: 1;
                opacity: 0;
                background: $light-black;
                width: 20px;
                height: 20px;
                border-radius: 100%;
                text-align: center;
                &:before {
                    content: "\f054";
                    font-family: "Font Awesome 5 Free";
                    font-size: 12px;
                    color: $white;
                    opacity: 1;
                    line-height: 20px;
                    position: absolute;
                    top: 1px;
                    left: 3px;
                    right: 0;
                }
                &:hover {
                    background-color: var(--green-color);
                }
            }
            &:hover {
                .slick-next {
                    opacity: 1;
                    right: -8px;
                }
                .slick-prev {
                    opacity: 1;
                    left: -8px;
                }
            }
        }

        .national-rank {
            .national-rank-list {
                // display: flex;
                // align-items: center;
                margin-left: -10px;
                margin-right: -10px;
                .rank-list {
                    background: $white-light;
                    border-radius: 5px;
                    padding: 30px 15px;
                    margin: 0 10px;
                    // width: 50%;
                    // &:last-child{
                    //     margin-right: 0;
                    // }
                    .rank-status {
                        .form-group {
                            margin-bottom: 0;
                            .form-control {
                                height: 33px;
                                color: $light-black;
                            }
                        }
                    }
                }
            }
        }
    }
}
.batting-pitching-section {
    background: $white;
    border-radius: 5px;
    padding: 25px;
    // display: flex;
    // @include media767 {
    //     display: block;
    // }
    .list-group {
        width: 30%;
        margin: 0 auto 15px;
        @include media575 {
            width: 100%;
        }
    }
    .individual-stats {
        // width: 50%;
        border-right: 1px solid #e0dcde;
        padding: 0 20px;
        @include media767 {
            width: 100%;
            border-right: 0;
            margin-bottom: 30px;
            padding: 0;
            &:last-child {
                margin-bottom: 0;
            }
        }
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            border-right: 0;
            padding-right: 0;
        }
        .stats-blog {
            background: $white-light;
            border: 1px solid rgba(108, 123, 149, 0.2);
            box-sizing: border-box;
            border-radius: 5px;
            padding: 10px;
            position: relative;
            .table-responsive-xl {
                display: block;
                width: 100%;
                overflow-x: auto;
                max-height: 407px;
            }

            .table {
                border-collapse: separate;
                @include media767 {
                    white-space: nowrap;
                }
                thead {
                    background-color: $white;
                    th {
                        @include defaultFontStyle(500, 14, 1.2, $black);
                        padding: 10px;
                        text-align: center;
                        min-width: 20px;
                        border: none;
                        border-right-color: 1px solid #6c7b95;
                        border-bottom: 5px solid $white-light;
                        &:first-child {
                            border-radius: 8px 0 0 0;
                        }
                        &:last-child {
                            border-radius: 0 8px 0 0;
                            border-right: none;
                        }
                        &:nth-child(1) {
                            min-width: 150px;
                            max-width: 150px;
                            text-align: left;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        }
                    }
                }
                tbody {
                    background-color: $white;
                    td {
                        @include defaultFontStyle(500, 14, 1.2, $light-black);
                        padding: 10px;
                        border: none;
                        border-right-color: 1px solid #6c7b95;
                        border-bottom: 5px solid $white-light;
                        span {
                            color: $green-btn-light;
                        }
                        &:first-child {
                            color: #6c7b95;
                        }
                        &:last-child {
                            border-right: none;
                        }
                    }
                }
            }
            .ranking-banner {
                position: absolute;
                width: 100%;
                top: 185px;
                left: 0;
            }
        }
    }
}
.team-roster-awards-section {
    position: relative;
    .team-roster-awards {
        background: $white;
        border-radius: 5px;
        padding: 25px 25px 0;
        display: flex;
        // height: 560px;
        // overflow: auto;
        @include media767 {
            display: block;
        }
        h3 {
            @include defaultFontStyle(bold, 20, 1.4, $black-light);
            padding-bottom: 10px;
            text-align: center;
        }
        .team-roster-list {
            border-right: 1px solid #e0dcde;
            padding-right: 20px;
            width: 30%;
            @include media767 {
                width: 100%;
                margin-bottom: 30px;
                border-right: 0;
                padding-right: 0;
            }
            .team-roster {
                background: $white-light;
                border: 1px solid rgba(108, 123, 149, 0.2);
                box-sizing: border-box;
                border-radius: 5px;
                padding: 0 20px;
                height: 477px;
                overflow-y: auto;
                @include media991 {
                    padding: 0 10px;
                }
                .roster-item {
                    border-bottom: 1px solid #6c7b954f;
                    padding-bottom: 15px;
                    padding-top: 15px;
                    &:last-child {
                        border-bottom: 0;
                    }
                    .roster-name {
                        display: flex;
                        align-items: center;
                        @include defaultFontStyle(500, 16, 1.2, $black);
                        @include media991 {
                            font-size: 15px;
                        }
                        img {
                            width: 36px;
                            height: 36px;
                            border-radius: 100%;
                            margin: 0 14px 0 0;
                            object-fit: cover;
                            @include media991 {
                                width: 30px;
                                height: 30px;
                                margin: 0 10px 0 0;
                            }
                        }
                    }
                }
            }
        }
        .team-awaeds-list {
            width: 100%;
            @include media767 {
                width: 100%;
                padding-left: 0;
            }
            .team-awaeds {
                background: $white-light;
                border: 1px solid rgba(108, 123, 149, 0.2);
                box-sizing: border-box;
                border-radius: 5px;
                padding: 20px;
                @include media991 {
                    padding: 10px;
                }

                .table-responsive-xl {
                    // max-height: 435px;
                    // overflow-y: auto;
                }
                .table {
                    @include media767 {
                        white-space: nowrap;
                    }
                    thead {
                        background-color: $white;
                        th {
                            @include defaultFontStyle(500, 14, 1.2, $black);
                            padding: 10px;
                            // min-width: 200px;
                            border: none;
                            border-bottom: 5px solid $white-light;
                            border-right: none;
                            // &:first-child {
                            //     border-radius: 8px 0 0 0;
                            //     min-width: 250px;
                            // }
                            // &:last-child {
                            //     border-radius: 0 8px 0 0;
                            //     min-width: 130px;
                            // }
                            // &:nth-child(1) {
                            //     text-align: left;
                            //     text-overflow: ellipsis;
                            //     overflow: hidden;
                            //     white-space: nowrap;
                            // }
                        }
                    }
                    tbody {
                        background-color: $white;
                        td {
                            @include defaultFontStyle(500, 14, 1.2, $light-black);
                            padding: 10px;
                            border: none;
                            border-bottom: 5px solid $white-light;
                            border-right: none;
                            &:first-child {
                                color: #6c7b95;
                            }

                            &.green-color {
                                color: $green-btn-light;
                            }
                            &.team-name {
                                @include defaultFontStyle(500, 16, 1.2, $black-light);
                                @include media991 {
                                    font-size: 14px;
                                }
                                span {
                                    display: flex;
                                    align-items: center;
                                    img {
                                        width: 36px;
                                        height: 36px;
                                        border-radius: 100%;
                                        margin: 0 14px 0 0;
                                        @include media991 {
                                            margin: 0 10px 0 0;
                                            width: 32px;
                                            height: 32px;
                                        }
                                    }
                                }
                            }
                            .user-img {
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                margin: 0;
                            }
                        }

                        tr {
                            &:last-child {
                                td {
                                    border-bottom: 0;
                                }
                            }
                        }
                    }
                }
                .lock_view {
                    margin-top: 5px;
                }
            }
        }
    }
    .ranking-banner {
        background-color: rgba(0, 0, 0, 0.77);
        position: absolute;
        width: 100%;
        bottom: 0;
    }
}
// Profil Summary

.personal-info {
    padding: 22px 15px;
    margin-top: 60px;
    background-color: $white;
    display: flex;
    flex-wrap: wrap;
    @include media1366 {
        margin-top: 30px;
    }
    .pi-box {
        padding: 8px 31px;
        border-right: 1px solid #ece9ea;
        width: 25%;
        @include media1366 {
            border-right: none;
            width: 50%;
        }
        @include media575 {
            width: 100%;
        }
        &:first-child {
            padding-left: 0;
            display: flex;
            align-items: center;
        }
        &:last-child {
            // padding-right: 0;
            border-right: none;
        }
        .pi-input {
            &:last-child {
                .form-group {
                    margin-bottom: 0;
                    position: relative;
                    label {
                        display: flex;
                        align-items: center;
                        img {
                            margin: 0;
                            margin-left: 5px;
                        }
                    }
                    .loc-section {
                        position: absolute;
                        width: 23px;
                        height: 23px;
                        right: 6px;
                        top: 54%;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .user-img {
        .media {
            display: flex;
            align-items: center;
            img {
                margin: 0;
                width: 90px;
                height: 90px;
                border-radius: 50%;
                border: 4px solid $white;
            }
            .media-info {
                margin-left: 15px;
                h5 {
                    @include defaultFontStyle(700, 20, 1.2, $black-light);
                    margin-bottom: 10px;
                }
                button {
                    padding: 8px 25px;
                }
                .share-icon {
                    position: relative;
                    .blue_btn {
                        z-index: 2;
                        position: relative;
                        width: 100%;
                        padding: 8px 25px;
                    }
                    ul {
                        list-style: none;
                        display: flex;
                        align-items: center;
                        border: 1px solid $gray-btn;
                        border-radius: 8px;
                        position: absolute;
                        visibility: hidden;
                        opacity: 0;
                        transition: all 0.2s ease 0s;
                        margin-top: 5px;
                        width: 120px;
                        justify-content: center;
                        top: calc(100% - 32px);
                        background-color: $white;
                        li {
                            margin: 3px 5px;
                            list-style: none;
                            display: inline-block;
                            vertical-align: middle;
                            transform: scale(0.92);
                            transition: all 0.5s ease 0s;
                            button {
                                width: 28px;
                                height: 28px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                outline: none;
                                svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            &:hover {
                                transform: scale(1);
                                transition: all 0.5s ease 0s;
                            }
                        }
                    }
                    &.active {
                        ul {
                            top: 100%;
                            visibility: visible;
                            opacity: 1;
                            transition: all 0.2s ease 0s;
                        }
                    }
                }
            }
        }
    }
    .user-info {
        .pi-input {
            input {
                height: 35px;
            }
        }
    }
    // .social-btn{
    //     max-width: 130px;
    //     .btn-social{
    //         padding: 10px 14px;
    //         @include defaultFontStyle(500,14,1.2,$white);
    //         width: 100%;
    //         text-align: left;
    //         max-width: 130px;
    //         i{
    //             width: 20px;
    //             text-align: center;
    //             padding-right: 16px;
    //             margin-right: 10px;
    //             border-right: 1px solid #fbf9fa;
    //         }
    //         &.facebook{
    //             background-color: $facebook;
    //             border: 1px solid;
    //             border-radius: 5px;
    //         }
    //         &.twitter{
    //             background-color: $twitter;
    //             border: 1px solid;
    //             border-radius: 5px;
    //         }
    //     }
    // }
}
.score-info {
    display: flex;
    background-color: $white;
    border-radius: 5px;
    margin-top: 20px;
    flex-wrap: wrap;
    // @include media1429{
    //     display: block;
    // }
    .score-box {
        width: 25%;
        padding: 15px;
        @include media1429 {
            width: 50%;
        }
        @include media767 {
            width: 100%;
        }
        // .input-group {
        //     &:hover{
        //         border: 1px ;
        //     }
        // }
        .score-box-titel {
            @include defaultFontStyle(700, 20, 1.5, $black-light);
            margin-bottom: 10px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .rank-form,
        .team-score,
        .schedule,
        .player-video {
            min-height: 360px;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            border: 1px solid $gray-btn;
            border-radius: 10px;
            padding: 10px;
            @include media767 {
                min-height: auto;
            }
        }
    }
    // .national-rank {
    //     margin: 0 10px;
    //     h3 {
    //         @include defaultFontStyle(700, 20, 1.5, $black-light);
    //         margin-bottom: 10px;
    //         text-align: center;
    //     }
    //     .national-rank-list {
    //         display: flex;
    //         align-items: center;
    //         margin-left: -10px;
    //         margin-right: -10px;
    //         position: relative;
    //         .rank-list {
    //             background: $white-light;
    //             border-radius: 5px;
    //             padding: 0px 15px 30px;
    //             // margin: 0 10px;
    //             width: 50%;
    //             border: 1px solid $green-btn-light;
    //             // &:last-child{
    //             //     margin-right: 0;
    //             // }
    //             h5 {
    //                 @include defaultFontStyle(500, 16, 1.2, $black-light);
    //                 padding: 6px 0;
    //                 text-align: center;
    //             }
    //             .rank-status {
    //                 .form-group {
    //                     margin-bottom: 0;
    //                     .form-control {
    //                         height: 33px;
    //                         color: $light-black;
    //                         text-align: center;
    //                     }
    //                 }
    //             }
    //         }
    //         .loc-section {
    //             z-index: 1;
    //             cursor: pointer;
    //             img {
    //                 width: 35px;
    //             }
    //         }
    //         &.no-lock {
    //             .rank-list {
    //                 &:first-child {
    //                     margin-right: 15px;
    //                 }
    //                 &:last-child {
    //                     margin-left: 15px;
    //                 }
    //             }
    //         }
    //     }
    // }
    // .committed {
    //     h3 {
    //         @include defaultFontStyle(700, 20, 1.5, $black-light);
    //         margin-bottom: 10px;
    //         text-align: center;
    //     }
    //     .national-rank-list {
    //         display: flex;
    //         align-items: center;
    //         // margin-left: -10px;
    //         // margin-right: -10px;
    //         // @include media575 {
    //         //     display: block;
    //         // }
    //         @include media575 {
    //             display: block;
    //         }
    //         &:last-child {
    //             margin-left: -10px;
    //             margin-right: -10px;
    //         }
    //         .title-rank-list {
    //             margin: 0 10px;
    //             width: 50%;
    //             @include media767 {
    //                 width: 50%;
    //             }
    //             @include media575 {
    //                 margin-bottom: 10px;
    //                 width: 100%;
    //                 margin: 0;
    //             }
    //             // .rank-list {
    //             //     padding: 18px 15px;
    //             // }
    //             &:last-child {
    //                 .rank-list {
    //                     padding: 0;
    //                 }
    //             }
    //         }
    //         .rank-list {
    //             background: $white-light;
    //             border-radius: 5px;
    //             padding: 30px 15px;
    //             // margin: 0 10px;
    //             width: 100%;
    //             // &:last-child{
    //             //     margin-right: 0;
    //             // }
    //             .rank-status {
    //                 .form-group {
    //                     margin-bottom: 0;
    //                     .form-control {
    //                         height: 33px;
    //                         color: $light-black;
    //                         text-align: center;
    //                     }
    //                     .calculate-text {
    //                         @include defaultFontStyle(500, 13, 1.4, $black-light);
    //                         margin-top: 6px;
    //                         text-align: center;
    //                         cursor: pointer;
    //                     }
    //                     .input-group {
    //                         display: block;
    //                     }
    //                 }
    //             }
    //             .team-img {
    //                 width: 100px;
    //                 height: 100px;
    //                 padding: 16px;
    //             }
    //         }
    //     }
    // }

    .schedule {
        .schedule-title {
            @include defaultFontStyle(700, 20, 1.5, $black-light);
            margin-bottom: 10px;
            text-align: center;
        }
        .event_box {
            position: relative;
            h3 {
                padding: 0;
                text-align: left;
            }
            .event_card {
                margin-bottom: 0;
                border-radius: 10px;
                border: none;
                &:hover {
                    border: none;
                }
                .event_card_desc {
                    padding: 0px 10px 10px;
                }
                .event_card_img {
                    img {
                        border-radius: 10px 10px 0 0;
                        max-height: 150px !important;
                        min-height: 150px !important;
                    }
                }
            }
        }
        .event_box {
            padding: 5px;
        }
        .slick-prev {
            left: 0;
            z-index: 1;
            opacity: 0;
            background: $light-black;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            text-align: center;
            &:before {
                content: "\f053";
                font-size: 12px;
                font-family: "Font Awesome 5 Free";
                color: $white;
                opacity: 1;
                line-height: 21px;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
            }
            &:hover {
                background-color: var(--green-color);
            }
        }
        .slick-next {
            right: 0;
            z-index: 1;
            opacity: 0;
            background: $light-black;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            text-align: center;
            &:before {
                content: "\f054";
                font-family: "Font Awesome 5 Free";
                font-size: 12px;
                color: $white;
                opacity: 1;
                line-height: 20px;
                position: absolute;
                top: 1px;
                left: 3px;
                right: 0;
            }
            &:hover {
                background-color: var(--green-color);
            }
        }
        &:hover {
            .slick-next {
                opacity: 1;
                right: -8px;
            }
            .slick-prev {
                opacity: 1;
                left: -8px;
            }
        }
        .lock_view {
            border: none;
            padding: 0px 10px 10px;
            .logo_lock {
                //height: 250px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                flex-direction: row;
                justify-content: center;
                .lock_img {
                    position: static;
                    margin-left: 20px;
                }
            }
        }
    }
    .player-video {
        .player-title {
            @include defaultFontStyle(700, 20, 1.5, $black-light);
            margin-bottom: 10px;
            text-align: center;
        }
        .video-react-big-play-button {
            top: 33%;
        }
        .slick-prev {
            left: 0;
            z-index: 1;
            opacity: 0;
            background: $light-black;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            text-align: center;
            &:before {
                content: "\f053";
                font-size: 12px;
                font-family: "Font Awesome 5 Free";
                color: $white;
                opacity: 1;
                line-height: 21px;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
            }
            &:hover {
                background-color: var(--green-color);
            }
        }
        .slick-next {
            right: 0;
            z-index: 1;
            opacity: 0;
            background: $light-black;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            text-align: center;
            &:before {
                content: "\f054";
                font-family: "Font Awesome 5 Free";
                font-size: 12px;
                color: $white;
                opacity: 1;
                line-height: 20px;
                position: absolute;
                top: 1px;
                left: 3px;
                right: 0;
            }
            &:hover {
                background-color: var(--green-color);
            }
        }
        &:hover {
            .slick-next {
                opacity: 1;
                right: -8px;
            }
            .slick-prev {
                opacity: 1;
                left: -8px;
            }
        }
    }
    .rank-form {
        .rank-group {
            display: flex;
            align-items: center;
            margin-right: -7px;
            margin-left: -7px;
            .rank-col {
                width: 100%;
                padding-right: 7px;
                padding-left: 7px;
                &.col-2 {
                    width: 50%;
                }
                &.col-3 {
                    width: 33.33%;
                }
                .form-group {
                    margin-bottom: 10px;
                    position: relative;
                    .loc-section {
                        position: absolute;
                        width: 23px;
                        height: 23px;
                        right: 6px;
                        top: 30%;
                    }
                    label {
                        white-space: nowrap;
                        display: flex;
                        align-items: center;
                        img {
                            cursor: pointer;
                            width: 20px;
                            margin: 0;
                            margin-left: 5px;
                        }
                    }
                    .input-group {
                        border: none;
                        .form-control {
                            border: 1px solid #e5e9f2 !important;
                            padding: 5px;
                            &:first-child {
                                width: 60px;
                                margin-right: 5px;
                            }
                        }
                    }
                    input {
                        height: 35px;
                    }
                }
            }
            .loc-section {
                margin-top: 15px;
                cursor: pointer;
            }
            .lock_view {
                height: 34px;
            }
        }
    }
    .team-score {
        .team-info {
            img {
                width: 90px;
                height: 90px;
                border-radius: 50%;
            }
            h4 {
                @include defaultFontStyle(700, 16, 1.4, $black-color);
                text-align: center;
                margin-top: 15px;
                padding-bottom: 15px;
                position: relative;
                &::before {
                    position: absolute;
                    left: 0;
                    right: 0;
                    content: "";
                    height: 3px;
                    background-color: var(--green-color);
                    width: 28px;
                    bottom: 0;
                    margin: 0 auto;
                }
            }
        }
        .slick-prev {
            left: 0;
            z-index: 1;
            opacity: 0;
            background: $light-black;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            text-align: center;
            &:before {
                content: "\f053";
                font-size: 12px;
                font-family: "Font Awesome 5 Free";
                color: $white;
                opacity: 1;
                line-height: 21px;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
            }
            &:hover {
                background-color: var(--green-color);
            }
        }
        .slick-next {
            right: 0;
            z-index: 1;
            opacity: 0;
            background: $light-black;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            text-align: center;
            &:before {
                content: "\f054";
                font-family: "Font Awesome 5 Free";
                font-size: 12px;
                color: $white;
                opacity: 1;
                line-height: 20px;
                position: absolute;
                top: 1px;
                left: 3px;
                right: 0;
            }
            &:hover {
                background-color: var(--green-color);
            }
        }
        &:hover {
            .slick-next {
                opacity: 1;
                right: -8px;
            }
            .slick-prev {
                opacity: 1;
                left: -8px;
            }
        }

        .form-group {
            margin-top: 15px;
            input {
                height: 35px;
            }
            .input-btn {
                display: flex;
                button {
                    padding: 5px;
                    white-space: nowrap;
                    margin-left: 5px;
                    font-size: 13px;
                }
            }
        }
    }
}
.tab-summary {
    background-color: $white;
    padding: 30px;
    margin-top: 60px;
    border: 1px solid #e1e3e8;
    border-radius: 5px 5px 0 0;
    @include media991 {
        padding: 10px 10px 20px;
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background-color: $white-light;

        li {
            list-style: none;
            text-align: center;
            padding: 19px 10px;
            width: 14.28%;
            min-width: 170px;
            position: relative;
            cursor: pointer;
            a {
                // white-space: nowrap;
                display: flex;
                align-items: center;
                justify-content: center;
                span {
                    &:first-child {
                        margin-right: 10px;
                        height: 20px;
                        width: auto;
                    }
                }
            }
            &.active {
                background-color: var(--green-color-light);
                transition: all 0.5s ease 0s;
                a {
                    color: $white;
                }
                &::before {
                    border: 9px solid transparent;
                    border-top-color: var(--green-color-light);
                    content: "";
                    height: 0;
                    transition: opacity 0.1s cubic-bezier(0.3, 0.5, 0.5, 1);
                    position: absolute;
                    bottom: calc(-8px + -9px);
                    // width: 1px;
                    // left: auto;
                    right: 45%;

                    opacity: 1;
                    transition: all 0.5s ease 0s;
                }

                img {
                    filter: brightness(0) invert(1);
                    transition: all 0.5s ease 0s;
                }
            }
            &:hover {
                background-color: var(--green-color-light);
                transition: all 0.5s ease 0s;
                a {
                    color: $white;
                }
                img {
                    filter: brightness(0) invert(1);
                    transition: all 0.5s ease 0s;
                }
            }
            &.disable {
                background-color: $white-light;
                cursor: not-allowed;
                a {
                    color: #b1afb7;
                    cursor: not-allowed;
                }
                img {
                    filter: brightness(1) invert(0);
                    transition: all 0.5s ease 0s;

                    margin-left: 5px;
                }
                .lock-img {
                    cursor: pointer;
                }
                .info-tooltip {
                    height: auto;
                    cursor: pointer;
                }
            }
        }
    }
}
.tab-summary-content {
    background-color: $white;
    padding: 30px;
    border: 1px solid #e1e3e8;
    border-radius: 0 0 5px 5px;
    border-top: none;
    margin-bottom: 60px;
    @include media991 {
        padding: 20px 10px 10px;
        margin-bottom: 30px;
    }
    .summary-content {
        display: flex;
        margin: 0 -15px;
        margin-bottom: 40px;
        @include media991 {
            margin: 0 -5px;
        }
        @include media767 {
            display: block;
        }
        .section_title {
            @include defaultFontStyle(700, 24, 1.4, $black);
            margin-bottom: 30px;
            @include media1366 {
                font-size: 20px;
            }
        }
        .event-result {
            width: 25%;
            min-width: 165px;
            margin: 0 15px;
            background-color: $white-light;
            padding: 30px;
            @include media1366 {
                padding: 10px;
            }
            @include media991 {
                padding: 10px;
                margin: 0 5px;
            }
            @include media767 {
                width: 100%;
                margin: 0;
                padding: 10px;
            }

            ul {
                li {
                    list-style: none;
                    background-color: $white;
                    border-radius: 5px;
                    padding: 20px 10px;
                    margin-bottom: 5px;
                    text-align: center;

                    h3 {
                        @include media1366 {
                            font-size: 18px;
                        }
                    }
                    p {
                        margin-bottom: 0;
                        @include media1366 {
                            font-size: 13px;
                        }
                    }
                }
            }
            &.pw-event-result {
                h5 {
                    @include defaultFontStyle(700, 18, 1.4, $black);
                    text-align: center;
                    margin-bottom: 10px;
                }
                ul {
                    margin-bottom: 20px;
                    li {
                        padding: 10px;
                        h3 {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .to-state {
            width: 50%;
            margin: 0 15px;
            background-color: $white-light;
            padding: 30px;
            @include media1366 {
                padding: 10px;
            }
            @include media991 {
                margin: 0 5px;
                padding: 10px;
            }
            @include media767 {
                width: 100%;
                margin: 0;
                padding: 10px;
            }

            .section_title {
                margin-bottom: 8px;
            }
            .list-group {
                width: 50%;
                margin: auto;
            }
            .table-responsive-xl {
                display: block;
                width: 100%;
                overflow-x: auto;
            }
            .to-state-table {
                width: 100%;
                border-collapse: separate;
                margin-top: 7px;

                th {
                    background-color: $white;
                    padding: 15px;
                    border-bottom: 5px solid $white-light;
                    @include defaultFontStyle(500, 20, 1.2, $black);
                    @include media1366 {
                        font-size: 16px;
                        padding: 10px;
                    }
                }
                td {
                    background-color: $white;
                    padding: 15px;
                    border-bottom: 5px solid $white-light;
                    @include defaultFontStyle(400, 16, 1.2, $light-black);
                    &:first-child {
                        color: $black;
                        min-width: 175px;
                    }
                    &:last-child {
                        min-width: 350px;
                    }
                    @include media1366 {
                        font-size: 14px;
                        padding: 10px;
                    }
                }
                .logo_lock {
                    height: 287px;
                    .lock_img {
                        width: auto;
                        bottom: 3%;
                    }
                }
            }
        }
    }
    .PW-scouting-feed {
        width: 100%;
        background: $white-light;
        border-radius: 5px;
        margin-right: 23px;
        // padding: 30px;
        margin-bottom: 25px;
        @include media767 {
            width: 100%;
            margin-right: 0;
        }
        .section_title {
            font-size: 24px;
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 30px 30px 15px;
        }
        .scouting-heading {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            background: #ffffffcc;
            border: 1px solid rgba(108, 123, 149, 0.2);
            box-sizing: border-box;
            padding: 14px 30px;
            cursor: pointer;

            .scouting-title {
                display: flex;
                align-items: center;
                @include defaultFontStyle(700, 20, 1.2, $black-light);
                @include customMedia(1024) {
                    padding: 10px;
                    font-size: 18px;
                }
                @include media991 {
                    padding: 10px;
                }
                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 100%;
                    margin: 0 14px 0 0;
                    object-fit: cover;
                }
            }
            .scouting-btn {
                padding: 0 10px;

                &.pw_scouting_btn > button {
                    margin: 0 !important;
                }
                .btn {
                    padding: 10px 30px;
                    &:first-child {
                        margin-right: 20px;
                    }
                    &:last-child {
                        margin-left: 20px;
                    }
                }
            }
        }
        .scouting-feed-media {
            margin-top: 15px;
            height: 585px;
            overflow-x: auto;
            padding: 10px 30px 0;
            @include customMedia(1024) {
                padding: 10px;
            }
            .media {
                display: flex;
                margin-bottom: 20px;
                img {
                    border-radius: 100%;
                    width: 45px;
                    height: 45px;
                    object-fit: cover;
                    margin: 0 14px 0 0;
                    @include customMedia(1024) {
                        width: 40px;
                        height: 40px;
                    }
                }
                .media-contant {
                    h4 {
                        @include defaultFontStyle(500, 20, 1.2, $black-light);
                        padding-bottom: 10px;
                        @include customMedia(1024) {
                            font-size: 18px;
                        }
                    }
                    .media-type {
                        // background: $white;
                        border-radius: 10px 10px 10px 0px;
                        .text {
                            padding: 10px;
                            @include defaultFontStyle(500, 13, 1.2, $light-black);
                            margin: 0;
                            background: $white;
                            span {
                                color: $green-btn;
                                // display: -webkit-box;
                                // -webkit-line-clamp: 1;
                                // -webkit-box-orient: vertical;
                                // overflow: hidden;
                                // max-width: 50px;
                                // margin-right: 3px;
                                // white-space: nowrap;
                                // text-overflow: ellipsis;
                                display: inline-block;
                                // margin-bottom: -2px;
                            }
                            b {
                                color: $green-btn;
                            }
                        }
                        .media-img {
                            img {
                                border-radius: 10px 10px 10px 0px;
                                width: 193px;
                                height: 148px;
                                object-fit: cover;
                                margin: 0;
                            }
                        }
                        .media-audio {
                            audio {
                                padding: 5px;
                                max-width: 100%;
                            }
                        }
                        .media-file {
                            .file-formate {
                                background: $green-color;
                                border-radius: 10px 10px 10px 0px;
                                padding: 5px;
                                display: flex;
                                align-items: center;
                                .file-icon {
                                    background: $white;
                                    border-radius: 8px;
                                    width: 55px;
                                    height: 55px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    img {
                                        width: 26px;
                                        height: 30px;
                                        border-radius: 0;
                                        margin: 0;
                                    }
                                }
                                .file-name {
                                    margin-left: 12px;
                                    width: calc(100% - 55px);
                                    h4 {
                                        @include defaultFontStyle(500, 16, 1.2, $white);
                                        padding-bottom: 5px;
                                        word-break: break-word;
                                    }
                                    p {
                                        @include defaultFontStyle(400, 12, 1.2, $white);
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .Paginate {
            background: $white;
            padding: 20px;
            border: 1px solid rgba(108, 123, 149, 0.2);
            border-radius: 0 0 6px;
            text-align: center;
        }

        .pagination {
            display: inline-block;
            background-color: #fff;
            border-radius: 8px;

            li {
                @include defaultFontStyle(500, 16, 1.2, $black);
                display: block;
                float: left;
                //padding: 13px 0px;
                text-decoration: none;

                a {
                    padding: 9px 14px;
                    background: #f8f6f7;
                    border-right: 1px solid #eef1f6;
                }
                &:last-child {
                    border-right: none;
                }
                &:hover {
                    background-color: #ddd;
                }
                &.active {
                    a {
                        background-color: var(--green-color);
                        color: white;
                    }
                }
            }
        }
    }
    .event-report-content {
        .event-report {
            .event-report-filter {
                display: flex;
                justify-content: center;
                width: 100%;
                border-bottom: 1px solid #e1e3e8;
                @include media767 {
                    display: block;
                }
                .custom-checkbox {
                    background: $white-light;
                    padding: 12px 15px 13px;
                    border-radius: 3px;
                    label {
                        @include defaultFontStyle(500, 14, 0, $light-black);
                        display: block;
                        position: relative;
                        padding-left: 30px;
                        margin-bottom: 0px;
                        line-height: 20px;
                        cursor: pointer;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                    }
                    label input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                    }
                    .checkmark {
                        position: absolute;
                        top: 0px;
                        left: 0;
                        height: 20px;
                        width: 20px;
                        background-color: var(--green-color-light);
                        border-radius: 4px;
                    }
                    label:hover input ~ .checkmark {
                        background-color: var(--green-color-light);
                    }
                    label input:checked ~ .checkmark {
                        background-color: var(--green-color-light);
                        border-radius: 3px;
                    }
                    .checkmark:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }
                    label input:checked ~ .checkmark:after {
                        display: block;
                    }
                    label .checkmark:after {
                        left: 7px;
                        top: 3px;
                        width: 6px;
                        height: 12px;
                        border: solid $white;
                        border-width: 0 3px 3px 0;
                        transform: rotate(45deg);
                    }
                }
                .form-group {
                    max-width: 100%;
                    width: 100%;
                    margin-right: 30px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            .event-report-group {
                display: flex;
                margin-top: 30px;
                @include media767 {
                    display: block;
                }

                .event-blog {
                    width: 50%;
                    background: $white-light;
                    border-radius: 5px;
                    padding: 30px 25px;
                    margin-bottom: 25px;
                    height: 896px;
                    overflow-y: auto;

                    @include customMedia(1024) {
                        padding: 10px;
                    }
                    @include media767 {
                        width: 100%;
                    }
                    .event_box {
                        width: 50%;
                        padding: 0 8px;
                        @include media1429 {
                            width: 100%;
                        }
                        .event_card {
                            .event_card_img {
                                img {
                                    border-radius: 5px 5px 0 0;
                                }
                            }
                        }
                    }
                }
                ::-webkit-scrollbar-track {
                    background-color: $light-color;
                    border-radius: 5px;
                }
                ::-webkit-scrollbar {
                    // height: 5px;
                    width: 5px;
                    border-radius: 5px;
                    background-color: $light-color;
                }
                ::-webkit-scrollbar-thumb {
                    background-color: $light-black;
                    border: 2px solid $light-black;
                    border-radius: 5px;
                    &:hover {
                        background-color: $light-black;
                        border: 2px solid $light-black;
                    }
                }
            }
        }
    }
    .state-content {
        .list-group {
            width: 30%;
            margin: auto;
            margin-bottom: 40px;
            @include media991 {
                margin-bottom: 10px;
            }
        }
        .pitching-state {
            background-color: $white-light;
            border-radius: 10px;
            padding: 25px;
            margin: 40px 0;
            @include media991 {
                padding: 10px;
                margin: 10px 0;
            }
            .section_title {
                font-size: 30px;
                @include media767 {
                    font-size: 24px;
                }
            }
        }
        table {
            width: 100%;
            border-collapse: separate;
            margin-top: 30px;
        }
        thead {
            th {
                &:first-child {
                    background-color: transparent;
                }
            }
        }
        th {
            background-color: $white;
            padding: 15px;
            border-bottom: 5px solid $white-light;
            @include defaultFontStyle(500, 18, 1.2, $black);
            text-align: center;
            @include customMedia(1024) {
                font-size: 16px;
            }
        }
        td {
            background-color: $white;
            padding: 15px;
            border-bottom: 5px solid $white-light;
            @include defaultFontStyle(400, 16, 1.2, $light-black);
            img {
                display: inline-block;
                margin-left: 10px;
            }
        }
    }
    .awards-content {
        .awards-filter {
            display: flex;
            justify-content: center;
            padding-bottom: 20px;
            @include media575 {
                display: block;
            }
            .bc_relative {
                margin: 0 10px;
                @include media575 {
                    margin: 0;
                    &:first-child {
                        margin-bottom: 15px;
                    }
                }
                input {
                    background-color: $white-light;
                }
            }
        }
        .award-table {
            background-color: $white-light;
            padding: 20px;
            border-radius: 10px;
            @include media991 {
                padding: 10px;
            }
            table {
                width: 100%;
                border-collapse: separate;
                white-space: nowrap;
            }
            th {
                background-color: $white;
                padding: 15px;
                border-bottom: 5px solid $white-light;
                @include defaultFontStyle(500, 20, 1.2, $black);
                @include media991 {
                    font-size: 16px;
                    padding: 10px;
                }
            }
            td {
                background-color: $white;
                padding: 15px;
                border-bottom: 5px solid $white-light;
                @include defaultFontStyle(400, 16, 1.2, $light-black);
                @include media991 {
                    font-size: 15px;
                    padding: 10px;
                }

                img {
                    display: inline-block;
                    margin-left: 10px;
                    width: 32px;
                }
            }
        }
    }
    .media-content {
        display: flex;
        @include media767 {
            display: block;
        }
        .player-list {
            width: 50%;
            background: $white-light;
            border-radius: 5px;
            //padding: 30px 25px;
            margin-bottom: 25px;
            margin-right: 23px;
            border-radius: 0px 0px 10px 10px;
            @include customMedia(1024) {
                padding: 10px;
            }
            @include media767 {
                width: 100%;
                margin-right: 0;
            }
            .section_title {
                font-size: 24px;
                margin-bottom: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 30px 25px 15px;
            }
            .player-list-group {
                height: 620px;
                overflow-x: auto;
                padding: 0px 25px;
                .player-list-item {
                    background: $white;
                    border-radius: 5px;
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 16px;
                    // &:last-child {
                    //     margin-bottom: 0;
                    // }
                    .player-profile {
                        width: 88px;
                        height: 88px;
                        margin-right: 18px;
                        @include customMedia(1024) {
                            margin-right: 10px;
                        }
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 5px;
                        }
                    }
                    .media-player-info {
                        width: calc(100% - 88px);
                        h4 {
                            @include defaultFontStyle(500, 20, 1.3, $black-light);
                            padding-bottom: 10px;
                            @include customMedia(1024) {
                                font-size: 18px;
                            }
                        }
                        p {
                            @include defaultFontStyle(400, 15, 1.2, $light-black);
                        }
                    }
                }
            }
        }
        .PW-scouting-feed {
            margin-right: 0;
            border-radius: 0px 0px 10px 10px;
            width: 50%;
            @include customMedia(767) {
                width: 100%;
            }
            .scouting-heading {
                cursor: auto;
            }
            .scouting-feed-media {
                height: 620px;
                overflow: auto;
            }
        }
        .lock_img {
            width: auto;
            bottom: 3%;
        }
    }
    .gallery-report-filter {
        display: flex;
        align-items: center;
        // justify-content: center;
        width: 100%;
        border-bottom: 1px solid #e1e3e8;
        margin-bottom: 30px;
        @include media767 {
            display: block;
        }
        .filter-text {
            max-width: 13%;
            width: 100%;
            margin-bottom: 15px;
            margin-right: 30px;
            @include media767 {
                max-width: 100%;
            }
            h3 {
                @include defaultFontStyle(500, 20, 1.3, $black-light);
                text-align: center;
            }
        }

        .form-group {
            max-width: 100%;
            width: 100%;
            margin-right: 30px;
            &:last-child {
                margin-right: 0;
            }
            &.select-box {
                max-width: 360px;
                @include media767 {
                    max-width: 100%;
                }
            }
        }
    }
    .gallery-content {
        background-color: $white-light;
        padding: 30px;
        @include media991 {
            padding: 10px;
        }
        .gallary-video {
            display: flex;
            flex-wrap: wrap;
            margin-left: -15px;
            margin-right: -15px;
            padding-top: 25px;
            .video-box {
                flex: 0 0 auto;
                width: 25%;
                padding-right: 15px;
                padding-left: 15px;
                margin: 15px 0;
                @include media1199 {
                    flex: 0 0 auto;
                    width: 50%;
                }
                @include media575 {
                    flex: 0 0 auto;
                    width: 100%;
                }
                .video-react-big-play-button {
                    width: 50px;
                    height: 50px;
                    transform: translate(0px, -30px);
                    &::before {
                        top: 0;
                    }
                }
                .video-react-video {
                    border-radius: 5px;
                }

                .counter_text {
                    position: relative;
                    @include defaultFontStyle(500, 15, 1.5, $black-color);
                    align-items: center;
                    h3 {
                        @include defaultFontStyle(700, 20, 1.5, $black-color);
                        margin-top: 12px;
                        padding-right: 65px;
                        @include customMedia(1024) {
                            font-size: 16px;
                        }
                    }
                    p {
                        position: absolute;
                        top: 6px;
                        right: 3px;
                        color: $black-color;
                        span {
                            background: #e3e3e3;
                            border-radius: 100%;
                            padding: 4px 6px 3px;
                            margin-right: 6px;
                            @include defaultFontStyle(700, 12, 1.1, $black-color);
                        }
                    }
                }
            }
        }
    }
    .infocontact-content {
        .section_title {
            font-size: 24px;
        }
        .social-profile-content {
            border-bottom: 1px solid #e1e3e8;
            padding-bottom: 30px;
            .social-profile {
                background: $white-light;
                border-radius: 5px;
                padding: 30px;
                @include media1366 {
                    padding: 20px;
                }
                @include media991 {
                    padding: 10px;
                }
                .social-icon-group {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    margin-left: -10px;
                    margin-right: -10px;
                    .social-icon-list {
                        width: 25%;
                        padding: 10px 10px;
                        position: relative;
                        @include media991 {
                            width: 50%;
                        }
                        @include media575 {
                            width: 100%;
                        }
                        .social-icon {
                            background: $white;
                            border-radius: 10px;
                            padding: 6px;
                            @include defaultFontStyle(700, 16, 1.2, $black-light);
                            display: flex;
                            align-items: center;
                            word-break: break-word;
                            img {
                                width: 52px;
                                height: 52px;
                                margin: 0 15px 0 0;
                                @include customMedia(1024) {
                                    width: 40px;
                                    height: 40px;
                                    margin: 0 10px 0 0;
                                }
                            }
                        }
                        .form-group {
                            display: flex;
                            align-items: center;
                            padding: 3px 10px;
                            position: absolute;
                            left: 0;
                            width: 100%;
                            z-index: 9;
                            input {
                                border-radius: 4px 0 0 4px;
                                height: 35px;
                                padding: 5px;
                            }
                            button {
                                padding: 5px;
                                border-radius: 0 4px 4px 0;
                                height: 35px;
                            }
                        }
                    }
                }
            }
        }
        .personal-details-content {
            margin-top: 30px;
            .personal-details {
                background: $white-light;
                border-radius: 5px;
                padding: 30px;
                @include media991 {
                    padding: 10px;
                }
                .personal-details-group {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    // justify-content: center;
                    margin-left: -10px;
                    margin-right: -10px;
                    // border-bottom: 1px solid #e1e3e8;
                    // padding-bottom: 30px;
                    // margin-bottom: 30px;
                    &:last-child {
                        border-bottom: 0;
                        padding-bottom: 0;
                        margin-bottom: 0;
                    }
                    .personal-details-list {
                        width: 33.3%;
                        padding: 10px 10px;
                        @include media991 {
                            width: 50%;
                        }
                        @include media675 {
                            width: 100%;
                        }
                        .personal-item {
                            background: $white;
                            border-radius: 10px;
                            padding: 6px;
                            @include defaultFontStyle(700, 16, 1.2, $black-light);
                            display: flex;
                            align-items: center;
                            word-break: break-word;
                            img {
                                width: 52px;
                                height: 52px;
                                margin: 0 15px 0 0;
                                @include customMedia(1024) {
                                    width: 40px;
                                    height: 40px;
                                    margin: 0 10px 0 0;
                                }
                            }
                        }
                    }
                    &.coach-detail {
                        background-color: #f1f1f1;
                        border-radius: 10px;
                        margin-bottom: 10px;
                    }
                }
                .edit-icon {
                    display: inline-block;
                    img {
                        width: 25px;
                        margin-right: 10px;
                    }
                }
                .delete-icon {
                    display: inline-block;
                    img {
                        width: 25px;
                    }
                }
                .border-part {
                    height: 1px;
                    border-bottom: 1px solid #e1e3e8;
                    padding-bottom: 30px;
                    margin-bottom: 30px;
                }
            }
            .edit-50 {
                background-color: $white;
                display: flex;
                flex-wrap: wrap;
                margin: 15px -15px 0 -15px;
                padding-bottom: 15px;
                @include media767 {
                    display: block;
                }
                .w-50 {
                    flex: 0 0 50%;
                    max-width: 50%;
                    padding: 0 15px;
                    @include media767 {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
                .check_box {
                    display: -webkit-flex;
                    display: flex;
                    -webkit-align-items: center;
                    align-items: center;
                    -webkit-justify-content: space-between;
                    justify-content: space-between;
                }
                .serch-box {
                    position: relative;
                    .form-control {
                        padding-right: 50px;
                    }
                    .serch-btn {
                        position: absolute;
                        height: 45px;
                        width: 65px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: $blue;
                        color: $white;
                        top: 0;
                        right: 0;
                        border-radius: 0 4px 4px 0;
                        @include media480 {
                            height: 40px;
                        }
                        &:hover {
                            opacity: 0.9;
                        }
                        &.input-email-btn {
                            width: 140px;
                            background: $green-btn;
                            @include media991 {
                                position: static;
                                border-radius: 4px;
                                margin-top: 8px;
                            }
                        }
                    }
                    span.tooltip {
                        visibility: visible;
                        width: 120px;
                        background-color: $gra-dark;
                        color: $white;
                        text-align: center;
                        border-radius: 6px;
                        padding: 5px 0;
                        position: absolute;
                        z-index: 1;
                        bottom: 112%;
                        right: -37px;
                        opacity: 1;
                        transition: opacity 0.5s;
                        &:after {
                            content: "";
                            position: absolute;
                            top: 100%;
                            left: 50%;
                            margin-left: -5px;
                            border-width: 5px;
                            border-style: solid;
                            border-color: $gra-dark transparent transparent transparent;
                        }
                    }
                    > div {
                        width: 100%;
                        > input,
                        input:active,
                        input:focus {
                            box-shadow: none;
                            height: 45px;
                            width: 100%;
                            padding: 10px;
                            border: 1px solid $gray-btn;
                            border-radius: 4px;
                            @include defaultFontStyle(500, 16, 1.2, $black-color);
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                            @include media480 {
                                height: 40px;
                                @include defaultFontStyle(400, 14, 1.2, $black-color);
                            }
                            &:hover,
                            &:visited,
                            &:active,
                            &:focus {
                                border: 1px solid $light-black;
                                color: $black-color;
                            }
                            &:disabled {
                                background: $gray-btn;
                                border: 1px solid transparent;
                            }
                        }
                        .inner-option {
                            display: block;
                            margin-bottom: 10px;
                            font-size: 14px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                .otherbrand-logo {
                    display: flex;
                    align-items: center;
                    padding: 15px;
                    flex-wrap: wrap;
                    // border-bottom: 1px solid $gray-btn;
                    height: 182px;
                    overflow: auto;

                    .brand-logo-inner {
                        padding: 0 10px;
                        padding-left: 0;
                        .brand-logo-main {
                            border: 1px solid $gray-btn;
                            display: flex;
                            margin: 0 0 15px;
                            align-items: center;
                            flex-wrap: wrap;
                            border-radius: 8px;
                            height: 70px;
                            width: 105px;
                            @include media1199 {
                                width: 120px;
                            }
                            @include media767 {
                                width: 90px;
                                height: 60px;
                                margin: 0 0 10px;
                            }
                            @include media575 {
                                width: 110px;
                                height: 70px;
                            }
                            @include media480 {
                                height: 60px;
                                width: 90px;
                            }
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                }
            }
            .flex-align {
                padding: 20px 0;
                border-top: 1px solid #e5e9f2;
                width: 100%;
                margin: 20px auto;
            }
            .btn {
                margin: 0 15px;
                width: 150px;
            }
        }
        .lock_view {
            margin-top: 15px;
        }
    }
}
.avg-table {
    background-color: $white-light;
    padding: 15px 30px 30px;
    margin: 40px 0;
    @include media991 {
        padding: 10px;
    }
    @include media767 {
        padding: 10px;
    }
    .avg-table-logo {
        padding-bottom: 15px;
        margin-bottom: 30px;
        border-bottom: 1px solid #e1e3e8;
    }
    table {
        width: 100%;
        border-collapse: separate;
    }
    th {
        background-color: $white;
        padding: 15px;
        border-bottom: 5px solid $white-light;
        @include defaultFontStyle(500, 20, 1.2, $black);
        min-width: 200px;
        @include customMedia(1024) {
            font-size: 16px;
            padding: 10px;
        }
    }
    td {
        background-color: $white;
        padding: 15px;
        border-bottom: 5px solid $white-light;
        @include defaultFontStyle(400, 16, 1.2, $light-black);
        @include customMedia(1024) {
            font-size: 14px;
            padding: 10px;
        }
        > img {
            display: inline-block;
            margin-left: 10px;
        }
    }
    .lock_view {
        border: none;
    }
}
.to-state-new-table {
    margin: 0;
    thead {
        tr {
            th {
                min-width: 100px;
                &:nth-child(1) {
                    min-width: 200px;
                }
                &:nth-child(2) {
                    min-width: 200px;
                }
                // &:nth-child(1){
                //     position: -webkit-sticky;
                //     position: sticky;
                //     background-color: white;
                //     left: 0px;
                // }
            }
        }
    }
    tbody {
        tr {
            td {
                padding: 8px 15px;
                line-height: 20px;
                // &:nth-child(1){
                //     position: -webkit-sticky;
                //     position: sticky;
                //     background-color: white;
                //     left: 0px;
                // }
            }
        }
    }
}
// advancedSearch-section
.advancedsearch-section {
    padding: 60px 0 35px;
    @include media1366 {
        padding: 30px 0 35px;
    }
    .advanced-filter {
        background: $white;
        border-radius: 10px;
        padding: 20px;
        display: flex;
        // flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        @include media767 {
            display: block;
            justify-content: center;
            padding: 10px;
        }
        .fiter-list {
            //margin-right: 15px;
            .list-group {
                .list-group-item {
                    padding: 10px 25px;
                }
            }
        }
        .fiter-input {
            max-width: 400px;
            width: 100%;
            @include media767 {
                max-width: 100%;
                margin-top: 15px;
            }
            .player-search {
                position: relative;
                input {
                    width: 100%;
                    border: transparent;
                    padding: 15px 24px;
                    border-radius: 10px;
                    background: #f0eeefeb;
                }
                .search-icon {
                    position: absolute;
                    top: -7px;
                    right: -7px;
                    padding: 15px;
                    transform: translate(-8px, 7px);
                    border-radius: 0 10px 10px 0;
                    background-color: var(--green-color-light);
                    width: 50px;
                    cursor: pointer;
                }
            }
            .export-print {
                text-align: right;
                mask-type: 10px;
                a {
                    min-width: 75px;
                    padding: 12px;
                    font-size: 14px;
                    margin: 0 5px;
                }
            }
        }
    }
    .advanced-event {
        background-color: $white;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        @include media767 {
            display: block;
        }
        .img-event {
            width: 30%;
            img {
                width: 428px;
                height: 200px;
                object-fit: cover;
            }
            @include media767 {
                width: 100%;
            }
        }
        .info-event {
            width: 30%;
            @include media767 {
                width: 100%;
            }
            .event_card_desc {
                padding: 12px 10px 10px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                position: relative;
                .event_card_logo {
                    margin-right: 12px;
                    width: 78px;
                    height: 78px;
                    padding: 8px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                }
                .event_card_titles {
                    width: calc(100% - 90px);
                    h3 {
                        @include defaultFontStyle(600, 16, 1.2, $black-light);
                        margin-bottom: 10px;
                        margin-top: 12px;
                    }
                }
                .event_card_dates {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    // justify-content: space-between;
                    p {
                        position: relative;
                        padding-right: 20px;
                        margin-bottom: 5px;
                        padding-left: 26px;
                        &.date_icon {
                            &:before {
                                position: absolute;
                                content: "";
                                background: url("../../../src/assets/images/comapnylanding/date_icon.svg") no-repeat;
                                width: 15px;
                                height: 16px;
                                background-size: cover;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                        &.location_icon {
                            &:before {
                                position: absolute;
                                content: "";
                                background: url("../../../src/assets/images/comapnylanding/location_icon.svg") no-repeat;
                                width: 14px;
                                height: 18px;
                                background-size: cover;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                        &.team_icons {
                            &:before {
                                position: absolute;
                                content: "";
                                background: url("../../../src/assets/images/comapnylanding/user_icons.svg") no-repeat;
                                width: 19px;
                                height: 15px;
                                background-size: cover;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                            &.showSpotAvail {
                                font-weight: bold;
                                color: #221f34;
                            }
                        }
                    }
                }
                .event_tags {
                    position: absolute;
                    right: 5px;
                    top: 3px;
                    @include media767 {
                        top: 1px;
                    }
                    span {
                        background: var(--green-color);
                        display: inline-block;
                        padding: 2px 8px;

                        @include defaultFontStyle(400, 12, 1.2, $white);
                    }
                }
            }
        }
        .text-event {
            width: 40%;
            @include media767 {
                width: 100%;
            }
            .text {
                margin: 0;
                padding: 20px;
                max-height: 110px;
                overflow-y: auto;
            }
        }
    }
    .search-data {
        .data-section {
            display: flex;
            @include media767 {
                display: block;
            }
            .side-filter {
                width: 30%;
                border-radius: 10px;
                margin-right: 30px;
                margin-bottom: 30px;
                @include media767 {
                    width: 100%;
                    margin-right: 0;
                }
                .white-bg {
                    padding: 20px;
                    box-shadow: unset;
                    @include media767 {
                        padding: 10px;
                    }
                    .awards_group {
                        h2 {
                            @include defaultFontStyle(500, 26, 1.3, $black-light);
                            text-align: center;
                            border-bottom: 3px solid #c4c4c4;
                            padding-bottom: 5px;
                            @include media991 {
                                font-size: 28px;
                            }
                        }
                        .awards_list {
                            margin-top: 17px;
                            .awards_item {
                                display: flex;
                                align-items: center;
                                margin-bottom: 10px;
                                padding: 5px;
                                &:hover {
                                    background: $gray-light;
                                }
                                .awards_img {
                                    width: 40px;
                                    height: 40px;
                                    img {
                                        border-radius: 100%;
                                        border: 5px solid #c4c4c4;
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                                .awards_info {
                                    cursor: pointer;
                                    @include defaultFontStyle(500, 16, 1.2, $black-light);
                                    padding-left: 20px;
                                    width: calc(100% - 40px);
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    @include media991 {
                                        padding-left: 10px;
                                        font-size: 14px;
                                    }
                                    @include media767 {
                                        padding-left: 20px;
                                        font-size: 18px;
                                    }
                                    span {
                                        &:first-child {
                                            width: calc(100% - 50px);
                                            // width: 23%;
                                            // text-align: right;
                                        }
                                        img {
                                            cursor: default;
                                            display: inline-block;
                                            margin-left: 15px;
                                            width: 32px;
                                            transition: 0.3s;
                                        }
                                    }
                                    .zoom_img {
                                        &:hover {
                                            transform: scale(5);
                                            transition: 0.3s;
                                            @include media767 {
                                                transform: scale(5) translate(-10px, 7px);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .advanced-filter-collaps {
                    margin-bottom: 10px;
                    .collapsible {
                        background: #e0dcde80;
                        border-radius: 10px;
                        @include defaultFontStyle(500, 16, 1.2, $black-light);
                        cursor: pointer;
                        padding: 15px 25px;
                        width: 100%;
                        border: 1px solid #e0dcde80;
                        text-align: left;
                        outline: none;
                        @include media991 {
                            padding: 10px;
                            font-size: 14px;
                        }
                        &:after {
                            content: "\002B";
                            color: $light-black;
                            font-weight: bold;
                            float: right;
                            margin-left: 5px;
                        }
                    }
                    .content {
                        overflow: hidden;
                        transition: max-height 0.2s ease-out;
                        border: 1px solid #e0dcde80;
                        border-radius: 0 0 10px 10px;
                        padding: 5px 15px 5px 25px;
                        display: none;
                        transition: 2s;
                        max-height: 200px;
                        overflow-y: auto;
                        @include media991 {
                            padding: 10px;
                        }
                    }
                    &.active {
                        .collapsible {
                            border-radius: 10px 10px 0 0;
                            border-bottom: 0;
                            &:after {
                                content: "\2212";
                            }
                        }
                        .content {
                            display: block;
                            transition: 2s;
                            border-top: 0;
                        }
                    }
                }
                .checkbox {
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin: 15px 0;
                    display: flex;
                    justify-content: space-between;
                    @include media767 {
                        margin: 10px 0;
                    }
                    input {
                        position: absolute;
                        opacity: 0;
                        left: 0;
                        right: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        z-index: 1;
                        cursor: pointer;
                    }
                    .square {
                        position: relative;
                        width: 20px;
                        height: 20px;
                        border-radius: 4px;
                        transition: all 0.5s ease 0s;
                        background-color: $white;
                        border: 1px solid $light-black;
                        margin-left: 12px;
                    }
                    .square:after {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 3px;
                        left: 5px;
                        width: 5px;
                        height: 10px;
                        border: solid $white;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                    }

                    label {
                        @include defaultFontStyle(400, 14, 1.2, $light-black);
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: calc(100% - 20px);
                    }
                    input:checked ~ .square::after {
                        opacity: 1;
                    }
                    input:checked ~ .square {
                        background-color: var(--green-color-light);
                        border: 1px solid var(--green-color-light);
                    }
                }
                .see-more-btn {
                    padding: 0;
                    max-width: 110px;
                    width: 100%;
                }
            }
            .advanced-data-table {
                width: 70%;
                background: $white;
                border-radius: 10px;
                padding: 20px;
                margin-bottom: 30px;
                @include media767 {
                    width: 100%;
                    padding: 10px;
                }
                .table-responsive-xl {
                    overflow: auto;
                }
                .table {
                    //border-collapse: separate;
                    border: 1px solid #e0dcde;
                    @include media767 {
                        white-space: nowrap;
                    }
                    thead {
                        background-color: $white-light;
                        th {
                            @include defaultFontStyle(500, 14, 1.2, $light-black);
                            padding: 18px 10px;
                            text-align: center;
                            min-width: 20px;
                            border: none;
                            border-right: 1px solid #e0dcde;
                            border-bottom: 4px double #e0dcde;
                            vertical-align: middle;
                            white-space: nowrap;
                            cursor: pointer;
                            &:first-child {
                                border-radius: 8px 0 0 0;
                            }
                            &:last-child {
                                border-radius: 0 8px 0 0;
                                border-right: none;
                            }

                            &:nth-child(2) {
                                min-width: 300px;
                                text-align: left;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                            }
                            // &:nth-child(4) {
                            //     background: $green-btn-light;
                            //     color: $white;
                            //     min-width: 70px;
                            //     div {
                            //         display: flex;
                            //         align-items: center;
                            //         white-space: nowrap;
                            //         img {
                            //             margin-left: 5px;
                            //         }
                            //     }
                            // }
                        }
                    }
                    tbody {
                        background-color: $white-light;
                        td {
                            @include defaultFontStyle(400, 14, 1.2, $black-light);
                            padding: 10px;
                            border: none;
                            border-right: 1px solid #e0dcde;
                            border-bottom: 1px solid #e0dcde;

                            &:first-child {
                                color: #6c7b95;
                            }
                            &:last-child {
                                border-right: none;
                            }
                            // &:nth-child(4) {
                            //     background: $green-btn-light;
                            //     color: $white;
                            // }
                            &.team-name {
                                @include defaultFontStyle(500, 16, 1.2, $black-light);
                                .athlete-user-info {
                                    display: flex;
                                    align-items: center;
                                    img {
                                        width: 100px;
                                        height: 100px;
                                        margin: 0 14px 0 0;
                                        object-fit: cover;
                                    }
                                    .athlete-info {
                                        h3 {
                                            @include defaultFontStyle(500, 16, 1.2, $black-light);
                                            padding-bottom: 8px;
                                            cursor: pointer;
                                        }
                                        p {
                                            @include defaultFontStyle(400, 14, 1.2, $light-black);
                                            display: flex;
                                            flex-wrap: wrap;
                                            margin: 0;
                                            span {
                                                border-right: 1px solid $light-black;
                                                padding: 3px 10px;
                                                &:first-child {
                                                    padding-left: 0;
                                                }
                                            }
                                        }
                                        h6 {
                                            @include defaultFontStyle(400, 14, 1.2, $light-black);
                                            padding-top: 8px;
                                            b {
                                                color: $black-light;
                                                font-weight: 400;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .text-event {
                    @include media767 {
                        width: 100%;
                    }
                    .text {
                        margin: 0;
                        padding: 20px 0;
                        max-height: 500px;
                        overflow-y: auto;
                    }
                    ol {
                        list-style-type: decimal;
                        padding-left: 18px;
                    }
                    ul {
                        padding-left: 18px;
                    }
                }
                .pagination {
                    display: flex;
                    justify-content: flex-end;
                    background-color: #fff;
                    border-radius: 8px;

                    li {
                        @include defaultFontStyle(500, 16, 1.2, $black);
                        display: block;
                        float: left;
                        //padding: 13px 0px;
                        text-decoration: none;

                        a {
                            padding: 9px 14px;
                            background: #f8f6f7;
                            border-right: 1px solid #eef1f6;
                        }
                        &:last-child {
                            border-right: none;
                        }
                        &:hover {
                            background-color: #ddd;
                        }
                        &.active {
                            a {
                                background-color: var(--green-color);
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Product List

.product-list-group {
    padding: 30px;
    background-color: $white-light;
    margin: 40px 0 0;
    @include media991 {
        padding: 10px;
    }
    .product-list {
        display: flex;
        overflow-x: auto;
        padding-bottom: 10px;
        .product-list-box {
            // min-width: 347px;
            position: relative;
            min-width: 230px;
            height: 175px;
            display: flex;
            align-items: center;
            background-color: $white;
            // border-radius: 5px;
            border-radius: 25px;
            padding: 15px;
            margin-right: 30px;
            &:last-child {
                margin-right: 0;
            }
            .product-list-img {
                display: flex;
                align-items: center;
                width: 125px;
                height: 125px;
                border-radius: 5px;
                background-color: $white-light;
                position: relative;
                img {
                    width: 100px;
                }
                .lock-tag {
                    position: absolute;
                    width: 35px;
                    height: 35px;
                    top: 0;
                    left: 0;
                    background-color: $green-btn-light;
                    border-radius: 5px 0px 17.5px;
                    display: flex;
                    align-items: center;
                    img {
                        width: 12px;
                    }
                }
            }
            .product-list-info {
                // margin-left: 25px;
                margin: auto;
                margin: auto;
                img {
                    position: absolute;
                    top: 43px;
                    margin: auto;
                    left: 0;
                    right: 0;
                    &.dimond_connect_logo {
                        top: 19px;
                        height: 83px;
                    }
                }
                h4 {
                    @include defaultFontStyle(500, 20, 1.5, $black-light);
                    margin-bottom: 10px;
                }
                .btn {
                    position: absolute;
                    bottom: 19px;
                    width: 100%;
                    left: 0;
                    right: 0;
                    max-width: 150px;
                    margin: auto;
                    padding: 8px 11px;
                }
            }
        }
    }
    ::-webkit-scrollbar-track {
        background-color: $light-color;
        border-radius: 5px;
    }
    ::-webkit-scrollbar {
        height: 5px;
        border-radius: 5px;
        background-color: $light-color;
    }
    ::-webkit-scrollbar-thumb {
        background-color: $light-black;
        border: 2px solid $light-black;
        border-radius: 5px;
        &:hover {
            background-color: $light-black;
            border: 2px solid $light-black;
        }
    }
}

.__react_component_tooltip {
    max-width: 400px !important;
    @include media575 {
        width: 100% !important;
    }
}
.tooltip {
    background-color: $black-light;
    padding: 20px 25px !important;
    border-radius: 5px;
    hr {
        margin: 18px 0;
        opacity: 0.1;
    }
    b {
        @include defaultFontStyle(700, 18, 1.8, $white);
        padding: 10px 0;
    }
    p {
        @include defaultFontStyle(400, 14, 1.5, $white);
        margin: 0;
    }
}
.single-title {
    padding-top: 50px;
    margin-bottom: 30px;
    text-align: center;
    justify-content: center;
    @include media991 {
        flex-wrap: wrap;
    }
    h1 {
        margin-bottom: 15px;
        margin-left: 320px;
        width: 100%;
        @include media991 {
            margin-left: 0px;
        }
    }
}
.recap-info-section {
    background-color: #ffffff;
    padding-bottom: 70px;
    .recap_claim_btn {
        max-width: 236px;
        font-weight: 500;
        width: 100%;
        margin-top: 10px;
        cursor: pointer;
        padding: 8px 13px;
        display: block;
        font-size: 14px;
        margin-right: 100px;
        @include media991 {
            margin-right: 0px;
        }
    }
}
// playerinfo-section
.playerinfo-section {
    background: $white;
    border: 1px solid rgba(108, 123, 149, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 40px 35px 35px;
    @include media767 {
        padding: 30px 20px;
    }

    h2 {
        @include defaultFontStyle(bold, 36, 1.4, $green-color);
        text-align: center;
        padding-bottom: 40px;
        @include media1366 {
            font-size: 32px;
            padding-bottom: 20px;
        }
        @include media991 {
            font-size: 28px;
            line-height: 1.3;
        }
        @include media575 {
            font-size: 26px;
        }
    }
    .user-img {
        border-radius: 10px;
        margin: 0 auto;
        img {
            width: 1000px;
            height: 550px;
            max-width: 100%;
            border-radius: 10px;
            object-fit: cover;
        }
    }
    .player-detail {
        margin-top: 35px;
        @include media575 {
            margin-top: 25px;
        }
        p {
            @include defaultFontStyle(400, 15, 1.6, $light-black);
            &:last-child {
                margin-bottom: 0;
            }
            @include media575 {
                margin: 0 0 15px;
            }
            span {
                color: $blue;
                font-weight: 500;
            }
        }
    }
}

.lock_view {
    position: relative;
    //background: linear-gradient(270deg, #767676 -4.96%, #dddddd 100%);
    background: $white;
    width: 100%;
    border: 1px solid rgba(108, 123, 149, 0.2);
    border-radius: 0px 0px 10px 10px;
    // height: 100%;
    padding: 15px;
    .logo_lock {
        display: flex;
        align-items: center;
        justify-content: center;
        //height: 100%;
        flex-direction: column;
    }
    .lock_text {
        @include defaultFontStyle(normal, 12, 1.2, $black-color);
        max-width: 440px;
        text-align: center;
        padding-top: 10px;
        span {
            color: var(--green-color-light);
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .logo_img {
        max-width: 230px;
    }
    .lock_img {
        // width: 23px;
        position: absolute;
        right: 2%;
        bottom: 18%;
        cursor: pointer;
    }
}
// champions-Score

.champions-Score {
    padding: 60px 0;
    .submit-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            margin-left: 10px;
            i {
                font-size: 13px;
            }
        }
        &:not([disabled])hover {
            span {
                i {
                    color: var(--green-color-light);
                }
            }
        }
    }
    // .submit-arrow[disabled]{
    //     &:hover{
    //         span {
    //             i {
    //                 color: $white;
    //             }
    //         }
    //     }
    // }
    .select_btn {
        margin-top: 30px;
        .custom-select {
            height: 49px;
        }
        button {
            width: 100%;
        }
    }
    .shapreter {
        height: 1px;
        border-top: 1px solid #8a97b4;
        margin: 30px 0;
    }
    .champion-runner {
        .champion-runner-list {
            background-color: $white;
            border-radius: 5px;
            display: flex;
            align-items: center;
            img {
                margin: 0;
            }
            .info {
                padding-left: 20px;
                margin-left: 8px;
                border-left: 1px solid #dadde6;
                h4 {
                    @include defaultFontStyle(700, 15, 1.2, $black-color);
                }
                p {
                    margin-bottom: 0;
                }
            }
        }
    }
}
.touranment-awards {
    background-color: $white;
    padding: 60px 0;
    h1 {
        margin-bottom: 15px;
    }
    .row {
        justify-content: center;
        margin: 0 -15px;
        .col-4 {
            width: 33.33%;
            padding: 0 15px;
            margin-bottom: 30px;
            @include media575 {
                width: 100%;
            }
            .awards-type {
                // padding: 35px 0px;
                text-align: center;
                border: 1px solid #bbc2d2;
                border-radius: 10px;
                .award-static-img {
                    margin: 35px 15px 0;
                    img {
                        border: 2px solid var(--green-color);
                        border-radius: 20px;
                    }
                }
                .award-img {
                    margin: 35px auto;
                    height: 120px;
                    width: 120px;
                    img {
                        padding: 15px;
                        border: 2px solid var(--green-color);
                        border-radius: 20px;
                    }
                }
                h4 {
                    @include defaultFontStyle(700, 24, 1.2, $black-color);
                    margin-bottom: 10px;
                    padding-top: 18px;
                    @include media575 {
                        font-size: 20px;
                    }
                }
                p {
                    font-size: 20px;
                    font-weight: 700;
                    margin-bottom: 35px;
                }
                .list {
                    ul {
                        list-style: none;
                        padding-left: 0;
                        text-align: left;
                        li {
                            padding: 10px 30px;
                            @include defaultFontStyle(500, 16, 1.2, $light-black);
                            border-top: 1px solid #bbc2d2;
                            &:nth-child(odd) {
                                background-color: #f4eff2;
                            }
                            &:nth-child(even) {
                                background-color: #f8f6f7;
                            }
                            &:last-child {
                                border-radius: 0 0 10px 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    .touranment-team-rank {
        border: 1px solid #bbc2d2;
        border-radius: 10px;
        margin-bottom: 30px;
        .touranment-awards-type {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            @include media991 {
                display: block;
            }
            .award-img {
                width: 20%;
                // margin: 35px 15px 0;
                padding: 20px 10px;
                text-align: center;
                @include media991 {
                    width: 100%;
                }
                img {
                    border: 2px solid var(--green-color);
                    border-radius: 20px;
                }
                h4 {
                    @include defaultFontStyle(700, 24, 1.2, $black-color);
                    margin-bottom: 10px;
                    padding-top: 18px;
                    @include media575 {
                        font-size: 20px;
                    }
                }
            }
            .rank-list {
                width: 80%;
                display: flex;
                flex-wrap: wrap;
                @include media991 {
                    width: 100%;
                }
                .col-4 {
                    width: 33.33%;
                    margin-bottom: 0;

                    p {
                        @include defaultFontStyle(500, 16, 1.2, $light-black);
                        padding: 10px 30px;
                        margin-bottom: 0;
                    }
                }
                ul {
                    list-style: none;
                    padding-left: 0;
                    text-align: left;
                    width: 33.33%;
                    border-left: 1px solid #bbc2d2;

                    @include media575 {
                        width: 100%;
                    }
                    &:last-child {
                        li {
                            &:first-child {
                                border-radius: 0 10px 0 0;
                            }
                            &:last-child {
                                border-radius: 0 0 10px 0;
                            }
                        }
                        border-bottom: none;
                    }
                    li {
                        padding: 10px 30px;
                        @include defaultFontStyle(500, 16, 1.2, $light-black);
                        border-bottom: 1px solid #bbc2d2;
                        &:nth-child(odd) {
                            background-color: #f4eff2;
                        }
                        &:nth-child(even) {
                            background-color: #f8f6f7;
                        }
                        &:first-child {
                            // border-top: none;
                        }
                        &:last-child {
                            // border-radius: 0 0 10px 10px;
                            border-bottom: none;
                        }
                    }
                }
                .team-list {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    .team-name {
                        padding: 10px 30px;
                        @include defaultFontStyle(500, 16, 1.2, $light-black);
                        border-top: 1px solid #bbc2d2;
                        background-color: #f4eff2;
                        width: 33.33%;
                        @include media575 {
                            width: 100%;
                        }

                        &:nth-child(1) {
                            border-top: none;
                        }
                        &:nth-child(2) {
                            border-top: none;
                            @include media575 {
                                border-top: 1px solid #bbc2d2;
                            }
                        }
                        &:nth-child(3) {
                            border-top: none;
                            @include media575 {
                                border-top: 1px solid #bbc2d2;
                            }
                            border-radius: 0 10px 0 0;
                        }
                        &:last-child {
                            border-radius: 0 0 10px 0;
                        }
                    }
                    &.no-data {
                        align-items: center;
                        justify-content: center;
                        min-height: 261px;
                        p {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

.stat-leaders {
    padding: 60px 0;
    .section-sub-title {
        margin-top: 40px;
        margin-bottom: 25px;
        @include defaultFontStyle(700, 24, 1.2, $black-color);
    }
    .row {
        margin: 0 -7px;
        .col-3 {
            width: 25%;
            padding: 0 7px;
            margin-bottom: 30px;
            @include media991 {
                width: 33.3%;
            }
            @include media767 {
                width: 50%;
            }
            @include media575 {
                width: 100%;
            }
        }
    }
    .stat-leaders-box {
        background: #ffffff;
        border: 1px solid #bbc2d2;
        box-sizing: border-box;
        border-radius: 10px;
        .title-tag {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding: 15px;
            h5 {
                @include defaultFontStyle(700, 18, 1.2, $black-color);
            }
            .btn {
                padding: 8px 20px;
                font-size: 14px;
                @include media991 {
                    padding: 4px;
                    font-size: 12px;
                }
            }
        }
        .list {
            ul {
                list-style: none;
                padding-left: 0;
                text-align: left;
                li {
                    padding: 10px 15px;
                    @include defaultFontStyle(500, 16, 1.2, $light-black);
                    border-top: 1px solid #bbc2d2;
                }
            }
        }
    }
}

.bracket-info {
    .champions-Score {
        &.schedule-filter {
            padding: 40px 0 0;
        }
        .select_btn {
            .w-50 {
                @include media991 {
                    max-width: 50%;
                    flex: 0 0 50%;
                }
                @include media767 {
                    max-width: 100%;
                    flex: 0 0 100%;
                    // padding: 0;
                }
            }
        }
    }
}
.poll_standings {
    padding: 60px 0 30px;
}
.Schedule {
    padding: 30px 0 60px;
}
.recap-table-info {
    padding-top: 40px;
    table {
        width: 100%;
        border-collapse: separate;
        // border: 1px solid #E5E9F2;
        // border-radius: 8px;
    }
    // tbody{
    //     tr{
    //         &:nth-child(odd){
    //             td{

    //                 background: #e5e9f214;
    //             }
    //         }
    //         &:nth-child(even){
    //             td{

    //                 background: #e5e9f247;
    //             }
    //         }

    //     }
    // }
    th {
        background-color: $white;
        padding: 15px;
        border-bottom: 1px solid $white-light;
        @include defaultFontStyle(500, 18, 1.2, $black);
        @include media991 {
            font-size: 16px;
            padding: 10px;
        }
        &:first-child {
            min-width: 310px;
        }
    }
    td {
        background-color: $white;
        padding: 15px;
        border-bottom: 1px solid $white-light;
        @include defaultFontStyle(400, 16, 1.2, $light-black);
        @include media991 {
            font-size: 15px;
            padding: 10px;
        }

        img {
            display: inline-block;
            margin-left: 10px;
            width: 32px;
        }
    }
}
.bracket_chart_section {
    // overflow: auto;
    padding: 40px 0;
    .bracket-tab-btn {
        margin: 30px 0 0;
        .select-type {
            background: $white;
            padding: 15px;
            button {
                margin-right: 10px;
                margin-top: 10px;
                margin-bottom: 10px;
                &.active {
                    background: var(--green-color-light);
                    border: 2px solid var(--green-color-light);
                    color: white;
                }
            }
        }
    }
    .bracket_chart {
        margin-top: 10px;
        overflow-x: auto;
        .bracket_chart_info {
            display: flex;
            align-items: center;
            // justify-content: center;
            width: 100%;
            // @include customMedia(1250) {
            //     display: block;
            // }
        }
        .full_bracket_chart {
            width: auto;
            margin-right: 30px;
            @include customMedia(1250) {
                //    overflow: auto;
                margin-bottom: 20px;
            }
            @include media575 {
                width: unset;
                margin-right: 30px;
                // margin-left: 90px;
            }
        }
        .winner_team {
            width: auto;
            padding-right: 20px;
            @include media575 {
                width: unset;
            }
            img {
                width: 110px;
                height: 110px;
                object-fit: cover;
                border-radius: 100%;
                @include customMedia(1250) {
                    height: 93px;
                    width: 93px;
                }
                // @include media575 {
                //     width: auto;
                //     height: auto
                // }
            }
            p {
                @include defaultFontStyle(400, 16, 1.3, $black);
                text-align: center;
                padding: 10px 10px 2px 10px;
                margin-bottom: 0;
                width: 200px;
                @include customMedia(1250) {
                    width: unset;
                }
            }
            span {
                @include defaultFontStyle(500, 18, 1.2, $black);
                text-align: center;
                display: block;
            }
        }
    }
}

.no-schedule {
    padding: 60px 0 1px 0;
    p {
        font-size: 25px;
    }
}
.no-schedule-age {
    background: $white;
    padding: 0 0 1px 0;
    p {
        font-size: 25px;
    }
}

.recap-info-tab {
    .playerinfo-section {
        margin-bottom: 40px;
    }
    .champions-Score {
        padding: 0;
        .select_btn {
            margin: 40px 0 30px;
            .row {
                .row {
                    row-gap: 5px;
                }
            }
            .custom-select {
                border-radius: 5px;
            }
        }
    }
    .list-group-scroll {
        .list-group {
            .list-group-item {
                color: $light-black;
                padding: 13.5px 5px;
                background: $white;
                &.active {
                    color: $white;
                    background: var(--green-color-light);
                }
                &:first-child {
                    border-radius: 5px 0 0 5px;
                }
                &:last-child {
                    border-radius: 0 5px 5px 0;
                }
            }
        }
    }
}
.recap-tab-info-section {
    .recap-info-tab,
    .poll_standings,
    .bracket-results-tab {
        padding: 30px 0 60px;
    }
    .stats-leaders-tab {
        padding: 30px 0 0;
    }
    .bracket-results-tab {
        .bracket-tab {
            .w-33 {
                @include media991 {
                    width: 33.33%;
                }
                @include media575 {
                    width: 100%;
                }
            }
            .tab {
                @include defaultFontStyle(500, 16, 1.2, $light-black);
                background: $white;
                border: 1.5px solid #e5e9f2;
                box-sizing: border-box;
                border-radius: 5px;
                width: 100%;
                padding: 12px 10px;
                &.active {
                    color: $white;
                    background: var(--green-color-light);
                }
                &.disable {
                    pointer-events: none;
                    background-color: lightgray;
                }
            }
        }
        .bracket_chart {
            margin-top: 30px;
            border: 1px solid #e1e2e7;
            padding: 20px;
            overflow-x: auto;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            .bracket_chart_info {
                display: flex;
                align-items: center;
                // justify-content: center;
                width: 100%;
                &.bracket_chart_center {
                    justify-content: center;
                }
            }
            .full_bracket_chart {
                width: auto;
                margin-right: 30px;
                @include media575 {
                    width: unset;
                    margin-right: 30px;
                    // margin-left: 90px;
                }
            }
            .winner_team {
                width: auto;
                padding-right: 20px;
                @include media575 {
                    width: unset;
                }
                img {
                    width: 110px;
                    height: 110px;
                    object-fit: cover;
                    border-radius: 100%;
                    @include media991 {
                        height: 93px;
                        width: 93px;
                    }
                    // @include media575 {
                    //     width: auto;
                    //     height: auto
                    // }
                }
                p {
                    @include defaultFontStyle(400, 16, 1.3, $black);
                    text-align: center;
                    padding: 10px 10px 2px 10px;
                    margin-bottom: 0;
                    width: 200px;
                }
                span {
                    @include defaultFontStyle(500, 18, 1.2, $black);
                    text-align: center;
                    display: block;
                }
            }
        }
    }
    .stats-leaders-tab {
        background: $white;
        padding-top: 60px;
        .stats-leaders-info {
            .stats-leaders-row {
                display: flex;
                @include media675 {
                    display: block;
                }
                .stats-hitting {
                    margin-right: 20px;
                }
                .stats-hitting,
                .stats-pitching {
                    width: 50%;
                    background: $white-light;
                    border: 1px solid $gray-btn;
                    box-sizing: border-box;
                    border-radius: 5px 5px 0px 0px;
                    margin-bottom: 20px;
                    @include media675 {
                        width: 100%;
                    }
                }
                .w-25 {
                    @include media991 {
                        flex: 0 0 33.3%;
                        max-width: 33.3%;
                        margin-bottom: 0px;
                    }
                    @include media767 {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                    @include media480 {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
                &:hover {
                    cursor: pointer;
                }
            }
            .stats-info {
                padding: 30px 20px;
                .section_title {
                    font-size: 24px;
                }
                .sub-tab {
                    padding: 10px 0 0;
                    min-height: 213px;
                    .tab-text {
                        @include defaultFontStyle(500, 14, 1.2, $light-black);
                        background: $white;
                        border: 1.5px solid $gray-btn;
                        box-sizing: border-box;
                        border-radius: 5px;
                        text-align: center;
                        padding: 13px 10px;
                        margin: 8px 0;
                        &.active {
                            background: var(--green-color-light);
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}

/* pdf css */

.page-pdf {
    height: 100%;
    max-height: 850px;
    overflow: auto;
    .react-pdf__Page {
        margin-top: 10px;
    }
    .react-pdf__Page__textContent {
        border: 1px solid darkgrey;
        box-shadow: 5px 5px 5px 1px #cccccc73;
        // max-width: 900px;
        // width: 100% !important;
    }
    .react-pdf__Page__canvas {
        margin: 0 auto;
        // max-width: 900px;
        // width: 100% !important;
    }
    .react-pdf__message--loading {
        text-align: center;
        padding-top: 20px;
    }
}
