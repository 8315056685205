.uploadcsv-create {
    .white-bg {
        .multi-radio {
            display: flex;
            align-items: center;
            height: 45px;
            border-radius: 4px;
            margin-bottom: 15px;
            background-color: $gray-btn;
            @include media767 {
                display: block;
                margin-bottom: 0;
                height: 100%;
                padding: 8px 0;
            }
            @include media480 {
                height: 100%;
            }
            .check_inputradio {
                width: 50%;
                padding: 12px 15px;
                height: 100%;
                display: inline-block;
                @include media767 {
                    width: 50%;
                    padding: 8px 15px;
                    display: inline-block;
                }
                @include media575 {
                    width: 100%;
                    padding: 8px 15px;
                    display: block;
                }
                label {
                    margin-bottom: 0;
                    @include defaultFontStyle(500, 16, 1.2, $black-color);
                    @include media767 {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

//  Uploadcsv datasource css
.uploadcsv-datasource {
    .white-bg .info-wrap {
        padding: 10px 0 20px;
    }
    .icon-input {
        display: flex;
        align-items: center;
        input {
            margin-right: 8px;
        }
        img {
            height: 45px;
        }
    }
}
//  Uploadcsv Link data css
.uploadcsv-linkdata {
    .tableinfo {
        padding: 25px;
    }
    .select_team {
        padding: 0 25px 25px;
        display: flex;
        align-items: center;
        @include media480 {
            display: block;
        }
        .team_title {
            width: 30%;
            margin-bottom: 10px;
            @include media480 {
                width: 100%;
            }
        }
        .team-selectbox {
            width: 70%;
            margin-bottom: 10px;
            @include media480 {
                width: 100%;
            }
        }
    }
    .btn {
        margin-top: 30px;
    }
    .table {
        td {
            min-width: 400px;
            @include media991 {
                min-width: 100%;
                width: 100%;
            }
            @include media767 {
                min-width: 240px;
                width: 100%;
            }
        }
        tr {
            &:last-child {
                td {
                    &:last-child {
                        .css-2b097c-container {
                            .css-26l3qy-menu {
                                // top: auto;
                                // bottom: 100%;
                            }
                        }
                    }
                }
            }
        }
        tr {
            &:nth-last-child(-n + 2) {
                td {
                    &:nth-last-child(-n + 2) {
                        .css-2b097c-container {
                            .css-26l3qy-menu {
                                // top: auto;
                                // bottom: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .css-2b097c-container {
        width: 400px;
        @include media991 {
            width: 330px;
        }
        @include media767 {
            width: 250px;
        }
        .css-yk16xz-control,
        .css-1pahdxg-control {
            width: 100%;
            box-shadow: none;
            border: 1px solid $gray-btn;
            .css-g1d714-ValueContainer {
                width: 100%;
                .css-b8ldur-Input {
                    width: 100%;
                    > div {
                        width: 100%;
                        input {
                            width: 100%;
                        }
                    }
                }
            }
            &:hover {
                border-color: $black-color !important;
            }
        }
    }
}

//  Uploadcsv eventmodal css
.uploadcsv-eventmodal {
    .tableinfo {
        padding: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0;
        @include media350 {
            display: block;
        }
        .select-timestamp {
            width: 35%;
            @include media480 {
                width: 50%;
            }
            @include media575 {
                width: 50%;
            }
            @include media350 {
                width: 100%;
                margin-top: 10px;
            }
        }
    }
    .btn {
        margin-top: 30px;
        padding: 10px 36px;
    }
    .file_custom_upload {
        height: auto;
        padding: 0px;
        border: 0px;
        background-color: transparent;
        color: $blue;
        text-decoration: underline;
        text-align: left;
        .preview_img {
            width: 73px;
        }
    }
    .fileview {
        span {
            display: inline-block;
            color: $blue;
        }
        .fileaudio {
            float: left;
            margin-right: 5px;
            color: $light-black;
        }
        .filedelate {
            margin-left: 5px;
            color: $light-black;
            i {
                font-size: 13px;
            }
        }
    }
    .new_add_file {
        padding: 0 25px;
        width: 35%;
        @include media1429 {
            width: 45%;
        }
        @include media991 {
            width: 100%;
        }
        .selectfile {
            margin-right: 10px;
            .file_custom_upload {
                height: 100%;
                text-decoration: none;
            }
            p {
                margin-top: 0;
            }
        }
    }
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .uploadradio-button {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 15px;
        .round-radio {
            margin-right: 20px;
            margin-bottom: 10px;
            @include media991 {
                margin-right: 15px;
            }
        }
    }
    .icon-input {
        margin-top: 10px;
    }
    .select_width {
        min-width: 160px;
        white-space: unset !important;
    }
    th {
        white-space: normal !important;
    }
}
//  Uploadcsv uploadvideo css
.uploadcsv-uploadvideo {
    .white-bg {
        .info-wrap {
            padding: 10px 0 40px;
            .selectfile {
                padding: 7px;
                .file_custom_upload {
                    padding: 6px 25px;
                }
            }
        }
    }
}
//  Uploadcsv uploadcsv data-1  css
.uploadcsv-uploadcsvdata {
    .tableinfo {
        padding: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include media350 {
            display: block;
        }
    }
    .btn-addnewevent {
        margin-top: 0px;
        padding: 10px 12px;
        @include media350 {
            margin-top: 10px;
        }
    }
    table {
        margin-bottom: 25px;
        th {
            width: 22%;
        }
        td {
            &:nth-child(3) {
                border-right: 0px;
            }
            &.common_btn {
                min-width: 350px;
                align-items: center;
            }
            &.btnleft {
                justify-content: left;
            }
        }
    }
    .action_btn {
        padding: 8px 25px;
        &.blue_btn {
            padding: 6px 15px;
            font-size: 14px;
            @include media1199 {
                font-size: 12px;
                padding: 12px 12px;
            }
        }
        &.gray {
            padding: 6px 15px;
            color: $blue;
            margin: 0 15px;
            font-size: 14px;
            @include media1199 {
                font-size: 12px;
                padding: 12px 12px;
                margin: 0 12px;
            }
        }
        &.red_btn {
            padding: 6px 15px;
            font-size: 14px;
            @include media1199 {
                font-size: 12px;
                padding: 12px 12px;
            }
        }
    }
    .pagination {
        display: flex;
        padding-left: 0;
        list-style: none;
        align-items: center;
        justify-content: flex-end;
        border-radius: 0.25rem;
        padding-right: 25px;
    }
    .tablecontent {
        padding-bottom: 25px !important;
    }
}

.diamondkinetics-form {
    padding: 25px;
    h5 {
        @include defaultFontStyle(500, 22, 1.2, $black-color);
        margin-bottom: 30px;
    }
    p {
        color: red;
    }
    .logo-text {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        @include media575 {
            display: block;
            text-align: center;
        }
        .logo {
            width: 150px;
            @include media575 {
                margin: 0 auto 10px;
            }
        }
        .text {
            p {
                @include defaultFontStyle(500, 16, 1.2, $black-color);
                margin-bottom: 0;
                padding: 0 12px;
            }
        }
    }
}
