.common-modal {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    transition: opacity 0.15s linear;
    background: rgba(0, 0, 0, 0.55);
    padding: 0;
    z-index: 1072;
    .modal-dialog {
        position: relative;
        width: auto;
        pointer-events: none;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        max-width: 700px;
        margin: 1.75rem auto;
        min-height: calc(100% - 3.5rem);
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
        -webkit-transform: translate(0, -50px);
        transform: translate(0, -50px);
        @include media767 {
            margin: 1.75rem 15px;
            max-width: 100%;
        }
        &.pwprofile_popup {
            .profile_name {
                font-size: 20px;
                margin-bottom: 20px;
            }
            .profile_btn {
                text-align: center;
            }
        }
    }
    &.show {
        display: block;
        opacity: 1;
        .modal-dialog {
            -webkit-transform: none;
            transform: none;
        }
    }
    .modal-content {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: $white;
        background-clip: padding-box;
        border-radius: 4px;
        outline: 0;
        box-shadow: 0px 10px 5px -7px rgba(0, 0, 0, 0.09);
        &.popup_540 {
            max-width: 540px;
            background: $white;
            border-radius: 10px;
        }
        &.popup_600 {
            max-width: 600px;
        }
        &.popup_310 {
            max-width: 310px;
            background: $white;
            border-radius: 10px;
        }
        &.popup_700 {
            max-width: 700px;
            background: $white;
            border-radius: 10px;
            max-height: 100%;
            overflow: auto;
        }
        .inner_popup {
            padding: 25px;
            background: #fff;
            border-radius: 10px;
            position: relative;
        }
        .close_popup {
            position: absolute;
            right: 17px;
            top: 20px;
            color: #8392a5;
            cursor: pointer;
            font-size: 18px;
            z-index: 2;
            @include media767 {
                top: 10px;
                right: 8px;
            }
        }
        .modal-header {
            display: flex;
            align-content: center;
            justify-content: space-between;
            padding: 25px;
            border-radius: 4px 4px 0 0;
            @include media575 {
                padding: 15px 25px;
            }
            @include media480 {
                padding: 15px;
            }
            h3 {
                @include defaultFontStyle(600, 22, 1.4, $black-color);
                @include media575 {
                    @include defaultFontStyle(600, 18, 1.4, $black-color);
                }
            }
            .modal_close {
                height: 30px;
                width: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                @include media575 {
                    height: 25px;
                    width: 25px;
                }
                img {
                    width: 18px;
                    height: 18px;
                    @include media991 {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
            &.green-header {
                background-color: var(--green-color-light);
                h3 {
                    color: $white;
                }
            }
        }
        .modal-body {
            .next-button {
                padding: 15px 0;
                .btn {
                    margin: 0 5px;
                }
            }
            .file_custom_upload {
                padding: 40px 10px;
            }
        }
        .onlymodal_close {
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            right: 15px;
            position: absolute;
            top: 15px;
            left: auto;
            z-index: 3;
            @include media575 {
                height: 25px;
                width: 25px;
            }
            img {
                width: 18px;
                height: 18px;
                @include media991 {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
}

body.modal-open {
    padding-right: 17px;
    height: 100%;
    overflow: hidden;
    width: 100%;
    // position: fixed;
    .common-modal {
        overflow-x: hidden;
        overflow-y: auto;
    }
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1071;
        width: 100vw;
        height: 100vh;
        opacity: 0.5;
        background-color: $black-color;
    }
}

// team modal
.claimHeading {
    padding-top: 25px;
    text-align: center;
    h3 {
        font-size: 20px;
        margin-bottom: 8px;
    }
}
.team-body-inner {
    border-top: 1px solid $gray-light;
    border-bottom: 1px solid $gray-light;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 20px 20px;
    .flex-align {
        @include media767 {
            flex-wrap: wrap;
        }
        .social_btn {
            margin: 0 15px;
            margin-left: 0;
            @include media767 {
                width: 100%;
                margin: 0 auto;
                margin-bottom: 10px;
                > img {
                    left: 10px;
                    padding-left: 5px;
                }
            }
        }
        .form-group {
            margin-bottom: 0;
            width: 50%;
            padding: 0 15px;
            @include media575 {
                width: 100%;
            }
        }
    }
    h4 {
        margin: 15px 0;
    }
    .multi-radio {
        margin-top: 15px;
        display: flex;
        align-items: center;
        label {
            margin-right: 30px;
        }
    }
    .add_roster {
        display: flex;
        align-content: center;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 10px;
        .common-lable {
            margin-bottom: auto;
            margin-top: auto;
        }
        .btn {
            padding: 7px 10px;
            font-size: 13px;
        }
    }
    .css-2b097c-container {
        .css-xb97g8 {
            cursor: pointer;
        }
        .css-yk16xz-control,
        .css-1pahdxg-control {
            box-shadow: none;
            border: 1px solid $gray-btn;
            .css-g1d714-ValueContainer {
                width: 100%;
            }
            &:hover {
                border-color: $black-color !important;
            }
        }
    }
    .upgrade-radio {
        margin-top: 20px;
        .round-radio {
            margin-top: 20px;
            margin-bottom: 0;
            a {
                color: $blue;
                position: relative;
                z-index: 1;
                margin-left: 2px;
                &:hover {
                    color: $black-color;
                }
            }
        }
    }
}

// sccess_team
.sccess_team {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 40px;
    .thumb_up {
        margin: 0 auto 20px;
        width: 100px;
        height: 100px;
        display: flex;
        align-content: center;
        justify-content: center;
    }
    h4 {
        @include defaultFontStyle(600, 32, 1.4, $black-color);
        @include media767 {
            @include defaultFontStyle(600, 24, 1.4, $black-color);
        }
        @include media480 {
            @include defaultFontStyle(600, 20, 1.4, $black-color);
        }
    }
    p {
        margin: 20px 0;
        @include defaultFontStyle(500, 18, 1.5, $light-black);
        @include media767 {
            @include defaultFontStyle(500, 15, 1.5, $light-black);
            margin: 15px 0;
            br {
                display: none;
            }
        }
        @include media480 {
            @include defaultFontStyle(500, 14, 1.5, $light-black);
        }
    }
    .btn {
        margin: 0 10px;
        @include media480 {
            padding: 12px 28px;
            font-size: 15px;
        }
    }
    .flex-align {
        .btn {
            min-width: 110px;
            margin: 0 5px;
            padding: 12px 10px;
        }
    }
}

// payment sccess
.sccess_payment {
    border-radius: 30px !important;
    .sccess_payment_inner {
        padding: 40px;
        display: flex;
        flex-direction: column;
        text-align: center;
        @include media575 {
            padding: 30px 25px;
        }
        .thumb_up {
            margin: 0 auto 20px;
            width: 190px;
            height: 100%;
            display: flex;
            align-content: center;
            justify-content: center;
            @include media767 {
                width: 160px;
            }
            @include media575 {
                width: 130px;
            }
            @include media480 {
                width: 100px;
            }
            img {
                height: 100%;
                width: 100%;
            }
        }
        h4 {
            @include defaultFontStyle(600, 32, 1.4, $black-color);
            @include media767 {
                @include defaultFontStyle(600, 24, 1.4, $black-color);
            }
            @include media480 {
                @include defaultFontStyle(600, 18, 1.4, $black-color);
            }
        }
        p {
            margin: 10px 0 20px;
            @include defaultFontStyle(500, 18, 1.5, $light-black);
            @include media767 {
                @include defaultFontStyle(500, 15, 1.5, $light-black);
            }
            @include media480 {
                @include defaultFontStyle(500, 14, 1.5, $light-black);
            }
        }
        .btn {
            @include media480 {
                padding: 12px 28px;
                font-size: 15px;
            }
        }
    }
}

// notifiaction content
.notifiaction-content {
    padding: 30px;
    .notifiaction-content-scroll {
        max-height: 255px;
        overflow-y: scroll;
        .noti_item {
            margin-bottom: 15px;
            display: flex;
            flex-wrap: wrap;
            .notifiaction-img {
                height: 70px;
                width: 70px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 30px;
                border-radius: 100%;
                border: 2px solid $gray-light;
                > img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 100%;
                }
            }
            .notifiaction-dec {
                flex: 1;
                h5 {
                    @include defaultFontStyle(600, 18, 1.4, $black-color);
                    margin-bottom: 10px;
                    span {
                        @include defaultFontStyle(400, 14, 1.4, $light-black);
                    }
                }
                .blue_btn {
                    padding: 6px 8px;
                    font-size: 13px;
                    margin-right: 10px;
                    &:last-child {
                        margin-right: unset;
                    }
                }
            }
        }
    }
}

.notifiaction-content-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $gray-btn;
    background-color: $gray-btn;
    border-radius: 5px;
}
.notifiaction-content-scroll::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
    background-color: $gray-btn;
}
.notifiaction-content-scroll::-webkit-scrollbar-thumb {
    background-color: $blue;
    border: 2px solid $blue;
    border-radius: 5px;
}

// payment billing add card
.pay-billing-addcard {
    .form-group-checkbox {
        margin-top: 25px;
        label {
            @include defaultFontStyle(400, 14, 1.4, $light-black);
            &:before {
                border: 2px solid $light-black;
            }
        }
    }
    .form-group {
        .form-control {
            color: #6c7b95;
            font-weight: 400;
        }
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
        color: rgba(0, 0, 0, 0);
        opacity: 1;
        display: block;
        background: url("../../../src/assets/images/payment/calendar.svg") no-repeat;
        width: 20px;
        height: 20px;
        border-width: thin;
        cursor: pointer;
        &:focus {
            outline: none;
        }
    }
    input[type="number"]:hover {
        -webkit-appearance: none;
    }
}

// subscription confirm modal
.confirmcancel {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 40px;
    @include media575 {
        padding: 30px 25px;
    }
    .cancellation {
        margin: 0 auto 20px;
        width: 90px;
        height: 90px;
        display: flex;
        align-content: center;
        justify-content: center;
        @include media767 {
            width: 70px;
            height: 70px;
        }
    }
    h4 {
        @include defaultFontStyle(600, 32, 1.4, $black-color);
        @include media767 {
            @include defaultFontStyle(600, 24, 1.4, $black-color);
        }
        @include media480 {
            @include defaultFontStyle(600, 18, 1.4, $black-color);
        }
    }
    p {
        max-width: 68%;
        margin: 20px auto;
        @include defaultFontStyle(500, 18, 1.5, $light-black);
        @include media767 {
            @include defaultFontStyle(500, 15, 1.5, $light-black);
        }
        @include media575 {
            max-width: 100%;
        }
        @include media480 {
            @include defaultFontStyle(500, 14, 1.5, $light-black);
        }
    }
}

// add-vide-inner
.add-vide-inner {
    border-top: 1px solid $gray-light;
    border-bottom: 1px solid $gray-light;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 20px 20px;
    .selectfile {
        display: flex;
        align-items: center;
        border: 1px solid $gray-btn;
        border-radius: 4px;
        padding: 6px;
        p {
            margin-bottom: 0px;
            font-size: 12px;
        }
        .file_custom_upload {
            padding: 6px 40px !important;
            height: 30px;
            margin-right: 14px;
            background-color: $gray-btn;
            border: 2px dashed $gray-text;
        }
    }
    .video-thumbnail {
        list-style: none;
        max-height: 236px;
        overflow: auto;
        li {
            list-style: none;
            display: flex;
            align-items: center;
            border: 1px solid $gray-btn;
            margin-bottom: 10px;
            border-radius: 5px;
            margin-right: 10px;
            position: relative;
            @include media480 {
                margin-right: 5px;
            }
            .video_img {
                width: 70px;
                height: 70px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 5px;
                }
                .video-icon {
                    width: 25px;
                    height: 25px;
                    background-color: rgba(51, 52, 64, 0.8);
                    border-radius: 100%;
                    color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 auto;
                    position: absolute;
                    left: 0;
                    right: 0;
                    cursor: pointer;
                    font-size: 17px;
                    padding-left: 2px;
                    &:hover {
                        background-color: var(--green-color);
                        color: $white;
                    }
                }
            }
            .video_dec {
                margin-left: 15px;
                h6 {
                    @include defaultFontStyle(500, 15, 1.2, $black-color);
                    // text-transform: capitalize;
                    @include media480 {
                        font-size: 12px;
                    }
                }
            }
            .delete-video {
                position: absolute;
                right: 8px;
                top: 8px;
                width: 25px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border: 2px solid $gray-btn;
                border-radius: 100%;
                background-color: $white;
                transition: all 0.5s ease 0s;
                @include media480 {
                    border-width: 1px;
                    width: 20px;
                    height: 20px;
                }
                img {
                    width: 100%;
                    height: 100%;
                }
                &:hover {
                    transition: all 0.5s ease 0s;
                    border-color: $text-red;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        p {
            text-align: center;
            margin: 0 auto;
            color: $black-color;
        }
    }
}

.video-thumbnail::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $white;
    background-color: $white;
    border-radius: 5px;
}
.video-thumbnail::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
    background-color: $white;
}
.video-thumbnail::-webkit-scrollbar-thumb {
    background-color: $gray-text;
    border: 2px solid $gray-text;
    border-radius: 5px;
    &:hover {
        background-color: $light-black;
        border: 2px solid $light-black;
    }
}

// leave_team and join team
.leave_team {
    padding: 40px;
    display: flex;
    flex-direction: column;
    text-align: center;
    @include media575 {
        padding: 30px 25px;
    }
    h4 {
        @include defaultFontStyle(600, 32, 1.4, $black-color);
        @include media767 {
            @include defaultFontStyle(600, 24, 1.4, $black-color);
        }
        @include media480 {
            @include defaultFontStyle(600, 18, 1.4, $black-color);
        }
    }
    p {
        margin: 10px 0 20px;
        @include defaultFontStyle(500, 18, 1.5, $light-black);
        @include media767 {
            @include defaultFontStyle(500, 15, 1.5, $light-black);
        }
        @include media480 {
            @include defaultFontStyle(500, 14, 1.5, $light-black);
        }
    }
    .flex-align {
        .btn,
        .btn:focus,
        .btn:active,
        .btn:visited {
            margin: 0 10px;
            width: 120px;
            padding: 12px 0;
        }
    }
}

// press_team
.press_team {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 40px 5px 20px 20px;
    @include media575 {
        padding: 50px 5px 20px 20px;
    }
    h4 {
        @include defaultFontStyle(600, 20, 1.4, $black-color);
        margin-bottom: 15px;
        @include media575 {
            @include defaultFontStyle(600, 18, 1.4, $black-color);
        }
    }
    h5 {
        @include defaultFontStyle(600, 16, 1.4, $black-color);
        margin-bottom: 15px;
        @include media575 {
            @include defaultFontStyle(600, 14, 1.4, $black-color);
        }
    }
    .press_team_scroll {
        max-height: 600px;
        overflow: auto;
        padding-right: 5px;
        span {
            @include defaultFontStyle(500, 16, 1.2, $light-black);
            display: block;
            @include media575 {
                @include defaultFontStyle(500, 14, 1.2, $light-black);
            }
        }
        span.dates {
            margin: 5px 0 15px;
        }
        .press_room_modal_img {
            display: block;
            margin-bottom: 15px;
            img {
                width: 100%;
                height: 100%;
                border-radius: 5px;
            }
        }
        p {
            margin-bottom: 15px;
            font-size: 14px;
            .sub_title {
                text-align: center;
                margin-bottom: 10px;
                font-size: 18px;
                letter-spacing: 0.8px;
            }
            &:last-child {
                margin-bottom: 0;
            }
            a {
                padding: 0 5px;
                padding-left: 0;
                color: $blue;
                text-decoration: underline;
                &:hover {
                    color: $blue;
                }
            }
        }
    }
}

.press_team_scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $white;
    background-color: $white;
    border-radius: 5px;
}
.press_team_scroll::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
    background-color: $white;
}
.press_team_scroll::-webkit-scrollbar-thumb {
    background-color: $gray-text;
    border: 2px solid $gray-text;
    border-radius: 5px;
    &:hover {
        background-color: $light-black;
        border: 2px solid $light-black;
    }
}

// our_team
.our_team {
    display: flex;
    text-align: left;
    padding: 40px 20px 20px 20px;
    z-index: 2;
    position: relative;
    @include media575 {
        padding: 50px 20px 20px 20px;
    }
    .our_team_inner {
        margin: 0 -15px;
        display: flex;
        flex-wrap: wrap;
        .our_team_part {
            padding: 0 15px;
            flex: 0 0 50%;
            max-width: 50%;
            @include media767 {
                flex: 0 0 100%;
                max-width: 100%;
            }
            &.our_team_inner_part {
                max-height: 600px;
                overflow: auto;
                padding-right: 5px;
                @include media767 {
                    max-height: 200px;
                }
            }
            .ourteam_img {
                border-radius: 5px;
                margin-top: 100px;
                @include media767 {
                    margin-top: 30px;
                    margin-bottom: 30px;
                }
                @include media480 {
                    margin-top: 15px;
                }
                img {
                    border-radius: 5px;
                }
            }
            h5 {
                @include defaultFontStyle(600, 20, 1.4, $blue);
                @include media575 {
                    @include defaultFontStyle(600, 18, 1.4, $blue);
                }
            }
            span {
                @include defaultFontStyle(500, 16, 1.2, $black-color);
                margin: 10px 0;
                display: block;
                @include media575 {
                    @include defaultFontStyle(500, 14, 1.2, $black-color);
                }
            }
            p {
                margin-bottom: 10px;
                font-size: 14px;
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    padding: 0 5px;
                    padding-left: 0;
                    color: $blue;
                    text-decoration: underline;
                    &:hover {
                        color: $blue;
                    }
                }
            }
        }
    }
}

.our_team_inner_part::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $white;
    background-color: $white;
    border-radius: 5px;
}
.our_team_inner_part::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
    background-color: $white;
}
.our_team_inner_part::-webkit-scrollbar-thumb {
    background-color: $gray-text;
    border: 2px solid $gray-text;
    border-radius: 5px;
    &:hover {
        background-color: $light-black;
        border: 2px solid $light-black;
    }
}

// Thank You

.thank-info {
    max-width: 500px !important;
    text-align: center;
    .modal-header {
        justify-content: flex-end !important;
    }
    h4 {
        @include defaultFontStyle(700, 22, 1.2, var(--green-color));
        margin-bottom: 14px;
    }
    p {
        max-width: 282px;
        margin: auto;
        margin-bottom: 34px;
    }
    .next-button {
        padding: 0 !important;
        .btn {
            width: 50%;
            border-radius: 0;
            margin: 0 !important;
            &:first-child {
                border-radius: 0 0 0 4px;
            }
            &:last-child {
                border-radius: 0 0 4px 0;
            }
        }
    }
}

// strength-info

.strength-info {
    max-width: 80% !important;
    @include media991 {
        max-width: 100% !important;
    }
    .modal-content {
        background-color: $white-light;
    }
    .strength-info-content {
        display: flex;
        flex-wrap: wrap;
        padding: 0px 25px 25px;
        margin-right: -15px;
        margin-left: -15px;
        .strength-item {
            padding-right: 15px;
            padding-left: 15px;
            width: 25%;
            @include media1199 {
                width: 50%;
            }
            @include media575 {
                width: 100%;
            }
            .form-group {
                position: relative;
                .loc-section {
                    position: absolute;
                    width: 23px;
                    height: 23px;
                    right: 6px;
                    top: 22%;
                    cursor: pointer;
                }
            }
        }
        .strength-box {
            background-color: $white;
            border-radius: 5px;
            @include media1199 {
                margin-bottom: 15px;
            }

            h3 {
                @include defaultFontStyle(500, 20, 1.5, $black-color);
                padding: 15px;
                border-bottom: 2px solid $white-light;
            }

            .strength-select {
                // display: flex;
                padding: 15px;

                .checkbox {
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin: 15px 0;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px !important;
                    input {
                        position: absolute;
                        opacity: 0;
                        left: 0;
                        right: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        z-index: 1;
                        cursor: pointer;
                    }
                    .square {
                        position: relative;
                        width: 20px;
                        height: 20px;
                        border-radius: 4px;
                        transition: all 0.5s ease 0s;
                        background-color: $white;
                        border: 1px solid $light-black;
                    }
                    .square:after {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 3px;
                        left: 5px;
                        width: 5px;
                        height: 10px;
                        border: solid $white;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                    }

                    label {
                        @include defaultFontStyle(400, 14, 1.2, $light-black);
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: calc(100% - 20px);
                    }
                    input:checked ~ .square::after {
                        opacity: 1;
                    }
                    input:checked ~ .square {
                        background-color: var(--green-color-light);
                        border: 1px solid var(--green-color-light);
                    }
                }
                input {
                    height: 35px;
                }
            }
        }
    }
}

.installment-popup {
    max-width: 500px !important;
    .installment-list {
        max-width: 300px;
        margin: 15px auto;
        ul {
            list-style: none;
            li {
                box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
                padding: 10px;
                margin: 5px 0;
                border-radius: 6px;
                span {
                    float: right;
                }
            }
        }
    }
}

// total-base

.total-base {
    max-width: 314px !important;
    ul {
        list-style: none;
        li {
            padding: 10px 30px;
            @include defaultFontStyle(500, 16, 1.2, $light-black);
            border-top: 1px solid #bbc2d2;
        }
    }
}

// purchase prospectwire
.purchase-prospectwire {
    display: flex !important;
    .modal-dialog {
        // max-width: 780px !important;
        max-width: 100%;
        @include media767 {
            max-width: 575px !important;
            margin: 0 auto !important;
            height: 1050px;
        }
    }
    .modal-header {
        justify-content: flex-end !important;
        padding: 8px !important;
    }
    .modal-body {
        // @include media767 {
        //     height: 610px;
        //     overflow: auto;
        // }

        // padding: 0 52px 52px;
        // @include media575 {
        //     padding: 0 12px 12px;
        // }
        // h2 {
        //     @include defaultFontStyle(700, 25, 1.2, $black-color);
        //     margin-bottom: 20px;
        //     text-align: center;
        // }
        // p {
        //     @include defaultFontStyle(400, 14, 1.2, $black-light);
        //     text-align: center;
        // }
        // .info-price {
        //     display: flex;
        //     justify-content: space-between;
        //     .info {
        //         p {
        //             @include defaultFontStyle(400, 14, 1.2, $black-light);
        //             max-width: 282px;
        //             text-align: left;
        //         }
        //     }
        //     .price {
        //         text-align: right;
        //         h4 {
        //             margin-bottom: 20px;
        //         }
        //         button {
        //             padding: 6px 20px;
        //         }
        //     }
        // }
        // .or {
        //     position: relative;
        //     z-index: 1;
        //     margin-top: 10px;
        //     margin-bottom: 23px;
        //     h6 {
        //         @include defaultFontStyle(400, 20, 1.2, $black-light);
        //         background-color: $white;
        //         max-width: 30px;
        //         margin: 0 auto;
        //         text-align: center;
        //     }
        //     &::after {
        //         content: "";
        //         position: absolute;
        //         width: 100%;
        //         height: 1px;
        //         border: 1px dashed #6c7b95;
        //         top: 50%;
        //         left: 0;
        //         z-index: -1;
        //     }
        // }
        // .popup-footer {
        //     button {
        //         background-color: $gra-bg;
        //         color: $black-light;
        //         border: none;
        //         text-transform: uppercase;
        //         float: right;
        //         margin-top: 14px;
        //         &:hover {
        //             color: $white;
        //             border: none;
        //             box-shadow: none;
        //         }
        //     }
        // }
        .popup-content {
            display: flex;
            justify-content: center;
            margin: 0 12px;
            @include media767 {
                display: block;
            }
            .price-box {
                // width: 33.33%;
                max-width: 250px;
                // padding-bottom: 10px;
                padding: 0 12px;
                margin-bottom: 12px;
                @include media767 {
                    display: block;
                    width: 100%;
                }
                .price-info {
                    background-color: #f4f6fa;
                    padding-bottom: 10px;
                    h5 {
                        background-color: var(--green-color-light);
                        @include defaultFontStyle(700, 18, 1.2, $white);
                        padding: 11px 20px;
                        border-radius: 5px 5px 0px 0px;
                    }
                    ul {
                        list-style: disc;
                        border-bottom: 1px solid #d5dfe9;
                        // padding-bottom: 25px;
                        padding: 11px 20px 11px 34px;
                        max-height: 165px;
                        overflow-y: auto;

                        li {
                            @include defaultFontStyle(400, 14, 1.2, $black-light);
                            margin: 5px 0;
                        }
                    }
                    h3 {
                        @include defaultFontStyle(700, 18, 1.2, $black-light);
                        padding: 15px 10px;
                        text-align: center;
                        span {
                            @include defaultFontStyle(500, 12, 1, $gra-input);
                        }
                    }
                    .upgrade-btn {
                        padding: 0 10px;
                        button {
                            width: 100%;
                            font-size: 14px;
                            text-transform: uppercase;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                            img {
                                margin: 0 8px 0 0;
                            }
                            &:hover {
                                img {
                                    filter: brightness(0) invert(1);
                                }
                            }
                        }
                    }
                    ::-webkit-scrollbar-track {
                        background-color: #f4f6fa;
                        border-radius: 5px;
                    }
                    ::-webkit-scrollbar {
                        width: 4px;
                        border-radius: 5px;
                        background-color: #f4f6fa;
                    }
                    ::-webkit-scrollbar-thumb {
                        background-color: $light-black;
                        border: 2px solid $light-black;
                        border-radius: 5px;
                        &:hover {
                            background-color: $light-black;
                            border: 2px solid $light-black;
                        }
                    }
                }
            }
        }
    }
}
