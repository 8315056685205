body {
    background-color: $gray-bg;
    @include defaultFontStyle(500, 16, 1.2, $black-color);
    margin: 0;
    overflow-x: hidden;

    // BC-Team Scroll hide
    &.scroll-hide {
        height: 100%;
        overflow: hidden;
        #root {
            height: 100%;
        }
    }
}

body p {
    @include defaultFontStyle(500, 15, 1.5, $light-black);
    margin: 0 0 20px;
    padding: 0;
    @include media991 {
        @include defaultFontStyle(500, 14, 1.5, $light-black);
    }
    @include media480 {
        @include defaultFontStyle(500, 13, 1.5, $light-black);
        margin: 0 0 15px;
    }
}

p {
    a {
        color: var(--green-color);
        text-decoration: underline;
        &:hover {
            color: $black-color;
            text-decoration: underline;
        }
    }
}

body p.p-black {
    @include defaultFontStyle(500, 15, 1.5, $black-color);
    @include media991 {
        @include defaultFontStyle(500, 14, 1.5, $black-color);
    }
    @include media480 {
        @include defaultFontStyle(500, 13, 1.5, $black-color);
        margin: 0 0 15px;
    }
}

section {
    padding-top: 60px;
    padding-bottom: 60px;
    @include media767 {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

// @media (min-width: 992px) {
//     .container {
//         max-width: 960px;
//     }
// }

// @media (min-width: 1200px) {
//     .container {
//         max-width: 1140px;
//     }
// }

@media (min-width: 1430px) {
    .container {
        max-width: 1440px;
    }
}

@media (max-width: 768px) {
    .container {
        max-width: 100%;
    }
}

.container-fluid {
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -8px;
    margin-left: -8px;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}
.w-15 {
    flex: 0 0 15%;
    max-width: 15%;
    padding: 0 8px;
    @include media991 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
}
.w-25 {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 8px;
    @include media991 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
}

.w-33 {
    width: 33.33%;
    padding: 0 8px;
    @include media991 {
        width: 50%;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
    @include media575 {
        width: 100%;
    }
}
.w-50 {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 8px;
    @include media991 {
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
}
.w-75 {
    flex: 0 0 75%;
    max-width: 75%;
    padding: 0 8px;
    @include media991 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
}
.w-85 {
    flex: 0 0 85%;
    max-width: 85%;
    padding: 0 8px;
    @include media991 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
}
.w-full {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 8px;
    @include media991 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
}
// .w-100 {
//     flex: 0 0 100%;
//     max-width: 100%;
//     padding: 0 8px;
//     @include media991 {
//         flex: 0 0 100%;
//         max-width: 100%;
//         margin-bottom: 15px;
//         &:last-child {
//             margin-bottom: 0px;
//         }
//     }
// }

.heading-title {
    text-align: center;
    margin-bottom: 40px;
    @include media575 {
        margin-bottom: 30px;
    }
    h2 {
        @include defaultFontStyle(700, 35, 1.4, $black-color);
        position: relative;
        display: inline-block;
        padding-bottom: 15px;
        margin-bottom: 20px;
        @include media991 {
            @include defaultFontStyle(700, 24, 1.4, $black-color);
        }
        @include media480 {
            @include defaultFontStyle(700, 20, 1.4, $black-color);
        }
        @include media350 {
            @include defaultFontStyle(700, 18, 1.4, $black-color);
        }
        &:before {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            content: "";
            height: 3px;
            background-color: var(--green-color);
            width: 28px;
            bottom: 0;
        }
    }
    p {
        @include media991 {
            br {
                display: none;
            }
        }
    }
}

.heading-for {
    h3 {
        @include defaultFontStyle(700, 26, 1.4, $black-color);
        position: relative;
        display: block;
        padding-bottom: 15px;
        margin-bottom: 15px;
        @include media991 {
            @include defaultFontStyle(700, 20, 1.4, $black-color);
        }
        @include media480 {
            @include defaultFontStyle(700, 20, 1.4, $black-color);
        }
        @include media350 {
            @include defaultFontStyle(700, 15, 1.4, $black-color);
            padding-bottom: 10px;
            margin-bottom: 10px;
        }
        &:before {
            position: absolute;
            left: 0;
            right: auto;
            content: "";
            height: 3px;
            background-color: var(--green-color);
            width: 28px;
            bottom: 0;
        }
        span {
            color: var(--green-color);
        }
    }
}

h3 {
    @include defaultFontStyle(500, 24, 1.4, $black-color);
    display: block;
    @include media991 {
        @include defaultFontStyle(500, 20, 1.4, $black-color);
    }
    @include media350 {
        @include defaultFontStyle(500, 18, 1.4, $black-color);
    }
}

.white-bg {
    background-color: $white;
    box-shadow: 0px 10px 5px -7px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
}

.gray-bg {
    background-color: $gray-btn;
}

.white_bg {
    background-color: $white;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}
.ml-auto {
    margin-left: auto;
}
.m-0,
.mb-0,
.ml-0,
.mr-0,
.mt-0 {
    margin: 0 !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}
.mb-20 {
    margin-bottom: 20px;
}
.mt-20 {
    margin-top: 20px;
}
.mrb {
    margin-bottom: 0px !important;
}

.p-0,
.pb-0,
.pl-0,
.pr-0,
.pt-0 {
    padding: 0 !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.pr-10 {
    padding: 10px !important;
}
.p-50 {
    padding: 50px;
}

.mr-15 {
    margin-right: 15px;
}

.w-100 {
    width: 100%;
}

.table {
    width: 100%;
}
table {
    border-collapse: collapse;
}

@media (max-width: 1199px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}
.table thead th {
    vertical-align: bottom;
}
.table td,
.table th {
    vertical-align: middle;
}
th {
    text-align: inherit;
    text-align: -webkit-match-parent;
}

.common-lable {
    @include defaultFontStyle(600, 15, 1.4, $black-color);
    margin: 20px 0 8px;
    display: block;
    text-align: left;
    @include media767 {
        @include defaultFontStyle(600, 14, 1.4, $black-color);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    @include media575 {
        text-overflow: unset;
        white-space: unset;
        overflow: unset;
        font-size: 12px;
    }
    span {
        @include defaultFontStyle(600, 13, 1.4, $light-black);
        @include media991 {
            @include defaultFontStyle(600, 12, 1.4, $light-black);
        }
    }
}

.flex-wrap {
    flex-wrap: wrap;
}
.d-flex {
    display: flex;
}
.align-items-center {
    align-items: center;
}
.justify-content-between {
    justify-content: space-between;
}
.justify-content-end {
    justify-content: flex-end !important;
}
.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}
.flex-align {
    display: flex;
    align-content: center;
    justify-content: center;
}

.validMsg {
    margin-top: 5px;
    display: block;
    @include defaultFontStyle(600, 13, 1.2, $text-red);
}

.commomn-bg {
    background: url("../../assets/images/commen-bg/common-bg.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-self: center;
    min-height: 400px;
    padding: 0;
    background-size: cover;
    position: relative;
    &.tryout_bg {
        &::before {
            content: unset;
        }
    }
    &::before {
        position: absolute;
        height: 100%;
        width: 100%;
        content: "";
        background-color: rgba(0, 0, 0, 0.6);
        &.tryout_bg {
            background: url("../../assets/images/commen-bg/tryout_camps.png");
            background-color: none;
        }
    }
    &.common-bg-active {
        &::before {
            z-index: -1;
        }
    }
    .commomn-bg-text {
        position: relative;
        h1 {
            @include defaultFontStyle(700, 37, 1.2, $white);
            text-align: center;
            @include media767 {
                @include defaultFontStyle(700, 32, 1.2, $white);
            }
            @include media575 {
                @include defaultFontStyle(700, 26, 1.2, $white);
            }
        }
        &.thanks-for {
            display: block;
            text-align: center;
            a {
                @include defaultFontStyle(700, 37, 1.2, $white);
                text-align: center;
                margin-top: 20px;
                display: block;
                @include media767 {
                    @include defaultFontStyle(700, 32, 1.2, $white);
                }
                @include media575 {
                    @include defaultFontStyle(700, 26, 1.2, $white);
                }
                &:hover {
                    color: $blue;
                    text-decoration: underline;
                }
            }
        }
    }
    @include media991 {
        min-height: 400px;
    }
    @include media575 {
        min-height: 320px;
    }
    .profile_banner {
        position: absolute;
        right: 20px;
        left: auto;
        bottom: 42px;
        .profile_banner_inner {
            cursor: pointer;
            position: relative;
            background-color: rgba(0, 0, 0, 0.2);
            display: flex;
            align-items: center;
            border-radius: 5px;
            padding: 12px;
            @include defaultFontStyle(500, 15, 1.2, $white);
            border: 1px solid $white;
            i {
                font-size: 15px;
                margin-right: 10px;
                color: $white;
            }
            input[type="file"] {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
            }
        }
    }
    // &.event-bg {
    //     h1 {
    //         padding-top: 160px;
    //     }
    // }
}

.file_custom_upload {
    position: relative;
    border: 2px dashed $gray-light;
    padding: 45px 10px;
    overflow: hidden;
    background-color: $gray-bg;
    border-radius: 5px;
    height: 170px;
    > i {
        font-size: 35px;
        color: $drop-icon;
        opacity: 0.6;
        margin-bottom: 10px;
    }
    p {
        @include defaultFontStyle(500, 16, 1.2, $light-black);
        margin-bottom: 0;
    }
    input[type="file"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    .preview_img {
        width: 200px;
        height: 100%;
        border-radius: 5px;
        border: 2px dashed $gray-light;
        position: absolute;
        // background-color: $gray-bg;
        background-color: $white;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 0 15px;
        pointer-events: none;
        .preview_img_inner {
            // height: 100%;
            // object-fit: cover;
            // padding: 10px;
            // border-radius: 100%;
            // max-width: 100%;
            object-fit: cover;
            padding: 10px;
            border-radius: 100%;
            max-width: 100%;
            width: 160px;
            height: 160px;
        }
        i {
            content: "";
            position: absolute;
            right: 10px;
            top: 10px;
            width: 22px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $text-red;
            text-align: center;
            border-radius: 50%;
            color: $white;
            cursor: pointer;
            font-size: 14px;
            // z-index: 1;
            pointer-events: all;
            &:hover {
                opacity: 0.8;
            }
        }
    }
}

.text-uppercase {
    text-transform: uppercase;
}
.mt-15 {
    margin-top: 15px;
}
.mt-10 {
    margin-top: 10px;
}
.mb-15 {
    margin-bottom: 15px;
}
.pt-15 {
    padding-top: 15px;
}

.overflow-horizontal-auto {
    overflow-x: auto;
}

// uploadcsv page
.uploadcsv {
    padding-top: 0px;
    margin-top: 40px;
    position: relative;
    z-index: 1;
    .container {
        max-width: 900px;
    }
    ::placeholder {
        color: $light-black;
    }
    .white-bg {
        padding: 25px;
        &.tablecontent {
            padding: 0px;
            &.linkTableTwo th {
                width: 50%;
            }
            &.linkTableThree th {
                width: 33%;
            }
        }
        .w-50 {
            width: 50%;
            padding-right: 15px;
            @include media575 {
                width: 100%;
                padding: 0;
            }
        }
        .pr-0 {
            padding-right: 0px;
        }
        .info-wrap {
            border-top: 1px solid $gray-btn;
            border-bottom: 1px solid $gray-btn;
            padding: 10px 0;
            margin: 20px 0px;
            @include media767 {
                padding: 10px 0 20px;
            }
        }
        h3 {
            margin-bottom: 3px;
        }
        label {
            font-weight: 600;
        }
        .flex-align .btn {
            margin: 0 10px;
            width: 150px;
            padding: 10px 36px;
        }
    }
    table {
        width: 100%;
        table-layout: unset !important;
        th {
            @include defaultFontStyle(500, 15, 1.4, $light-black);
            padding: 18px 24px;
            text-align: left;
            background-color: $light-white;
            border-right: 1px solid $gray-btn;
            vertical-align: middle;
            white-space: nowrap;
            &:last-child {
                border-right: 0px;
            }
        }
        td {
            padding: 6px 12px;
            border-bottom: 1px solid $gray-btn;
            border-right: 1px solid $gray-btn;
            white-space: nowrap;
            &:last-child {
                border-right: 0px;
            }
            .fileview {
                display: flex;
            }
        }
        tr {
            &:last-child {
                td {
                    &:last-child {
                        // border-bottom: none;
                    }
                }
            }
        }
    }
    .selectfile {
        display: flex;
        align-items: center;
        border: 1px solid $gray-btn;
        border-radius: 4px;
        padding: 6px;
        &.selectfile-upload {
            margin-right: 8px;
            width: calc(100% - 42px);
        }
        p {
            margin-bottom: 0px;
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 0;
            color: $light-black;
        }
        .file_custom_upload {
            padding: 6px 40px;
            height: 30px;
            margin-right: 14px;
            background-color: $gray-btn;
            border: 2px dashed $gray-text;
        }
    }
    .new_add_file {
        margin: 15px 0;
        @include media480 {
            margin-bottom: 0px;
        }
        .round-radio {
            margin-right: 15px;

            @include media480 {
                display: block;
                margin-bottom: 10px;
            }
        }
    }
}

.uploadcsv {
    &.inner-row {
        .container {
            max-width: 1330px;
            @include media1429 {
                max-width: 1140px;
            }
            @include media1199 {
                max-width: 960px;
            }
            @include media991 {
                max-width: 100%;
            }
            .table-responsive-xl {
                display: block;
                width: 100%;
                overflow: auto;
                -webkit-overflow-scrolling: touch;
                height: 580px;
            }
        }
    }
}

.ant-btn-primary.ant-btn-sm {
    color: $white;
    background-color: $blue;
    border-color: $blue;
    &:hover {
        background-color: $gray-light;
        color: $black-color;
        border-color: $gray-light;
    }
}

.ant-btn-sm {
    background-color: $gray-light;
    color: $black-color;
    border-color: $gray-light;
    &:hover {
        color: $white;
        background-color: $blue;
        border-color: $blue;
    }
}
.ant-input:hover,
.ant-input:focus,
.ant-input-focused {
    border-color: $blue;
    box-shadow: none;
}
.ant-pagination {
    padding: 15px;
    li {
        list-style: none;
        border-radius: 5px;
        a {
            padding: 0px 10px;
            font-size: 18px;
            color: $black-color;
            &:focus,
            &:hover {
                color: $blue;
            }
        }
        &.ant-pagination-item-active {
            border-color: $blue;
            border-radius: 5px;
            a {
                color: $blue;
            }
        }
        .ant-pagination-item-link {
            background-color: $blue;
            color: $white;
            box-shadow: none;
            border: none;
            outline: none;
            border-radius: 5px;
            cursor: pointer;
            &:hover {
                background-color: $gray-light;
                color: $black-color;
            }
        }
        &:focus,
        &:hover {
            border-color: $blue;
            a {
                color: $blue;
                &:focus,
                &:hover {
                    color: $blue;
                }
            }
        }
    }
}
// square checkbox custom
.tablecheckbox {
    label {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 0px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    label input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .checkmark {
        position: absolute;
        top: -10px;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: $blue;
        border-radius: 4px;
    }
    label:hover input ~ .checkmark {
        background-color: $blue;
    }
    label input:checked ~ .checkmark {
        background-color: $blue;
        border-radius: 4px;
    }
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    label input:checked ~ .checkmark:after {
        display: block;
    }
    label .checkmark:after {
        left: 9px;
        top: 4px;
        width: 8px;
        height: 14px;
        border: solid $white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
    }
}

// datepicker style

.bc_relative {
    position: relative;
    .bc_datepicker-icon {
        position: absolute;
        width: 20px;
        height: 20px;
        right: 16px;
        bottom: 8%;
        transform: translateY(-50%);
        @include media480 {
            width: 15px;
            height: 15px;
        }
        &.active {
            bottom: 35%;
        }
    }
    input {
        box-shadow: none;
        height: 45px;
        width: 100%;
        padding: 10px;
        border: 1px solid $gray-btn;
        border-radius: 4px;
        -webkit-appearance: none;
        appearance: none;
        @include defaultFontStyle(500, 16, 1.2, $black-color);
        @include media480 {
            height: 40px;
            @include defaultFontStyle(400, 14, 1.2, $black-color);
        }
        &:disabled {
            background: $gray-btn;
            border: 1px solid transparent;
        }
    }
    .react-datepicker {
        &-wrapper {
            width: 100% !important;
        }
        &__triangle {
            left: 50% !important;
            transform: translateY(-50%);
            margin-top: -3px !important;
        }
    }
}
// time picker style
.react-time-picker__wrapper {
    border: none;
}

.datearrow {
    display: inline-block;
    font-size: 18px;
    color: $light-black;
    font-weight: 600;
    padding: 0 5px;
    vertical-align: middle;
    &:hover {
        color: $black-color;
    }
}

.react-datepicker__header {
    &.react-datepicker__header--custom {
        select {
            // height: 456px;
            // overflow: hidden;
            // overflow-y: scroll;
        }
    }
}

.text-area-counter {
    position: relative;
    .textarea__count {
        position: absolute;
        display: inline-block;
        vertical-align: middle;
        right: 15px;
        bottom: 15px;
        @include defaultFontStyle(400, 16, 1.2, $light-black);
    }
}

span.tooltip {
    visibility: visible;
    width: 120px;
    background-color: $gra-dark;
    color: $white;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 112%;
    right: -37px;
    opacity: 1;
    transition: opacity 0.5s;
    &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: $gra-dark transparent transparent transparent;
    }
}

// video_upload_progressbar
.video_upload_progressbar {
    display: flex;
    align-items: center;
    color: $white;
    justify-content: center;
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1072;
    font-size: 22px;
    flex-direction: column;
    text-shadow: 2px 1px var(--green-color);
    @include media991 {
        font-size: 20px;
    }
    @include media575 {
        font-size: 18px;
        text-align: center;
        padding: 0 10px;
    }
    img {
        width: 120px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }
}

// compnay title section

.section_title {
    @include defaultFontStyle(700, 36, 1.4, $black);
    text-align: center;
    padding-bottom: 15px;
    position: relative;
    &:before {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        content: "";
        height: 2px;
        background-color: var(--green-color-light);
        width: 40px;
        bottom: 0;
    }
    @include media1366 {
        font-size: 30px;
    }
    @include media767 {
        font-size: 26px;
    }
}
.section_title_left {
    @include defaultFontStyle(700, 36, 1.4, $black);
    text-align: left;
    padding-bottom: 15px;
    position: relative;
    &:before {
        position: absolute;
        left: 0;
        margin: 0 auto;
        content: "";
        height: 2px;
        background-color: var(--green-color-light);
        width: 40px;
        bottom: 0;
    }
    @include media1366 {
        font-size: 30px;
    }
    @include media767 {
        font-size: 26px;
    }
}

// Stripe payment

.creditcard-popup {
    max-width: 550px !important;
    .modal-header {
        justify-content: flex-end !important;
        background: #f6f9fc;
        padding: 0 !important;
        .modal_close {
            height: 15px !important;
            width: 15px !important;
            position: absolute;
            right: 15px;
            top: 20px;
        }
    }
}

.stripe_payment {
    padding: 20px;
    background: #f6f9fc;
    label {
        display: block;
    }
    button {
        white-space: nowrap;
        border: 0;
        outline: 0;
        display: inline-block;
        height: 40px;
        line-height: 40px;
        padding: 0 14px;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
        color: #fff;
        border-radius: 4px;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.025em;
        background-color: #6772e5;
        text-decoration: none;
        -webkit-transition: all 150ms ease;
        transition: all 150ms ease;
        margin-top: 10px;
        &:hover {
            color: #fff;
            cursor: pointer;
            background-color: #7795f8;
            transform: translateY(-1px);
            box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        }
    }
}

.StripeElement {
    padding: 10px 10px;
    border: 1px solid #ddd;
    background: $white;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

// map geocoding
.geosuggest__suggests.geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
}

.geosuggest__suggests {
    border: 1px solid #e4e7ea;
    li {
        padding: 2px 10px;
        list-style: none;
        cursor: pointer;
        span {
            font-size: 14px;
        }
        &:hover {
            background: $blue;
            color: $white;
        }
    }
}

.geosuggest__item--active {
    background: $blue;
    color: $white;
}

.geosuggest__suggests-wrapper {
    position: absolute;
    width: 100%;
    background: white;
    z-index: 0;
}

.geosuggest__input {
    box-shadow: none;
    height: 45px;
    width: 100%;
    padding: 10px;
    border: 1px solid #e5e9f2;
    border-radius: 4px;
    -webkit-appearance: none;
    appearance: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color: #28263d;
}

// shop section css
#sqd-cta__app {
    box-shadow: none;
}

.linkTableThree {
    .row {
        margin-right: -8px !important;
        margin-left: -8px !important;
    }
}

.manag-roster-table {
    thead {
        th {
            padding: 8px 15px !important;
            &.toggle-btn {
                // display: flex;
                // align-items: center;
                label {
                    margin-left: 10px;
                }
            }
        }
    }
}

.toggle-btn {
    // padding: 0 !important;
    .switch {
        &.switch-default {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            width: 40px;
            height: 24px;
            background-color: transparent;
            cursor: pointer;
            .switch-input {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                &:checked {
                    ~ {
                        .switch-handle {
                            left: 18px;
                            border-color: #96cf04;
                        }
                    }
                    ~ {
                        .switch-label {
                            background: #96cf04 !important;
                            border-color: #96cf04;
                        }
                    }
                }
            }
            .switch-label {
                position: relative;
                display: block;
                height: inherit;
                font-size: 10px;
                font-weight: 600;
                text-transform: uppercase;
                background-color: #fff;
                border: 1px solid #c2cfd6;
                border-radius: 15px;
                transition: opacity background 0.15s ease-out;
            }
            .switch-handle {
                position: absolute;
                top: 2px;
                left: 2px;
                width: 20px;
                height: 20px;
                background: #fff;
                border: 1px solid #c2cfd6;
                border-radius: 1px;
                transition: left 0.15s ease-out;
                border-radius: 50%;
            }
        }
    }
}

.lable-btn-group {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
    .btn-label {
        padding: 6px;
        font-style: 14px;
        margin-left: 10px;
        &:active {
            padding: 6px;
        }
        &:hover {
            padding: 6px;
        }
        &:focus {
            padding: 6px;
        }
    }
}

@media only screen and (max-height: 360px) {
    header .navbar-expand-lg .container .avatar-dropdown-menu:hover .avatar-dropdown-menu-items {
        height: 283px;
        overflow: auto;
    }
}

.analyzr-table {
    h2 {
        @include defaultFontStyle(700, 26, 1.4, $black-color);
        padding: 20px;
        text-align: center;
    }
    table {
        width: 100%;
        th {
            @include defaultFontStyle(500, 16, 1.4, $light-black);
            padding: 19px 24px;
            text-align: left;
            background-color: $light-white;
            &:nth-child(2) {
                min-width: 270px;
            }
        }
        td {
            padding: 20px 24px;
            p {
                @include defaultFontStyle(400, 14, 1.4, $light-black);
                margin: 0;
                padding: 0;
            }
        }
        .green-status {
            text-decoration: none;
        }
    }
}

.dashboard-text {
    text-align: center;
    padding: 40px 0;
    margin: 30px 0;
}

.p-0-14 {
    padding: 0 14px !important;
}

// tooltip for copy text
.tooltips {
    transform: translateX(-50%);
    width: 67px;
    visibility: hidden;
    background-color: #373739;
    color: #fff;
    font-size: 12px;
    font-family: Helvetica, Arial, sans-serif;
    cursor: default;
    text-shadow: none;
    text-align: center;
    overflow: visible;
    padding: 6px 5px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
    -webkit-transition: opacity 0.3s ease-in 0.3s;
    transition: opacity 0.3s ease-in 0.3s;
    position: absolute;
    opacity: 0;
    top: 0;
    &.active {
        visibility: visible;
        opacity: 1;
        z-index: 999;
    }
    &:after {
        content: " ";
        position: absolute;
        top: 35%;
        left: -1px;
        display: block;
        height: 0;
        width: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #373739;
        margin-left: -10px;
        transform: rotate(-90deg);
    }
}

.table-responsive-xl.scroll_hide {
    overflow-x: inherit;
}

.join-form {
    .inner_join_form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        .product-price-list {
            float: left;
            &.price_list_join_form{
                width: 100%;
            }
        }
        .css-2b097c-container {
            width: 100%;
            float: left;
        }
    }
}

.css-1pahdxg-control:hover,
.css-1pahdxg-control:active,
.css-1pahdxg-control:focus {
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-shadow: none;
}

.common-modal.show {
    .modal-dialog {
        &.create_child_acc_modal {
            max-width: 597px;
            .modal-area {
                max-width: 482px;
                margin: 0 auto;
                text-align: center;
                h3 {
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 800;
                    font-size: 19px;
                    line-height: 23px;
                    color: #000000;
                    text-align: center;
                    margin: 0 0 25px;
                }
                p {
                    text-align: left;
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 15px;
                    color: #000000;
                }
            }
            .modal-header {
                padding: 20px;
            }

            button.create_btn_player {
                background: #91cf2f;
                border-radius: 3px;
                font-family: "Inter";
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #000000;
                padding: 11px 19px;
                border: 1px solid #91cf2f;
                transition: all 0.4s ease-in-out;
                &:hover {
                    background-color: #fff;
                    border: 1px solid #91cf2f;
                    transition: all 0.4s ease-in-out;
                    color: #91cf2f;
                }
            }
            .modal-body {
                padding-bottom: 33px;
            }
        }
    }
}
