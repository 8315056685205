:root {
  --green-color: #7cbd42;
  --green-color-light: #96cf04;
  --green-color-extra-light: #eaf6cc;
  --green-color-shadow: #7cbd42;
}
$primary-font: "Helvetica Now Text";
$secondary-font: "Roboto";

$light-color: #ffffff;
$white: #ffffff;
$black-color: #28263d;
$light-black: #6c7b95;
$gray-light: #e1e2e7;
$gray-bg: #f9f6f7;
$blue: #4d8aae;
$text-red: #fe3434;
$bg-red-light: #ffe0e0;
$menu-bg: #edf3f7;
$green-color: #7cbd42;
$drop-icon: #02a8a8;
$light-white: #f7f8fb;

// question input
$gra-dark: #555658;
$gra-input: #75787b;
$gra-bg: #ccd3d9;

/* Button color */
$green-btn: #7cbd42;
$gray-btn: #e5e9f2;
$green-btn-light: #96cf04;

// timeline-color
$gray-count: #eeeced;
$gray-text: #babeca;

// socail-btn
$facebook: #39559f;
$instagram: linear-gradient(to right, #fab749, #f56129, #dd2a7b, #4134af);
$snapchat: #fed652;
$twitter: #00a7e7;

// company landing
$black-light: #221f34;
$white-light: #f8f6f7;
$black: #000000;
$extra-light: #e5e5e5;

// responsive view variables
// Small smartphones
$screen-350: 350px;
// Small smartphones
$screen-480: 480px;
// Small tablets and large smartphones (landscape view)
$screen-575: 575px;

$screen-675: 675px;
// Small tablets (portrait view)
$screen-767: 767px;
// Tablets and small desktops
$screen-991: 991px;
// Large tablets and desktops
$screen-1199: 1199px;
// extra Large tablets and desktops
$screen-1366: 1366px;
//extra Large tablets and desktops
$screen-1429: 1429px;
// extra Large desktops
$screen-1599: 1599px;
