.analyzr-main {
    display: flex;
    align-items: center;
    padding: 40px 0;
    @include media767 {
        display: block;
    }
    .analyzr-img {
        width: 50%;
        @include media767 {
            width: 100%;
        }
        img {
            max-width: 600px;
            width: 100%;
        }
    }
    .analyzr-info {
        width: 50%;
        text-align: center;
        padding: 20px;
        @include media767 {
            width: 100%;
            margin-top: 20px;
        }
        img {
            max-width: 440px;
            width: 100%;
        }
        h1 {
            @include defaultFontStyle(700, 40, 1.4, $light-white);
            margin: 20px 0 20px;
        }
        p {
            @include defaultFontStyle(700, 30, 1.4, $light-white);
        }
        .btn {
            padding: 16px 50px;
            margin: 5px;
        }
    }
}
.get-started {
    padding: 40px 0;
    text-align: center;
    p {
        @include defaultFontStyle(700, 30, 1.4, $black-color);
        max-width: 1130px;
        margin: 0 auto;
        padding-bottom: 30px;
        @include media991 {
            font-size: 20px;
        }
    }
}
.analyzr-info-section {
    padding: 40px 0;
    .heading-logo-title {
        text-align: left;
        h4 {
            margin-bottom: 15px;
        }
    }
}

.choose-tamplate {
    padding: 40px 0;
    .choose-img-part {
        display: flex;
        flex-wrap: wrap;
        .choose-img-info {
            width: 50%;
            text-align: center;
            margin-bottom: 20px;
            @include media575 {
                width: 100%;
            }
            h4 {
                @include defaultFontStyle(700, 22, 1.4, $black-color);
            }
            img {
                max-width: 390px;
                width: 100%;
                padding: 15px;
            }
        }
    }
}

.reporting-soluction {
    padding: 40px 0;
    h5 {
        @include defaultFontStyle(700, 22, 1.4, $black-color);
        margin-bottom: 15px;
        @include media991 {
            font-size: 18px;
        }
    }
    .reporting-soluction {
        display: flex;
        flex-wrap: wrap;
        img {
            padding: 15px;
            width: 100%;
            max-width: 450px;
        }
    }
}

.pricing {
    padding: 40px 0;
    text-align: center;
    max-width: 1200px;
    margin: auto;
    p {
        @include defaultFontStyle(700, 22, 1.4, $light-black);
        @include media991 {
            font-size: 18px;
        }
    }
}
.credits-table {
    padding: 40px 0;
    table {
        width: 100%;
        th,
        td {
            padding: 14px;
        }
        thead {
            background-color: $green-color;
            th {
                @include defaultFontStyle(500, 18, 1.2, $light-white);
            }
        }
        tbody {
            tr {
                &:nth-child(2n + 1) {
                    background-color: #e2e2e2;
                }
                .btn {
                    margin-left: 15px;
                    padding: 5px 18px;
                    font-size: 16px;
                }
                td {
                    // &:last-child{
                    //     div{
                    //     display: flex;
                    //     align-items: center;
                    //     }
                    // }
                    .price-buy {
                        display: flex;
                        align-items: center;
                        .price-tag {
                            min-width: 78px;
                        }
                    }
                }
            }
        }
    }
}
