.bcteam-page {
    background-color: $white;
    .hero-banner {
        .row {
            justify-content: center;
        }
        .new-tag {
            @include defaultFontStyle(500, 12, 1.2, $white);
            border: 2px solid;
            padding: 5px 8px;
            border-radius: 4px;
        }
        .hero-dec {
            text-align: center;
            .heading-logo {
                max-width: 500px;
                margin: 0 auto;
                padding-top: 30px;
            }
            h1 {
                font-size: 52px;
                max-width: 740px;
                margin: 0 0 20px;
                @include media991 {
                    font-size: 40px;
                }
            }
        }
    }
    .game-analyze {
        h2 {
            @include defaultFontStyle(700, 40, 1.2, $black-color);
            max-width: 750px;
            margin: 0 auto;
            text-align: center;
            padding-bottom: 40px;
            @include media991 {
                font-size: 30px;
            }
        }
        .row {
            @include media575 {
                display: block;
            }
            .col-4 {
                flex: 0 0 33.333333%;
                max-width: 33.333333%;
                padding: 0 15px;
                @include media575 {
                    max-width: 100%;
                    flex: 0 0 100%;
                }
            }
        }
        .game-analyze-box {
            text-align: center;
            @include media575 {
                text-align: center;
            }
            img {
                // margin-bottom: 24px;
                margin: 0 auto 24px auto;
                @include media575 {
                    max-width: 60%;
                    margin: auto auto 15px auto;
                }
            }
            h5 {
                @include defaultFontStyle(700, 24, 1.2, $black-color);
                margin-bottom: 15px;
            }
            p {
                max-width: 250px;
                margin: 0 auto 15px;
            }
        }
    }
    .tab-slider {
        h2 {
            @include defaultFontStyle(700, 40, 1.2, $black-color);
            max-width: 750px;
            margin: 0 auto;
            text-align: center;
            padding-bottom: 12px;
            @include media991 {
                font-size: 30px;
            }
        }
        p {
            // @include defaultFontStyle(500, 24, 1.2, $light-black);
            max-width: 750px;
            margin: 0 auto;
            text-align: center;
            padding-bottom: 10px;
            @include media767 {
                padding-bottom: 15px;
            }
        }
        .slider-nav {
            padding-bottom: 20px;
            @include media991 {
                padding-bottom: 15px;
            }
            h3 {
                @include defaultFontStyle(700, 20, 1.2, $black-color);
                text-align: center;
                padding: 20px 10px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                @include customMedia(910) {
                    min-height: 108px;
                }
                @include media575 {
                    font-size: 16px;
                    font-weight: 500;
                    padding: 10px 0;
                    min-height: 58px;
                    display: block;
                }
                @include customMedia(380) {
                    font-size: 12px;
                    min-height: 48px;
                }
            }
            .slick-slide {
                border-bottom: 1px solid #d7d7d7;
            }
            .slick-current {
                border-bottom: 1px solid $black-color;
            }
        }
        .slider-img {
            img {
                max-width: 950px;
                margin: 0 auto;
                @include media991 {
                    max-width: 100%;
                }
            }
            .slick-prev {
                left: -14px;
                z-index: 1;
                background: #7cbd42;
                width: 50px;
                height: 50px;
                border-radius: 10px;
                text-align: center;
                @include media575 {
                    width: 30px;
                    height: 30px;
                }
                &:before {
                    content: "\f053";
                    font-size: 12px;
                    font-family: "Font Awesome 5 Free";
                    color: #ffffff;
                    line-height: 21px;
                    position: absolute;
                    top: 30%;
                    left: 0;
                    right: 0;
                    @include media575 {
                        top: 20%;
                    }
                }
            }
            .slick-next {
                right: -14px;
                z-index: 1;
                background: #7cbd42;
                width: 50px;
                height: 50px;
                border-radius: 10px;
                text-align: center;
                @include media575 {
                    width: 30px;
                    height: 30px;
                }
                &:before {
                    content: "\f054";
                    font-family: "Font Awesome 5 Free";
                    font-size: 12px;
                    color: #ffffff;
                    line-height: 20px;
                    position: absolute;
                    top: 30%;
                    left: 3px;
                    right: 0;
                    @include media575 {
                        top: 20%;
                    }
                }
            }
        }
    }
    .find-slider {
        .container {
            max-width: 1140px;
        }
        .fing-slider-box {
            display: flex;
            align-items: center;
            @include media575 {
                display: block;
            }
            .fing-img {
                img {
                    max-width: 500px;
                    border-radius: 5px;
                    @include media991 {
                        max-width: 400px;
                    }
                    @include media767 {
                        max-width: 330px;
                    }
                    @include media575 {
                        max-width: 100%;
                    }
                }
            }
            .fing-tetx {
                padding: 0 80px;
                @include media991 {
                    padding: 0 20px;
                }
                @include media575 {
                    padding-top: 15px;
                    text-align: center;
                }
                h4 {
                    @include defaultFontStyle(700, 40, 1.2, $black-color);
                    padding-bottom: 20px;
                    @include media991 {
                        font-size: 24px;
                        padding-bottom: 15px;
                    }
                }
                p {
                    @include defaultFontStyle(500, 20, 1.2, $light-black);
                    @include media575 {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .place-home {
        .row {
            align-items: center;
            max-width: 1119px;
            margin: 0 auto;
            @include media767 {
                display: block;
            }
        }
        .place-home-info {
            width: 50%;
            padding: 0 60px;
            @include media991 {
                padding: 0 15px;
            }
            @include media767 {
                width: 100%;
                padding: 0;
            }
            h4 {
                @include defaultFontStyle(700, 40, 1.2, $black-color);
                padding-bottom: 30px;
                @include media991 {
                    font-size: 32px;
                    padding-bottom: 15px;
                }
                @include media575 {
                    font-size: 30px;
                    padding-bottom: 15px;
                }
            }
            p {
                max-width: 450px;
                @include defaultFontStyle(500, 20, 1.2, $light-black);
                @include media575 {
                    font-size: 16px;
                }
            }
        }
        .place-home-img {
            width: 50%;
            @include media767 {
                width: 100%;
            }
            img {
                @include media767 {
                    // width: 60%;
                    padding-bottom: 15px;
                }
            }
        }
    }
    .pricing-pitchr {
        text-align: center;
        padding-bottom: 0;
        .price-bg {
            background-color: #e2e2e2;
            padding: 60px 15px;
            h2 {
                @include defaultFontStyle(700, 64, 1.2, $black-color);
                padding-bottom: 30px;
                @include media575 {
                    font-size: 30px;
                    padding-bottom: 15px;
                }
            }
            p {
                @include defaultFontStyle(500, 24, 1.2, $light-black);
                padding-bottom: 25px;
                max-width: 750px;
                margin: 0 auto;
                @include media575 {
                    font-size: 18px;
                    padding-bottom: 15px;
                }
            }
        }
    }
}
