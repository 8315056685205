article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

body,
ol,
ul,
li,
div,
p,
span,
form,
h1,
h2,
h3,
h4,
h5,
h6,
table,
thead,
th,
tbody,
tr,
td,
img,
input,
textarea,
dd,
dt,
dl {
  word-wrap: break-word;
  outline: none;
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

textarea {
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dd,
dt,
dl {
  -webkit-margin-before: 0px;
  -webkit-margin-after: 0px;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
}

a img,
img {
  border: none;
  outline: none;
  display: block;
  width: auto;
  max-width: 100%;
}

a {
  cursor: pointer;
  text-decoration: none;
  outline: 0;
}

input {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-font-smoothing: antialiased;
}

::-moz-selection {
  text-shadow: none;
  color: $white;
  background-color: $black-color;
}

::selection {
  text-shadow: none;
  color: $white;
  background-color: $black-color;
}

ul,
menu,
dir {
  margin: 0px;
  padding: 0px;
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
}

* {
  box-sizing: border-box;

  &:after,
  &:before {
    box-sizing: border-box;
  }
}

a,
div a:hover,
div a:active,
div a:focus,
button {
  text-decoration: none;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.no-list li,
.no-list ul,
.no-list ol,
footer li,
footer ul,
footer ol,
header li,
header ul,
header ol {
  list-style: inside none none;
}

.no-list ul,
.no-list ol,
footer ul,
footer ol,
header ul,
header ol {
  margin: 0;
  padding: 0;
}

a {
  outline: none;
  color: $black-color;
}

a:hover {
  color: var(--green-color);
}

a,
div a:hover,
div a:active,
div a:focus,
button {
  text-decoration: none;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

a,
span,
div a:hover,
div a:active,
button {
  text-decoration: none;
}

*::after,
*::before,
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

img {
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

img {
  vertical-align: middle;
  border-style: none;
}

// Pagination css
.pagination {
  li {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    &.active {
      a {
        color: var(--green-color);
      }
    }
    a {
      position: relative;
      display: block;
      padding: 0.5rem 0.75rem;
      margin-left: -1px;
      line-height: 1.25;
      color: #28263d;
      background-color: #ffffff;
      border: 1px solid #e5e9f2;
    }
  }
}
