@import "style/helpers/variable.scss";
@import "style/helpers/mixins.scss";

@import "style/base/reset.scss";
@import "style/base/common.scss";

@import "style/components/button.scss";
@import "style/components/input.scss";
@import "style/components/modal.scss";
@import "style/components/typography.scss";

@import "style/font-awsome/fontawesome.scss";
@import "style/font-awsome/solid.scss";
@import "style/font-awsome/brands.scss";

@import "style/modules/header.scss";
@import "style/modules/footer.scss";

@import "style/modules/landing.scss";
@import "style/modules/ballr.scss";
@import "style/modules/analyzr.scss";
@import "style/modules/dc.scss";
@import "style/modules/pitchr.scss";
@import "style/modules/bcteam.scss";

@import "style/modules/login.scss";

@import "style/modules/timeline.scss";

@import "style/modules/coachdashborad.scss";
@import "style/modules/coachprofiletab.scss";

@import "style/modules/playerdashborad.scss";
@import "style/modules/fandashborad.scss";

@import "style/modules/webgl.scss";
@import "style/modules/playercart.scss";

@import "style/modules/payment.scss";
@import "style/modules/billing.scss";
@import "style/modules/subcription.scss";

@import "style/modules/uploadcsv.scss";
@import "style/modules/licence.scss";
@import "style/modules/loader.scss";
@import "style/modules/videomodal.scss";
@import "style/modules/innerpage.scss";
@import "style/modules/_additional_info.scss";

// company landing css
@import "style/modules/companylanding.scss";

// PW Ranking css
@import "style/modules/pwranking.scss";

// account creation css
@import "style/modules/accountcreation.scss";

@import "style/modules/custom_bracket.scss";


.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  height: 200px;
  overflow: hidden;
  overflow-y: scroll;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

main {
  display: flex;
  flex-direction: row;
}
.round {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 200px;
  list-style: none;
  padding: 0;
}
.round .spacer {
  flex-grow: 1;
}
.round .spacer:first-child,
.round .spacer:last-child {
  flex-grow: 0.5;
}

.round .game-spacer {
  flex-grow: 1;
}

.round .qf-game-spacer {
  flex-grow: unset;
}

li.game {
  padding-left: 20px;
}

li.game.winner {
  font-weight: bold;
}
li.game span {
  float: right;
  margin-right: 5px;
}

li.game-top {
  border-bottom: 1px solid #aaa;
}

li.game-spacer {
  border-right: 1px solid #aaa;
  min-height: 40px;
}

li.qf-game-spacer {
  border-right: 1px solid #aaa;
  min-height: 40px;
}

li.game-bottom {
  border-top: 1px solid #aaa;
}

// .testing {
//   rect{
//     fill: #f9f6f7 !important;
//     stroke: #96cf04 !important;
//   }
//   line {
//     stroke: #f9f6f7 !important;
//   }
// }
