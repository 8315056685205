.dc-main {
    display: flex;
    align-items: center;
    padding: 40px 0;
    @include media767 {
        display: block;
        justify-content: center;
    }
    .dc-img {
        width: 30%;
        @include media767 {
            width: 100%;
        }
        // img {
        //     max-width: 303px;
        // }
    }
    .dc-info {
        width: 70%;
        text-align: center;
        padding: 20px;
        @include media767 {
            width: 100%;
            margin-top: 20px;
        }
        img {
            // max-width: 440px;
            max-width: 100%;
        }
        h1 {
            @include defaultFontStyle(700, 40, 1.4, $light-white);
            margin: 20px 0 20px;
            @include media991 {
                font-size: 30px;
            }
        }
        .shaprater {
            height: 3px;
            width: 78px;
            background-color: $light-color;
            margin: 30px auto;
        }
        p {
            @include defaultFontStyle(400, 20, 1.4, $light-white);
            margin-bottom: 15px;
        }
        .btn {
            padding: 16px 50px;
            margin: 5px;
        }
        .store-btn {
            display: flex;
            justify-content: center;
            a {
                margin: 5px;
            }
        }
    }
}

.mobile-slider {
    background-color: $light-color;
    p {
        @include defaultFontStyle(400, 20, 1.2, $black-color);
        text-align: center;
    }
    .shaprater {
        height: 3px;
        width: 40px;
        background-color: $green-btn;
        margin: 30px auto;
    }
    .mob-img {
        margin: 0 30px;
        img {
            margin: 0 auto;
            transition: 0.3s;
        }
    }
    .slick-current {
        .slider-items {
            transition: 5s;
            img {
                width: 350px;
                transition: 0.3s;
                @include media1366 {
                    width: 274px;
                }
            }
        }
    }
    .slick-prev {
        left: -14px;
        z-index: 1;
        background: $green-btn;
        width: 50px;
        height: 50px;
        border-radius: 10px;
        text-align: center;
        &:before {
            content: "\f053";
            font-size: 12px;
            font-family: "Font Awesome 5 Free";
            color: $white;

            line-height: 21px;
            position: absolute;
            top: 30%;
            left: 0;
            right: 0;
        }
        &:hover {
            background-color: var(--green-color);
        }
    }
    .slick-next {
        right: -14px;
        z-index: 1;
        background: $green-btn;
        width: 50px;
        height: 50px;
        border-radius: 10px;
        text-align: center;
        &:before {
            content: "\f054";
            font-family: "Font Awesome 5 Free";
            font-size: 12px;
            color: $white;

            line-height: 20px;
            position: absolute;
            top: 30%;
            left: 3px;
            right: 0;
        }
        &:hover {
            background-color: var(--green-color);
        }
    }
    .slick-track {
        display: flex;
        align-items: center;
    }
}

.event-handled {
    background-image: url("../../assets/images/dc/handel-bg.png");
    p {
        @include defaultFontStyle(400, 20, 1.2, $light-color);
        text-align: center;
    }
    .shaprater {
        height: 3px;
        width: 78px;
        background-color: $green-btn;
        margin: 30px auto;
    }
}

.connect-section {
    background-color: $light-color;
    .container {
        padding: 0;
    }
    p {
        @include defaultFontStyle(400, 20, 1.2, $light-black);
        text-align: center;
        padding-left: 15px;
        padding-right: 15px;
    }
    .shaprater {
        height: 3px;
        width: 78px;
        background-color: $green-btn;
        margin: 30px auto;
    }
    .connect-bg {
        background-image: url("../../assets/images/dc/connect-bg.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding-top: 90px;
        padding-bottom: 320px;
        margin-bottom: 220px;
        position: relative;
        @include media1199 {
            margin-bottom: 140px;
        }
        @include media767 {
            margin-bottom: 80px;
        }
        @include media575 {
            margin-bottom: 0px;
            padding-bottom: 0px;
        }
        p {
            @include defaultFontStyle(400, 20, 1.2, $light-color);
            margin-bottom: 47px;
        }
        .doubble-mob {
            position: absolute;
            // bottom: -44%;
            width: 100%;
            margin: 0 auto;
            @include media575 {
                position: static;
            }
            img {
                // width: 50%;
            }
        }
    }
}

.integrated-partners {
    // background-color: $light-color;
    .row {
        @include media991 {
            display: block;
        }
        .info {
            width: 40%;
            padding: 0 15px;
            @include media991 {
                width: 100%;
            }
            h4 {
                @include defaultFontStyle(700, 26, 1.2, $black-color);
                padding-bottom: 14px;
                margin-bottom: 14px;
                position: relative;
                &::before {
                    position: absolute;
                    left: 0;
                    right: 0;
                    // margin: 0 auto;
                    content: "";
                    height: 3px;
                    background-color: var(--green-color);
                    width: 28px;
                    bottom: 0;
                }
            }
            h6 {
                @include defaultFontStyle(400, 20, 1.2, $black-color);
                margin-bottom: 10px;
            }
            p {
                @include defaultFontStyle(400, 15, 1.2, $light-black);
            }
            .partners-info-img {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                img {
                    margin: 11px;
                    // width: 33.33%;
                    border: 1px solid transparent;
                    border-radius: 8px;
                    cursor: pointer;
                    &.active {
                        border: 1px solid $green-btn;
                    }
                }
            }
        }
        .img-group {
            width: 60%;
            text-align: center;
            display: flex;
            padding: 0 15px;
            // flex-wrap: wrap;
            @include media991 {
                width: 100%;
            }
            img {
                padding: 15px;
                margin: 0;
                width: 50%;
            }
        }
    }
}

.award-group {
    .row {
        align-items: center;
        @include media767 {
            display: block;
        }
        .info {
            width: 40%;
            padding: 0 15px;
            @include media767 {
                width: 100%;
            }
            h4 {
                @include defaultFontStyle(700, 26, 1.2, $black-color);
                padding-bottom: 14px;
                margin-bottom: 14px;
                position: relative;
                &::before {
                    position: absolute;
                    left: 0;
                    right: 0;
                    // margin: 0 auto;
                    content: "";
                    height: 3px;
                    background-color: var(--green-color);
                    width: 28px;
                    bottom: 0;
                }
            }
            h6 {
                @include defaultFontStyle(400, 18, 1.2, $black-color);
                margin-bottom: 10px;
            }
            p {
                @include defaultFontStyle(400, 15, 1.2, $light-black);
            }
            .partners-info-img {
                display: flex;
                width: 100%;
                img {
                    margin: 11px;
                    width: 33.33%;
                }
            }
        }
        .img-group {
            width: 60%;
            text-align: center;
            padding: 0 15px;
            img {
                padding: 20px 0;
            }
            @include media767 {
                width: 100%;
            }
        }
    }
}

.store-section {
    background-image: url(../../assets/images/dc/store_bg.png);
    position: relative;
    z-index: 1;
    &::after {
        content: "";
        position: absolute;
        background-image: url(../../assets/images/dc/shap_bg.png);
        background-position: right;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        max-width: 940px;
        top: 0;
        z-index: -1;

        @include media1366 {
            max-width: 680px;
        }
        @include media1199 {
            max-width: 625px;
        }
        @include media991 {
            max-width: unset;
            width: 100%;
            background-position: center;
        }
        @include media575 {
            background-size: cover;
        }
    }
    &::before {
        content: "";
        position: absolute;
        background-image: url(../../assets/images/dc/arrow-round.png);
        background-repeat: no-repeat;
        width: 15%;
        height: 100%;
        top: 44%;
        left: 822px;
        @include media1366 {
            left: 560px;
        }
        @include media1199 {
            left: 506px;
        }
        @include media991 {
            content: none;
        }
    }
    .store {
        max-width: 480px;
        text-align: center;
        @include media991 {
            margin: 0 auto;
        }
        h3 {
            @include defaultFontStyle(700, 34, 1.4, $light-color);
            margin-bottom: 32px;
        }
        .shaprater {
            height: 3px;
            width: 78px;
            background-color: $green-btn;
            margin: 30px auto;
        }
        .store-group {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            img {
                max-width: 100%;
                margin: 10px;
            }
        }
    }
}
.comming-soon {
    text-align: center;
    background-color: $light-color;
    .info {
        max-width: 1168px;
        margin: 0 auto;
        padding: 0 10px;
        h5 {
            @include defaultFontStyle(700, 35, 1.4, $black-color);
            position: relative;
            padding-bottom: 18px;
            margin-bottom: 18px;
            &::before {
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                content: "";
                height: 3px;
                background-color: var(--green-color);
                width: 28px;
                bottom: 0;
            }
        }
        p {
            @include defaultFontStyle(400, 15, 1.2, $light-black);
        }
    }
    .computer-img {
        margin-top: 35px;
    }
}
