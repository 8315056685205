.videomodal {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    transition: opacity 0.15s linear;
    padding: 0;
    z-index: 1074;
    &.show {
        display: block;
        opacity: 1;
    }
    .modal-dialog {
        position: relative;
        width: auto;
        display: -ms-flexbox;
        display: flex;
        max-width: 700px;
        -ms-flex-align: center;
        align-items: center;
        margin: 1.75rem auto;
        min-height: calc(100% - 3.5rem);
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
        -webkit-transform: translate(0, -50px);
        transform: translate(0, -50px);
        @include media767 {
            margin: 1.75rem 15px;
            max-width: 100%;
        }
    }
    &.show {
        .modal-dialog {
            -webkit-transform: none;
            transform: none;
        }
    }
    .video-inner {
        position: relative;
        width: 100%;
        @include media767 {
            margin: 1.75rem auto;
            width: 100%;
        }
        .modal_close {
            position: absolute;
            right: -15px;
            top: -15px;
            border: 1px solid $white;
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: $white;
            z-index: 2;
            font-size: 15px;
            cursor: pointer;
        }
        .video-react {
            background-color: $black-color;
            border-radius: 5px;
            .video-react-poster {
                background-size: cover;
                border-radius: 5px;
            }
            .video-react-big-play-button {
                display: none;
            }
        }
        .slick-slider {
            .item {
                border: 5px solid $white;
                border-radius: 5px;
                align-items: center;
                position: relative;
                outline: 0;
                box-shadow: 0px 10px 5px -7px rgba(0, 0, 0, 0.09);
            }
        }
        .slick-prev {
            left: 10px;
            z-index: 1;
            opacity: 0;
            width: 30px;
            height: 30px;
            top: 30px;
            &:before {
                font-size: 28px;
                @include media991 {
                    font-size: 22px;
                }
            }
        }
        .slick-next {
            right: 10px;
            z-index: 1;
            opacity: 0;
            width: 30px;
            height: 30px;
            top: 30px;
            &:before {
                font-size: 28px;
                @include media991 {
                    font-size: 22px;
                }
            }
        }
        &:hover {
            .slick-next {
                right: 10px;
                opacity: 1;
            }
            .slick-prev {
                left: 10px;
                opacity: 1;
            }
        }
    }
}

body.video-open {
    overflow: hidden;
    padding-right: 17px;
    .common-modal {
        overflow-x: hidden;
        overflow-y: auto;
    }
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1073;
        width: 100vw;
        height: 100vh;
        opacity: 0.5;
        background-color: $black-color;
    }
}
