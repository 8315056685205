// Banner section
.main-hero {
    background: url("../../assets/images/landing/main-hero.png");
    background-position: top center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-self: center;
    min-height: 800px;
    padding: 0;
    background-size: cover;
    position: relative;
    z-index: -1;
    // &::before {
    //     position: absolute;
    //     height: 100%;
    //     width: 100%;
    //     content: "";
    //     background-color: rgba(0, 0, 0, 0.6);
    //     z-index: -1;
    // }
    @include media767 {
        min-height: 650px;
    }
    .main-hero-text {
        text-align: center;
        h1 {
            margin-bottom: 30px;
            margin-top: 15px;
            @include defaultFontStyle(700, 52, 1.4, $white);
            @include media991 {
                @include defaultFontStyle(700, 32, 1.4, $white);
            }
            @include media767 {
                @include defaultFontStyle(700, 30, 1.4, $white);
            }
            @include media575 {
                @include defaultFontStyle(700, 26, 1.4, $white);
            }
            @include media480 {
                @include defaultFontStyle(700, 24, 1.4, $white);
            }
        }
        .btn {
            margin: 0 8px;
            @include media575 {
                margin: 0 5px;
            }
        }
        .newLogo {
            display: flex;
            align-items: center;
            max-width: 365px;
            width: 100%;
            margin: 0 auto;
            justify-content: center;
            padding-top: 25px;
            @include media767 {
                flex-direction: column;
                max-width: 300px;
            }
            img {
                margin-bottom: 10px;
            }
        }
    }
}

.buy_text {
    text-align: center;
    .heading-title {
        margin-top: 60px;
        h2 {
            margin-bottom: 0;
            font-size: 40px;
            @include media991 {
                font-size: 24px;
            }
            @include media767 {
                font-size: 22px;
            }
            @include media480 {
                font-size: 20px;
            }
            &::before {
                display: none;
            }
        }
    }
    .heading-title-img {
        max-width: 50%;
        width: 100%;
        margin: auto;
        @include media991 {
            max-width: 100%;
        }
    }
}

// Other font size
.other-size {
    h3 {
        @include defaultFontStyle(700, 40, 1.4, $black-color);
        display: block;
        margin-bottom: 20px;
        @include media991 {
            @include defaultFontStyle(700, 22, 1.4, $black-color);
            br {
                display: none;
            }
        }
        @include media480 {
            @include defaultFontStyle(700, 20, 1.4, $black-color);
        }
        @include media350 {
            @include defaultFontStyle(700, 18, 1.4, $black-color);
        }
    }
}

//Common appstore
.common-appstore {
    display: flex;
    justify-content: space-around;
    @include media767 {
        flex-wrap: wrap;
    }
    .heading-for {
        width: 50%;
        @include media767 {
            order: 2;
            margin-top: 30px;
            width: 100%;
        }
        @include media767 {
            h3 {
                br {
                    display: none;
                }
            }
        }
        h4 {
            @include defaultFontStyle(600, 30, 1.4, $black-color);
            margin-bottom: 15px;
            @include media991 {
                @include defaultFontStyle(600, 18, 1.4, $black-color);
            }
        }
        p {
            font-size: 20px;
            @include media991 {
                font-size: 16px;
            }
            @include media480 {
                font-size: 14px;
            }
        }
    }
    .border_way {
        width: 50%;
        position: relative;
        display: block;
        padding-left: 18px;
        padding-bottom: 18px;
        z-index: 1;
        margin-left: 40px;
        height: 100%;
        @include media767 {
            margin: 0;
            order: 1;
            width: 100%;
        }
        @include media350 {
            padding-left: 15px;
            padding-bottom: 15px;
        }
        img {
            border-radius: 5px;
            width: 100%;
        }
        &:before {
            border-radius: 5px;
            position: absolute;
            left: 0;
            right: 18px;
            border: 2px solid $gray-light;
            content: "";
            top: 18px;
            bottom: 0;
            z-index: -1;
            @include media350 {
                right: 15px;
                top: 15px;
            }
        }
    }
}

//Logo section
.common-class {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 80px;
    @include media1199 {
        margin-bottom: 60px;
    }
    @include media767 {
        flex-wrap: wrap;
        flex-direction: column;
        margin: 0px 0 40px;
    }
    .heading-logo-title {
        text-align: center;
        width: 50%;
        margin: 0 60px;
        @include media1199 {
            margin: 0 auto;
        }
        @include media767 {
            width: 100%;
            order: 2;
            margin-top: 30px;
        }
        .heading-logo {
            width: 300px;
            margin: 0 auto;
            display: block;
            margin-bottom: 25px;
            @include media1199 {
                width: 200px;
            }
            @include media991 {
                width: 160px;
            }
            &.w-100 {
                width: 80%;
                @include media1199 {
                    width: 280px;
                }
                @include media991 {
                    width: 200px;
                }
                @include media575 {
                    width: 220px;
                }
            }
        }
        h4 {
            @include defaultFontStyle(600, 32, 1.2, $black-color);
            @include media1199 {
                @include defaultFontStyle(600, 22, 1.2, $black-color);
            }
            @include media767 {
                @include defaultFontStyle(600, 20, 1.2, $black-color);
            }
            @include media350 {
                @include defaultFontStyle(600, 18, 1.2, $black-color);
            }
        }
        span {
            margin: 40px 0;
            display: block;
            @include defaultFontStyle(500, 24, 1.2, $black-color);
            @include media767 {
                margin: 30px 0;
                @include defaultFontStyle(500, 20, 1.2, $black-color);
            }
            @include media350 {
                @include defaultFontStyle(500, 18, 1.2, $black-color);
                margin: 25px 0;
            }
        }
        .heading-btn {
            .btn {
                margin: 0 10px;
                @include media991 {
                    margin: 0 5px;
                }
            }
        }
    }
    .for_way {
        position: relative;
        display: block;
        padding-left: 18px;
        padding-bottom: 18px;
        z-index: 1;
        width: 50%;
        margin: 0 60px;
        @include media1199 {
            margin: 0 30px;
        }
        @include media991 {
            margin: 0;
        }
        @include media767 {
            width: 100%;
            order: 1;
        }
        @include media350 {
            padding-left: 15px;
            padding-bottom: 15px;
        }
        img {
            border-radius: 4px;
            width: 100%;
        }
        &:before {
            border-radius: 4px;
            position: absolute;
            left: 0;
            right: 18px;
            background-color: $gray-light;
            content: "";
            top: 18px;
            bottom: 0;
            z-index: -1;
            @include media350 {
                top: 15px;
                right: 15px;
            }
        }
        &.bg-none {
            &:before {
                display: none;
            }
        }
    }
}

.perks-section {
    // display: flex;
    // align-items: center;
    // padding: 30px 0;

    .perks-inof {
        // width: 50%;
        margin: 0 60px;
        text-align: left;
        @include media1366 {
            margin: 0 30px;
        }
        @include media767 {
            width: 100%;
            margin: 0;
            padding-top: 15px;
        }
        h3 {
            @include defaultFontStyle(600, 38, 1.2, $black-color);
            margin-bottom: 15px;
            @include media991 {
                font-size: 30px;
                margin-bottom: 8px;
            }
        }
        p {
            margin-bottom: 10px;
            // max-width: 500px;
            font-size: 18px;
            @include media991 {
                font-size: 16px;
            }
        }
    }
    .img-link {
        display: flex;
        align-items: center;
        padding: 30px 0;
        @include media575 {
            display: block;
        }
        .perks-img {
            width: 50%;
            margin: 0 60px;
            @include media1366 {
                margin: 0 30px;
            }
            @include media767 {
                width: 100%;
                margin: 0;
            }
            img {
                max-width: 100%;
            }
        }
    }
    .link-info {
        width: 50%;
        margin: 0 60px;
        text-align: left;
        @include media991 {
            margin: 0 20px;
        }
        @include media575 {
            margin: 10px 0;
        }
    }
}
