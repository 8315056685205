.playercart {
    .container {
        max-width: 1010px;
    }
    .white-bg {
        padding: 30px;
        @include media575 {
            padding: 20px;
        }
        .car_profile {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 30px;
            @include media767 {
                margin-bottom: 20px;
            }
            .car_profile_img {
                display: flex;
                align-items: center;
                width: 50%;
                @include media767 {
                    margin-bottom: 20px;
                    width: 100%;
                }
                .car_profile_images {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 110px;
                    height: 110px;
                    border: 1px solid $gray-btn;
                    border-radius: 100%;
                    @include media575 {
                        width: 80px;
                        height: 80px;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                        object-fit: cover;
                    }
                }
                .car_profile_name {
                    margin-left: 30px;
                    @include media991 {
                        margin-left: 20px;
                    }
                    h4 {
                        @include defaultFontStyle(600, 20, 1.2, $black-color);
                        @include media575 {
                            font-size: 15px;
                        }
                    }
                    p {
                        margin: 10px 0;
                        @include media575 {
                            margin: 6px 0;
                            font-size: 12px;
                        }
                    }
                    .btn {
                        font-size: 14px;
                        padding: 6px 0;
                        margin-left: 5px;
                        width: 70px;
                        z-index: 1;
                        position: relative;
                        @include media575 {
                            font-size: 13px;
                            padding: 5px 10px;
                        }
                    }
                    .share-icon {
                        position: relative;
                        display: inline-block;
                        .blue_btn {
                            font-size: 14px;
                            padding: 6px 0;
                            z-index: 2;
                            width: 70px;
                            position: relative;
                            @include media575 {
                                font-size: 13px;
                                padding: 5px 10px;
                            }
                        }
                        ul {
                            list-style: none;
                            display: flex;
                            align-items: center;
                            border: 1px solid $gray-btn;
                            border-radius: 8px;
                            position: absolute;
                            visibility: hidden;
                            opacity: 0;
                            transition: all 0.2s ease 0s;
                            margin-top: 5px;
                            width: 120px;
                            justify-content: center;
                            top: calc(100% - 32px);
                            background-color: $white;
                            li {
                                margin: 3px 5px;
                                list-style: none;
                                display: inline-block;
                                vertical-align: middle;
                                transform: scale(0.92);
                                transition: all 0.5s ease 0s;
                                button {
                                    width: 28px;
                                    height: 28px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    outline: none;
                                    svg {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                                &:hover {
                                    transform: scale(1);
                                    transition: all 0.5s ease 0s;
                                }
                            }
                        }
                        &.active {
                            ul {
                                top: 100%;
                                visibility: visible;
                                opacity: 1;
                                transition: all 0.2s ease 0s;
                            }
                        }
                    }
                }
            }
            .car_profile_dec {
                width: 50%;
                @include media767 {
                    width: 100%;
                }
                @include media480 {
                    border: none;
                }
                .cart-logo {
                    display: block;
                    max-width: 220px;
                    margin: 0px auto;
                    @include media575 {
                        max-width: 180px;
                    }
                }
                .car_profile_dec_innner {
                    border: 1px solid $gray-btn;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    margin-top: 20px;
                    @include media575 {
                        border: none;
                        margin-top: 0;
                    }
                    ul {
                        width: 50%;
                        list-style: none;
                        text-align: center;
                        @include media575 {
                            border: 1px solid $gray-btn;
                            width: 100%;
                            margin-top: 20px;
                        }
                        li {
                            text-align: left;
                            list-style: none;
                            border-right: 2px solid $white;
                            padding: 18px 10px;
                            background-color: $light-white;
                            @include defaultFontStyle(500, 14, 1.2, $light-black);
                            span {
                                margin-right: 5px;
                                @include defaultFontStyle(600, 14, 1.2, $black-color);
                                @include media991 {
                                    font-size: 13px;
                                }
                            }
                            &:nth-child(2) {
                                background: $white;
                                border-right-color: $gray-btn;
                            }
                            @include media991 {
                                padding: 15px 10px;
                                font-size: 12px;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                            @include media575 {
                                border: none;
                            }
                        }
                        &:last-child {
                            li {
                                border-right: none;
                            }
                        }
                    }
                }
            }
        }
        .w-50 {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 15px;
            border-right: 1px solid $gray-btn;
            @include media575 {
                flex: 0 0 100%;
                max-width: 100%;
                border-right: none;
                margin-bottom: 20px;
            }
            &:last-child {
                border: none;
                margin-bottom: 0px;
            }
            .common-lable {
                font-size: 14px;
                margin: 15px 0 10px;
                &:first-child {
                    margin-top: 0;
                }
            }
            .addvideo {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;
                .common-lable {
                    margin: auto 0;
                }
                .btn {
                    font-size: 14px;
                    padding: 7px;
                    @include media575 {
                        font-size: 12px;
                        padding: 5px;
                    }
                }
            }
            .table-responsive-xl {
                margin-bottom: 10px;
                padding-bottom: 10px;
                .table {
                    text-align: center;
                    thead {
                        tr {
                            border: 1px solid $gray-btn;
                            background: $white;
                            th {
                                padding: 15px 0;
                                background-color: $light-white;
                                @include defaultFontStyle(600, 14, 1.2, $black-color);
                                span {
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                    tbody {
                        tr {
                            border: 1px solid $gray-btn;
                            background-color: $white;
                            @include defaultFontStyle(500, 14, 1.2, $light-black);
                            td {
                                padding: 15px 0;
                            }
                        }
                    }
                }
            }
            .slick-slider {
                overflow: hidden;
                .slick-list {
                    margin: 0 -15px;
                    @include media767 {
                        margin: 0 -10px;
                    }
                    .items {
                        position: relative;
                        height: 100%;
                        width: 155px;
                        margin: 2px 15px;
                        display: flex;
                        align-items: center;
                        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.19);
                        border-radius: 5px;
                        // @include media991 {
                        //     width: 200px;
                        // }
                        // @include media767 {
                        //     margin: 0 10px;
                        //     width: 220px;
                        // }
                        .video_img {
                            position: relative;
                            display: flex;
                            align-items: center;
                            position: relative;
                            justify-content: center;
                            width: 155px;
                            height: 155px;
                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 5px;
                                object-fit: cover;
                            }
                            .video-icon {
                                width: 32px;
                                height: 32px;
                                background-color: rgba(51, 52, 64, 0.8);
                                border-radius: 100%;
                                color: $white;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin: 0 auto;
                                position: absolute;
                                left: 0;
                                right: 0;
                                cursor: pointer;
                                font-size: 22px;
                                padding-left: 2px;
                                &:hover {
                                    background-color: var(--green-color);
                                    color: $white;
                                }
                            }
                        }
                        .video_dec {
                            position: absolute;
                            bottom: 0px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            left: 0px;
                            right: 0;
                            padding: 8px 10px;
                            background-color: rgba(51, 52, 64, 0.8);
                            border-radius: 0 0 5px 5px;
                            h6 {
                                @include defaultFontStyle(500, 12, 1.2, $white);
                                text-transform: capitalize;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                            span {
                                @include defaultFontStyle(500, 12, 1.2, $white);
                            }
                        }
                    }
                }
            }
            .collage_logo_name {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                .collage_logo_img {
                    border: 1px solid $gray-btn;
                    border-radius: 5px;
                    max-width: 130px;
                    height: 70px;
                    margin: 0 15px 15px 0;
                    @include media991 {
                        max-width: 100px;
                        margin-right: 5px;
                    }
                    @include media575 {
                        max-width: 120px;
                    }
                    img {
                        width: 100%;
                        border-radius: 5px;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}
