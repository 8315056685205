//  licenceassignment bollr  css
.licencetabs {
    .edit_pro_scroll {
        .edit_pro_inner {
            // justify-content: center;
            // align-items: center;
            li {
                // margin-right: 30px;
                // @include media1199 {
                //     margin-right: 5px;
                // }
            }
        }
    }
}
//  licenceassignment bollr  css
.licenceassignment-bollr {
    .tableinfo {
        padding: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include media350 {
            display: block;
        }
    }
    span {
        color: $blue;
    }
    .btn-addnewevent {
        margin-top: 0px;
        padding: 10px 12px;
        @include media350 {
            margin-top: 10px;
        }
    }
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        table {
            th {
                min-width: 150px;
                border-right: 0px;
                padding: 15px;
            }
            td {
                border-right: 0px;
                padding: 15px;
                &.edit-deleter {
                    display: flex;
                    a {
                        margin: 0 5px;
                    }
                }
            }
        }
    }
    .tab-bar {
        padding: 25px;
        border-bottom: 1px solid $gray-light;
        ul {
            display: flex;
            list-style: none;
            li {
                margin-right: 22px;
                a {
                    @include defaultFontStyle(500, 22, 1.2, $light-black);
                    &:hover {
                        color: var(--green-color);
                    }
                    &.active {
                        color: var(--green-color);
                    }
                }
            }
        }
    }
}

.assignlicense-modal {
    .modal-content .modal-body {
        .next-button {
            padding: 25px 0;
            .btn {
                padding: 9px 40px;
            }
        }
    }
    label {
        font-weight: 600;
    }
}
