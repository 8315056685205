// Banner section
.hero-banner {
    background: url(../../assets/images/ballr/banner_hero.png);
    background-position: top center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-self: center;
    min-height: 800px;
    padding: 0;
    background-size: cover;
    position: relative;
    z-index: 1;
    // &::before {
    //     position: absolute;
    //     height: 100%;
    //     width: 100%;
    //     content: "";
    //     background-color: rgba(0, 0, 0, 0.6);
    //     z-index: -1;
    // }
    .row {
        align-items: center;
        @include media1199 {
            justify-content: space-between;
        }
        .col-lg-6 {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 15px;
            @include media991 {
                -ms-flex: 0 0 58.333333%;
                flex: 0 0 58.333333%;
                max-width: 58.333333%;
            }
            @include media767 {
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .col-lg-5 {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 15px;
            @include media1199 {
                -ms-flex: 0 0 41.666667%;
                flex: 0 0 41.666667%;
                max-width: 41.666667%;
            }
            @include media767 {
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .hero_img {
            max-width: 100%;
            margin-left: 0;
            @include media767 {
                max-width: 420px;
                margin: 0px auto 0;
            }
            img {
                width: 100%;
                height: 100%;
            }
        }
        .hero-dec {
            text-align: center;
            margin-top: 50px;
            .hero-logo {
                display: inline-block;
                max-width: 350px;
                @include media1199 {
                    max-width: 250px;
                }
                @include media767 {
                    max-width: 220px;
                }
                @include media575 {
                    max-width: 180px;
                }
            }
            h1 {
                margin: 20px 0 20px;
                @include defaultFontStyle(700, 40, 1.4, $white);
                @include media1429 {
                    font-size: 32px;
                }
                @include media1199 {
                    font-size: 28px;
                }
                @include media991 {
                    margin: 20px 0 10px;
                    font-size: 26px;
                }
                @include media575 {
                    font-size: 24px;
                }
                @include media480 {
                    font-size: 20px;
                }
                @include media350 {
                    font-size: 18px;
                }
            }
            p {
                margin: 0px 0 40px;
                @include defaultFontStyle(500, 36, 1.2, $white);
                @include media1199 {
                    font-size: 24px;
                    margin-bottom: 30px;
                }
                @include media991 {
                    font-size: 22px;
                }
                @include media480 {
                    font-size: 18px;
                    margin-bottom: 20px;
                }
            }
            .hero-btn {
                .btn {
                    padding: 16px 50px;
                    margin: 0 15px;
                    @include media1199 {
                        padding: 14px 35px;
                        margin: 0 5px;
                    }
                    @include media767 {
                        padding: 12px 18px;
                        font-size: 14px;
                    }
                    @include media350 {
                        padding: 12px 9px;
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

.buy_text {
    text-align: center;
    p {
        font-size: 20px;
        max-width: 65%;
        margin: 0 auto 20px;
        @include media991 {
            max-width: 100%;
            font-size: 18px;
        }
        @include media480 {
            font-size: 14px;
        }
    }
}

.one_way_inner {
    display: block;
    img {
        width: 100%;
        height: 100%;
    }
}

// slider section
.one_way_section {
    padding: 0;
    > .white_bg {
        padding-top: 60px;
        @include media767 {
            padding-top: 40px;
        }
    }
    .ballr_slider {
        overflow: hidden;
        padding-top: 30px;
        padding-bottom: 30px;
        .slick-slider {
            .slick-slide {
                .item {
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 4px;
                    }
                }
            }
            &:hover {
                .slick-next {
                    right: -120px;
                    opacity: 1;
                    @include media1599 {
                        right: 15px;
                    }
                }
                .slick-prev {
                    left: -120px;
                    opacity: 1;
                    @include media1599 {
                        left: 15px;
                    }
                }
            }
        }
        .slick-prev {
            left: -20px;
            z-index: 1;
            opacity: 0;
            width: 60px;
            height: 60px;
            transition: all 0.5s ease 0s;
            @include media1599 {
                width: 30px;
                height: 30px;
            }
            &:before {
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                background: url(../../assets/images/ballr/slider_left.svg) center center no-repeat;
                opacity: 0.6;
                transition: all 0.5s ease 0s;
                @include media1599 {
                    opacity: 1;
                    width: 30px;
                    height: 30px;
                    background-color: $light-black;
                    background-size: 15px;
                    border-radius: 100%;
                    box-shadow: 0px 10px 5px -7px rgba(0, 0, 0, 0.09);
                }
            }
            &:hover {
                &:before {
                    opacity: 1;
                }
            }
        }
        .slick-next {
            right: -20px;
            z-index: 1;
            opacity: 0;
            width: 60px;
            height: 60px;
            transition: all 0.5s ease 0s;
            @include media1599 {
                width: 30px;
                height: 30px;
            }
            &:before {
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                content: "";
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0;
                background: url(../../assets/images/ballr/slider_right.svg) center center no-repeat;
                opacity: 0.6;
                transition: all 0.5s ease 0s;
                @include media1599 {
                    opacity: 1;
                    width: 30px;
                    height: 30px;
                    background-color: $light-black;
                    background-size: 15px;
                    border-radius: 100%;
                    box-shadow: 0px 10px 5px -7px rgba(0, 0, 0, 0.09);
                }
            }
            &:hover {
                &:before {
                    opacity: 1;
                }
            }
        }
    }
    h3 {
        font-size: 40px;
        @include media1199 {
            font-size: 32px;
            padding-bottom: 10px;
            margin-bottom: 10px;
        }
        @include media991 {
            font-size: 30px;
        }
        @include media767 {
            font-size: 22px;
        }
    }
    h2 {
        font-size: 40px;
        @include media1199 {
            font-size: 32px;
            padding-bottom: 10px;
            margin-bottom: 10px;
        }
        @include media991 {
            font-size: 30px;
        }
        @include media767 {
            font-size: 22px;
        }
    }
    span {
        display: block;
        margin-bottom: 10px;
        @include defaultFontStyle(500, 30, 1.2, $black-color);
        @include media1199 {
            font-size: 28px;
        }
        @include media991 {
            font-size: 22px;
        }
        @include media767 {
            font-size: 20px;
        }
    }
    p {
        font-size: 20px;
        &.new-way {
            max-width: 65%;
            margin: 0 auto !important;
            @include media991 {
                max-width: 100%;
            }
        }
        @include media991 {
            font-size: 18px;
        }
        @include media767 {
            font-size: 16px;
        }
        @include media480 {
            font-size: 14px;
        }
    }
}

.for_way_section {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin: 0 -15px;
    margin-bottom: 60px;
    @include media767 {
        margin: 0 -15px 30px;
    }
    .heading-for {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
        padding: 0 15px;
        @include media1199 {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
        @include media767 {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
        @include media767 {
            margin-top: 30px;
        }
    }
    .for_way_part {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
        padding: 0 15px;
        @include media1199 {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
        @include media767 {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
        .for_way {
            position: relative;
            display: block;
            padding-left: 18px;
            padding-bottom: 18px;
            z-index: 1;
            &:before {
                border-radius: 4px;
                position: absolute;
                left: 0;
                right: 18px;
                background-color: $gray-light;
                content: "";
                top: 18px;
                bottom: 0;
                z-index: -1;
            }
            img {
                border-radius: 4px;
                width: 100%;
                height: 100%;
            }
        }
    }
    &:last-child {
        margin-bottom: 0;
    }
    &:nth-child(odd) {
        .heading-for {
            @include media767 {
                order: 2;
            }
        }
        .for_way_part {
            @include media767 {
                order: 1;
            }
        }
    }
}

// graph section
.graph_inner_section {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -15px;
    .graph-img,
    .graph-text {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 15px;
        @include media767 {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .graph-text {
        padding-left: 60px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        @include media1199 {
            padding-left: 20px;
        }
        @include media991 {
            padding-left: 15px;
        }
        .heading-for {
            h3 {
                font-size: 40px;
                @include media1199 {
                    font-size: 32px;
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                }
                @include media991 {
                    font-size: 30px;
                }
                @include media767 {
                    font-size: 22px;
                }
            }
            p {
                font-size: 20px;
                @include media991 {
                    font-size: 18px;
                }
                @include media767 {
                    font-size: 16px;
                }
                @include media480 {
                    font-size: 14px;
                }
            }
            @include media767 {
                margin-top: 15px;
            }
        }
    }
}

// video section
.for_way_video {
    position: relative;
    display: block;
    z-index: 1;
    img {
        border-radius: 4px;
        width: 100%;
        height: 100%;
    }
    .video-bg {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        .video-react-big-play-button {
            position: absolute;
            height: 60px;
            width: 60px;
            margin: 0 auto;
            z-index: 1;
            top: 50%;
            left: 0;
            right: 0;
            border: none;
            background-color: rgba(51, 52, 64, 0.8);
            border-radius: 100%;
            background-size: contain;
            border: 2px solid $white;
            display: flex;
            align-items: center;
            justify-content: center;
            &:after {
                content: "\f0da";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                padding-left: 5px;
            }
            &:before {
                display: none;
            }
            @include media480 {
                width: 40px;
                height: 40px;
                top: 45%;
            }
        }
        .video-react.video-react-fluid {
            border-radius: 5px;
            .video-react-video {
                border-radius: 5px;
            }
        }
    }
}

// Leave way section
.leave_way_section {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin: 0 -15px;
    margin-bottom: 60px;
    @include media767 {
        margin: 0 -15px 30px;
    }
    .heading-for {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
        padding: 0 15px;
        @include media1199 {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
        @include media767 {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
        @include media767 {
            margin-top: 30px;
        }
        h3 {
            font-size: 40px;
            @include media1199 {
                font-size: 32px;
                padding-bottom: 10px;
                margin-bottom: 10px;
            }
            @include media991 {
                font-size: 30px;
            }
            @include media767 {
                font-size: 22px;
            }
        }
        span {
            display: block;
            margin-bottom: 10px;
            @include defaultFontStyle(500, 24, 1.2, $black-color);
            @include media1199 {
                font-size: 22px;
            }
            @include media991 {
                font-size: 20px;
            }
            @include media767 {
                font-size: 18px;
            }
        }
        p {
            font-size: 20px;
            @include media991 {
                font-size: 18px;
            }
            @include media767 {
                font-size: 16px;
            }
            @include media480 {
                font-size: 14px;
            }
        }
    }
    .leave_way {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
        padding: 0 15px;
        @include media1199 {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
        @include media767 {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
        display: block;
        z-index: 1;
        img {
            width: 100%;
            height: 100%;
        }
    }
    &:last-child {
        margin: 0 -15px 0;
    }
    &:nth-child(2) {
        .heading-for {
            @include media767 {
                order: 2;
            }
        }
        .leave_way {
            @include media767 {
                order: 1;
            }
        }
    }
}

// Logo brand section
.brand-logo {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    > a {
        display: block;
        margin: 15px;
        @include media1199 {
            max-width: 250px;
        }
        @include media991 {
            max-width: 200px;
        }
        @include media767 {
            max-width: 220px;
        }
        @include media575 {
            max-width: 180px;
        }
        @include media480 {
            max-width: 130px;
            margin: 5px;
        }
        img {
            border-radius: 5px;
            width: 100%;
            height: 100%;
        }
    }
}

// Feature Snapshots Section
.other-dec {
    text-align: center;
    p {
        @include defaultFontStyle(500, 18, 1.4, $light-black);
        margin-bottom: 50px;
        @include media991 {
            font-size: 16px;
        }
        @include media767 {
            margin-bottom: 40px;
        }
        @include media575 {
            font-size: 15px;
            margin-bottom: 30px;
        }
        @include media480 {
            font-size: 14px;
        }
        br {
            @include media1199 {
                display: none;
            }
        }
    }
    .diamond-kinetics {
        display: block;
        margin-bottom: 50px;
        @include media767 {
            margin-bottom: 40px;
        }
        @include media575 {
            margin-bottom: 30px;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
    .heading-title {
        h2 {
            font-size: 40px;
            margin-bottom: 15px;
            @include media1199 {
                font-size: 32px;
            }
            @include media991 {
                font-size: 30px;
            }
            @include media767 {
                font-size: 22px;
            }
        }

        p {
            font-size: 20px;
            @include media991 {
                font-size: 18px;
            }
            @include media767 {
                font-size: 16px;
            }
            @include media480 {
                font-size: 14px;
            }
        }
    }

    .container {
        .row {
            justify-content: center;
            .video_part {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
                padding-right: 15px;
                padding-left: 15px;
                @include media575 {
                    -ms-flex: 0 0 100%;
                    flex: 0 0 100%;
                    max-width: 100%;
                }
                .video_img {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 15px;
                    &::before {
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        content: "";
                        background: rgba(0, 0, 0, 0.3);
                        border-radius: 5px;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 5px;
                    }
                    > a {
                        position: absolute;
                        width: 40px;
                        height: 40px;
                        margin: 0 auto;
                        left: 0;
                        right: 0;
                        top: auto;
                        border-radius: 100%;
                        opacity: 1;
                        transform: scale(1);
                        transition: all 0.2s ease 0s;
                        background-color: rgba(51, 52, 64, 0.8);
                        color: $white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-left: 2px;
                        font-size: 26px;
                        border: 2px solid $white;
                        cursor: pointer;
                        // &:hover {
                        //     background-color: var(--green-color);
                        //     color: $white;
                        // }
                    }
                    &:hover {
                        a {
                            opacity: 1;
                            transform: scale(1);
                        }
                    }
                }
                h5 {
                    @include defaultFontStyle(600, 22, 1.4, $black-color);
                    text-transform: capitalize;
                    margin-bottom: 30px;
                    @include media1199 {
                        font-size: 20px;
                    }
                    @include media575 {
                        margin-bottom: 20px;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

// Pricing section
.pricing_section {
    .heading-title {
        h2 {
            font-size: 40px;
            @include media1199 {
                font-size: 32px;
            }
            @include media991 {
                font-size: 30px;
            }
            @include media767 {
                font-size: 22px;
            }
        }
    }
    .price-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        .btn,
        .btn:focus,
        .btn:active,
        .btn:visited {
            margin: 0 10px;
            width: 150px;
            cursor: pointer;
            .btn:hover,
            .btn:focus:hover,
            .btn:active:hover,
            .btn:visited:hover,
            &.active {
                border: 2px solid var(--green-color);
                border-radius: 5px;
                color: var(--green-color);
                background-color: $white;
                box-shadow: 0px 0px 17px -5px var(--green-color-shadow);
            }
        }
    }
    .row {
        justify-content: center;
        .price_part {
            -ms-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
            padding: 0 15px;
            @include media991 {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
            }
            @include media767 {
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }
            .main-price {
                background-color: $white;
                border-radius: 23px;
                padding-bottom: 20px;
                box-shadow: 0px 0px 0px 2px rgba(77, 138, 174, 1);
                -webkit-transition: all 0.5s ease 0s;
                -moz-transition: all 0.5s ease 0s;
                -ms-transition: all 0.5s ease 0s;
                -o-transition: all 0.5s ease 0s;
                transition: all 0.5s ease 0s;
                @include media991 {
                    margin-bottom: 30px;
                }
                .price-title {
                    background: url(../../assets/images/ballr/price/silverbg.png) center center no-repeat;
                    width: 223px;
                    height: 70px;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    padding: 0px 10px;
                    @include defaultFontStyle(500, 18, 1.2, $white);

                    @include media480 {
                        width: 160px;
                        height: 50px;
                        font-size: 16px;
                        background-size: contain;
                    }
                }
                .price_value {
                    display: block;
                    margin-top: 50px;
                    text-align: center;
                    margin-bottom: 30px;
                    .price_trial {
                        color: $black-color;
                        margin: 0 0 10px 0;
                    }
                    @include media575 {
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }
                    h4 {
                        @include defaultFontStyle(600, 40, 1.4, $black-color);
                        margin-bottom: 10px;
                        @include media1199 {
                            font-size: 32px;
                        }
                        @include media575 {
                            font-size: 28px;
                        }
                    }
                    > span {
                        display: block;
                        margin-bottom: 20px;
                        @include defaultFontStyle(500, 18, 1.2, $gray-text);
                        @include media1199 {
                            margin-bottom: 15px;
                            font-size: 16px;
                        }
                    }
                    p {
                        display: block;
                        margin-bottom: 30px;
                        @include defaultFontStyle(600, 20, 1.2, $light-black);
                        @include media1199 {
                            margin-bottom: 15px;
                            font-size: 18px;
                        }
                        @include media575 {
                            font-size: 16px;
                        }
                    }
                    .payradio-button {
                        display: block;
                        text-align: center;
                        margin-bottom: 20px;
                        .round-radio {
                            text-align: left;
                            width: 120px;
                            @include defaultFontStyle(600, 18, 1.2, $light-black);
                            @include media575 {
                                font-size: 16px;
                            }
                        }
                    }
                }
                .accordion {
                    .card-header {
                        display: block;
                        text-align: center;
                        > a {
                            display: inline-block;
                            vertical-align: middle;
                            @include defaultFontStyle(500, 18, 1.2, $light-black);
                            transition: all 0.5s ease 0s;
                            @include media1199 {
                                font-size: 16px;
                            }
                            i {
                                font-weight: 500;
                                color: var(--green-color);
                                margin-left: 4px;
                                font-size: 14px;
                            }
                            &:hover {
                                color: var(--green-color);
                            }
                        }
                    }
                    .card-body {
                        max-height: 100vh;
                        overflow-y: hidden;
                        transition: max-height 0.5s ease-in-out;
                        .card-body-inner {
                            border-top: 1px solid $gray-light;
                            padding: 25px 0 0;
                            margin: 25px 15px 0;
                            @include media1199 {
                                margin-top: 20px;
                                padding-top: 20px;
                            }
                            @include media575 {
                                margin-top: 15px;
                                padding-top: 15px;
                            }
                        }
                        p {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            margin-bottom: 15px;
                            @include defaultFontStyle(500, 18, 1.2, $black-color);
                            @include media1199 {
                                font-size: 15px;
                            }
                            img {
                                display: flex;
                                align-items: center;
                                justify-self: center;
                                margin-right: 15px;
                                width: 25px;
                                height: 25px;
                                margin-left: 0;
                                @include media1199 {
                                    margin-right: 10px;
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                        .card-body-view {
                            display: block;
                            span.box-view {
                                background-color: $gray-btn;
                                display: block;
                                text-align: center;
                                margin: 20px 0;
                                padding: 18px 0;
                                @include defaultFontStyle(600, 18, 1.2, $black-color);
                                @include media1199 {
                                    padding: 15px 0;
                                    font-size: 16px;
                                }
                            }
                            p {
                                margin: 0px 15px 15px;
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
                &.highlight {
                    box-shadow: none;
                    -webkit-transition: all 0.5s ease 0s;
                    -moz-transition: all 0.5s ease 0s;
                    -ms-transition: all 0.5s ease 0s;
                    -o-transition: all 0.5s ease 0s;
                    transition: all 0.5s ease 0s;
                    .price-title {
                        background: url(../../assets/images/ballr/price/bronze.png) center center no-repeat;
                        color: $blue;
                        -webkit-transition: all 0.5s ease 0s;
                        -moz-transition: all 0.5s ease 0s;
                        -ms-transition: all 0.5s ease 0s;
                        -o-transition: all 0.5s ease 0s;
                        transition: all 0.5s ease 0s;
                        @include media480 {
                            background-size: contain;
                        }
                    }
                    .accordion {
                        .card-header {
                            > a {
                                i {
                                    &::before {
                                        content: "\f077";
                                    }
                                }
                            }
                        }
                        .card-body {
                            max-height: 0;
                            transition: all 0.5s ease-in-out;
                        }
                    }
                }
            }
        }
    }
}

// ballr section title
.custom-title {
    h2 {
        font-size: 40px;
        @include media1199 {
            font-size: 32px;
        }
        @include media991 {
            font-size: 30px;
        }
        @include media767 {
            font-size: 22px;
        }
    }
    p {
        font-size: 20px;
        &.new-way {
            max-width: 65%;
            margin: 0 auto;
            @include media991 {
                max-width: 100%;
            }
        }
        @include media991 {
            font-size: 18px;
        }
        @include media767 {
            font-size: 16px;
        }
        @include media480 {
            font-size: 14px;
        }
    }
}

// video play button
.video-react .video-react-big-play-button.big-play-button-hide {
    display: none !important;
}
