// .webgl_section {
//   position: relative;
//   width: 100%;
//   overflow: hidden;
//   padding-top: 56.25%; /* 16:9 Aspect Ratio */
//   .responsive-iframe {
//     position: absolute;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     width: 75%;
//     height: 75%;
//     border: none;
//     margin: 0 auto;
//   }
// }
.webgl-main {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.bcteam_webgl_section {
  padding: 0;
  flex: 1;
  iframe {
    // height: calc(100vh - 76px);
    height: 100%;
    width: 100%;
  }
}

.bollr-webgl {
  .maximize {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 0;
    padding: 10px 8px;
    max-width: 1173px;
    margin: auto;
    @include media1429 {
      max-width: 1000px;
    }
    img {
      width: 30px;
      height: 30px;
      vertical-align: middle;
      margin: 0;
      cursor: pointer;
    }
    span {
      padding-right: 30px;
    }
  }
}
iframe#bollr_iframe {
  max-width: 1173px;
  height: 645px;
}
iframe#pitchr_iframe {
  max-width: 1173px;
  height: 660px;
}
iframe#bollr_iframe,
iframe#pitchr_iframe {
  // max-width: 1470px;
  // height: 828px;
  @include media1429 {
    max-width: 1000px;
    max-height: 570px;
  }
}
.bollr-webgl {
  .webgl-content {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -0%);
  }
}
