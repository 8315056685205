.main-timeline {
    margin-top: -120px;
    padding-top: 0;
    position: relative;
    @include media480 {
        margin-top: -80px;
    }
    .container {
        max-width: 930px;
        @include media1199 {
            max-width: 960px;
        }
    }
    .time-line {
        width: 100%;
        background-color: $white;
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;
        flex-wrap: wrap;
        height: 85px;
        overflow: hidden;
        box-shadow: 0px 10px 5px -7px rgba(0, 0, 0, 0.09);
        @include media767 {
            height: 100%;
            padding: 20px 10px;
        }
        .timeline-scoll {
            @include media480 {
                overflow: hidden;
                overflow-x: scroll;
                padding-bottom: 20px;
                width: 100%;
            }
            .timeline-inner {
                display: flex;
                @include media480 {
                    width: 100%;
                }
                li {
                    list-style: none;
                    position: relative;
                    width: 80px;
                    @include media991 {
                        width: 60px;
                    }
                    @include media767 {
                        width: 50px;
                    }
                    @include media575 {
                        margin-right: 4px;
                        width: 40px;
                    }
                    @include media480 {
                        width: 45px;
                        margin-right: 15px;
                    }
                    a {
                        width: 45px;
                        height: 45px;
                        background: $gray-count;
                        border-radius: 50%;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        z-index: 3;
                        @include defaultFontStyle(600, 20, 1.2, $gray-text);
                        border: 2px solid transparent;
                        @include media575 {
                            width: 40px;
                            height: 40px;
                            @include defaultFontStyle(600, 16, 1.2, $gray-text);
                        }
                    }

                    &.active {
                        &:before {
                            background: $blue;
                        }

                        a {
                            color: $white;
                            background: $blue;
                        }
                    }

                    &.select {
                        &:before {
                            background: $blue;
                        }

                        a {
                            color: $gray-text;
                            border: 2px solid $blue;
                            background: $white;
                        }
                    }

                    &:before {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        height: 6px;
                        background: $gray-count;
                        content: "";
                        width: 70%;
                        left: -45px;
                        @include media991 {
                            left: -30px;
                        }
                        @include media767 {
                            left: -30px;
                        }
                        @include media575 {
                            left: -26px;
                        }
                    }

                    &:first-child {
                        &:before {
                            content: "";
                            display: none;
                        }
                    }
                }
            }
        }
        .all_btn_step {
            display: flex;
            align-items: center;
            @include media575 {
                margin-top: 10px;
            }
            button {
                margin-right: 5px;
                padding: 13px 20px;
                &:last-child {
                    margin-right: 0;
                }
                @include media1199 {
                    padding: 13px 26px;
                    font-size: 15px;
                }
                @include media767 {
                    padding: 12px 10px;
                    font-size: 14px;
                }
                @include media480 {
                    padding: 12px 40px;
                    font-size: 13px;
                }
            }
        }  
    }
}

.before-login {
    padding: 15px 0;
    overflow: visible;
    .white-bg {
        border-radius: 4px;
        padding: 40px;
        @include media991 {
            padding: 30px;
        }
        @include media767 {
            padding: 20px;
        }
        @include media480 {
            padding: 15px;
        }
        &.playes-team {
            .check_input {
                &.add_team {
                    .form-group {
                        width: 100% !important;
                    }
                }
            }
            .scrollbar_team {
                margin-bottom: 0;
            }
        }
        .title-name {
            text-align: center;
            border-bottom: 1px solid $gray-light;
            padding-bottom: 25px;
            margin-bottom: 30px;
            @include media575 {
                padding-bottom: 15px;
                margin-bottom: 20px;
            }
            h2 {
                @include defaultFontStyle(500, 26, 1.4, $black-color);
                margin-bottom: 10px;
                @include media991 {
                    @include defaultFontStyle(500, 22, 1.4, $black-color);
                }
                @include media575 {
                    @include defaultFontStyle(500, 20, 1.4, $black-color);
                    margin-bottom: 5px;
                }
                @include media480 {
                    @include defaultFontStyle(500, 17, 1.4, $black-color);
                }
            }
            p {
                margin-bottom: 0;
            }
        }
        .serch-box {
            position: relative;
            .form-control {
                padding-right: 50px;
            }
            .serch-btn {
                position: absolute;
                height: 45px;
                width: 65px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $blue;
                color: $white;
                top: 0;
                right: 0;
                border-radius: 0 4px 4px 0;
                @include media480 {
                    height: 40px;
                }
                &:hover {
                    opacity: 0.9;
                }
            }
            span.tooltip {
                visibility: visible;
                width: 120px;
                background-color: $gra-dark;
                color: $white;
                text-align: center;
                border-radius: 6px;
                padding: 5px 0;
                position: absolute;
                z-index: 1;
                bottom: 112%;
                right: -37px;
                opacity: 1;
                transition: opacity 0.5s;
                &:after {
                    content: "";
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    margin-left: -5px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: $gra-dark transparent transparent transparent;
                }
            }
            > div {
                width: 100%;
                > input,
                input:active,
                input:focus {
                    box-shadow: none;
                    height: 45px;
                    width: 100%;
                    padding: 10px;
                    border: 1px solid $gray-btn;
                    border-radius: 4px;
                    @include defaultFontStyle(500, 16, 1.2, $black-color);
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    @include media480 {
                        height: 40px;
                        @include defaultFontStyle(400, 14, 1.2, $black-color);
                    }
                    &:hover,
                    &:visited,
                    &:active,
                    &:focus {
                        border: 1px solid $light-black;
                        color: $black-color;
                    }
                    &:disabled {
                        background: $gray-btn;
                        border: 1px solid transparent;
                    }
                }
                .inner-option {
                    display: block;
                    margin-bottom: 10px;
                    font-size: 14px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .check_input {
            display: flex;
            flex-wrap: wrap;
            .check_box,
            .check_inputbox {
                width: 50%;
                padding: 0 15px;
                @include media575 {
                    width: 100%;
                    padding: 0 15px;
                }
                &.true {
                    margin-bottom: 15px;
                    width: 25%;
                    @include media991 {
                        width: 50%;
                    }
                    @include media575 {
                        width: 100%;
                    }
                    @include media480 {
                        margin-bottom: 0;
                    }
                    .check-box {
                        @include media480 {
                            flex-wrap: wrap;
                            margin-bottom: 0;
                        }
                        .checkbox {
                            width: 100%;
                            margin: 0;
                            @include media480 {
                                width: 100%;
                                margin: 0 0 10px !important;
                            }
                            label {
                                @include defaultFontStyle(500, 14, 1.4, $light-black);
                                position: relative;
                                transition: all 0.5s ease 0s;
                                border: 1px solid $gray-btn;
                                border-radius: 5px;
                                background: $light-white;
                                &:before {
                                    content: "";
                                    position: absolute;
                                    width: 16px;
                                    height: 8px;
                                    border: 3px solid $white;
                                    border-top: 0;
                                    border-right: 0;
                                    transform: rotate(-40deg) scale(0.6);
                                    top: 40%;
                                    left: 8px;
                                    transition: all 0.5s ease 0s;
                                    opacity: 0;
                                }
                                @include media480 {
                                    @include defaultFontStyle(500, 14, 1.4, $light-black);
                                    br {
                                        display: none;
                                    }
                                }
                            }
                            input[type="checkbox"] {
                                &:checked ~ label {
                                    padding-left: 20px;
                                    background: $blue;
                                    color: $white;
                                    border: 1px solid $blue;
                                    @include media991 {
                                        padding-left: 0;
                                    }
                                    &:before {
                                        transform: rotate(-40deg) scale(1);
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                }
                &.question_input {
                    .checkbox {
                        position: relative;
                        .bg_input {
                            transform: translateY(0px);
                            visibility: visible;
                            opacity: 1;
                            position: absolute;
                            top: 60px;
                            padding: 30px 15px 20px;
                            background-color: rgba(0, 0, 0, 0.8);
                            border-radius: 5px;
                            z-index: 4;
                            width: 395px;
                            transition: all 0.3s ease 0s;
                            @include media1199 {
                                width: 410px;
                            }
                            @include media991 {
                                width: 332px;
                            }
                            @include media480 {
                                width: 100%;
                            }
                            &::before {
                                position: absolute;
                                top: -14px;
                                left: 15px;
                                display: block;
                                content: "";
                                width: 0;
                                border-style: solid;
                                border-width: 0 10px 18px;
                                border-color: transparent transparent #313232;
                                box-sizing: border-box;
                                right: auto;
                                margin: 0 auto;
                                height: 0;
                            }
                            p {
                                @include defaultFontStyle(500, 14, 1.2, $white);
                                margin-bottom: 20px;
                                @include media480 {
                                    margin-bottom: 15px;
                                    font-size: 13px;
                                }
                            }
                            .form-group {
                                position: relative;
                                margin-bottom: 20px;
                                @include media480 {
                                    margin-bottom: 15px;
                                }
                                .form-control {
                                    background-color: $gra-input;
                                    border: none;
                                    height: 50px;
                                    padding-right: 85px;
                                    @include defaultFontStyle(400, 14, 1.2, $white);
                                    @include media480 {
                                        height: 40px;
                                    }
                                    &::placeholder {
                                        color: $white;
                                        opacity: 1; /* Firefox */
                                    }

                                    &:-ms-input-placeholder {
                                        /* Internet Explorer 10-11 */
                                        color: $white;
                                    }

                                    &::-ms-input-placeholder {
                                        /* Microsoft Edge */
                                        color: $white;
                                    }
                                }
                                .years-btn {
                                    height: 50px;
                                    top: 0;
                                    right: 0;
                                    font-size: 16px;
                                    color: $gra-dark;
                                    padding: 0 20px;
                                    position: absolute;
                                    line-height: 50px;
                                    border-radius: 0 5px 5px 0;
                                    background-color: $gra-bg;
                                    @include media480 {
                                        height: 40px;
                                        line-height: 40px;
                                        font-size: 14px;
                                    }
                                }
                            }
                            .clear_now {
                                margin-top: 20px;
                                display: block;
                                text-align: center;
                                @include defaultFontStyle(500, 14, 1.2, $white);
                                @include media480 {
                                    margin-top: 15px;
                                }
                                &:hover {
                                    color: var(--green-color);
                                }
                            }
                        }
                        label {
                            &:before {
                                display: none;
                            }
                        }
                        input[type="checkbox"] {
                            &:checked ~ label {
                                padding-left: 0px !important;
                            }
                        }
                    }
                }
            }
            .basic_info {
                display: flex;
                align-items: center;
                .check_inputbox {
                    width: 33.33%;
                    padding: 0 5px;
                }
            }
            .check-box {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .checkbox {
                    width: 100%;
                    position: relative;
                    margin: 0 15px;
                    margin-left: 0;
                    label {
                        width: 100%;
                        border-radius: 4px;
                        background-color: transparent;
                        text-align: center;
                        height: 45px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include defaultFontStyle(500, 16, 1.4, $blue);
                        border: 1px solid $gray-btn;
                        @include media480 {
                            @include defaultFontStyle(500, 14, 1.4, $blue);
                        }
                        @include media480 {
                            height: 40px;
                        }
                    }
                    input[type="radio"],
                    input[type="checkbox"] {
                        opacity: 0;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        cursor: pointer;

                        &:checked ~ label {
                            background-color: $blue;
                            color: $white;
                        }
                    }
                }
                &.true {
                    margin-bottom: 15px;
                    .checkbox {
                        label {
                            @include defaultFontStyle(500, 14, 1.4, $blue);
                            position: relative;
                            transition: all 0.5s ease 0s;
                            &:before {
                                content: "";
                                position: absolute;
                                width: 16px;
                                height: 8px;
                                border: 3px solid $white;
                                border-top: 0;
                                border-right: 0;
                                transform: rotate(-40deg) scale(0.6);
                                top: 40%;
                                left: 15px;
                                transition: all 0.5s ease 0s;
                                opacity: 0;
                            }
                            @include media480 {
                                @include defaultFontStyle(500, 14, 1.4, $blue);
                            }
                        }
                        input[type="checkbox"] {
                            &:checked ~ label {
                                padding-left: 15px;
                                &:before {
                                    transform: rotate(-40deg) scale(1);
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
            &.add_team {
                display: flex;
                align-items: center;
                padding-top: 15px;
                padding-bottom: 15px;
                border-bottom: 1px solid $gray-light;
                justify-content: space-between;
                @include media575 {
                    flex-wrap: wrap;
                }
                .form-group {
                    width: calc(100% - 220px);
                    @include media767 {
                        width: calc(100% - 200px);
                    }
                    @include media575 {
                        width: 100%;
                    }
                }
                .svg-btn.trans {
                    @include media575 {
                        margin-bottom: 15px !important;
                    }
                }
            }
            .social_btn {
                margin-bottom: 0;
                @include media767 {
                    > img {
                        left: 5px;
                        width: 20px;
                        padding: 0px;
                    }
                }
                @include media575 {
                    margin-bottom: 10px;
                    font-size: 13px;
                    > img {
                        left: 15px;
                        width: 35px;
                        padding: 7px;
                    }
                }
            }
            > .common-lable {
                width: 100%;
                margin: 20px 15px 8px;
            }
        }
        .otherbrand-logo {
            display: flex;
            align-items: center;
            margin: 20px 0;
            flex-wrap: wrap;
            border-bottom: 1px solid $gray-btn;
            @include media767 {
                margin: 0px 0 15px;
            }
            .brand-logo-inner {
                padding: 0 15px;
                padding-left: 0;
                @include media767 {
                    padding: 0 10px;
                    padding-left: 0px;
                }
                .brand-logo-main {
                    border: 1px solid $gray-btn;
                    display: flex;
                    margin: 0 0 15px;
                    align-items: center;
                    flex-wrap: wrap;
                    border-radius: 8px;
                    height: 70px;
                    width: 120px;
                    @include media767 {
                        width: 110px;
                        height: 60px;
                        margin: 0 0 10px;
                    }
                    @include media480 {
                        height: 70px;
                        width: 120px;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
        .scrollbar_team {
            max-height: 435px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            overflow-y: scroll;
            margin: 15px 10px;
            @include media767 {
                margin: 10px 0;
            }
            @include media575 {
                max-height: 380px;
            }
            @include media480 {
                max-height: 250px;
            }
            .select_team {
                min-height: 100%;
                width: 65%;
                margin-left: auto;
                margin-right: 50px;
                @include media767 {
                    width: 90%;
                    margin-right: auto;
                }
                .team-flex {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    margin-bottom: 15px;
                    .team-data {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .team-img {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 60px;
                            height: 60px;
                            margin-right: 20px;
                            border-radius: 100%;
                            > img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 100%;
                                border: 1px solid $gray-light;
                            }
                            @include media575 {
                                margin-right: 5px;
                            }
                            @include media480 {
                                width: 50px;
                                height: 50px;
                            }
                        }
                        h4 {
                            @include defaultFontStyle(500, 18, 1.4, $black-color);
                            @include media575 {
                                @include defaultFontStyle(500, 16, 1.4, $black-color);
                            }
                            @include media480 {
                                @include defaultFontStyle(500, 15, 1.4, $black-color);
                            }
                        }
                    }
                    .radiobox {
                        position: relative;
                        display: flex;
                        input {
                            position: absolute;
                            opacity: 0;
                            left: 0;
                            right: 0;
                            top: 0;
                            height: 100%;
                            width: 100%;
                            z-index: 1;
                            cursor: pointer;
                        }
                        span {
                            position: relative;
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            transition: all 0.5s ease 0s;
                            background-color: $gray-btn;
                            @include media480 {
                                width: 30px;
                                height: 30px;
                            }
                        }
                        span:after {
                            content: "";
                            position: absolute;
                            width: 20px;
                            height: 10px;
                            border: 3px solid $white;
                            border-top: 0;
                            border-right: 0;
                            transform: rotate(-40deg) scale(0.6);
                            top: 31%;
                            left: 11px;
                            transition: all 0.5s ease 0s;
                            opacity: 0;
                            @include media480 {
                                width: 16px;
                                height: 8px;
                                left: 7px;
                            }
                        }
                        input:checked ~ span::after {
                            transform: rotate(-40deg) scale(1);
                            opacity: 1;
                        }
                        input:checked ~ span {
                            background: $blue;
                        }
                    }
                }
            }
        }
        .claim-button {
            display: flex;
            align-items: center;
            padding-top: 20px;
            border-top: 1px solid $gray-light;
            @include media575 {
                flex-wrap: wrap;
                justify-content: center;
            }
            p {
                margin: 0;
                margin-left: 15px;
                @include media575 {
                    margin: 0;
                    margin-top: 20px;
                    font-size: 14px;
                }
            }
        }
        .add_claim {
            padding: 20px;
            border: 1px dashed $gray-light;
            margin-top: 30px;
            margin-bottom: 30px;
            @include media767 {
                padding: 15px;
                margin: 15px 0;
            }
            .title_claim {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .add-claim {
                    width: 35px;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .add_claim_inner {
                padding: 0 20px 20px;
                margin-top: 15px;
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                @include media767 {
                    padding: 0 15px 15px;
                }
                .removle_claim {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 10px;
                    margin-bottom: -25px;
                    .remove-claim {
                        width: 35px;
                        height: 35px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        z-index: 1;
                    }
                }
            }
        }
        .flex-align {
            .btn {
                margin: 0 15px;
                @include media575 {
                    margin: 0 5px;
                }
            }
        }
        .select_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .select_box_inner {
                width: 33.33%;
                padding: 0 15px;
                @include media767 {
                    width: 50%;
                }
                @include media575 {
                    width: 100%;
                }
            }
        }
        .facilitytab {
            margin-bottom: 15px;
            .tabsfacility {
                display: flex;
                list-style: none;
                align-items: center;
                background-color: $blue;
                border-radius: 5px;
                li {
                    width: 100%;
                    cursor: pointer;
                    text-align: center;
                    padding: 15px 0;
                    background-color: $gray-btn;
                    transition: all 0.5s ease 0s;
                    @include media767 {
                        padding: 12px 0;
                    }
                    @include media480 {
                        padding: 10px 0;
                    }
                    a {
                        @include defaultFontStyle(500, 16, 1.2, $black-color);
                        @include media767 {
                            @include defaultFontStyle(500, 15, 1.2, $black-color);
                        }
                    }
                    &:first-child {
                        border-radius: 5px 0px 12px 0px;
                    }
                    &:nth-child(2) {
                        border-radius: 0px 5px 0px 12px;
                    }
                    &.active {
                        background-color: $blue;
                        a {
                            color: $white;
                        }
                    }
                }
            }
            .tabsfacility-view {
                border: 1px dashed $blue;
                padding: 15px;
                border-radius: 0 0 5px 5px;
            }
            .uploadtab {
                display: flex;
                list-style: none;
                align-items: center;
                border: 1px solid $gray-light;
                border-radius: 5px;
                margin: 20px 0;
                li {
                    width: 100%;
                    text-align: center;
                    padding: 15px 0;
                    cursor: pointer;
                    background-color: transparent;
                    transition: all 0.5s ease 0s;
                    @include media767 {
                        padding: 12px 0;
                    }
                    @include media480 {
                        padding: 10px 0;
                    }
                    a {
                        @include defaultFontStyle(500, 16, 1.2, $black-color);
                        @include media767 {
                            @include defaultFontStyle(500, 15, 1.2, $black-color);
                        }
                    }
                    &:first-child {
                        border-radius: 5px 0px 0px 5px;
                    }
                    &:nth-child(2) {
                        border-radius: 0px 5px 5px 0px;
                    }
                    &.active {
                        background-color: $blue;
                        a {
                            color: $white;
                        }
                    }
                }
            }
            .uploadtab_view {
                border: 2px dashed $gray-light;
                border-radius: 5px;
                .table-responsive-xl {
                    padding: 15px;
                    table {
                        margin-bottom: 20px;
                        .common_btn {
                            min-width: auto;
                            padding: 0 15px;
                            padding-right: 0;
                            @include media767 {
                                padding-right: 15px;
                                padding-left: 0;
                            }
                            .blue_btn,
                            .red_btn {
                                padding: 0;
                                width: 100px;
                                height: 45px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                @include media480 {
                                    width: 60px;
                                    font-size: 13px;
                                    padding: 0;
                                }
                            }
                        }
                        tr {
                            border-bottom: 10px solid transparent;
                            &:last-child {
                                border-bottom: none;
                            }
                        }
                        th,
                        td {
                            min-width: 30px;
                            text-align: left;
                            padding: 0;
                            @include defaultFontStyle(400, 16, 1.2, $light-black);
                            @include media480 {
                                @include defaultFontStyle(400, 15, 1.2, $light-black);
                            }
                        }
                        td {
                            min-width: 450px;
                            @include defaultFontStyle(400, 16, 1.2, $light-black);
                            @include media991 {
                                min-width: 350px;
                            }
                            @include media767 {
                                min-width: 250px;
                            }
                            @include media480 {
                                min-width: 150px;
                                @include defaultFontStyle(400, 15, 1.2, $light-black);
                            }
                        }
                    }
                }
                .gray_btn {
                    width: 100%;
                }
            }
        }
        .ground {
            .ground_inner {
                width: calc(100% / 2);
                padding: 0 15px;
                @include media767 {
                    width: 100%;
                }
                .button_transform {
                    display: flex;
                    align-items: center;
                    margin-bottom: 50px;
                    @include media575 {
                        margin-bottom: 20px;
                    }
                    .check_input {
                        width: 100%;
                        .check_box {
                            width: 100%;
                            padding: 0;
                            .checkbox {
                                margin: 0 5px;
                                label {
                                    padding: 0 20px;
                                    @include media480 {
                                        padding: 0 10px;
                                    }
                                }
                            }
                            .check-box {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                .ground_bg {
                    padding: 30px;
                    border: 1px solid $gray-btn;
                    border-radius: 5px;
                    position: relative;
                    @include media575 {
                        padding: 15px;
                    }
                    .ground_img {
                        position: relative;
                        max-width: 320px;
                        margin: 0 auto;
                        > img {
                            width: 100%;
                            height: 100%;
                        }
                        span {
                            position: absolute;
                            margin: 0 auto;
                            cursor: pointer;
                            .inner_ground {
                                width: 28px;
                                height: 28px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 100%;
                                position: relative;
                                margin: 0 auto;
                                @include media480 {
                                    width: 22px;
                                    height: 22px;
                                }
                                .inner_span {
                                    background-color: $light-white;
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    right: 0;
                                    display: flex;
                                    border-radius: 100%;
                                    align-items: center;
                                    justify-content: center;
                                    @include defaultFontStyle(500, 10, 1.2, $black-color);
                                    @include media480 {
                                        font-size: 9px;
                                    }
                                }
                                input {
                                    position: absolute;
                                    opacity: 0;
                                    left: 0;
                                    right: 0;
                                    top: 0;
                                    z-index: 1;
                                    width: 100%;
                                    height: 100%;
                                    cursor: pointer;
                                }
                                input:checked ~ .inner_span {
                                    background-color: $blue;
                                    color: $white;
                                }
                            }
                            &.cf {
                                top: 35px;
                                left: 0;
                                right: 0;
                                @include media350 {
                                    top: 25px;
                                }
                            }
                            &.lf {
                                top: 85px;
                                left: 50px;
                                right: auto;
                                @include media991 {
                                    top: 70px;
                                    left: 40px;
                                }
                                @include media350 {
                                    left: 35px;
                                    top: 50px;
                                }
                            }
                            &.rf {
                                top: 85px;
                                left: auto;
                                right: 50px;
                                @include media991 {
                                    top: 70px;
                                    right: 40px;
                                }
                                @include media350 {
                                    right: 35px;
                                    top: 50px;
                                }
                            }
                            &.tow {
                                bottom: 50%;
                                left: auto;
                                right: 30%;
                            }
                            &.three {
                                top: auto;
                                left: 26%;
                                right: auto;
                                bottom: 25%;
                            }
                            &.ones {
                                top: auto;
                                right: 26%;
                                left: auto;
                                bottom: 25%;
                            }
                            &.gp {
                                top: auto;
                                bottom: 25%;
                                right: 0px;
                                left: 0;
                            }
                            &.see {
                                top: auto;
                                bottom: 8%;
                                right: 0px;
                                left: 0;
                            }
                            &.ss {
                                top: auto;
                                bottom: 50%;
                                right: auto;
                                left: 30%;
                            }
                            &.r {
                                top: auto;
                                bottom: 10%;
                                right: auto;
                                left: 41%;
                            }
                            &.l {
                                top: auto;
                                bottom: 10%;
                                left: auto;
                                right: 41%;
                            }
                            &.sr {
                                top: auto;
                                bottom: 10%;
                                left: auto;
                                right: 41%;
                            }
                            &.sl {
                                top: auto;
                                bottom: 10%;
                                right: auto;
                                left: 41%;
                            }
                        }
                    }
                    .button_transform {
                        display: flex;
                        align-items: center;
                        margin-bottom: 50px;
                        @include media575 {
                            margin-bottom: 20px;
                        }
                        .check_input {
                            width: 100%;
                            .check_box {
                                width: 100%;
                                padding: 0;
                                .checkbox {
                                    margin: 0 5px;
                                    label {
                                        padding: 0 20px;
                                        @include media480 {
                                            padding: 0 10px;
                                        }
                                    }
                                }
                                .check-box {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .checkbox-tab {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 15px -15px;
            @include media480 {
                flex-wrap: wrap;
            }
            .checkbox {
                width: 22%;
                position: relative;
                margin: 0 15px;
                @include media991 {
                    width: 50%;
                }
                @include media480 {
                    width: 100%;
                    margin-bottom: 10px;
                }
                input[type="radio"] {
                    opacity: 0;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    cursor: pointer;
                }
                label {
                    width: 100%;
                    border-radius: 4px;
                    background-color: transparent;
                    text-align: center;
                    height: 45px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @include defaultFontStyle(500, 16, 1.4, $blue);
                    border: 1px solid $gray-btn;
                }
                input[type="radio"] {
                    &:checked ~ label {
                        background-color: $blue;
                        color: $white;
                    }
                }
            }
        }
        .parent-table {
            .table-responsive-xl {
                max-height: 300px;
                overflow-y: scroll;
            }
            thead {
                position: sticky;
                top: 0;
            }
            table {
                width: 100%;
                table-layout: unset !important;
                th {
                    @include defaultFontStyle(500, 15, 1.4, $light-black);
                    padding: 18px 24px;
                    text-align: left;
                    background-color: $light-white;
                    border-right: 1px solid $gray-btn;
                    vertical-align: middle;
                    white-space: nowrap;
                    &:last-child {
                        border-right: 0px;
                    }
                }
                td {
                    padding: 6px 12px;
                    border-bottom: 1px solid $gray-btn;
                    border-right: 1px solid $gray-btn;
                    white-space: nowrap;
                    &:last-child {
                        border-right: 0px;
                    }
                    .fileview {
                        display: flex;
                    }
                }
                tr {
                    &:last-child {
                        td {
                            &:last-child {
                                // border-bottom: none;
                            }
                        }
                    }
                }
                .no-data {
                    p {
                        padding: 20px;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    &.leaveinfo {
        padding-top: 0px;
        margin-top: -40px;
        position: relative;
        padding-bottom: 60px;
        z-index: 1;
        @include media767 {
            padding-bottom: 40px;
        }
        .container {
            max-width: 930px;
            @include media1199 {
                max-width: 960px;
            }
        }
        .playes-team {
            padding-bottom: 15px;
        }
        .flex-align {
            margin-top: 20px;
            border-top: 1px solid $gray-btn;
            padding-top: 20px;
            .btn {
                width: 130px;
                padding: 12px 0;
                margin: 0 5px;
            }
        }
    }
    .sheprapter {
        position: relative;
        padding: 20px;
        p {
            background-color: #fff;
            text-align: center;
            width: 100px;
            margin: auto;
            position: relative;
        }
        .border {
            border-top: 1px solid $gray-light;
            // position: absolute;
            // width: 100%;
            // top: 50%;
            // z-index: -1;
            margin: -11px 0 11px;
        }
    }
}

.scrollbar_team::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $gray-btn;
    background-color: $gray-btn;
    border-radius: 5px;
}
.scrollbar_team::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
    background-color: $gray-btn;
}
.scrollbar_team::-webkit-scrollbar-thumb {
    background-color: $blue;
    border: 2px solid $blue;
    border-radius: 5px;
}

.modal-body {
    .check_input {
        display: flex;
        flex-wrap: wrap;
        .check_box,
        .check_inputbox {
            width: 50%;
            padding: 0 15px;
            @include media575 {
                width: 100%;
                padding: 0 15px;
            }
            &.true {
                margin-bottom: 15px;
                width: 25%;
                @include media991 {
                    width: 50%;
                }
                @include media575 {
                    width: 100%;
                }
                @include media480 {
                    margin-bottom: 0;
                }
                .check-box {
                    @include media480 {
                        flex-wrap: wrap;
                        margin-bottom: 0;
                    }
                    .checkbox {
                        width: 100%;
                        margin: 0;
                        @include media480 {
                            width: 100%;
                            margin: 0 0 10px !important;
                        }
                        label {
                            @include defaultFontStyle(500, 14, 1.4, $light-black);
                            position: relative;
                            transition: all 0.5s ease 0s;
                            border: 1px solid $gray-btn;
                            border-radius: 5px;
                            background: $light-white;
                            &:before {
                                content: "";
                                position: absolute;
                                width: 16px;
                                height: 8px;
                                border: 3px solid $white;
                                border-top: 0;
                                border-right: 0;
                                transform: rotate(-40deg) scale(0.6);
                                top: 40%;
                                left: 8px;
                                transition: all 0.5s ease 0s;
                                opacity: 0;
                            }
                            @include media480 {
                                @include defaultFontStyle(500, 14, 1.4, $light-black);
                                br {
                                    display: none;
                                }
                            }
                        }
                        input[type="checkbox"] {
                            &:checked ~ label {
                                padding-left: 20px;
                                background: $blue;
                                color: $white;
                                border: 1px solid $blue;
                                @include media991 {
                                    padding-left: 0;
                                }
                                &:before {
                                    transform: rotate(-40deg) scale(1);
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
            &.question_input {
                .checkbox {
                    position: relative;
                    .bg_input {
                        transform: translateY(0px);
                        visibility: visible;
                        opacity: 1;
                        position: absolute;
                        top: 60px;
                        padding: 30px 15px 20px;
                        background-color: rgba(0, 0, 0, 0.8);
                        border-radius: 5px;
                        z-index: 4;
                        width: 395px;
                        transition: all 0.3s ease 0s;
                        @include media1199 {
                            width: 410px;
                        }
                        @include media991 {
                            width: 332px;
                        }
                        @include media480 {
                            width: 100%;
                        }
                        &::before {
                            position: absolute;
                            top: -14px;
                            left: 15px;
                            display: block;
                            content: "";
                            width: 0;
                            border-style: solid;
                            border-width: 0 10px 18px;
                            border-color: transparent transparent #313232;
                            box-sizing: border-box;
                            right: auto;
                            margin: 0 auto;
                            height: 0;
                        }
                        p {
                            @include defaultFontStyle(500, 14, 1.2, $white);
                            margin-bottom: 20px;
                            @include media480 {
                                margin-bottom: 15px;
                                font-size: 13px;
                            }
                        }
                        .form-group {
                            position: relative;
                            margin-bottom: 20px;
                            @include media480 {
                                margin-bottom: 15px;
                            }
                            .form-control {
                                background-color: $gra-input;
                                border: none;
                                height: 50px;
                                padding-right: 85px;
                                @include defaultFontStyle(400, 14, 1.2, $white);
                                @include media480 {
                                    height: 40px;
                                }
                                &::placeholder {
                                    color: $white;
                                    opacity: 1; /* Firefox */
                                }

                                &:-ms-input-placeholder {
                                    /* Internet Explorer 10-11 */
                                    color: $white;
                                }

                                &::-ms-input-placeholder {
                                    /* Microsoft Edge */
                                    color: $white;
                                }
                            }
                            .years-btn {
                                height: 50px;
                                top: 0;
                                right: 0;
                                font-size: 16px;
                                color: $gra-dark;
                                padding: 0 20px;
                                position: absolute;
                                line-height: 50px;
                                border-radius: 0 5px 5px 0;
                                background-color: $gra-bg;
                                @include media480 {
                                    height: 40px;
                                    line-height: 40px;
                                    font-size: 14px;
                                }
                            }
                        }
                        .clear_now {
                            margin-top: 20px;
                            display: block;
                            text-align: center;
                            @include defaultFontStyle(500, 14, 1.2, $white);
                            @include media480 {
                                margin-top: 15px;
                            }
                            &:hover {
                                color: var(--green-color);
                            }
                        }
                    }
                    label {
                        &:before {
                            display: none;
                        }
                    }
                    input[type="checkbox"] {
                        &:checked ~ label {
                            padding-left: 0px !important;
                        }
                    }
                }
            }
        }
        .check-box {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .checkbox {
                width: 100%;
                position: relative;
                margin: 0 15px;
                margin-left: 0;
                label {
                    width: 100%;
                    border-radius: 4px;
                    background-color: transparent;
                    text-align: center;
                    height: 45px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @include defaultFontStyle(500, 16, 1.4, $blue);
                    border: 1px solid $gray-btn;
                    @include media480 {
                        @include defaultFontStyle(500, 14, 1.4, $blue);
                    }
                    @include media480 {
                        height: 40px;
                    }
                }
                input[type="radio"],
                input[type="checkbox"] {
                    opacity: 0;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    cursor: pointer;

                    &:checked ~ label {
                        background-color: $blue;
                        color: $white;
                    }
                }
            }
            &.true {
                margin-bottom: 15px;
                .checkbox {
                    label {
                        @include defaultFontStyle(500, 14, 1.4, $blue);
                        position: relative;
                        transition: all 0.5s ease 0s;
                        &:before {
                            content: "";
                            position: absolute;
                            width: 16px;
                            height: 8px;
                            border: 3px solid $white;
                            border-top: 0;
                            border-right: 0;
                            transform: rotate(-40deg) scale(0.6);
                            top: 40%;
                            left: 15px;
                            transition: all 0.5s ease 0s;
                            opacity: 0;
                        }
                        @include media480 {
                            @include defaultFontStyle(500, 14, 1.4, $blue);
                        }
                    }
                    input[type="checkbox"] {
                        &:checked ~ label {
                            padding-left: 15px;
                            &:before {
                                transform: rotate(-40deg) scale(1);
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
        &.add_team {
            display: flex;
            align-items: center;
            padding-top: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid $gray-light;
            justify-content: space-between;
            @include media575 {
                flex-wrap: wrap;
            }
            .form-group {
                width: calc(100% - 220px);
                @include media767 {
                    width: calc(100% - 200px);
                }
                @include media575 {
                    width: 100%;
                }
            }
            .svg-btn.trans {
                @include media575 {
                    margin-bottom: 15px !important;
                }
            }
        }
        .social_btn {
            margin-bottom: 0;
            @include media767 {
                > img {
                    left: 5px;
                    width: 20px;
                    padding: 0px;
                }
            }
            @include media575 {
                margin-bottom: 10px;
                font-size: 13px;
                > img {
                    left: 15px;
                    width: 35px;
                    padding: 7px;
                }
            }
        }
        > .common-lable {
            width: 100%;
            margin: 20px 15px 8px;
        }
    }
}

.custom-checkbox {
    padding: 20px 0;
    border-radius: 3px;
    label {
        @include defaultFontStyle(500, 14, 0, $light-black);
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 0px;
        line-height: 20px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    label input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .checkmark {
        position: absolute;
        top: 0px;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: var(--green-color-light);
        border-radius: 4px;
    }
    label:hover input ~ .checkmark {
        background-color: var(--green-color-light);
    }
    label input:checked ~ .checkmark {
        background-color: var(--green-color-light);
        border-radius: 3px;
    }
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    label input:checked ~ .checkmark:after {
        display: block;
    }
    label .checkmark:after {
        left: 7px;
        top: 3px;
        width: 6px;
        height: 12px;
        border: solid $white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
    }
}

.add-player {
    p {
        color: $black-color;
        margin: 0;
        font-size: 16px;
        font-weight: 400;
    }
    .player-info {
        display: flex;
        align-items: center;
        padding: 25px 0;
        @include media575 {
            flex-wrap: wrap;
        }
        img {
            width: 250px;
            height: auto;
            margin-right: 10px;
            @include media575 {
                margin: auto;
                margin-bottom: 10px;
            }
        }
    }
    ul {
        padding-left: 20px;
        li {
            margin-bottom: 10px;
        }
    }
    .player-select {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
        padding-bottom: 15px;
        .w-50 {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 15px;
            @include media575 {
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 0;
            }
        }
    }
}

.form-group.game_state_input input {
    position: static;
    opacity: 1;
}

.add-player {
    ul {
        & > p {
            font-size: 12px;
        }
    }
}
