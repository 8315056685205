.company_page_sec {
    width: 100%;
    float: left;
    padding: 50px 0;
    background: #fff;
    h2 {
        text-align: center;
        border-bottom: 1px solid #000;
        max-width: 600px;
        margin: 0 auto 40px;
    }
    .inner_page_sec {
        width: 100%;
        float: left;
        ul {
            @include media1599 {
                padding: 0 20px;
            }
            li {
                padding-bottom: 10px;
                span {
                    display: block;
                    margin-bottom: 6px;
                    &.underline {
                        display: inline;
                        border-bottom: 1px solid #000;
                    }
                }
                .italic_font {
                    font-style: italic;
                    margin-top: 7px;
                }
                p {
                    font-weight: 400;
                }
            }
            ul {
                li {
                    font-weight: 400;
                }
            }
        }
    }
    .payment_notes {
        span {
            text-align: center;
            display: block;
            margin: 60px 0 25px;
            font-weight: 400;
        }
        p {
            font-weight: 400;
        }
    }
}

.proud-sponsors {
    width: 100%;
    float: left;
    background-color: #f9f6f7;
    margin-bottom: 30px;
}
