.btn,
.btn:focus,
.btn:active,
.btn:visited {
    box-shadow: none;
    background-color: var(--green-color);
    color: $white;
    border: 2px solid var(--green-color);
    display: inline-block;
    vertical-align: middle;
    padding: 13px 36px;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    @include media1199 {
        padding: 13px 26px;
        font-size: 15px;
    }
    @include media767 {
        padding: 12px 20px;
        font-size: 14px;
    }
    @include media350 {
        padding: 12px 10px;
        font-size: 13px;
    }
    &.trans,
    &.trans:focus,
    &.trans:active,
    &.trans:visited {
        border: 2px solid var(--green-color);
        color: var(--green-color);
        background-color: transparent;
        border-radius: 5px;
        &:hover {
            background-color: var(--green-color);
            color: $white;
            border-radius: 5px;
            border: 2px solid var(--green-color);
            box-shadow: 0px 0px 17px -5px var(--green-color-shadow);
        }
    }
    &.light-green,
    &.light-green:focus,
    &.light-green:active,
    &.light-green:visited {
        background-color: var(--green-color-light);
        color: $white;
        border: 2px solid var(--green-color-light);
        border-radius: 5px;
        &:hover {
            border: 2px solid var(--green-color-light);
            border-radius: 5px;
            color: var(--green-color-light);
            background-color: $white;
            box-shadow: 0px 0px 17px -5px var(--green-color-shadow);
        }
    }
    &:hover {
        border: 2px solid var(--green-color);
        border-radius: 5px;
        color: var(--green-color);
        background-color: $white;
        box-shadow: 0px 0px 17px -5px var(--green-color-shadow);
    }
    &.white-btn {
        background-color: $light-color;
        color: $green-btn;
        border: 2px solid var $light-color;
        border-radius: 5px;
        &:hover {
            border: 2px solid var(--green-color);
            border-radius: 5px;
            color: $light-color;
            background-color: $green-btn;
            box-shadow: 0px 0px 17px -5px var(--green-color-shadow);
        }
    }
}

header {
    .btn,
    .btn:focus,
    .btn:active,
    .btn:visited {
        box-shadow: none;
        background-color: var(--green-color-light);
        color: $white;
        border: 2px solid var(--green-color-light);
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        padding: 13px 36px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
        -webkit-transition: all 0.5s ease 0s;
        -moz-transition: all 0.5s ease 0s;
        -ms-transition: all 0.5s ease 0s;
        -o-transition: all 0.5s ease 0s;
        transition: all 0.5s ease 0s;
        @include media991 {
            padding: 11px 20px;
        }
        @include media575 {
            padding: 11px 18px;
            font-size: 14px;
        }
        @include media480 {
            padding: 10px;
            font-size: 13px;
        }
        @include media350 {
            padding: 7px;
            font-size: 12px;
        }
        &.trans,
        &.trans:focus,
        &.trans:active,
        &.trans:visited {
            border: 2px solid var(--green-color-light);
            color: var(--green-color-light);
            background-color: transparent;
            border-radius: 5px;
            &:hover {
                background-color: var(--green-color-light);
                color: $white;
                border-radius: 5px;
                border: 2px solid var(--green-color-light);
                box-shadow: 0px 0px 17px -5px var(--green-color-shadow);
            }
        }
        &:hover {
            border: 2px solid var(--green-color-light);
            border-radius: 5px;
            color: var(--green-color-light);
            background-color: $white;
            box-shadow: 0px 0px 17px -5px var(--green-color-shadow);
        }
    }
}

.blue_btn,
.blue_btn:focus,
.blue_btn:active,
.blue_btn:visited {
    box-shadow: none;
    background-color: $blue;
    color: $white;
    border: 2px solid transparent;
    display: inline-block;
    vertical-align: middle;
    padding: 13px 36px;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    @include media1199 {
        padding: 13px 26px;
        font-size: 15px;
    }
    @include media767 {
        padding: 12px 20px;
        font-size: 14px;
    }
    &.trans,
    &.trans:focus,
    &.trans:active,
    &.trans:visited {
        border: 2px solid transparent;
        color: $blue;
        background-color: $gray-btn;
        border-radius: 5px;
        &:hover {
            background-color: $blue;
            color: $white;
            border-radius: 5px;
            border: 2px solid $blue;
            box-shadow: 0px 0px 17px -5px rgba(77, 138, 174, 0.9);
        }
    }
    &:hover {
        border: 2px solid $blue;
        border-radius: 5px;
        color: $blue;
        background-color: $white;
        box-shadow: 0px 0px 17px -5px rgba(77, 138, 174, 0.9);
    }
}

.red_btn,
.red_btn:focus,
.red_btn:active,
.red_btn:visited {
    box-shadow: none;
    background-color: $bg-red-light;
    color: $text-red;
    border: 2px solid transparent;
    display: inline-block;
    vertical-align: middle;
    padding: 13px 36px;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    @include media1199 {
        padding: 13px 26px;
        font-size: 15px;
    }
    @include media767 {
        padding: 12px 20px;
        font-size: 14px;
    }
    &:hover {
        border: 2px solid $text-red;
        border-radius: 5px;
        color: $white;
        background-color: $text-red;
        box-shadow: 0px 0px 17px -5px rgba(254, 52, 52, 0.9);
    }
}

.gray_btn,
.gray_btn:focus,
.gray_btn:active,
.gray_btn:visited {
    box-shadow: none;
    background-color: $gray-btn;
    color: $black-color;
    border: 2px solid $gray-btn;
    display: inline-block;
    vertical-align: middle;
    padding: 13px 36px;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    @include media1199 {
        padding: 13px 26px;
        font-size: 15px;
    }
    @include media767 {
        padding: 12px 20px;
        font-size: 14px;
    }
    &:hover {
        border: 2px solid transparent;
        border-radius: 5px;
        color: $black-color;
        background-color: $gray-btn;
        box-shadow: 0px 0px 17px -5px rgba(229, 233, 242, 0.9);
    }
}

.social_btn {
    border: none;
    background: var(--green-color);
    padding: 0;
    text-align: center;
    position: relative;
    @include defaultFontStyle(500, 16, 1.2, $white);
    display: inline-block;
    vertical-align: middle;
    border-radius: 5px;
    box-shadow: 0px 0 24px -6px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
    height: 60px;
    text-transform: capitalize;
    line-height: 60px;
    transition: all 0.5s ease 0s;
    @include media575 {
        height: 55px;
        line-height: 55px;
    }
    > img {
        position: absolute;
        left: 15px;
        color: $white;
        font-size: 26px;
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        bottom: 0;
        fill: $white;
        padding: 17px;
    }
    &:hover {
        opacity: 0.888;
        border-radius: 5px;
        color: $white;
        transition: all 0.5s ease 0s;
    }
    &.insta {
        background: $instagram;
    }
    &.facebook {
        background: $facebook;
    }
    &.snapchat {
        background: $snapchat;
    }
    &.twitter {
        background: $twitter;
    }
}

.social-icon {
    .insta {
        background: $instagram;
    }
    .facebook {
        background: $facebook;
    }
    &.snapchat {
        background: $snapchat;
    }
    .twitter {
        background: $twitter;
    }
}
.svg-btn {
    &.trans {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background-color: transparent;
        color: $blue;
        border: 1px solid $blue;
        padding: 0px 15px 0px 15px;
        border-radius: 4px;
        height: 45px;
        > img {
            margin-right: 15px;
            @include media767 {
                margin-right: 10px;
            }
        }
        &:hover {
            color: $blue;
            border: 1px solid $blue;
            box-shadow: 0px 0px 17px -5px rgba(77, 138, 174, 0.9);
            background-color: transparent;
        }
    }
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: $blue;
    color: $white;
    border: 1px solid $blue;
    padding: 0px 15px 0px 15px;
    border-radius: 4px;
    height: 45px;
    > img {
        margin-right: 15px;
        @include media767 {
            margin-right: 10px;
        }
    }
    &:hover {
        color: $white;
        border: 1px solid $blue;
        box-shadow: 0px 0px 17px -5px rgba(77, 138, 174, 0.9);
        background-color: $blue;
    }
}

.btn.gray,
.btn.gray:focus,
.btn.gray:active,
.btn.gray:visited {
    box-shadow: none;
    background-color: $gray-btn;
    color: $black-color;
    border: 2px solid $gray-btn;
    display: inline-block;
    vertical-align: middle;
    padding: 13px 36px;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    @include media1199 {
        padding: 13px 26px;
        font-size: 15px;
    }
    @include media767 {
        padding: 12px 20px;
        font-size: 14px;
    }
    &:hover {
        border: 2px solid $gray-btn;
        border-radius: 5px;
        color: $black-color;
        background-color: transparent;
        box-shadow: 0px 0px 17px -5px rgba(229, 233, 242, 0.9);
    }
}

//  round-radio button
.round-radio {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    @include defaultFontStyle(500, 15, 1.2, $black-color);
    @include media767 {
        font-size: 14px;
    }
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: var(--green-color-light);
        border-radius: 50%;
        @include media767 {
            height: 18px;
            width: 18px;
        }
        &:after {
            content: "";
            position: absolute;
            top: 5px;
            left: 5px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            @include media767 {
                width: 8px;
                height: 8px;
            }
        }
    }
    input:checked ~ .checkmark {
        background-color: var(--green-color-light);
    }
    input:checked ~ .checkmark:after {
        background: $white;
    }
}

#my-login-button-target {
    button {
        width: 100%;
        border-radius: 4px;
        height: 60px;
        background-image: url("../../assets/images/social/snapchat-icon.svg");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 0;
        background-position: 0;
        background-color: unset;
        animation: unset;
        margin-bottom: 20px;
        box-shadow: 0px 0 24px -6px rgba(0, 0, 0, 0.5);
        cursor: pointer;
        @include media575 {
            height: 55px;
            line-height: 55px;
        }
        &::before {
            content: "Snapchat";
            @include defaultFontStyle(500, 16, 1.2, $white);
        }
        span {
            display: none;
        }
    }
}

#my-login-button {
    button {
        width: 100%;
        border-radius: 4px;
        height: 60px;
        background-image: url("../../assets/images/social/snapchat-login.svg");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 0;
        background-position: 0;
        background-color: unset;
        animation: unset;
        margin-bottom: 20px;
        cursor: pointer;
        @include media575 {
            height: 55px;
            line-height: 55px;
        }
        &::before {
            content: "Snapchat";
            @include defaultFontStyle(500, 16, 1.2, $white);
            position: relative;
            left: 15px;
        }
        span {
            display: none;
        }
    }
}

// toggle_switch redio button css
.toggle_switch {
    display: flex;
    align-items: center;
    .switch {
        position: relative;
        display: inline-block;
        width: 36px;
        height: 21px;
        display: inline-block;
        vertical-align: middle;
        input {
            opacity: 0;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            cursor: pointer;
        }
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $gray-btn;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            &::before {
                position: absolute;
                content: "";
                height: 11px;
                width: 11px;
                left: 3px;
                bottom: 5px;
                background-color: $white;
                transition: 0.4s;
            }
            &.round {
                border-radius: 34px;
                &:before {
                    border-radius: 50%;
                }
            }
        }
        input:checked + .slider {
            background-color: var(--green-color);
        }
        input:checked + .slider:before {
            -webkit-transform: translateX(17px);
            -ms-transform: translateX(17px);
            transform: translateX(17px);
        }
    }
    p {
        display: inline-block;
        margin: 0;
        padding-left: 10px;
        font-weight: 400;
        font-size: 14px;
    }
}

//  normal checkbox css
.form-group-checkbox {
    display: block;
    margin-bottom: 25px;
    input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
    }

    label {
        position: relative;
        cursor: pointer;
        @include defaultFontStyle(500, 16, 1.4, $black-color);
        display: block;
        padding-left: 30px;
        @include media480 {
            @include defaultFontStyle(500, 14, 1.4, $black-color);
        }
        &:before {
            content: "";
            -webkit-appearance: none;
            background-color: $menu-bg;
            border: 2px solid var(--green-color);
            padding: 10px;
            display: inline-block;
            position: absolute;
            left: 0;
            cursor: pointer;
            margin-right: 15px;
            border-radius: 6px;
        }
    }
    input:checked + label:after {
        content: "";
        display: block;
        position: absolute;
        width: 13px;
        height: 7px;
        border: 2px solid var(--green-color);
        border-top: 0;
        border-right: 0;
        -webkit-transform: rotate(-40deg) translateY(50%);
        -ms-transform: rotate(-40deg) translateY(50%);
        transform: rotate(-40deg) translateY(50%);
        top: 16%;
        left: 4px;
    }
}

// red-status
.red-status {
    background: $bg-red-light;
    color: $text-red;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    padding: 4px 14px;
    border-radius: 24px;
    font-size: 14px;
    font-weight: 500;
    &:hover {
        color: $white;
        background-color: $text-red;
    }
}
// .green-status
.green-status {
    background: $gray-bg;
    color: var(--green-color);
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    padding: 4px 14px;
    border-radius: 24px;
    font-size: 14px;
    font-weight: 500;
    &:hover {
        color: $white;
        background-color: var(--green-color);
    }
}

// blue-status
.blue-status {
    background: $gray-bg;
    color: $blue;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    padding: 4px 14px;
    border-radius: 24px;
    font-size: 14px;
    font-weight: 500;
    &:hover {
        color: $white;
        background-color: $blue;
    }
}

.btn:disabled,
.btn:focus:disabled,
.btn:active:disabled,
.btn:visited:disabled,
.btn[disabled],
.btn:focus[disabled],
.btn:active[disabled],
.btn:visited[disabled] {
    border: 2px solid $gray-text !important;
    background-color: $gray-text !important;
    color: $white !important;
    box-shadow: none !important;
    cursor: not-allowed;
}
.btn,
button,
a {
    cursor: pointer;
}
