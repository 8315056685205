.playerdashboard {
    padding-top: 20px;
    padding-bottom: 20px;
    .event-roster-select {
        .custom-select {
            background: url("../../assets/images/social/down_arrow.svg") no-repeat 99% center #fff;
        }
    }
    .common-dashbord {
        .common-div {
            flex-wrap: wrap;
            flex-direction: unset;
            h3 {
                width: 100%;
                text-align: center;
            }
            select {
                height: 50px;
            }
        }
        .dashbord-center {
            .upload_file {
                .upload_inner {
                    align-items: center;
                    flex-direction: row;
                    background-color: $white;
                    padding: 15px 10px;
                    width: calc(100% / 2);
                    @include media575 {
                        padding: 30px;
                        width: 100%;
                        margin: 20px auto 0;
                    }
                    .upload_img {
                        margin: 0 16px;
                        width: 55px;
                        height: 55px;
                        background-color: var(--green-color-light);
                        img {
                            max-width: 25px;
                        }
                    }
                }
            }
        }
    }
    .profile-card {
        padding: 20px;
        .mainprofile {
            position: relative;
            @include media1429 {
                width: 100%;
                height: 100%;
            }
        }
        .profiletext {
            position: absolute;
            left: 0;
            right: 0;
            font-size: 16px;
            line-height: 1.2;
            color: $black-color;
            background-image: url("../../assets/images/dashborad/playertitle.png");
            height: 62px;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            width: 95%;
            bottom: 26%;
            @include media1429 {
                bottom: 33%;
                height: 56px;
            }
            @include media1199 {
                width: 61%;
                bottom: 27%;
                height: 57px;
            }
            @include media991 {
                width: 60%;
            }
            @include media767 {
                margin-top: 10%;
                margin-right: 38px;
                width: 79%;
                left: 0;
                bottom: 31%;
            }
            @include media575 {
                bottom: 26%;
                width: 46%;
            }
            @include media480 {
                width: 60%;
            }
            @include media350 {
                width: 79%;
            }
            .profiletitle {
                padding: 6px 0;
                left: 35%;
                position: absolute;
                @include media1429 {
                    padding: 6px 0;
                }
                @include media767 {
                    padding: 6px 4px;
                }
                span {
                    font-size: 10px;
                    font-weight: 400;
                }
                h5 {
                    font-size: 10px;
                    font-weight: 600;
                    line-height: 1.2;
                }
                h6 {
                    font-size: 9px;
                    word-break: break-word;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 74px;
                    white-space: nowrap;
                    @include media1429 {
                        font-size: 8px;
                    }
                    @include media991 {
                        font-size: 7px;
                    }
                    @include media767 {
                        font-size: 7px;
                    }
                    @include media480 {
                        font-size: 7px;
                    }
                }
            }
        }
        .playerprofileicon {
            float: left;
            width: 54px;
            margin-right: 7px;
            position: absolute;
            left: 8%;
            border-radius: 100%;
            height: 55px;
            border: 1px solid $gray-btn;
            > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 100%;
            }
            @include media767 {
                width: 50px;
                height: 50px;
                margin-right: 2px;
                left: 9%;
            }
            @include media480 {
                left: 9%;
                width: 50px;
                height: 50px;
            }
        }
        .img-profile {
            border-radius: 8px;
            background-color: $gra-input;
            width: 250px;
            height: 299px;
            @include media1429 {
                width: 100%;
            }
            img {
                width: 100%;
                height: 100%;
                border-radius: 8px;
                object-fit: cover;
            }
        }
        .file_profile_upload {
            cursor: pointer;
            position: relative;
            > i {
                font-size: 35px;
                color: $white;
                opacity: 0.6;
                margin-bottom: 10px;
            }
            .fa-camera {
                font-size: 15px;
                opacity: 1;
                background-color: rgba(0, 0, 0, 0.2);
                border-radius: 5px;
                cursor: pointer;
                width: 35px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 0;
                border: 1px solid $white;
            }
            input[type="file"] {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
            }
            .preview_img {
                background-color: transparent;
                width: auto;
                position: relative;
                border: 0;
                height: 100%;
                border-radius: 5px;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                left: 0;
                right: 0;
                margin: 0 auto;
                padding: 0 15px;
                pointer-events: none;
                .preview_img_inner {
                    height: 100%;
                    object-fit: cover;
                    padding: 10px;
                    border-radius: 100%;
                    max-width: 100%;
                }
                i {
                    content: "";
                    position: absolute;
                    right: 8px;
                    top: 6px;
                    width: 22px;
                    height: 22px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: $text-red;
                    text-align: center;
                    border-radius: 50%;
                    color: $white;
                    cursor: pointer;
                    font-size: 14px;
                    z-index: 1;
                    pointer-events: all;
                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
        .dragdrop-img {
            position: absolute;
            top: 12px;
            right: 12px;
            cursor: pointer;
        }
        .playerclip {
            clip-path: polygon(0 0, 100% 0, 89% 100%, 7% 100%);
            color: $white;
            font-size: 9px;
            padding: 3px 15px;
            width: 172px;
            margin-left: 8px;
            background-image: linear-gradient(to left, rgba(35, 31, 32, 0.5), rgb(86, 85, 85));
            height: 20px;
            position: absolute;
            top: 89%;
        }
    }
    .dashbord-center .slider-common .item {
        height: 300px;
    }
    .profile-btn {
        margin-top: auto;
        padding-top: 15px;
    }
    .profile-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
        padding-top: 15px;
        .btn {
            width: 100%;
            margin-right: 10px;
            padding: 13px 0;
        }
        .btn,
        .share-icon {
            width: calc(100% / 2);
        }
        .share-icon {
            position: relative;
            .blue_btn {
                z-index: 2;
                position: relative;
                width: 100%;
                padding: 13px 0;
            }
            ul {
                list-style: none;
                display: flex;
                align-items: center;
                border: 1px solid $gray-btn;
                border-radius: 8px;
                position: absolute;
                visibility: hidden;
                opacity: 0;
                transition: all 0.2s ease 0s;
                margin-top: 5px;
                width: 120px;
                justify-content: center;
                top: calc(100% - 32px);
                background-color: $white;
                li {
                    margin: 3px 5px;
                    list-style: none;
                    display: inline-block;
                    vertical-align: middle;
                    transform: scale(0.92);
                    transition: all 0.5s ease 0s;
                    button {
                        width: 28px;
                        height: 28px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        outline: none;
                        svg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    &:hover {
                        transform: scale(1);
                        transition: all 0.5s ease 0s;
                    }
                }
            }
            &.active {
                ul {
                    top: 100%;
                    visibility: visible;
                    opacity: 1;
                    transition: all 0.2s ease 0s;
                }
            }
        }
    }
}
.linkplayer-filter {
    display: flex;
    justify-content: space-between;
    @include media575 {
        display: block;
    }
    .form-group {
        flex: 0 0 50%;
        max-width: 50%;
        @include media575 {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .btn {
        white-space: nowrap;
        padding: 8px;
        margin: 0 10px;
        &:first-child {
            margin-right: 0;
        }
        @include media575 {
            margin-top: 10px;
            margin-left: 0;
            &:first-child {
                margin-right: 10px;
            }
        }
    }
}
