.coachsubscription {
    padding: 0;
    .subscriptioninfo {
        margin-bottom: 20px;
    }
    .white-bg {
        padding: 20px;
        .table {
            margin: 30px 0 10px;
            @include media480 {
                margin-top: 15px;
            }
            tr {
                border-bottom: 2px solid $gray-btn;
                @include defaultFontStyle(500, 14, 1.2, $black-color);
                td {
                    @include defaultFontStyle(500, 14, 1.2, $light-black);
                    &.lightgray {
                        color: #cbcbcb;
                        width: 53%;
                    }
                }
                th,
                td {
                    padding: 20px 0;
                }
                &:last-child {
                    border-bottom: none;
                    th,
                    td {
                        padding-bottom: 0;
                        @include media767 {
                            padding: 20px 0;
                        }
                    }
                }
                &:first-child {
                    th,
                    td {
                        padding-top: 0;
                    }
                }
                th {
                    @include media767 {
                        min-width: 300px;
                    }
                }
                td {
                    @include media767 {
                        min-width: 180px;
                        text-align: center;
                    }
                }
            }
            .subscription_link {
                text-align: right;
                a {
                    color: $text-red;
                    &:hover {
                        color: $black-color;
                    }
                }
            }
        }
    }
}
