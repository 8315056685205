.event-main {
  position: relative;
  // margin-top: 180px;
  // @include media1366 {
  //     margin-top: 127px;
  // }
  // @include media991 {
  //     margin-top: 110px;
  // }
  // @include customMedia(380) {
  //     margin-top: 102px;
  // }
  .division-btn {
    position: fixed;
    left: 1px;
    top: 124px;
    background: $black-light;
    color: $white;
    width: 25px;
    height: 25px;
    text-align: center;
    z-index: 999;
    border-radius: 50%;
    line-height: 25px;
    cursor: pointer;
    @include media1366 {
      top: 114px;
    }
    @include media991 {
      top: 114px;
    }
    &.withoutAuth-division-btn {
      top: 138px;
      @include media1366 {
        top: 119px;
      }
      @include media991 {
        top: 114px;
      }
    }
    &.active {
      i {
        transform: rotate(180deg);
      }
    }
    span {
      display: none;
    }
    &:hover {
      width: 95px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      padding: 12px;
      font-size: 12px;
      font-weight: 400;
      i {
        margin-right: 4px;
      }
      span {
        display: block;
      }
    }
  }

  //    .tooltiptext {
  //     visibility: hidden;
  //     width: 120px;
  //     background-color: black;
  //     color: #fff;
  //     text-align: center;
  //     border-radius: 6px;
  //     padding: 5px 0;

  //     /* Position the tooltip */
  //     position: absolute;
  //     z-index: 1;
  //   }

  //   .division-btn:hover .tooltiptext {
  //     visibility: visible;
  //   }
  &.custom-event-scroll {
    .upcoming_events {
      height: 1360px;
      overflow-y: auto;
      overflow-x: hidden;
    }
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px $light-color;
      background-color: $light-color;
      border-radius: 5px;
    }
    ::-webkit-scrollbar {
      width: 3px;
      border-radius: 5px;
      background-color: $light-color;
    }
    ::-webkit-scrollbar-thumb {
      background-color: $light-black;
      border: 2px solid $light-black;
      border-radius: 5px;
      &:hover {
        background-color: $light-black;
        border: 2px solid $light-black;
      }
    }
  }
}
.showcase-slider {
  padding-top: 6px;
  margin-bottom: 0;
  @include media1366 {
    padding-top: 6px;
    margin-bottom: 10px;
  }
  .showcase-division {
    display: flex;
    overflow-x: auto;
    padding-bottom: 10px;
    .showcase-box {
      margin-right: 6px;
      background: #ffffff;
      border-radius: 10px;
      &:last-child {
        margin-right: 0;
      }

      .showcase-info {
        background: $white;
        border-radius: 10px;
        width: 230px;
        .event-name {
          h3 {
            @include defaultFontStyle(500, 15, 1.4, $black-light);
            background: var(--green-color-light);
            border-radius: 10px 10px 0 0;
            color: $white;
            text-align: center;
            padding: 5px;
          }
        }
        .showcase-title {
          padding: 9px 15px;
          border-bottom: 1px solid $white-light;
          h5 {
            @include defaultFontStyle(500, 15, 1.4, $black-light);
            // text-align: center;
            sup {
              @include defaultFontStyle(500, 13, 1.4, $black-light);
              margin-left: 5px;
            }
          }
        }
        .showcase-text {
          padding: 3px 15px;
          .showcase-text-vs {
            text-align: left;
            // height: 90px;
            margin: auto;
            // display: flex;
            // flex-direction: column;
            // align-items: center;
            h4 {
              @include defaultFontStyle(500, 15, null, $light-black);
              white-space: nowrap;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-bottom: 10px;
              &:last-child {
                width: 30px;
                text-align: center;
              }
            }
            p {
              @include defaultFontStyle(500, 14, null, $white);
              background: var(--green-color-light);
              border-radius: 5px;
              width: 40px;
              height: 20px;
              margin: 6px 0;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              display: none;
            }
          }
        }
        &.central-showcase {
          background: var(--green-color-light);
          border-radius: 10px;
          width: 250px;
          position: relative;
          .setting-bar-icon {
            position: absolute;
            top: 0;
            right: 0;
            background: #ffffff36;
            padding: 6px 11px 17px 18px;
            border-radius: 0 10px 0 100%;
            img {
              width: 15px;
              height: 15px;
            }
          }
          .showcase-info-selectbox {
            padding: 20px 14px 5px 14px;
            .form-group {
              margin-bottom: 0;
              width: 100%;
              margin-right: 12px;
              &:last-child {
                margin-right: 0;
              }
              .custom-select {
                height: 30px;
                padding: 2px 25px 2px 10px;
                font-size: 14px;
              }
            }
          }
          .showcase-text {
            padding: 10px 14px 15px 14px;
            word-break: break-all;
            // height: 60px;
            align-items: center;
            display: flex;

            h3 {
              @include defaultFontStyle(400, 18, 1.2, $white);
              display: block;
              display: -webkit-box;
              max-width: 100%;
              word-break: break-word;
              // -webkit-line-clamp: 2;
              // -webkit-box-orient: vertical;
              // overflow: hidden;
              // text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $light-color;
    background-color: $light-color;
    border-radius: 5px;
  }
  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    border-radius: 5px;
    background-color: $light-color;
  }
  ::-webkit-scrollbar-thumb {
    background-color: $light-black;
    border: 2px solid $light-black;
    border-radius: 5px;
    &:hover {
      background-color: $light-black;
      border: 2px solid $light-black;
    }
  }
  // .container-fluid {
  //   padding: 0 6px;
  //   overflow: auto;
  //   max-height: 393px;
  //   .scoreLoader_item{
  //     > span{
  //       display: flex;
  //     }
  //   }
  //   .scoreLoader{
  //     min-width: 200px;
  //     height: 101px;
  //     border-radius: 10px;
  //     margin-right: 6px;

  //     &:first-child{
  //       min-width: 250px;
  //     }
  //   }
  // }
  .container-fluid {
    padding: 0 6px;
    overflow: auto;
    max-height: 393px;
    .scoreLoader_item {
      width: 230px;
      height: 126px;
      // border-radius: 10px;
      // margin-right: 6px;
      // background-color: #fff;
      // &:first-child{
      //   width: 250px;
      // }
      .info-selectbox {
        display: flex;
        width: 100%;
        padding: 20px 14px 5px 14px;
        > span {
          display: flex;
          width: 100%;
          height: 30px;
          margin-right: 12px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .event-name {
        padding: 7px 15px 3px;
        > span {
          display: flex;
          width: 100%;
          height: 21px;
        }
      }
      .showcase-text {
        padding: 10px 14px 15px 14px;
        width: 100%;
        > span {
          display: flex;
          width: 100%;
          height: 17px;
        }
      }
      .showcase-title {
        padding: 5px 15px;
        border-bottom: 1px solid #f8f6f7;
        > span {
          display: flex;
          width: 40%;
          height: 20px;
        }
      }
      .div_showcase-text {
        padding: 3px 15px;
        .showcase-text-vs {
          .d-flex {
            > span {
              display: flex;
              width: 40%;
              height: 19px;
            }
          }
          p {
            text-align: center;
            line-height: 16px;
          }
        }
      }
    }
    // .scoreLoader{
    //   min-width: 200px;
    //   height: 101px;
    //   border-radius: 10px;
    //   margin-right: 6px;
    //   background-color: #fff;
    //   &:first-child{
    //     min-width: 250px;
    //   }
    // }
  }
}
.filter-popup {
  .filter-popup-info {
    border-bottom: 1px solid #e2e5ea;
    padding-bottom: 15px;
    // padding: 0 30px 15px;
    .form-group {
      position: relative;
      input {
        background-color: $white-light;
      }
      // img {
      //     position: absolute;
      //     right: 16px;
      //     width: 22px;
      //     height: 22px;
      //     display: flex;
      //     align-items: center;
      //     justify-content: center;
      //     top: 37px;
      //     cursor: pointer;
      // }
      .custom-select {
        background-color: $white-light;
      }
    }
    .css-g1d714-ValueContainer {
      padding: 9px;
      color: $light-black;
    }
  }
  .modal-content {
    padding: 25px;
    .modal-header {
      padding: 0 !important;
      .modal_close img {
        width: 15px !important;
      }
    }
    .next-button {
      padding-top: 30px !important;
      padding-bottom: 0 !important;
    }
  }
}

// banner css
.banner_section {
  background-color: $white;
  padding-bottom: 60px;

  .banner_img {
    img {
      width: 100%;
      height: auto;

      // height: 785px;
      // width: auto;
      @include media1366 {
        max-height: 590px;
      }
    }
    .no-ads {
      height: 778px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #e2e4ea;
      border-radius: 10px;
      box-shadow: 0px 5px #f3f3f3;
      p {
        @include defaultFontStyle(700, 50, 1.5, $light-black);
      }
    }
  }
  .banner_img:hover {
    cursor: pointer;
  }
  .banner_slider {
    background: $white;
    border-radius: 10px;
    padding: 25px 30px 35px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -100px;
    position: relative;

    @include media767 {
      padding: 20px 15px 20px;
    }
    @include media575 {
      padding: 20px 15px 10px;
    }
    @include media991 {
      margin-top: 20px;
    }
    .banner_slider_box {
      box-shadow: 0px 5px #f3f3f3;
      border-radius: 10px;
      max-width: calc(25% - 20px);
      border: 2px solid #ececec;
      cursor: pointer;
      @include media767 {
        max-width: calc(25% - 10px);
      }
      @include media575 {
        max-width: calc(50% - 10px);
        margin-bottom: 20px;
      }
      img {
        width: 100%;
        height: 140px;
        min-height: 140px;
        border-radius: 9px;
        @include media767 {
          height: 120px;
          min-height: 120px;
        }
      }
      &:hover {
        border: 2px solid var(--green-color-light);
      }
      .no-ads {
        max-width: 100%;
        width: 287px;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        p {
          margin: 0;
        }
      }
    }
  }
  .addslider {
    .slick-prev {
      left: 0px;
      z-index: 1;
    }
    .slick-next {
      right: 0px;
      z-index: 1;
    }
    .slick-arrow {
      height: 70px;
      width: 36px;
      background: var(--green-color);
    }
    .slick-prev:before {
      content: "\f104";
      color: $white;
      font-size: 24px;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
    }

    .slick-next:before {
      content: "\f105";
      color: $white;
      font-size: 24px;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
    }
    .slick-dots li.slick-active button:before {
      color: var(--green-color);
    }
    .slick-dots li button:before {
      color: $gra-dark;
      opacity: 0.75;
    }
    .slick-dots li button:hover:before {
      color: var(--green-color);
      opacity: 1;
    }
    .slick-dots {
      bottom: -40px;
      li {
        width: 34px;
        button {
          background: #707c8c;
          border: 1px solid;
          width: 34px;
          height: 4px;
          padding: 1px;
          &::before {
            font-family: none;
            font-size: 0;
          }
        }
        &.slick-active {
          button {
            background: var(--green-color);
          }
        }
      }
    }
  }
}

// top event css
.topevent_section {
  background: url("../../assets/images/ballr/banner_hero.png");
  background-position: top center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-self: center;
  min-height: 512px;
  padding: 0;
  background-size: cover;
  position: relative;
  z-index: 1;
  // &::before {
  //   position: absolute;
  //   height: 100%;
  //   width: 100%;
  //   content: "";
  //   background-color: rgba(0, 0, 0, 0.6);
  //   z-index: -1;
  // }
  .event_slider {
    h1 {
      // @include defaultFontStyle(bold, 36, 1.4, $white);
      color: $white;

      padding-bottom: 15px;
      &:before {
        background-color: $white;
      }
    }
  }
}

.event_slider {
  overflow: hidden;
  margin: 0 -10px;
  position: relative;
  .all_event {
    margin-top: 34px;
    &:hover {
      .slick-arrow {
        z-index: 2;

        &.slick-prev {
          left: 20px;
        }
        &.slick-next {
          right: 20px;
        }
      }
    }
    .event_box {
      padding: 0 10px;
      .event_card {
        position: relative;
        &.invite {
          border: 1.2px solid $gray-btn;
        }
        .black-layer {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #000000bf;
          z-index: 9;
          border-radius: 4px;
          display: flex;
          align-items: center;
          text-align: center;
          padding: 10px;
          p {
            color: $white;
          }
        }
      }
    }
    .slick-track {
      margin: 0;
    }
  }
}

.event_box {
  .event_card {
    border: 2px solid $white;
    border-radius: 5px;
    background: $white;
    box-shadow: 1px 3px 5px #8080803d;
    margin-bottom: 20px;
    &:hover {
      border: 2px solid var(--green-color-light);
    }
    .event_card_img {
      position: relative;
      img {
        max-width: 100%;
        min-height: 190px;
        max-height: 190px;
        width: 100%;
        object-fit: cover;
      }
      .event_card_address {
        display: block;
        @include defaultFontStyle(500, 14, 1.2, $white);
        padding: 6px 10px 6px 32px;
        position: absolute;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 10px 0 0 0;
        &:before {
          content: "";
          background: url("../../../src/assets/images/comapnylanding/map_marker.svg") no-repeat;
          width: 12px;
          height: 16px;
          background-size: 100% 100%;
          left: 7px;
          z-index: 1;
          position: absolute;
          top: 7px;
        }
      }
    }
    .event_tags {
      margin-top: 5px;
      margin-bottom: 6px;
      padding-right: 5px;
      position: relative;
      height: 0px;
      font-size: 13px;
      font-weight: 800;
      span {
        background: var(--green-color-light);
        display: inline-block;
        padding: 5px 8px;
        color: #fff;
        font-size: 11px;
      }
    }
    .event_card_desc {
      padding: 12px 10px 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      .event_card_logo {
        margin-right: 12px;
        width: 78px;
        height: 78px;
        padding: 8px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .event_card_titles {
        width: calc(100% - 90px);
        h3 {
          @include defaultFontStyle(600, 16, 1.2, $black-light);
          margin-bottom: 10px;
          margin-top: 12px;
        }
      }
      .roster-sign-up {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        .event-roster-select {
          margin-bottom: 5px;
          .custom-select {
            font-size: 13px;
            height: 32px;
            max-width: 180px;
            padding: 7px 25px 8px 10px;
            background-size: 12px;
          }
        }
        .leave-event {
          display: flex;
          flex-direction: column;
          margin-left: 5px;
          button {
            padding: 5px 6px;
            font-size: 12px;
            margin-bottom: 5px;
          }
        }
      }

      .event_card_dates {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        // justify-content: space-between;
        p {
          position: relative;
          padding-right: 20px;
          margin-bottom: 5px;
          padding-left: 26px;
          &.date_icon {
            &:before {
              position: absolute;
              content: "";
              background: url("../../../src/assets/images/comapnylanding/date_icon.svg") no-repeat;
              width: 15px;
              height: 16px;
              background-size: cover;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          &.team_icons {
            &:before {
              position: absolute;
              content: "";
              background: url("../../../src/assets/images/comapnylanding/user_icons.svg") no-repeat;
              width: 19px;
              height: 15px;
              background-size: cover;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
            &.showSpotAvail {
              font-weight: bold;
              color: #221f34;
            }
          }
        }
      }
      .event_tags {
        position: absolute;
        right: 5px;
        top: 3px;
        @include media767 {
          top: 1px;
        }
        span {
          background: var(--green-color);
          display: inline-block;
          padding: 2px 8px;

          @include defaultFontStyle(400, 12, 1.2, $white);
        }
      }
    }
  }
}

// upcoming event section
.upcomingevent_section {
  padding: 60px 0 35px;
  background: $white;
  @include media1366 {
    padding: 30px 0 35px;
  }
  .upcoming_events_title {
    position: relative;
    .see_post {
      @include defaultFontStyle(500, 18, 1.7, var(--green-color-light));
      text-decoration: underline;
      position: absolute;
      top: 25%;
      right: 0;
      @include media1366 {
        position: relative;
        position: relative;
        display: flex;
        justify-content: center;
        top: 14px;
      }

      a {
        padding: 0 10px;
        color: var(--green-color);
        border-right: 1px solid var(--green-color);
        &:last-child {
          border-right: none;
        }
      }
    }
  }
  .event-filter {
    padding: 30px 0 10px;
    display: flex;
    // flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    @include media767 {
      display: block;
    }
    @include media575 {
      padding: 30px 0 20px;
    }
    .form-group {
      max-width: 100%;
      width: 100%;
      margin-right: 30px;
      @include media991 {
        margin-right: 10px;
      }
      @include media575 {
        max-width: 100%;
        margin-right: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      input {
        background: $white-light !important;
      }
      input:placeholder-shown {
        text-overflow: ellipsis;
      }
      .custom-select {
        background-color: $white-light !important;
      }
      img {
        background-color: $white-light;
      }
    }
  }
  .custom-checkbox {
    background: $white-light;
    padding: 12px 15px 13px;
    border-radius: 3px;
    label {
      @include defaultFontStyle(500, 14, 0, $light-black);
      display: block;
      position: relative;
      padding-left: 30px;
      margin-bottom: 0px;
      line-height: 20px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    label input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      position: absolute;
      top: 0px;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: var(--green-color-light);
      border-radius: 4px;
    }
    label:hover input ~ .checkmark {
      background-color: var(--green-color-light);
    }
    label input:checked ~ .checkmark {
      background-color: var(--green-color-light);
      border-radius: 3px;
    }
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    label input:checked ~ .checkmark:after {
      display: block;
    }
    label .checkmark:after {
      left: 7px;
      top: 3px;
      width: 6px;
      height: 12px;
      border: solid $white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
  .event-group {
    margin-bottom: 40px;
    text-align: center;
    a {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
    .btn {
      &.active {
        background-color: var(--green-color);
        color: $white;
        border-radius: 5px;
        border: 2px solid var(--green-color);
        box-shadow: 0px 0px 17px -5px var(--green-color-shadow);
      }
    }
  }
  .upcoming_events {
    padding-top: 30px;
    .event_box {
      .event_card {
        cursor: pointer;
      }
    }
  }
}

// room_spotlight
.room_spotlight {
  padding: 60px 0;
  @include media991 {
    padding: 30px 0;
  }
  .room_spotlight_card {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    margin-top: 30px;
    @include media991 {
      padding: 20px;
    }
    img {
      margin-bottom: 30px;
    }
    .section_title_left {
      @include defaultFontStyle(700, 24, 1.4, $black);
    }
    p {
      margin-top: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .twitter-facebook {
      display: flex;
      margin-bottom: 25px;
      .social-btn {
        @include defaultFontStyle(600, 14, 1.2, $light-black);
        background-color: $white-light;
        padding: 17px 35px;
        border-radius: 5px;
        margin-right: 25px;
        @include media991 {
          padding: 15px;
        }
        &:last-child {
          margin-right: 0;
        }
        &.twitter {
          i {
            color: $twitter;
          }
          &:hover {
            background-color: $twitter;
            color: $white;
            i {
              color: $white;
            }
          }
          &.active {
            background-color: $twitter;
            color: $white;
            i {
              color: $white;
            }
          }
        }
        &.facebook {
          i {
            color: $facebook;
          }
          &:hover {
            background-color: $facebook;
            color: $white;
            i {
              color: $white;
            }
          }
          &.active {
            background-color: $facebook;
            color: $white;
            i {
              color: $white;
            }
          }
        }
        i {
          margin-right: 13px;
          font-size: 18px;
          vertical-align: bottom;
        }
      }
    }
  }
  .w-50 {
    padding: 0;
    @include media991 {
      padding: 0 8px;
      max-width: 50%;
    }
    @include media767 {
      max-width: 100%;
    }
    .press-room {
      padding-right: 48px;
      border-right: 1px solid #e3e4e8;
      @include media991 {
        padding-right: 0;
        border-right: none;
      }
    }
    .player-spotlight {
      padding-left: 48px;
      @include media991 {
        padding-left: 0;
      }
    }
  }
  .facebook-iframe {
    overflow: auto;
  }
}

// product sponsor css
.proud-sponsors {
  padding: 60px 0;
  @include media991 {
    padding: 30px 0;
  }
  .product-sponsor-list {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    @include media991 {
      flex-wrap: wrap;
    }
    .product-sponsor-box {
      margin-right: 33px;
      border: 2px solid transparent;
      &:last-child {
        margin-right: 0;
      }
      @include media991 {
        flex-wrap: wrap;
        margin: 13px;
      }
      @include media767 {
        margin-bottom: 20px;
        margin-right: 0;
      }
      img {
        border-radius: 10px;
      }
      &:hover {
        border: 2px solid var(--green-color-light);
        border-radius: 10px;
        transition: 0.5s;
        transform: scale(1.1);
      }
    }
  }
}

// Past Champion
.past-champions {
  padding: 60px 0;
  &.pwb-pastCham {
    .table {
      tbody {
        td {
          &:last-child {
            padding: 21px 30px;
          }
        }
      }
    }
  }
  .filter-champion {
    padding: 30px 0 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;
    border-bottom: 1px solid #e3e4e8;
    @include media575 {
      display: block;
    }
    .form-group {
      max-width: 270px;
      width: 100%;
      margin-right: 30px;
      margin-bottom: 0;
      &:last-child {
        margin-left: auto;
        margin-right: 0;
        text-align: right;
        @include media575 {
          text-align: center;
        }
      }
      @include media575 {
        margin-right: 8px;
        max-width: 100%;
        margin-bottom: 15px;
      }
    }
    .see_post {
      a {
        white-space: nowrap;
        color: var(--green-color);
      }
    }
  }
  .table {
    @include media767 {
      white-space: nowrap;
    }
    thead {
      background-color: #dce3eb;
      th {
        @include defaultFontStyle(500, 14, 1.2, $black);
        padding: 21px 30px;
        border: 1px solid #f6f9fe;
        &:first-child {
          border-radius: 8px 0 0 0;
        }
        &:last-child {
          border-radius: 0 8px 0 0;
        }
      }
    }
    tbody {
      background-color: #fff;
      td {
        @include defaultFontStyle(500, 14, 1.2, $light-black);
        padding: 21px 30px;
        border: 1px solid #eef1f7;
        &:last-child {
          padding: 0;
        }
      }
      ul {
        list-style: none;
        li {
          padding: 15px 30px;
          border-bottom: 1px solid #eef1f7;
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
  // .pagenation-search {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   padding: 30px 0 0;
  //   @include media675 {
  //     display: block;
  //   }
  //   .page-search {
  //     display: flex;
  //     align-items: center;
  //     @include media675 {
  //       padding-bottom: 15px;
  //     }
  //     .custom-select {
  //       width: 60px;
  //       margin-left: 16px;
  //       background-position: 36px;
  //     }
  //   }
  //   .champion-pagination {
  //     display: inline-block;
  //     background-color: #fff;
  //     border-radius: 8px;
  //     li {
  //       @include defaultFontStyle(500, 16, 1.2, $black);
  //       display: block;
  //       float: left;
  //       padding: 13px 0px;
  //       text-decoration: none;
  //       border-right: 1px solid #eef1f6;
  //       a {
  //         padding: 13px 18px;
  //       }
  //       &:last-child {
  //         border-right: none;
  //       }
  //       &:hover {
  //         background-color: #ddd;
  //       }
  //       &.active {
  //         background-color: var(--green-color);
  //         a {
  //           color: white;
  //         }
  //       }
  //     }
  //   }
  // }
  .past-champions-nodeta {
    padding: 30px;
  }
}
.pagenation-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 0;
  @include media675 {
    display: block;
  }
  &.bg-grey {
    .champion-pagination {
      background-color: $gray-bg;
      margin-left: auto;
    }
  }
  .page-search {
    display: flex;
    align-items: center;
    @include media675 {
      padding-bottom: 15px;
    }
    .custom-select {
      width: 60px;
      margin-left: 16px;
      background-position: 36px;
    }
  }
  .champion-pagination {
    display: inline-block;
    background-color: #fff;
    border-radius: 8px;
    li {
      @include defaultFontStyle(500, 16, 1.2, $black);
      display: block;
      float: left;
      padding: 13px 0px;
      text-decoration: none;
      border-right: 1px solid #eef1f6;
      a {
        padding: 13px 18px;
      }
      &:last-child {
        border-right: none;
      }
      &:hover {
        background-color: #ddd;
      }
      &.active {
        background-color: var(--green-color);
        a {
          color: white;
        }
      }
    }
  }
}
// showcase diffrent
.showcase-diffrent {
  padding: 60px 0;
  background-color: $white;
  @include media767 {
    padding: 30px 0;
  }
  h1 {
    margin-bottom: 15px;
  }
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

// player info
.showcase-info {
  ul {
    margin-bottom: 20px;
    list-style: none;
    li {
      @include defaultFontStyle(400, 15, 1.5, $light-black);
    }
  }
  .rang-product {
    padding: 20px 0 90px;
    align-items: flex-end;
    .w-25 {
      @include media991 {
        flex: 0 0 50%;
        width: 50%;
      }
      @include media767 {
        width: 50%;
        flex: 0 0 50%;
      }
      @include media575 {
        width: 100%;
        flex: 0 0 100%;
      }
    }
    .product-img {
      position: relative;
      img {
        height: 250px;
      }
      .overlay {
        display: none;
      }
      &:hover {
        .overlay {
          position: absolute;
          background-color: #b8bcc1;
          top: 0;
          width: 100%;
          height: 100%;
          padding: 63px 46px;
          text-align: center;
          display: block;
          p {
            color: $black-light;
          }
          a {
            text-decoration: underline;
            &:hover {
              color: $black-light;
            }
          }
        }
      }
    }
    .product-logo {
      margin-top: 10px;
      img {
        max-width: 200px;
        height: 40px;
      }
    }
    .ballr {
      .product-logo {
        img {
          height: 45px;
        }
      }
    }
    .analyzr {
      .product-logo {
        img {
          height: 45px;
        }
      }
    }
    .pitchr {
      .product-img {
        img {
          padding: 80px 2px 15px;
          margin: auto;
        }
      }
      .product-logo {
        img {
          height: 44px;
        }
      }
    }
    .analyzr {
      .product-img {
        img {
          padding: 26px;
        }
      }
    }
    .bcteam {
      .product-img {
        img {
          padding: 30px;
        }
      }
    }
  }
  .partnerships {
    padding: 10px 0;
    .row {
      justify-content: space-around;
      .w-25 {
        @include media991 {
          flex: 0 0 40%;
          max-width: 40%;
        }
        @include media575 {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
    .partnerships-box {
      position: relative;

      .partnerships-img {
        padding: 25px;
        @include media575 {
          padding: 5px;
        }
        img {
          margin: 25px 0;
          // max-width: 200px;
          // height: 300px;
          @include media575 {
            margin: 20px auto;
            max-width: 158px;
          }
        }
      }
      .overlay {
        display: none;
      }
      &:hover {
        .overlay {
          position: absolute;
          background-color: #b8bcc1;
          top: 0;
          width: 100%;
          height: 100%;
          padding: 10px;
          text-align: center;
          display: block;
          display: flex;
          flex-direction: column;
          justify-content: center;
          p {
            color: $black-light;
          }
          a {
            text-decoration: underline;
            &:hover {
              color: $black-light;
            }
          }
          h4 {
            font-weight: 600;
          }
        }
      }
    }
  }
  .text-center {
    font-size: 22px;
  }
}
.player-info {
  padding: 60px 0;
  @include media767 {
    padding: 30px 0;
  }
  // .container {
  //     max-width: 1100px;
  // }
  .row {
    justify-content: center;
  }
  .w-33 {
    margin-bottom: 15px;
    @include media991 {
      width: 33%;
      margin-bottom: 15px;
    }
    @include media767 {
      width: 50%;
      margin-bottom: 15px;
    }
    @include media575 {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .player-box {
    background-color: $white;
    border: 2px solid transparent;
    // border-radius: 10px;
    margin-bottom: 20px;
    margin: 0px 5px;
    transition: transform 0.2s;
    overflow: hidden;
    cursor: pointer;
    @include media991 {
      margin: 21px 15px;
    }

    .player-img {
      overflow: hidden;
    }
    img {
      border-radius: 0;
      // height: 350px;
      width: 100%;
      &:hover {
        transform: scale(1.1);
        transition: 0.3s;
      }
    }
    .player-name {
      padding: 24px 0;
      @include media991 {
        padding: 12px 0;
      }
      h5 {
        @include defaultFontStyle(700, 20, 1.2, $black-light);
        border-left: 4px solid var(--green-color);
        padding: 0 21px;
        text-transform: capitalize;
        @include media991 {
          font-size: 16px;
        }
      }
    }
    &:hover {
      border: 2px solid var(--green-color);
      border-radius: 0px;
      h5 {
        color: var(--green-color);
      }
    }
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $light-color;
    background-color: $light-color;
    border-radius: 5px;
  }
  ::-webkit-scrollbar {
    height: 5px;
    border-radius: 5px;
    background-color: $light-color;
  }
  ::-webkit-scrollbar-thumb {
    background-color: $light-black;
    border: 2px solid $light-black;
    border-radius: 5px;
    &:hover {
      background-color: $light-black;
      border: 2px solid $light-black;
    }
  }
  .player-slider {
    button {
      display: none !important;
    }
    &:hover {
      button {
        display: block !important;
        transition: 0.3s;
      }
      .slick-arrow {
        z-index: 2;

        &.slick-prev {
          left: 20px;
        }
        &.slick-next {
          right: 20px;
        }
      }
    }
  }
  .slick-prev:before,
  .slick-next:before {
    color: var(--green-color);
  }
}

// commend prospect form
.pwbrecommendprospect {
  padding-top: 60px;
  .rp-text {
    text-align: center;
    margin-top: 15px;
  }
  .form-title {
    @include defaultFontStyle(700, 24, 1.2, $black-light);
    margin-bottom: 25px;
  }
  form {
    label {
      span {
        color: $text-red;
      }
    }
    .round-radio {
      margin: 10px;
    }
    .radio-bg {
      background-color: $white;
      display: flex;
      flex-wrap: wrap;
      padding: 15px;
      border-radius: 5px;
    }
  }
  .personal-form {
    border-top: 1px solid #e8e8e8;
    padding: 40px 0;
  }
  .additional-form {
    border-top: 1px solid #e8e8e8;
    padding: 40px 0;
  }
  .other-form {
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    padding: 40px 0;
    margin-bottom: 40px;
  }
}

// Event information
.team-banner-section {
  position: relative;
  > img {
    max-width: 100%;
    min-height: 250px;
    max-height: 250px;
    width: 100%;
    object-fit: cover;
    // &::before {
    //     position: absolute;
    //     height: 100%;
    //     width: 100%;
    //     content: "";
    //     background-color: rgba(0, 0, 0, 0.6);
    //     z-index: 1;
    // }
    @include media767 {
      min-height: 300px;
      max-height: 300px;
    }
    @include media480 {
      min-height: 320px;
      max-height: 320px;
    }
  }
  .banner-details {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    @include media767 {
      flex-wrap: wrap;
      justify-content: center;
      max-width: 500px;
      width: 100%;
      text-align: center;
      padding: 0 10px;
    }

    .event_card_logo {
      margin-right: 30px;
      width: 130px;
      height: 120px;
      padding: 5px;
      @include media767 {
        margin-right: 0;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .event_card_titles {
      width: calc(100% - 170px);
      padding-left: 40px;
      border-left: 1px solid #ffffff70;

      @include media767 {
        border-left: 0;
        width: 100%;
        text-align: center;
        padding-left: 0;
      }
      h3 {
        @include defaultFontStyle(bold, 24, 1.4, $white);
        margin-bottom: 15px;
      }
      .event_card_dates {
        display: flex;
        align-items: center;
        @include media991 {
          flex-wrap: wrap;
          justify-content: center;
        }
        p {
          @include defaultFontStyle(300, 16, 1.2, $white);
          position: relative;
          padding-right: 20px;
          margin-bottom: 8px;
          padding-left: 26px;
          border-right: 1px solid #ffffff70;
          margin-left: 20px;
          white-space: nowrap;
          @include media767 {
            border-right: 0;
            margin-left: 0;
          }
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            border-right: 0px solid #ffffff70;
          }
          &.map_icon {
            &:before {
              position: absolute;
              content: "";
              background: url("../../../src/assets/images/comapnylanding/map_marker.svg") no-repeat;
              width: 12px;
              height: 17px;
              background-size: cover;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          &.date_icon {
            &:before {
              position: absolute;
              content: "";
              background: url("../../../src/assets/images/comapnylanding/calendar_line.svg") no-repeat;
              width: 15px;
              height: 16px;
              background-size: cover;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          &.team_icons {
            &:before {
              position: absolute;
              content: "";
              background: url("../../../src/assets/images/comapnylanding/user_line.svg") no-repeat;
              width: 19px;
              height: 15px;
              background-size: cover;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
            &.showSpotAvail {
              font-weight: bold;
              color: #221f34;
            }
          }
        }
      }
    }
  }
  .arrow {
    position: absolute;
    border: 0;
    left: 0;
    right: 0;
    bottom: -20px;
    z-index: 1;
  }
  .shap {
    position: absolute;
    border: 0;
    left: 0;
    right: 0;
    bottom: -1px;
  }
  &::before {
    position: absolute;
    height: 100%;
    width: 100%;
    content: "";
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 0;
  }
}

.recap-info {
  padding: 60px 0;
  h1 {
    margin-bottom: 15px;
  }
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.info-form {
  padding: 60px 0 45px;
  form {
    display: flex;
    .form-group {
      width: 100%;
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.winner-list {
  padding: 50px 0;
  border-top: 1px solid $gray-count;
  h1 {
    margin-bottom: 35px;
  }
  .list-info {
    display: flex;
    background-color: $white;
    padding: 0px 20px;
    @include media767 {
      display: block;
      padding-right: 0;
      padding: 0;
    }

    .name-point {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      position: relative;
      border-right: 1px solid var(--green-color);
      padding: 15px 0;
      @include media767 {
        border-right: none;
      }
      .team-name {
        display: flex;
        align-items: center;
        padding-right: 10px;
        p {
          margin-bottom: 0;
        }
        img {
          width: 60px;
          height: 60px;
          padding: 10px;
          margin-right: 8px;
        }
      }
      .team-point {
        p {
          margin-bottom: 0;
        }
        span {
          @include defaultFontStyle(500, 16, 1.2, $black);
        }
        span {
          background-color: transparent;
          padding: 12px;
          border-radius: 5px;
          color: $light-black;
          border: 1px solid $light-black;
        }
      }
      &.left-active {
        @include media767 {
          border-right: 1px solid var(--green-color);
        }
        &::after {
          content: "";
          position: absolute;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 10px solid var(--green-color);
          right: 0;
          @include media767 {
            content: none;
          }
        }
        .team-name {
          p {
            @include defaultFontStyle(500, 16, 1.2, $black);
          }
        }
        .team-point {
          span {
            background-color: transparent;
            color: var(--green-color);
            border: 1px solid var(--green-color);
          }
        }
      }
      &.right-active {
        @include media767 {
          border-right: 1px solid var(--green-color);
        }
        &::after {
          content: "";
          position: absolute;
          left: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left: 10px solid var(--green-color);
          @include media767 {
            content: none;
          }
        }
        .team-name {
          p {
            @include defaultFontStyle(500, 16, 1.2, $black);
          }
        }
        .team-point {
          span {
            background-color: transparent;
            color: var(--green-color);
            border: 1px solid var(--green-color);
          }
        }
      }
      &:first-child {
        padding-right: 30px;
        @include media767 {
          border-right: 0;
          padding-right: 30px;
        }
        @include media575 {
          padding-right: 10px;
        }
      }
      &:last-child {
        border-right: 0;
        padding-left: 30px;
        @include media767 {
          padding-right: 30px;
          padding-left: 0;
        }
        @include media575 {
          padding-right: 10px;
        }
      }
      .winner-tag {
        background-color: var(--green-color);
        color: $white;
        border-radius: 5px;
        padding: 6px 12px;
        height: 30px;
        margin-left: 20px;
        cursor: pointer;
        @include media575 {
          height: auto;
          text-align: center;
          padding: 4px 12px;
        }
        span {
          display: flex;
          @include media575 {
            display: block;
          }
        }
        img {
          height: 18px;
          width: 18px;
          padding: 0;
          @include media575 {
            margin: auto;
          }
        }
      }
    }
  }
  .no-winner-deta {
    padding: 60px;
    p {
      margin: 0;
    }
  }
}

.score-mvp {
  padding: 0 0 60px;
  display: flex;
  @include media991 {
    display: block;
  }
  .common-div {
    background-color: $white;
    width: 50%;
    border-radius: 5px;
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
      @include media991 {
        margin-top: 50px;
      }
    }
    @include media991 {
      width: 100%;
    }

    .score-box {
      .score-title {
        display: flex;
        justify-content: space-between;
        padding: 30px;
        p {
          margin-bottom: 0;
        }
      }
      table {
        border-collapse: collapse;
        white-space: nowrap;
        .name-point {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          @include media575 {
            min-width: 350px;
          }

          .team-name {
            display: flex;
            align-items: center;
            p {
              margin-bottom: 0;
            }
            img {
              width: 60px;
              height: 60px;
              padding: 10px;
              margin-right: 8px;
            }
          }
          .team-point {
            p {
              margin-bottom: 0;
            }
            span {
              @include defaultFontStyle(500, 16, 1.2, $black);
            }
            span {
              background-color: transparent;
              padding: 10px;
              border-radius: 5px;
            }
          }
          &.active {
            .team-name {
              p {
                @include defaultFontStyle(500, 16, 1.2, $black);
              }
            }
            .team-point {
              span {
                background-color: #eef1f6;
                padding: 10px;
                border-radius: 5px;
              }
            }
          }
        }
        h5 {
          @include defaultFontStyle(700, 18, 1.2, var(--green-color));
        }
        td,
        th {
          padding: 15px;
          border: 1px solid $gray-count;
        }
        thead {
          th {
            padding: 30px 15px;
          }
        }
        thead {
          th {
            border: none;
          }
        }
        tbody {
          tr {
            td {
              border-left: none;
              // border-bottom: none;
              &:last-child {
                border-right: none;
              }
            }
          }
        }
        tbody {
          display: block;
          height: 455px;
          overflow-y: auto;
          td {
            width: 100%;
            &:last-child {
              padding: 0 35px;
            }
          }
          ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px $light-color;
            background-color: $light-color;
            border-radius: 5px;
          }
          ::-webkit-scrollbar {
            height: 5px;
            border-radius: 5px;
            background-color: $light-color;
          }
          ::-webkit-scrollbar-thumb {
            background-color: $light-black;
            border: 2px solid $light-black;
            border-radius: 5px;
            &:hover {
              background-color: $light-black;
              border: 2px solid $light-black;
            }
          }
        }
        ::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px $light-color;
          background-color: $light-color;
          border-radius: 5px;
        }
        ::-webkit-scrollbar {
          width: 5px;
          border-radius: 5px;
          background-color: $light-color;
        }
        ::-webkit-scrollbar-thumb {
          background-color: $light-black;
          border: 2px solid $light-black;
          border-radius: 5px;
          &:hover {
            background-color: $light-black;
            border: 2px solid $light-black;
          }
        }
      }
      &.no-deta-score {
        p {
          padding: 55px;
        }
      }
    }

    .mvp-box {
      table {
        width: 100%;
        .mvp-img {
          display: flex;
          align-items: center;
          @include media575 {
            min-width: 350px;
          }
          img {
            margin: 0;
            border-radius: 50%;
            border: 1.5px solid #eef1f6;
            padding: 3px;
            width: 45px;
            height: 45px;
          }
          h5 {
            @include defaultFontStyle(500, 16, 1.2, $black);
            margin-left: 20px;
          }
        }
        td,
        th {
          padding: 15px;
          @include defaultFontStyle(500, 16, 1.2, $light-black);
          border-bottom: 1px solid $gray-count;
        }
        p {
          @include defaultFontStyle(500, 16, 1.2, $black);
        }
        thead {
          padding: 0 15px;
          td {
            padding: 31px 15px;
          }
        }
        tbody {
          padding: 0 15px;
        }
      }
    }
    .section_title_left {
      background-color: $gray-bg;
      padding-bottom: 50px;
      &::before {
        bottom: 35px;
      }
    }
  }
}

// Event flow
.event_details {
  .container {
    @include media1366 {
      max-width: 100%;
    }
  }
  .web_eventOuterList {
    margin-top: -80px;
    position: relative;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 55px;
    .web_eventlist_wrapper {
      box-shadow: 0px 2px 10px #dfd9dbb3;
      .web_eventList_img {
        width: 428px;
        border-radius: 4px 0 0 4px;
        overflow: hidden;
        background: #000;
        position: relative;
        border: 2px solid #fff;
        border-bottom: 0;
        @include media991 {
          width: 300px;
        }
        @include media767 {
          margin: auto;
          margin-top: 10px;
        }
        img {
          width: 100%;
          height: 100%;
          max-height: 175px;
          min-height: 175px;
          object-fit: cover;
          border-radius: 4px 0 0 4px;
          margin: auto;
          max-width: 100%;
        }
        .event_card_address {
          display: block;
          @include defaultFontStyle(500, 14, 1.2, $white);
          padding: 6px 10px 6px 32px;
          position: absolute;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.7);
          border-radius: 10px 0 0 0;
          &:before {
            content: "";
            background: url("../../../src/assets/images/comapnylanding/map_marker.svg") no-repeat;
            width: 12px;
            height: 16px;
            background-size: 100% 100%;
            left: 7px;
            z-index: 1;
            position: absolute;
            top: 7px;
          }
        }
        &.web_tryout_img {
          img {
            object-fit: contain;
          }
        }
      }
      .web_eventList_content {
        width: calc(100% - 448px);
        padding: 12px 9px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        @include media991 {
          width: calc(100% - 300px);
        }
        @include media767 {
          width: 100%;
          margin: 10px 0;
        }
        .web_eventList_data {
          width: 75%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          @include media1366 {
            width: 72%;
          }
          @include media575 {
            width: 100%;
            justify-content: center;
          }
          .web_eventList_logo {
            width: 109px;
            height: 109px;
            margin: 0 20px;
            border-radius: 50%;
            @include media991 {
              width: 80px;
              height: 80px;
              padding: 5px;
              margin: 0 10px;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }
          }
          .web_eventList_detail {
            margin: 0 22px;
            width: calc(100% - 195px);
            @include media991 {
              margin: 0 10px;
              width: calc(100% - 110px);
            }
            @include media575 {
              width: 100%;
              text-align: center;
            }
            h1 {
              @include defaultFontStyle(700, 26, 1.4, #1c2d41);
              margin-bottom: 10px;
              cursor: pointer;
              &:hover {
                color: var(--green-color);
              }
            }
            .web_eventList_icons {
              @include media575 {
                justify-content: center;
              }
              span {
                @include defaultFontStyle(500, 14, 1.2, $light-black);
                margin-right: 30px;
                margin-bottom: 8px;
                &:last-child {
                  margin-right: 0;
                  cursor: pointer;
                }
              }
              .common_softball_icon {
                position: relative;
                &::before {
                  background: url("../../../src/assets/images/comapnylanding/softball.svg") no-repeat;
                  background-size: 100% 100%;
                  display: inline-block;
                  width: 14px;
                  height: 20px;
                  content: "";
                  margin-right: 10px;
                }
              }
              .common_date_icon {
                position: relative;
                &::before {
                  background: url("../../../src/assets/images/comapnylanding/date_icon.svg") no-repeat;
                  background-size: 100% 100%;
                  display: inline-block;
                  width: 17px;
                  height: 17px;
                  content: "";
                  margin-right: 10px;
                }
              }
              .common_team_icon {
                position: relative;
                &::before {
                  background: url("../../../src/assets/images/comapnylanding/user_icons.svg") no-repeat;
                  background-size: 100% 100%;
                  display: inline-block;
                  width: 14px;
                  height: 20px;
                  content: "";
                  margin-right: 10px;
                }
              }
            }
          }
        }
        .web_eventList_join {
          width: 25%;
          @include media1366 {
            width: 28%;
          }
          @include media575 {
            width: 100%;
            margin: 10px;
            text-align: center;
          }
          h2 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include defaultFontStyle(700, 24, 1.4, var(--green-color-light));
            @include media991 {
              font-size: 16px;
            }
            span {
              @include defaultFontStyle(500, 16, 1.2, $light-black);
              // margin-left: 15px;
            }
          }
          button {
            font-weight: 500;
            width: 100%;
            // max-width: 178px;
            margin-top: 10px;
            cursor: pointer;
            padding: 13px;
          }
        }
      }
    }
  }
  .web_description_box {
    padding: 30px;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 30px;
    box-shadow: 0 2px 2px #8392871a;
    @include media575 {
      padding: 20px;
    }

    .web_desc_title {
      h3 {
        @include defaultFontStyle(500, 18, 1.2, $black-color);
        margin-bottom: 15px;
      }
    }
    .web_list_data {
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1.5px solid #e5e9f2;
      @include media575 {
        justify-content: center;
      }
      .web_list_data_box {
        width: 20%;
        padding-right: 10px;
        text-align: center;
        // &:last-child {
        //     width: 80%;
        // }
        @include media575 {
          width: 100%;
          margin-bottom: 20px;
          text-align: center;
        }
        label {
          @include defaultFontStyle(700, 14, 1.2, $light-black);
          // margin-bottom: 5px;
          padding: 8px;
          display: inline-block;
          max-width: 100%;
        }
        h3 {
          @include defaultFontStyle(500, 18, 1.2, $black-color);
          padding: 8px;
        }
        &.table {
          width: 60%;
          @include media575 {
            width: 100%;
          }
        }
      }
      table {
        width: 100%;
        th {
          @include defaultFontStyle(700, 14, 1.2, $light-black);
          padding: 8px;
        }
        td {
          @include defaultFontStyle(500, 18, 1.2, $black-color);
          padding: 8px;
        }
        .btn {
          padding: 9px 36px;
          @include media575 {
            padding: 10px 15px;
          }
        }
      }
    }
    p {
      @include defaultFontStyle(400, 14, 1.6, $light-black);
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    ul {
      padding-left: 20px;
      li {
        padding: 5px;
      }
    }
  }
  .common_export_icon {
    position: relative;
    padding: 0.5rem 0;

    &::before {
      background: url("../../../src/assets/images/comapnylanding/export.svg") no-repeat;
      background-size: 100% 100%;
      display: inline-block;
      width: 14px;
      height: 20px;
      content: "";
      margin-right: 10px;
    }
  }
  .web_description_fullborderbox {
    background: #fff;
    border-radius: 4px;
    margin-bottom: 30px;
    box-shadow: 0 2px 2px #8392871a;
    .web_desc_title {
      padding: 25px 30px;
      border-bottom: 1.5px solid #e5e9f2;
      h3 {
        @include defaultFontStyle(500, 18, 1.2, $black-color);
      }
    }
    .web_description_box_20 {
      padding: 30px 20px 20px;
      &.web_hotel_download {
        padding: 30px 10px 20px;
        .download_btn {
          display: flex;
          align-items: center;
          justify-content: center;
          a {
            width: 50%;
            margin-right: 5px;
            &:last-child {
              margin-right: 0;
            }
            &.android_link {
              img {
                margin-right: 0;
              }
            }
            &.ios_link {
              img {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
    .companyLogos {
      margin-bottom: 15px;
      img {
        // margin: 0;
        margin-bottom: 15px;
        max-height: 70px;
        // width: 200px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .company_social_link {
      display: flex;
      flex-direction: column;
      @include media1199 {
        display: grid;
      }
      @include media991 {
        display: flex;
      }
      @include media480 {
        display: grid;
      }
      a {
        padding: 11px;
        color: #fff;
        border-radius: 4px;
        transition: 0.3s ease;
        margin-bottom: 10px;
        margin-right: 10px;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media1199 {
          width: 100%;
        }
        &:last-child {
          margin-right: 0;
        }
        i {
          // @include defaultFontStyle(500, 18, 1.2);
          margin-right: 10px;
        }
      }
      .fblink {
        background: #4267b2;
        border: 1px solid #4267b2;
      }
      .twtlink {
        background: #1da1f2;
        border: 1px solid #1da1f2;
      }
      .emaillink {
        background: #30ae9b;
        border: 1px solid #30ae9b;
      }
      .instalink {
        background: linear-gradient(to right, #fab749, #f56129, #dd2a7b, #4134af);
        i {
          color: $white;
          font-size: 19px;
        }
      }
      .tiktoklink {
        background: #000;
        img {
          width: 18px;
          height: 18px;
          margin-left: 0;
          margin-right: 10px;
          text-align: center;
        }
      }
    }
  }

  .web_hotel_slidermain {
    .web_hotel_slide {
      position: relative;
      img {
        border-radius: 4px;
        width: 100%;
        object-fit: cover;
        // height: 245px;
        height: auto;
      }
      .web_hotel_address {
        position: absolute;
        bottom: 0;
        z-index: 3;
        padding: 18px;
        p {
          @include defaultFontStyle(400, 14, 1.4, $white);
          margin-bottom: 30px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        &.dc_city_text {
          background: rgba(0, 0, 0, 0.4);
          padding: 2px 12px;
          border-radius: 3px;
        }
      }
      // &::after {
      //   box-shadow: inset 0 -80px 70px -30px #000;
      //   position: absolute;
      //   bottom: 0;
      //   z-index: 2;
      //   content: "";
      //   width: 100%;
      //   height: 220px;
      // }
    }
    .web_hotel_slide:hover {
      cursor: pointer;
    }
    .slick-dots {
      li {
        margin: -6px 4px;
      }
    }
  }
  .web_description_box {
    &.table-box {
      padding: 30px 0;
      .web_desc_title {
        padding: 0 30px 10px;
      }
    }
    .participating-teams-table {
      .table {
        thead {
          background-color: #e1e2e742;
          th {
            @include defaultFontStyle(500, 14, 1.2, $light-black);
            padding: 21px 30px;

            &:first-child {
              border-radius: 8px 0 0 0;
            }
            &:last-child {
              border-radius: 0 8px 0 0;
            }
            &:nth-child(2) {
              min-width: 260px;
            }
          }
        }
        tbody {
          background-color: #fff;
          td {
            @include defaultFontStyle(600, 14, 1.2, $light-black);
            padding: 10px 30px;
            p {
              @include defaultFontStyle(600, 14, 1.2, $light-black);
              padding: 21px 30px;
            }
            .teams-logo {
              display: flex;
              align-items: center;

              span {
                img {
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  margin-right: 20px;
                }
              }
              .teams-text {
                width: calc(100% - 40px);
              }
            }
          }
          tr {
            &:hover {
              td {
                color: var(--green-color);
              }
            }
          }
        }
      }
    }
  }
  .event_left_side {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    max-width: 66.66667%;
    @include media991 {
      max-width: 100%;
    }
  }
  .event_right_side {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    max-width: 33.33333%;
    @include media991 {
      max-width: 100%;
    }
  }
  .main-timeline {
    margin-top: 0;
    margin-bottom: 60px;
  }
}

/* Accordion styles */
.tabs {
  overflow: hidden;
  border-radius: 4px;
  margin-bottom: 30px;
  box-shadow: 0 2px 2px #8392871a;
  &.web_desc {
    margin-bottom: 0;
    box-shadow: unset;
    .tab-label {
      padding: 0 0 15px;
    }
    .tab-content {
      padding-left: 0 !important;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  .tab {
    width: 100%;
    background: $white;
    overflow: hidden;
    &-label {
      @include defaultFontStyle(500, 18, 1.2, $black-color);
      display: flex;
      justify-content: space-between;
      padding: 15px 30px;
      cursor: pointer;

      &::after {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all 0.35s;
      }
    }
    &-content {
      @include defaultFontStyle(400, 14, 1.6, $light-black);
      max-height: 0;
      padding: 0 30px;
      transition: all 0.5s ease-in-out;
      p {
        margin-bottom: 0;
      }
      .participating-teams-table {
        .table-responsive-xl {
          max-height: 537px;
          display: block;
          width: 100%;
          overflow-x: auto;
        }
        .table {
          thead {
            tr {
              th {
                background-color: #f7f7f9;
                white-space: nowrap;
              }
            }
            .ant-table-column-sorters {
              padding: 0px;
            }
            th {
              @include defaultFontStyle(500, 14, 1.2, $light-black);
              padding: 21px 30px;

              &:first-child {
                border-radius: 8px 0 0 0;
              }
              &:last-child {
                border-radius: 0 8px 0 0;
              }
              &:nth-child(2) {
                min-width: 260px;
              }
            }
          }
          tbody {
            background-color: #fff;
            td {
              @include defaultFontStyle(600, 14, 1.2, $light-black);
              padding: 10px 30px;
              white-space: nowrap;
              border: none;
              p {
                @include defaultFontStyle(600, 14, 1.2, $light-black);
                padding: 21px 30px;
              }
              .teams-logo {
                display: flex;
                align-items: center;

                span {
                  img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-right: 20px;
                    object-fit: cover;
                  }
                }
                .teams-text {
                  width: calc(100% - 40px);
                }
              }
            }
            tr {
              &:hover {
                td {
                  color: var(--green-color);
                }
              }
            }
          }
        }
      }
    }
  }
  input:checked {
    ~ .tab-content {
      padding: 0 30px 15px;
      transition: all 0.5s ease-in-out;
      max-height: 500px;
      overflow: auto;
      padding-bottom: 20px;
      &.tournament_rules {
        max-height: 500px;
        overflow: auto;
      }
    }
  }
}

.ant-tooltip-inner {
  cursor: default;
}

.event-container {
  max-width: 900px;
  margin: 0 auto;
  .event-form {
    .radiobox {
      position: relative;
      display: flex;
      input {
        position: absolute;
        opacity: 0;
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        cursor: pointer;
      }
      span {
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        transition: all 0.5s ease 0s;
        background-color: $gray-btn;
        @include media480 {
          width: 30px;
          height: 30px;
        }
      }
      span:after {
        content: "";
        position: absolute;
        width: 20px;
        height: 10px;
        border: 3px solid $white;
        border-top: 0;
        border-right: 0;
        transform: rotate(-40deg) scale(0.6);
        top: 31%;
        left: 11px;
        transition: all 0.5s ease 0s;
        opacity: 0;
        @include media480 {
          width: 16px;
          height: 8px;
          left: 7px;
        }
      }
      input:checked ~ span::after {
        transform: rotate(-40deg) scale(1);
        opacity: 1;
      }
      input:checked ~ span {
        background: $blue;
      }
    }
    .join-form {
      width: 100%;
      float: left;
      margin: 10px 0;
      padding: 10px 30px;
      background-color: $white;
    }
    h4 {
      @include defaultFontStyle(700, 24, 1.2, $black-light);
      text-align: center;
      padding: 20px 0;
      border-bottom: 1px solid $extra-light;
      margin-bottom: 30px;
    }
    .file_input {
      position: relative;
      border: dashed 1px $extra-light;
      background: #eee;
      .image_upload_wrap {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
        &.active {
          background: $white;
        }
      }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        width: auto;
        max-width: 100%;
        max-height: 100%;
      }
      padding: 100px;
      text-align: center;
      &.edit_profile_image {
        padding: 40px 20px;
      }
      i {
        font-size: 40px;
        color: var(--green-color);
      }
      p {
        font-size: 16px;
        opacity: 0.5;
      }
      .img_file_input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
    .image_prev_name {
      position: relative;
      border: 1px solid $extra-light;
      padding: 13px;
      border-radius: 0 0 4px 4px;
      .image_name {
        width: calc(100% - 70px);
        font-size: 16px;
        color: var(--green-color);
        margin-bottom: 0;
      }
    }

    .round_close {
      width: 30px;
      height: 30px;
      background: $extra-light;
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      cursor: pointer;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    form {
      max-width: 60%;
      @include media767 {
        max-width: 100%;
      }
      .double-label {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        label {
          &:last-child {
            color: $blue;
          }
        }
      }
      .input-btn {
        display: flex;
        .btn {
          margin-left: 15px;
          white-space: nowrap;
        }
      }
    }
    .check_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      &.add_filed {
        flex-direction: column;
        align-items: inherit;
        .form-group {
          margin: 15px 0 0;
          @include media575 {
            margin: 15px 0 20px;
          }
          .form-control {
            padding-right: 65px;
          }
          .serch-btn {
            color: $white;
            font-size: 18px;
            width: 65px;
          }
        }
      }
      .checkbox {
        width: 100%;
        cursor: pointer;
        position: relative;
        margin: 0 15px;
        margin-left: 0;
        label {
          width: 100%;
          border-radius: 4px;
          background-color: transparent;
          text-align: center;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          @include defaultFontStyle(500, 16, 1.4, $blue);
          border: 1px solid $gray-btn;
          @include media480 {
            @include defaultFontStyle(500, 14, 1.4, $blue);
          }
          @include media480 {
            height: 42px;
          }
        }
        input[type="radio"],
        input[type="checkbox"] {
          opacity: 0;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          cursor: pointer;

          &:checked ~ label {
            background-color: $blue;
            color: $white;
          }
        }
      }
    }
    .product-price-list {
      h5 {
        @include defaultFontStyle(500, 18, 1.4, $black-color);
      }
      .product-price-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        max-width: 375px;
        .product-name {
          display: flex;
          align-items: center;
          label {
            margin-bottom: 0;
            margin-left: 15px;
            @include defaultFontStyle(500, 16, 1.4, $light-black);
          }
          .radiobox {
            span {
              width: 30px;
              height: 30px;
              &::after {
                width: 15px;
                height: 9px;
                left: 8px;
              }
            }
          }
        }
        .product-price {
          h6 {
            @include defaultFontStyle(500, 16, 1.4, $light-black);
            margin-left: 30px;
          }
        }
      }
    }
  }
  .event-thanq {
    padding: 10px 30px;
    background-color: #ffffff;
    margin-bottom: 60px;
    @include media767 {
      padding: 10px;
    }
    .thanq-title {
      text-align: center;
      border-bottom: 1px solid $extra-light;
      margin-bottom: 30px;
      h4 {
        @include defaultFontStyle(700, 24, 1.2, var(--green-color));

        padding: 20px 0;
      }
    }
    table {
      white-space: nowrap;
      th {
        @include defaultFontStyle(500, 16, 1.2, $black-light);
        padding: 6px;
      }
      td {
        @include defaultFontStyle(500, 16, 1.2, $gra-input);
        padding-left: 20px;
      }
    }
  }
}

// Hotel style

.hotel-section {
  .hotel-event-detail {
    background: $white;
    padding: 60px 0 0;
    .hotel-details {
      p {
        @include defaultFontStyle(400, 20, 1.4, $light-black);
        text-align: center;
        padding-bottom: 20px;
      }
    }
    .hotel-event {
      padding-bottom: 45px;
      @include media991 {
        padding-bottom: 0;
      }
      .event_box {
        .event_card {
          box-shadow: 1px 3px 5px #8080803d;
          margin-bottom: 25px;
        }
      }
    }
  }
  .ques-hotel {
    background: url("../../../src/assets/images/comapnylanding/hotel_mail.png") no-repeat;
    background-color: var(--green-color);
    padding: 25px 10px 26px;
    .ques-hotel-mail {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      h2 {
        @include defaultFontStyle(500, 24, 1.4, $white);
        margin-right: 30px;
      }
      .btn {
        &.trans {
          background-color: $white;
          border: 2px solid $white;
          &:hover {
            border: 2px solid $white;
            background-color: var(--green-color);
          }
        }
      }
    }
  }
}

// Hotel detail style
.hotelinfo-section {
  .hotels-list-section {
    padding: 70px 0;
    background: $white;
    .hotel-list {
      display: flex;
      border-bottom: 1px solid #6c7b952b;
      padding: 40px 0;
      @include media767 {
        display: block;
      }
      .hotel-view-img {
        max-width: 480px;
        height: 300px;
        position: relative;
        cursor: pointer;
        width: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
        }
        .overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100%;
          width: 100%;
          opacity: 0;
          transition: 0.5s ease;
          background-color: #221f345e;
          .search-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            text-align: center;
            display: none;
          }
        }
      }
      .hotel-view-img:hover .overlay {
        opacity: 1;
      }
      .hotel-info {
        padding-left: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include media767 {
          padding-left: 0;
          padding-top: 15px;
        }
        .hotel-name {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 25px;
          &::before {
            position: absolute;
            left: 0;
            margin: 0 auto;
            content: "";
            height: 2px;
            background-color: var(--green-color-light);
            width: 40px;
            bottom: 0;
          }
          h2 {
            @include defaultFontStyle(500, 24, 1.4, $black);
            margin-bottom: 10px;
          }
          .map-location {
            position: relative;
            @include defaultFontStyle(400, 16, 1.2, $light-black);
            margin-bottom: 25px;
            img {
              display: inline;
              width: 11px;
              margin-right: 8px;
            }
          }
          .btn {
            border: 2px solid var(--green-color-light);
            background-color: var(--green-color-light);
            white-space: nowrap;
            &:hover {
              color: var(--green-color-light);
              border: 2px solid var(--green-color-light);
              background-color: #ffffff;
            }
          }
        }
        .hotel-policy {
          h4 {
            @include defaultFontStyle(500, 18, 1.3, $black);
            padding: 0 0 4px;
          }
          p {
            @include defaultFontStyle(400, 16, 1.4, $light-black);
            margin: 0;
            &.note-text {
              font-size: 14px;
            }
          }
          .notes-info {
            margin-top: 25px;
          }
        }
      }
    }
  }
}

// About Us
.about-slider {
  padding: 60px 0;
  margin-bottom: 30px;
  @include media1366 {
    padding: 30px 0;
  }
  .slick-slide {
    opacity: 0.5;
    @include media767 {
      opacity: 1;
    }
  }
  .slick-active {
    opacity: 1;
  }
  .slider-items {
    img {
      max-width: 900px;
    }
    .slick-slider {
      max-width: 995px;
      margin: auto;
    }
    .about-slider-card {
      background-color: $white;
      padding: 60px 16px;
      margin: 0 30px;
      position: relative;
      @include media767 {
        margin: 0 10px;
      }
      @include media575 {
        margin: 0;
      }

      h5 {
        @include defaultFontStyle(700, 24, 1.4, $black);
        margin-bottom: 5px;
        margin-top: 10px;
      }
      h6 {
        @include defaultFontStyle(400, 18, 1.4, var(--green-color));
        margin-bottom: 25px;
      }
      p {
        @include defaultFontStyle(400, 18, 1.4, $light-black);
        max-width: 995px;
        margin: auto;
        padding: 0 30px;
        @include media575 {
          padding: 0 10px;
        }
      }
      &::after {
        content: "“";
        position: absolute;
        height: 111px;
        top: 30px;
        left: 140px;
        font-size: 250px;
        color: var(--green-color);
        font-family: sans-serif;
        line-height: 240px;
        @include media1366 {
          left: 70px;
        }
        @include media575 {
          left: 26px;
          // opacity: 0.2;
        }
      }
      &::before {
        content: "“";
        position: absolute;
        height: 111px;
        bottom: -50px;
        right: 50px;
        font-size: 140px;
        color: var(--green-color);
        font-family: sans-serif;
        line-height: 240px;
        transform: rotate(180deg);
        opacity: 0.2;
        @include media1366 {
          bottom: -40px;
          right: 30px;
          opacity: 0.1;
        }
      }
    }
  }
  .slick-dots {
    bottom: -50px;
    li {
      width: 34px;
      button {
        background: #707c8c;
        border: 1px solid;
        width: 34px;
        height: 4px;
        padding: 1px;
        &::before {
          font-family: none;
          font-size: 0;
        }
      }
      &.slick-active {
        button {
          background: var(--green-color);
        }
      }
    }
  }
}
.history-vision {
  background-color: $white;
  padding: 60px 0;
  @include media991 {
    padding: 30px 0;
  }
  h1 {
    margin-bottom: 15px;
  }
  p {
    @include defaultFontStyle(400, 16, 1.4, $light-black);
    text-align: center;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .history-vision-info {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    border-top: 1px solid #e9ebef;

    .hv-box {
      width: 50%;
      border-bottom: 1px solid #e9ebef;
      padding-bottom: 30px;
      padding: 30px;
      @include media767 {
        width: 100%;
      }

      &:nth-child(2n + 1) {
        border-right: 1px solid #e9ebef;
        padding-left: 0;
        @include media767 {
          border-right: 0;
          padding: 20px 0;
        }
      }
      &:nth-child(2n) {
        padding-right: 0;
        @include media767 {
          padding: 20px 0;
        }
      }

      .section_title_left {
        font-size: 24px;
        margin-bottom: 15px;
      }
      p {
        text-align: left;
      }
    }
    @include media991 {
      .w-50 {
        max-width: 100%;
        margin-bottom: 0;
      }
    }
    @include media575 {
      .w-50 {
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 0;
      }
    }
    .w-100 {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 8px;
    }
  }
  .rating-system {
    margin-top: 30px;
    h1 {
      font-size: 24px;
      margin-bottom: 15px;
    }
    a {
      @include defaultFontStyle(500, 16, 1.2, var(--green-color-light));
      text-decoration: underline;
    }
    .ratting-system-list {
      margin-top: 25px;
      .list-box {
        text-align: left;
        border: 1px solid #e2e5ea;
        padding: 12px 30px;
        margin-bottom: 20px;

        &:hover {
          border-color: var(--green-color);
          p {
            &::first-letter {
              color: var(--green-color);
            }
          }
        }
        p {
          @include defaultFontStyle(400, 18, 1.2, $light-black);
          margin-bottom: 0;
          text-align: left !important;
          &::first-letter {
            color: $black-light;
          }
        }
      }
    }
    p {
      text-align: left !important;
    }
  }
}
.our-team {
  padding: 60px 0;
  @include media991 {
    padding: 30px 0;
  }
  h1 {
    margin-bottom: 15px;
  }
  .team-list {
    padding-top: 60px;
    margin-top: 30px;
    border-top: 1px solid #e9ebef;

    .team-box {
      background-color: $white;
      padding: 25px 30px;
      margin-bottom: 20px;
      border-left: 3px solid transparent;
      @include media767 {
        margin-bottom: 0;
      }
      &:hover {
        border-left: 3px solid var(--green-color);
      }
      h5 {
        @include defaultFontStyle(700, 20, 1.2, $black-light);
        margin-bottom: 3px;
        span {
          @include defaultFontStyle(500, 18, 1.2, $light-black);
          float: right;
          i {
            vertical-align: middle;
            cursor: pointer;
          }
        }
      }
      span {
        @include defaultFontStyle(400, 16, 1.3, var(--green-color));
      }
      p {
        @include defaultFontStyle(500, 16, 1.2, $light-black);
        margin-bottom: 0;
      }
    }
    .row {
      justify-content: center;
    }
  }
}
.team-info-popup {
  .modal-content {
    border-radius: 15px;
    .modal-header {
      justify-content: flex-end !important;
      padding: 3px;
      overflow: hidden;
      position: relative;
      height: 56px;
      border-radius: 0 15px 0 0;
      &::before {
        content: "";
        position: absolute;
        top: -46px;
        right: -40px;
        background: var(--green-color);
        background-repeat: no-repeat;
        height: 85px;
        width: 85px;
        transform: rotate(132deg);
      }
      .modal_close {
        z-index: 1;
        img {
          width: 15px;
        }
      }
    }
    .modal-body {
      margin-top: -26px;
    }
  }
  .team-box {
    background-color: $white;
    padding: 0 30px;
    margin-bottom: 20px;
    border-left: 3px solid transparent;
    &:hover {
      border-left: 3px solid var(--green-color);
    }
    h5 {
      @include defaultFontStyle(700, 20, 2, $black-light);
      margin-bottom: 3px;
      cursor: pointer;
    }
    span {
      @include defaultFontStyle(400, 16, 1.3, var(--green-color));
    }
    p {
      @include defaultFontStyle(500, 16, 2, $light-black);
      margin-bottom: 0;
      padding: 0;
      border: none;
    }
  }
  .team-info-popup-scroll {
    padding: 0 30px;
    margin-bottom: 30px;
    max-height: 600px;
    overflow-y: auto;
    @include media1366 {
      max-height: 460px;
    }
    .top-title {
      p {
        @include defaultFontStyle(400, 20, 1.2, $black);
        margin-bottom: 15px;
      }
    }
    p {
      @include defaultFontStyle(400, 16, 1.2, $black);
      a {
        color: #0000ff;
        text-decoration: underline;
      }
    }
    .title-list-type {
      margin-bottom: 15px;
      h4 {
        margin-bottom: 8px;
      }
      ul {
        padding-left: 35px;
        list-style: disc;
        li {
          @include defaultFontStyle(400, 16, 1.2, $black);
        }
      }
    }
    .list-type {
      margin-bottom: 15px;
      ul {
        padding-left: 18px;
        list-style: disc;
        li {
          @include defaultFontStyle(400, 16, 1.2, $black);
          .color-red {
            color: $text-red;
          }
          .color-blue {
            color: #0000ff;
          }
        }
      }
    }
    .year-list {
      p {
        padding-left: 6px;
        margin-bottom: 6px;
      }
      table {
        width: 100%;
        @include defaultFontStyle(400, 16, 1.2, $black);
        margin-bottom: 15px;

        td {
          padding: 6px;
          &:first-child {
            width: 150px;
          }
          span {
            border-bottom: 1px solid red;
          }
        }
      }
    }
    .text-underline {
      text-decoration: underline;
    }
    .text-larg {
      @include defaultFontStyle(600, 22, 1.2, $black);
    }
    .border-bottom-red {
      border-bottom: 1px solid red;
    }
    .border-bottom-black {
      border-bottom: 1px solid $black;
    }
    img {
      margin-bottom: 15px;
    }
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $light-color;
    background-color: $light-color;
    border-radius: 5px;
  }
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
    background-color: $light-color;
  }
  ::-webkit-scrollbar-thumb {
    background-color: $light-black;
    border: 2px solid $light-black;
    border-radius: 5px;
    &:hover {
      background-color: $light-black;
      border: 2px solid $light-black;
    }
  }
}
.contact-us {
  background-color: var(--green-color);
  padding: 60px 0;
  @include media991 {
    padding: 30px 0;
  }
  h1 {
    color: $white;
    margin-bottom: 15px;
    &:before {
      background-color: $white;
    }
  }
  p {
    text-align: center !important;
    color: $white;
  }
  .btn {
    border-color: $white;
    color: $white;
    &:hover {
      border-color: $white;
      color: var(--green-color);
    }
  }
  form {
    .w-100 {
      padding: 0 8px;
    }
  }
}

// .slick-dots {
//     bottom: -50px;
//     li {
//         width: 34px;
//         button {
//             background: #707c8c;
//             border: 1px solid;
//             width: 34px;
//             height: 4px;
//             padding: 1px;
//             &::before {
//                 font-family: none;
//                 font-size: 0;
//             }
//         }
//         &.slick-active {
//             button {
//                 background: var(--green-color);
//             }
//         }
//     }
// }

// insurance
.insurance-info {
  padding: 60px 0;

  .insurance-box {
    padding-top: 40px;
    margin-top: 30px;
    border-top: 1px solid #e9ebef;
    i {
      color: var(--green-color);
      margin-right: 10px;
    }
    a {
      @include defaultFontStyle(400, 22, 1.2, var(--green-color));
      text-decoration: underline;
      margin-top: 25px;
      span {
        img {
          margin-right: 9px !important;
          display: initial;
        }
      }
    }
    p {
      @include defaultFontStyle(700, 16, 1.5, $black);
      max-width: 994px;
      padding-top: 12px;
      margin: auto;
      span {
        color: var(--green-color);
      }
    }
    .insurance_img {
      margin-bottom: 25px;
      width: 163px;
    }
    .utb_insurance_img {
      margin-bottom: 25px;
      width: 54px;
    }
  }
}
// league selection

.league-selection {
  .w-25 {
    @include media1199 {
      flex: 0 0 33%;
      max-width: 33%;
    }
  }
  .w-25 {
    @include media991 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  .w-25 {
    @include media675 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .event-select-checkbox {
    position: absolute;
    top: 20px;
    right: 0;
    .checkmark {
      border-radius: 100%;
      height: 30px;
      width: 30px;
      background-color: $white;
    }
    label:hover input ~ .checkmark {
      background-color: $white;
    }
    label input:checked ~ .checkmark {
      background-color: $blue;
      border-radius: 100%;
    }
    label .checkmark:after {
      left: 12px;
      top: 6px;
      width: 7px;
      height: 14px;
      border-width: 0 2px 2px 0;
    }
  }
}
.choose-super-event {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  h3 {
    .btn {
      margin-left: 30px;
      padding: 0;
      width: 40px;
      height: 40px;
      @include media575 {
        margin-left: 10px;
      }
    }
  }
  a {
    @include defaultFontStyle(700, 16, 1.5, $blue);
  }
}

// .fb_iframe_widget iframe {
//     width: 100% !important;
// }
// ._2p3a {
//     width: auto !important;
// }
// .fb_iframe_widget_fluid span {
//     width: 100% !important;
// }
// .fb-page{
//     width: 100%;
//     ._2p3a{
//         width: auto !important;
//     }
// }

.shoping-cart {
  .section_title {
    margin-bottom: 40px;
  }
  .product-list {
    background-color: $white;
    display: flex;
    justify-content: center;
    @include media767 {
      display: block;
    }

    @include media575 {
      display: block;
    }
    .product-search {
      width: 50%;
      padding: 20px;
      @include media767 {
        width: 100%;
      }
      .scrollbar_team {
        height: 435px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        overflow-y: scroll;
        margin: 15px 0px;
        @include media767 {
          margin: 10px 0;
        }
        @include media575 {
          max-height: 380px;
        }
        @include media480 {
          max-height: 250px;
        }
        .select_team {
          min-height: 100%;
          width: 100%;
          padding-right: 20px;
          @include media767 {
            width: 90%;
            margin-right: auto;
          }
          .team-flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 15px;
            .team-data {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .team-img {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 60px;
                height: 60px;
                margin-right: 20px;
                border-radius: 100%;
                > img {
                  max-width: 60px;
                  height: 60px;
                  object-fit: cover;
                  border-radius: 100%;
                  border: 1px solid #e1e2e7;
                }
                @include media575 {
                  margin-right: 5px;
                }
                @include media480 {
                  width: 50px;
                  height: 50px;
                }
              }
              h3 {
                @include defaultFontStyle(500, 16, 1.4, $black-color);
                @include media575 {
                  @include defaultFontStyle(500, 16, 1.4, $black-color);
                }
                @include media480 {
                  @include defaultFontStyle(500, 15, 1.4, $black-color);
                }
              }
            }
          }
        }
      }
    }
    .product-info {
      width: 50%;
      padding: 20px;
      @include media767 {
        width: 100%;
      }
      .product-pre {
        display: flex;
        padding-bottom: 15px;
        img {
          width: 70px;
          max-height: 119px;
          height: 70px;
          margin: 0;
          object-fit: contain;
        }
        .product-detail {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-left: 18px;
          .name {
            padding-right: 10px;
            h3 {
              @include defaultFontStyle(500, 16, 1.2, $black-color);
              margin-bottom: 4px;
            }
            h6 {
              @include defaultFontStyle(500, 14, 1.2, $light-black);
            }
          }
          .price {
            h3 {
              font-size: 18px;
            }
          }
        }
      }
      .note-total {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .note {
          p {
            margin-bottom: 0;
            font-size: 18px;
          }
        }
        .total {
          h3 {
            font-size: 18px;
          }
        }
      }
      .product-scroll {
        height: 425px;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        margin: 15px 0px;
        padding-right: 15px;
      }
      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px $gray-btn;
        background-color: $gray-btn;
        border-radius: 5px;
      }
      ::-webkit-scrollbar {
        width: 5px;
        border-radius: 5px;
        background-color: $gray-btn;
      }
      ::-webkit-scrollbar-thumb {
        background-color: $blue;
        border: 2px solid $blue;
        border-radius: 5px;
      }
    }
  }
}

.location-table {
  margin-top: 20px;
  table {
    width: 100%;
    thead {
      background-color: #e1e2e742;
      th {
        @include defaultFontStyle(500, 14, 1.2, $light-black);
        padding: 12px;
        border: 1px solid #f6f9fe;
        &:first-child {
          border-radius: 8px 0 0 0;
        }
        &:last-child {
          border-radius: 0 8px 0 0;
        }
      }
    }
    tbody {
      background-color: #fff;
      td {
        @include defaultFontStyle(600, 14, 1.2, $light-black);
        padding: 12px;
        border: 1px solid #eef1f7;
      }
    }
  }
}

// Download app

.download-app {
  background-color: $white;
  padding: 40px;
  margin: 70px 0;
  h3 {
    @include defaultFontStyle(500, 16, 1.2, $black-light);
    padding: 12px 0 35px 0;
    border-bottom: 1px solid #f8f6f7;
    margin: 0 auto;
    max-width: 835px;
    @include media767 {
      padding: 12px 0 15px 0;
    }
  }
  .store-img {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 35px;
    @include media767 {
      padding-top: 15px;
    }
    img {
      margin: initial;
      padding: 10px;
      @include media767 {
        width: 220px;
      }
    }
  }
}
body.modal-open {
  padding: 0;
}
.mobileapp-popup {
  @include media575 {
    margin: 10px;
  }
  .modal-content {
    background-image: url("../../assets/images/landing/main-hero.jpg");
    background-size: cover;
    position: relative;
    border-radius: 15px;
    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 1;
      border-radius: 15px;
    }
  }
  .modal-header {
    justify-content: flex-end !important;
    padding: 3px !important;
    overflow: hidden;
    position: relative;
    height: 56px;
    border-radius: 0 15px 0 0 !important;
    &::before {
      content: "";
      position: absolute;
      top: -46px;
      right: -40px;
      background: var(--green-color);
      background-repeat: no-repeat;
      height: 85px;
      width: 85px;
      transform: rotate(132deg);
      z-index: 1;
    }
    .modal_close {
      z-index: 1;
      img {
        width: 15px;
      }
    }
  }
  .modal-body {
    z-index: 1;
    padding-bottom: 56px;
    @include media575 {
      padding-bottom: 20px;
    }
    .downloadapp-content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0 52px;
      @include media575 {
        height: 470px;
        overflow-x: auto;
        align-items: normal;
        padding: 10px;
      }
      .download-info {
        text-align: center;
        padding: 0 20px;
        .logo {
          @include media575 {
            width: 120px;
          }
        }
        .bar {
          background-color: $white;
          width: 100px;
          height: 2px;
          margin: 40px auto 20px;
          @include media575 {
            margin: 20px auto;
          }
        }
        h3 {
          @include defaultFontStyle(700, 40, 1.5, $white);
          max-width: 600px;
          text-align: center;
          @include media575 {
            @include defaultFontStyle(500, 22, 1.5, $white);
          }
        }
        .store-img {
          display: flex;
          margin-top: 30px;
          a {
            margin: 0 10px;
          }
        }
      }
    }
    .mobile-app {
      @include media575 {
        display: none;
      }
    }
    .mobile-view {
      display: none;
      @include media575 {
        display: block;
        height: 170px;
      }
    }
  }
}

.check_box {
  // display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .checkbox {
    width: 100%;
    cursor: pointer;
    position: relative;
    margin: 0 15px;
    margin-left: 0;
    label {
      width: 100%;
      border-radius: 4px;
      background-color: transparent;
      text-align: center;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include defaultFontStyle(500, 16, 1.4, $blue);
      border: 1px solid $gray-btn;
      @include media480 {
        @include defaultFontStyle(500, 14, 1.4, $blue);
      }
      @include media480 {
        height: 42px;
      }
    }
    input[type="radio"],
    input[type="checkbox"] {
      opacity: 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      cursor: pointer;

      &:checked ~ label {
        background-color: $blue;
        color: $white;
      }
    }
  }
  &.disabled {
    display: flex;
    label {
      color: $black-light;
    }
    input[type="radio"],
    input[type="checkbox"] {
      opacity: 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      cursor: pointer;

      &:checked ~ label {
        // background-color: $gra-bg;
        // color: $black-light;
      }
    }
  }
}

// diamond connect event
// contact us
.contactus_section {
  padding: 60px 0 35px;
  .contactus_form {
    padding: 40px 0;
    border-top: 1px solid #6c7b952b;
    border-bottom: 1px solid #6c7b952b;

    .w-100 {
      padding: 0 8px;
    }
    .form-group {
      textarea,
      textarea:focus {
        height: 150px !important;
      }
      .form-control {
        height: 50px;
      }
    }
    ::placeholder {
      color: $light-black;
      font-weight: 400;
      font-size: 14px;
    }
    :focus,
    .form-control:hover {
      border: 1px solid $green-color !important;
    }
    .common-lable {
      color: $black-light;
      font-weight: 400;
      margin: 10px 0 12px;
      font-size: 16px;
      span {
        color: $text-red;
        font-size: 16px;
      }
    }
  }
}

/*  ....  */

.web_hotel_slide {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 2px #8392871a;
  img {
    width: 100%;
  }
}
.tabs_box {
  .tab {
    // background: #96CF04;

    &-label {
      color: #fff;
      font-size: 20px;
      align-items: center;
      padding: 5px 15px;
      position: relative;
      &::after {
        content: "";
        background: url("../../../src/assets/images/comapnylanding/plus.png") no-repeat;
        background-size: 100% 100%;
        display: inline-block;
        width: 30px;
        height: 30px;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        @include media575 {
          width: 25px;
          height: 25px;
        }
      }
    }

    &-content {
      background: #f9f9f9;
      border-radius: 0px 0px 5px 5px;
      border: 1px solid rgba(0, 0, 0, 0.25);

      p {
        color: #000000;
      }
    }
  }

  input:checked {
    + .tab-label {
      position: relative;
      &::after {
        content: "";
        background: url("../../../src/assets/images/comapnylanding/minus.png") no-repeat;
        background-size: 100% 100%;
        display: inline-block;
        width: 30px;
        height: 4px;
        margin-right: 0;
        position: absolute;
        top: 50%;
        right: 15px;
        @include media575 {
          width: 25px;
          height: 4px;
        }
      }
    }

    ~ .tab-content {
      max-height: 500px;
      overflow-y: auto;
      padding: 8px 11px 8px 11px;

      &.tournament_rules {
        max-height: 500px;
        overflow: auto;
      }
    }
  }
}

.tearm-policy {
  .tabs {
    .tab-label {
      display: block !important;
      padding: 7px 50px 8px 15px;
    }
  }
}
