.aboutus {
  text-align: center;
  .heading-title {
    margin-bottom: 30px;
    @include media767 {
      margin-bottom: 15px;
    }
    h2 {
      padding-bottom: 0;
      margin-bottom: 0;
      &:before {
        display: none;
      }
    }
  }
  p {
    width: 70%;
    margin: 0 auto 50px;
    @include media1429 {
      width: 100%;
      margin: 0 auto 20px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.pressroom {
  padding: 30px 0;
  .heading-title {
    margin-bottom: 30px;
    @include media767 {
      margin-bottom: 15px;
    }
    h2 {
      padding-bottom: 0;
      margin-bottom: 0;
      &:before {
        display: none;
      }
    }
  }
  .press_room_banner {
    margin-bottom: 30px;
    position: relative;
    // box-shadow: 0px 10px 5px -7px rgba(0, 0, 0, 0.09);
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    img {
      border-radius: 5px;
      box-shadow: 0px 9px 15px -7px rgba(0, 0, 0, 0.3);
      @include customMedia(400) {
        height: 150px;
      }
    }
    .press_room_banner_text {
      position: absolute;
      left: 0;
      right: 0;
      border: 5px solid $white;
      width: 40%;
      // height: 120px;
      background-color: rgba(0, 0, 0, 0.6);
      color: $white;
      margin: 0 auto;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      box-shadow: 0px 10px 5px -7px rgba(0, 0, 0, 0.09);
      padding: 15px;
      text-align: center;
      @include media1199 {
        width: 60%;
      }
      @include media767 {
        width: 70%;
        // height: 80px;
      }
      @include media575 {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        border: none;
        border-radius: 5px;
      }

      h5 {
        @include defaultFontStyle(700, 28, 1.2, $white);
        display: block;
        margin-bottom: 10px;
        cursor: pointer;
        transition: all 0.5s ease 0s;
        @include media767 {
          font-size: 20px;
        }
        &:hover {
          color: var(--green-color);
          text-decoration: underline;
        }
      }
      span {
        @include defaultFontStyle(500, 18, 1.2, $white);
        @include media767 {
          font-size: 14px;
        }
      }
    }
  }
  .press_room_blog {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    .press_room_blog_part {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      padding: 0 15px;
      margin-bottom: 30px;
      @include media767 {
        flex: 0 0 50%;
        max-width: 50%;
      }
      @include media575 {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .press_room_blog_inner {
        background-color: $light-color;
        padding: 0px 0 20px 0;
        border-radius: 5px;
        text-align: center;
        box-shadow: 0px 10px 5px -7px rgba(0, 0, 0, 0.09);

        .press_room_img {
          margin: 0 auto 20px;
          img {
            // border-radius: 5px;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        h5 {
          @include defaultFontStyle(700, 24, 1.2, $black-color);
          display: block;
          margin-bottom: 10px;
          cursor: pointer;
          transition: all 0.5s ease 0s;
          &:hover {
            color: var(--green-color);
            text-decoration: underline;
          }
        }
      }
    }
  }
  .press_room_thumb {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -15px;
    .press_room_thumb_part {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 15px;
      margin-bottom: 20px;
      @include media991 {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .press_room_thumb_inner {
        background-color: $white;
        padding: 15px;
        border-radius: 5px;
        display: flex;
        box-shadow: 0px 10px 5px -7px rgba(0, 0, 0, 0.09);
        @include media480 {
          display: block;
        }
        .press_room_thumb_img {
          width: 150px;
          height: 150px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 15px;
          @include media1199 {
            height: 120px;
            width: 120px;
          }
          @include media480 {
            width: 100%;
            margin-bottom: 15px;
            height: 100%;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
          }
        }
        .press_room_thumb_text {
          flex: 1;
          .news_thumb {
            @include defaultFontStyle(700, 15, 1.2, $light-black);
          }
          h5 {
            @include defaultFontStyle(700, 18, 1.4, $black-color);
            margin: 10px 0;
            cursor: pointer;
            transition: all 0.5s ease 0s;
            &:hover {
              text-decoration: underline;
              color: var(--green-color);
            }
            @include media1199 {
              font-size: 16px;
            }
          }
          .dates {
            @include defaultFontStyle(700, 15, 1.2, $light-black);
          }
        }
      }
    }
  }
}

.our_team_before {
  position: relative;
  overflow: hidden;
  &:before {
    position: absolute;
    width: 540px;
    content: "";
    height: 200px;
    background-color: #1e285e;
    z-index: 1;
    transform: rotate(-45deg);
    left: -35%;
    top: 0;
    @include media767 {
      left: -55%;
    }
    @include media480 {
      left: -85%;
      width: 540px;
      height: 140px;
    }
  }
}
.ourteam {
  padding: 30px 0;
  @include media767 {
    padding: 20px 0;
  }
  .heading-title {
    margin-bottom: 30px;
    @include media767 {
      margin-bottom: 15px;
    }
    h2 {
      padding-bottom: 0;
      margin-bottom: 0;
      &:before {
        display: none;
      }
    }
  }
  .ourteam_author {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    align-content: center;
    .ourteam_author_part {
      padding: 0 15px;
      flex: 0 0 33.33%;
      max-width: 33.33%;
      margin-bottom: 30px;
      text-align: center;
      @include media767 {
        flex: 0 0 50%;
        max-width: 50%;
      }
      @include media575 {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .ourteam_img {
        margin: 0 auto 20px;
        border-radius: 5px;
        width: 100%;
        display: flex;
        img {
          border-radius: 5px;
          width: 100%;
          height: 100%;
        }
      }
      h5 {
        @include defaultFontStyle(700, 22, 1.2, $black-color);
        @include media991 {
          font-size: 20px;
        }
        @include media575 {
          font-size: 18px;
        }
      }
      h6 {
        @include defaultFontStyle(500, 16, 1.2, $light-black);
        margin: 20px 0;
        @include media991 {
          font-size: 14px;
          margin: 10px 0;
        }
      }
      span {
        @include defaultFontStyle(600, 18, 1.2, $blue);
        cursor: pointer;
        transition: all 0.5s ease 0s;
        &:hover {
          color: $black-color;
        }
      }
    }
  }
  .staff_inner {
    display: flex;
    flex-wrap: wrap;
    .staff_inner_part {
      width: calc(100% / 4);
      padding: 0 15px;
      text-align: left;
      margin-bottom: 30px;
      @include media767 {
        width: calc(100% / 2);
        margin-bottom: 15px;
      }
      @include media575 {
        width: 100%;
      }
      h6 {
        @include defaultFontStyle(600, 22, 1.2, $black-color);
        margin-bottom: 10px;
        @include media1199 {
          font-size: 18px;
          margin-bottom: 5px;
        }
        @include media575 {
          font-size: 16px;
        }
      }
      span {
        @include defaultFontStyle(500, 16, 1.5, $light-black);
        @include media991 {
          font-size: 15px;
          // br {
          //   display: none;
          // }
        }
      }
    }
    &.three-parts {
      .staff_inner_part {
        width: calc(100% / 3);
        @include media767 {
          width: calc(100% / 2);
          margin-bottom: 15px;
        }
        @include media575 {
          width: 100%;
        }
      }
    }
  }
  &.white_bg {
    .heading-title {
      padding-bottom: 15px;
      border-bottom: 1px solid $gray-light;
      @include media767 {
        padding-bottom: 10px;
      }
    }
  }
}

// all inner product

.commen_product {
  padding: 30px 0;
  .heading-title {
    margin-bottom: 30px;
    @include media767 {
      margin-bottom: 15px;
    }
    h2 {
      padding-bottom: 0;
      margin-bottom: 0;
      &:before {
        display: none;
      }
    }
    a {
      color: $black-color;
      &:hover {
        text-decoration: underline;
        color: var(--green-color);
      }
    }
  }
  .video-bg {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    .video-react-big-play-button {
      position: absolute;
      height: 60px;
      width: 60px;
      margin: 0 auto;
      z-index: 1;
      top: 50%;
      left: 0;
      right: 0;
      border: none;
      background-color: rgba(51, 52, 64, 0.8);
      border-radius: 100%;
      background-size: contain;
      border: 2px solid $white;
      display: flex;
      align-items: center;
      justify-content: center;
      &:after {
        content: "\f0da";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        padding-left: 5px;
      }
      &:before {
        display: none;
      }
      @include media480 {
        width: 40px;
        height: 40px;
        top: 45%;
      }
    }
    .video-react.video-react-fluid {
      border-radius: 5px;
      .video-react-video {
        border-radius: 5px;
      }
    }
  }
  .video-react .video-react-poster {
    border-radius: 5px;
  }
}

.tearm-policy {
  .heading-title {
    margin-bottom: 15px;
    text-align: left;
    h2 {
      padding-bottom: 0;
      margin-bottom: 0;
      &:before {
        display: none;
      }
    }
  }
  span {
    @include defaultFontStyle(500, 16, 1.5, $black-color);
    margin-bottom: 15px;
    display: block;
  }
  p {
    margin-bottom: 15px;
    text-align: justify;
    @include defaultFontStyle(500, 16, 1.5, $light-black);
    padding: 0;
    @include media991 {
      @include defaultFontStyle(500, 15, 1.5, $light-black);
    }
    @include media480 {
      @include defaultFontStyle(500, 14, 1.5, $light-black);
    }
    &:last-child {
      margin-bottom: 0;
    }
    span {
      color: $black-color;
      font-weight: 600;
      display: inline-block;
      margin-bottom: 0;
    }
  }
  a {
    color: var(--green-color);
    &:hover {
      text-decoration: underline;
      color: $black-color;
    }
  }
  ol {
    &.list {
      list-style-type: inherit;
      padding-left: 30px;
      @include media991 {
        padding-left: 15px;
      }
    }
    li {
      margin-bottom: 15px;
      @include defaultFontStyle(500, 15, 1.5, $light-black);
      @include media991 {
        @include defaultFontStyle(500, 14, 1.5, $light-black);
      }
      @include media480 {
        @include defaultFontStyle(500, 13, 1.5, $light-black);
      }
    }
  }
  h3 {
    margin: 30px 0 10px;
    @include media767 {
      margin: 15px 0 10px;
    }
  }
  h4 {
    margin: 15px 0 5px;
    @include defaultFontStyle(500, 18, 1.4, $black-color);
    display: block;
    @include media991 {
      @include defaultFontStyle(500, 16, 1.4, $black-color);
    }
  }
}

.video-bg .video-react-big-play-button {
  position: absolute;
  height: 60px;
  width: 60px;
  margin: 0 auto;
  z-index: 1;
  top: 50%;
  left: 0;
  right: 0;
  background-color: rgba(51, 52, 64, 0.8);
  border-radius: 100%;
  background-size: contain;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-react .video-react-control:before,
.video-react .video-react-big-play-button:before {
  top: 6px;
}

.tearm-policy .tabs input:checked + .tab-label:after {
  transform: none;
}
