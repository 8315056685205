.loader {
    position: fixed;
    z-index: 9999;
    background: rgba(40, 38, 61, 0.7);
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
        max-width: 150px;
    }
    &.loaderBg {
        background: #fff;
    }
}
