.custom_chart_area {
    background: #f9f6f7;
    width: 100%;
    max-width: 1415px;
    min-width: 1415px;
    height: 550px;
    position: relative;
    overflow-y: auto;
    overflow-x: auto;
    border: 1px solid #e1e2e7;
    margin: 0 auto 30px auto;

    .custom_chart_area_sec {
        border-radius: 5px;
        min-width: 1403px;
        max-width: 1403px;
        width: 100%;
        height: 32000px;
        position: relative;

        .custom_drag_sec {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;

            .handle {
                background-color: transparent;
                border-right: 2px solid var(--green-color-light);
                border-top: 2px solid var(--green-color-light);
                border-bottom: 2px solid var(--green-color-light);
                border-radius: 2px;
                position: relative;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                padding: 3px 0 3px 5px;

                .handle_sec {
                    background: transparent;

                    .handle_part {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        height: 100%;
                        position: relative;
                        .game_number {
                            padding-right: 10px;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 5px;
                            p {
                                margin: 0 0 7px;
                            }
                        }
                        .source_section {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            height: 100%;
                            width: 98%;

                            .game_para_sec {
                                display: flex;
                                padding: 0 10px 0 3px;
                                justify-content: space-between;
                                align-items: flex-start;

                                .game_para {
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    padding: 0 5px 0 3px;
                                    margin-bottom: 0;
                                    font-size: 0.875rem;
                                    text-align: left;
                                    max-width: 175px;
                                    font-weight: 500;
                                    line-height: 1.5;
                                    color: #6c7b95;
                                }

                                .game_para_number_sec {
                                    margin-top: 5px;
                                    margin-bottom: 0;
                                    min-width: 30px;
                                    color: #fff;
                                    height: auto;
                                    display: flex;
                                    align-items: flex-start;
                                    background: var(--green-color-light);
                                    border-radius: 5px;
                                    padding: 1px 3px;
                                    justify-content: center;
                                }

                                &:last-child {
                                    align-items: flex-end;

                                    .game_para_number_sec {
                                        margin-bottom: 5px;
                                        margin-top: unset;
                                    }
                                }
                            }

                            &.final_section_line {
                                padding: 0 5px 0 0;
                                margin-bottom: 0;
                                font-size: 0.875rem;
                                text-align: left;
                                max-width: 100%;
                                font-weight: 500;
                                line-height: 1.5;
                                color: #6c7b95;
                            }
                        }
                    }
                }

                &.page_break_read_only {
                    border: none;
                    height: 100%;
                    padding: 0;

                    &::before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        background: var(--green-color-light);
                        top: 50%;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                    }
                }

                &.final_handle {
                    border: none !important;
                    padding-left: 0;

                    .final_section_line {
                        position: relative;
                        overflow: unset;
                        justify-content: center !important;

                        .source_list {
                            position: relative;

                            .final_para {
                                position: absolute;
                                top: -30px;
                                padding-left: 10px;
                                padding-bottom: 5px;
                                margin: 0;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                max-width: 250px;
                            }

                            .final_line {
                                position: relative;
                                border: unset;

                                &::before {
                                    content: "";
                                    position: absolute;
                                    width: 100%;
                                    background: var(--green-color-light);
                                    height: 2px;
                                    top: 50%;
                                    right: 0;
                                    transform: translateY(-50%);
                                }
                                p {
                                    margin: 0;
                                }
                            }
                            p {
                                margin-bottom: 0;
                                padding: 5px 0 0 12px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                max-width: 220px;
                            }
                        }
                    }
                }

                &.note_ele_handle {
                    padding: 3px 0 1.5px 0;
                    border-left: 2px solid var(--green-color-light);
                    border-radius: 10px;

                    .source_list {
                        height: 100%;

                        > div {
                            height: 100%;

                            .note_ele_text {
                                margin: 0 auto;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                padding: 0 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    @include customMedia(1440) {
        min-width: unset;
    }
}
