@font-face {
    font-family: "Helvetica Now Text";
    src: url("../font/HelveticaNowText-BlackItalic.eot");
    src: local("Helvetica Now Text  Black Ita"), local("HelveticaNowText-BlackItalic"),
        url("../font/HelveticaNowText-BlackItalic.eot?#iefix") format("embedded-opentype"),
        url("../font/HelveticaNowText-BlackItalic.woff2") format("woff2"),
        url("../font/HelveticaNowText-BlackItalic.woff") format("woff"),
        url("../font/HelveticaNowText-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "Helvetica Now Text";
    src: url("../font/HelveticaNowText-ExtBdIta.eot");
    src: local("Helvetica Now Text  ExtBd Ita"), local("HelveticaNowText-ExtBdIta"),
        url("../font/HelveticaNowText-ExtBdIta.eot?#iefix") format("embedded-opentype"),
        url("../font/HelveticaNowText-ExtBdIta.woff2") format("woff2"),
        url("../font/HelveticaNowText-ExtBdIta.woff") format("woff"),
        url("../font/HelveticaNowText-ExtBdIta.ttf") format("truetype");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "Helvetica Now Text";
    src: url("../font/HelveticaNowText-Black.eot");
    src: local("Helvetica Now Text  Black"), local("HelveticaNowText-Black"),
        url("../font/HelveticaNowText-Black.eot?#iefix") format("embedded-opentype"),
        url("../font/HelveticaNowText-Black.woff2") format("woff2"),
        url("../font/HelveticaNowText-Black.woff") format("woff"),
        url("../font/HelveticaNowText-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Helvetica Now Text";
    src: url("../font/HelveticaNowText-BoldItalic.eot");
    src: local("Helvetica Now Text  Bold Italic"), local("HelveticaNowText-BoldItalic"),
        url("../font/HelveticaNowText-BoldItalic.eot?#iefix") format("embedded-opentype"),
        url("../font/HelveticaNowText-BoldItalic.woff2") format("woff2"),
        url("../font/HelveticaNowText-BoldItalic.woff") format("woff"),
        url("../font/HelveticaNowText-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Helvetica Now Text";
    src: url("../font/HelveticaNowText-Bold.eot");
    src: local("Helvetica Now Text  Bold"), local("HelveticaNowText-Bold"),
        url("../font/HelveticaNowText-Bold.eot?#iefix") format("embedded-opentype"),
        url("../font/HelveticaNowText-Bold.woff2") format("woff2"),
        url("../font/HelveticaNowText-Bold.woff") format("woff"),
        url("../font/HelveticaNowText-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Helvetica Now Text";
    src: url("../font/HelveticaNowText-RegIta.eot");
    src: local("Helvetica Now Text  Reg Ita"), local("HelveticaNowText-RegIta"),
        url("../font/HelveticaNowText-RegIta.eot?#iefix") format("embedded-opentype"),
        url("../font/HelveticaNowText-RegIta.woff2") format("woff2"),
        url("../font/HelveticaNowText-RegIta.woff") format("woff"),
        url("../font/HelveticaNowText-RegIta.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Helvetica Now Text";
    src: url("../font/HelveticaNowText-ExtLtIta.eot");
    src: local("Helvetica Now Text  ExtLt Ita"), local("HelveticaNowText-ExtLtIta"),
        url("../font/HelveticaNowText-ExtLtIta.eot?#iefix") format("embedded-opentype"),
        url("../font/HelveticaNowText-ExtLtIta.woff2") format("woff2"),
        url("../font/HelveticaNowText-ExtLtIta.woff") format("woff"),
        url("../font/HelveticaNowText-ExtLtIta.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Helvetica Now Text";
    src: url("../font/HelveticaNowText-Thin.eot");
    src: local("Helvetica Now Text  Thin"), local("HelveticaNowText-Thin"),
        url("../font/HelveticaNowText-Thin.eot?#iefix") format("embedded-opentype"),
        url("../font/HelveticaNowText-Thin.woff2") format("woff2"),
        url("../font/HelveticaNowText-Thin.woff") format("woff"),
        url("../font/HelveticaNowText-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Helvetica Now Text";
    src: url("../font/HelveticaNowText-ExtraLight.eot");
    src: local("Helvetica Now Text  Extra Light"), local("HelveticaNowText-ExtraLight"),
        url("../font/HelveticaNowText-ExtraLight.eot?#iefix") format("embedded-opentype"),
        url("../font/HelveticaNowText-ExtraLight.woff2") format("woff2"),
        url("../font/HelveticaNowText-ExtraLight.woff") format("woff"),
        url("../font/HelveticaNowText-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Helvetica Now Text";
    src: url("../font/HelveticaNowText-Light.eot");
    src: local("Helvetica Now Text  Light"), local("HelveticaNowText-Light"),
        url("../font/HelveticaNowText-Light.eot?#iefix") format("embedded-opentype"),
        url("../font/HelveticaNowText-Light.woff2") format("woff2"),
        url("../font/HelveticaNowText-Light.woff") format("woff"),
        url("../font/HelveticaNowText-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Helvetica Now Text";
    src: url("../font/HelveticaNowText-ExtraBold.eot");
    src: local("Helvetica Now Text  Extra Bold"), local("HelveticaNowText-ExtraBold"),
        url("../font/HelveticaNowText-ExtraBold.eot?#iefix") format("embedded-opentype"),
        url("../font/HelveticaNowText-ExtraBold.woff2") format("woff2"),
        url("../font/HelveticaNowText-ExtraBold.woff") format("woff"),
        url("../font/HelveticaNowText-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Helvetica Now Text";
    src: url("../font/HelveticaNowText-MediumItalic.eot");
    src: local("Helvetica Now Text  Med Ita"), local("HelveticaNowText-MediumItalic"),
        url("../font/HelveticaNowText-MediumItalic.eot?#iefix") format("embedded-opentype"),
        url("../font/HelveticaNowText-MediumItalic.woff2") format("woff2"),
        url("../font/HelveticaNowText-MediumItalic.woff") format("woff"),
        url("../font/HelveticaNowText-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Helvetica Now Text";
    src: url("../font/HelveticaNowText-Regular.eot");
    src: local("Helvetica Now Text  Regular"), local("HelveticaNowText-Regular"),
        url("../font/HelveticaNowText-Regular.eot?#iefix") format("embedded-opentype"),
        url("../font/HelveticaNowText-Regular.woff2") format("woff2"),
        url("../font/HelveticaNowText-Regular.woff") format("woff"),
        url("../font/HelveticaNowText-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Helvetica Now Text";
    src: url("../font/HelveticaNowText-LightItalic.eot");
    src: local("Helvetica Now Text  Light Ita"), local("HelveticaNowText-LightItalic"),
        url("../font/HelveticaNowText-LightItalic.eot?#iefix") format("embedded-opentype"),
        url("../font/HelveticaNowText-LightItalic.woff2") format("woff2"),
        url("../font/HelveticaNowText-LightItalic.woff") format("woff"),
        url("../font/HelveticaNowText-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Helvetica Now Text";
    src: url("../font/HelveticaNowText-ThinItalic.eot");
    src: local("Helvetica Now Text  Thin Italic"), local("HelveticaNowText-ThinItalic"),
        url("../font/HelveticaNowText-ThinItalic.eot?#iefix") format("embedded-opentype"),
        url("../font/HelveticaNowText-ThinItalic.woff2") format("woff2"),
        url("../font/HelveticaNowText-ThinItalic.woff") format("woff"),
        url("../font/HelveticaNowText-ThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Helvetica Now Text";
    src: url("../font/HelveticaNowText-Medium.eot");
    src: local("Helvetica Now Text  Medium"), local("HelveticaNowText-Medium"),
        url("../font/HelveticaNowText-Medium.eot?#iefix") format("embedded-opentype"),
        url("../font/HelveticaNowText-Medium.woff2") format("woff2"),
        url("../font/HelveticaNowText-Medium.woff") format("woff"),
        url("../font/HelveticaNowText-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("../font/Roboto-Thin.eot");
    src: url("../font/Roboto-Thin.eot?#iefix") format("embedded-opentype"),
        url("../font/Roboto-Thin.woff2") format("woff2"), url("../font/Roboto-Thin.woff") format("woff"),
        url("../font/Roboto-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url("../font/Roboto-Regular.eot");
    src: url("../font/Roboto-Regular.eot?#iefix") format("embedded-opentype"),
        url("../font/Roboto-Regular.woff2") format("woff2"), url("../font/Roboto-Regular.woff") format("woff"),
        url("../font/Roboto-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url("../font/Roboto-Medium.eot");
    src: url("../font/Roboto-Medium.eot?#iefix") format("embedded-opentype"),
        url("../font/Roboto-Medium.woff2") format("woff2"), url("../font/Roboto-Medium.woff") format("woff"),
        url("../font/Roboto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url("../font/Roboto-Light.eot");
    src: url("../font/Roboto-Light.eot?#iefix") format("embedded-opentype"),
        url("../font/Roboto-Light.woff2") format("woff2"), url("../font/Roboto-Light.woff") format("woff"),
        url("../font/Roboto-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url("../font/Roboto-Bold.eot");
    src: url("../font/Roboto-Bold.eot?#iefix") format("embedded-opentype"),
        url("../font/Roboto-Bold.woff2") format("woff2"), url("../font/Roboto-Bold.woff") format("woff"),
        url("../font/Roboto-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url("../font/Roboto-Black.eot");
    src: url("../font/Roboto-Black.eot?#iefix") format("embedded-opentype"),
        url("../font/Roboto-Black.woff2") format("woff2"), url("../font/Roboto-Black.woff") format("woff"),
        url("../font/Roboto-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Avenir";
    src: url("../font/Metropolis-Black.woff2");
    src: url("../font/Metropolis-Black.woff2") format("woff2");
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: "Avenir";
    src: url("../font/Metropolis-ExtraBold.woff2");
    src: url("../font/Metropolis-ExtraBold.woff2") format("woff2");
    font-weight: 800;
    font-display: swap;
}

@font-face {
    font-family: "Avenir";
    src: url("../font/Metropolis-Bold.woff2");
    src: url("../font/Metropolis-Bold.woff2") format("woff2");
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: "Avenir";
    src: url("../font/Metropolis-SemiBold.woff2");
    src: url("../font/Metropolis-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: "Avenir";
    src: url("../font/Metropolis-Medium.woff2");
    src: url("../font/Metropolis-Medium.woff2") format("woff2");
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: "Avenir";
    src: url("../font/Metropolis-Regular.woff2");
    src: url("../font/Metropolis-Regular.woff2") format("woff2");
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Avenir";
    src: url("../font/Metropolis-Light.woff2");
    src: url("../font/Metropolis-Light.woff2") format("woff2");
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: "Avenir";
    src: url("../font/Metropolis-ExtraLight.woff2");
    src: url("../font/Metropolis-ExtraLight.woff2") format("woff2");
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: "Avenir";
    src: url("../font/Metropolis-Thin.woff2");
    src: url("../font/Metropolis-Thin.woff2") format("woff2");
    font-weight: 100;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("../font/Inter-Regular.woff2");
    src: url("../font/Inter-Regular.woff2") format("woff2");
    font-weight: 400;
    font-display: swap;
}
