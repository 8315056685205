.main_pro_tab {
  margin-top: 40px;
  padding-bottom: 20px;
  padding-top: 0;
  position: relative;
  @include media1199 {
    margin-top: 20px;
  }
  // @include media480 {
  //   margin-top: -36px;
  // }
  .container {
    max-width: 1120px;
    @include media1199 {
      max-width: 960px;
    }
  }
  .edit_pro_scroll {
    .edit_pro_inner {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      li {
        list-style: none;
        background-color: $white;
        box-shadow: 0px 10px 5px -7px rgba(0, 0, 0, 0.09);
        border-radius: 4px;
        width: 142px;
        text-align: center;
        height: 70px;
        line-height: 70px;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        transition: all 0.5s ease 0s;
        margin-right: 15px;
        margin-top: 8px;
        @include media1199 {
          width: 123px;
          height: 60px;
          line-height: 60px;
          margin: 5px;
        }

        @include media991 {
          width: 150px;
        }
        @include media575 {
          width: 46%;
        }
        a {
          @include defaultFontStyle(500, 20, 1.2, $black-color);
          transition: all 0.5s ease 0s;
          display: inline-block;
          vertical-align: middle;
          @include media1199 {
            font-size: 17px;
          }
          @include media991 {
            font-size: 15px;
          }
          @include media767 {
            font-size: 14px;
          }
          @include media575 {
            font-size: 15px;
          }
        }
        &.active,
        &:hover {
          background-color: var(--green-color-light);
          transition: all 0.5s ease 0s;
          box-shadow: 0px 0px 17px -5px var(--green-color-shadow);
          a {
            color: $white;
            transition: all 0.5s ease 0s;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
.before-login {
  &.other-before-dashboard {
    .white-bg {
      padding: 30px;
      display: flex;
      flex-direction: column;
      @include media1199 {
        padding: 20px;
      }
      @include media575 {
        padding: 0 20px 20px;
      }
      .flex-align {
        padding-top: 20px;
        border-top: 1px solid $gray-btn;
        width: 100%;
        margin-top: 20px;
        margin: 20px auto 0;
        .btn {
          margin: 0 15px;
          width: 150px;
          @include media480 {
            margin: 0 10px;
          }
        }
      }
      .common-lable {
        &:first-child {
          margin-top: 0;
          @include media575 {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

.before-dashboard {
  padding: 15px 0 0;
  .white-bg {
    padding: 30px;
    display: flex;
    flex-direction: column;
    @include media1199 {
      padding: 20px;
    }
    // @include media575 {
    //     padding: 0 20px 20px;
    // }
    .edit-50 {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
      .w-50 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 15px;
        @include media575 {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      textarea {
        height: 168px;
        @include media575 {
          height: auto;
        }
      }
    }
    .flex-align {
      padding-top: 20px;
      border-top: 1px solid $gray-btn;
      width: 100%;
      margin-top: 20px;
      margin: 20px auto 0;
      .btn {
        margin: 0 15px;
        width: 150px;
        @include media480 {
          margin: 0 10px;
        }
      }
    }
    .check_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.add_filed {
        flex-direction: column;
        align-items: inherit;
        .form-group {
          margin: 15px 0 0;
          @include media575 {
            margin: 15px 0 20px;
          }
          .form-control {
            padding-right: 65px;
          }
          .serch-btn {
            color: $white;
            font-size: 18px;
            width: 65px;
          }
        }
      }
      .checkbox {
        width: 100%;
        cursor: pointer;
        position: relative;
        margin: 0 15px;
        margin-left: 0;
        label {
          width: 100%;
          border-radius: 4px;
          background-color: transparent;
          text-align: center;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          @include defaultFontStyle(500, 16, 1.4, $blue);
          border: 1px solid $gray-btn;
          @include media480 {
            @include defaultFontStyle(500, 14, 1.4, $blue);
          }
          @include media480 {
            height: 42px;
          }
        }
        input[type="radio"],
        input[type="checkbox"] {
          opacity: 0;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          cursor: pointer;

          &:checked ~ label {
            background-color: $blue;
            color: $white;
          }
        }
      }
    }
    .serch-box {
      position: relative;
      .form-control {
        padding-right: 50px;
      }
      .serch-btn {
        position: absolute;
        height: 45px;
        width: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $blue;
        color: $white;
        top: 0;
        right: 0;
        border-radius: 0 4px 4px 0;
        @include media480 {
          height: 40px;
        }
        &:hover {
          opacity: 0.9;
        }
        &.input-email-btn {
          width: 140px;
          background: $green-btn;
          @include media991 {
            position: static;
            border-radius: 4px;
            margin-top: 8px;
          }
        }
      }
      span.tooltip {
        visibility: visible;
        width: 120px;
        background-color: $gra-dark;
        color: $white;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        bottom: 112%;
        right: -37px;
        opacity: 1;
        transition: opacity 0.5s;
        &:after {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: $gra-dark transparent transparent transparent;
        }
      }
      > div {
        width: 100%;
        > input,
        input:active,
        input:focus {
          box-shadow: none;
          height: 45px;
          width: 100%;
          padding: 10px;
          border: 1px solid $gray-btn;
          border-radius: 4px;
          @include defaultFontStyle(500, 16, 1.2, $black-color);
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          @include media480 {
            height: 40px;
            @include defaultFontStyle(400, 14, 1.2, $black-color);
          }
          &:hover,
          &:visited,
          &:active,
          &:focus {
            border: 1px solid $light-black;
            color: $black-color;
          }
          &:disabled {
            background: $gray-btn;
            border: 1px solid transparent;
          }
        }
        .inner-option {
          display: block;
          margin-bottom: 10px;
          font-size: 14px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    // .common-lable {
    //     &:first-child {
    //         margin-top: 0;
    //         @include media575 {
    //             margin-top: 20px;
    //         }
    //     }
    // }
    .select_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .select_box_inner {
        width: 33.33%;
        padding: 0 15px;
        margin-top: 20px;
        @include media767 {
          width: 50%;
        }
        @include media575 {
          width: 100%;
          margin-top: 0;
        }
      }
    }
    .check_input {
      display: flex;
      flex-wrap: wrap;
      .check_box,
      .check_inputbox {
        width: 50%;
        padding: 0 15px;
        @include media575 {
          width: 100%;
          padding: 0 15px;
        }
      }
    }
    .social_btn {
      margin-bottom: 0;
      @include media767 {
        > img {
          left: 5px;
          width: 20px;
          padding: 0px;
        }
      }
      @include media575 {
        margin-bottom: 10px;
        font-size: 13px;
        > img {
          left: 15px;
          width: 35px;
          padding: 7px;
        }
      }
    }
    .multi-radio {
      display: flex;
      align-items: center;
      border: 1px solid $gray-btn;
      height: 45px;
      border-radius: 4px;
      margin-bottom: 15px;
      @include media575 {
        margin-bottom: 0;
      }
      @include media480 {
        height: 40px;
      }
      .check_inputradio {
        width: 50%;
        padding: 0 15px;
        label {
          margin-bottom: 0;
          @include defaultFontStyle(500, 16, 1.2, $black-color);
          @include media767 {
            font-size: 14px;
          }
        }
      }
    }
    .facilitytab {
      @include media575 {
        margin-top: 20px;
        .form-group {
          margin-bottom: 0;
        }
      }
      .tabsfacility {
        display: flex;
        list-style: none;
        align-items: center;
        background-color: $blue;
        border-radius: 5px;
        li {
          width: 100%;
          cursor: pointer;
          text-align: center;
          padding: 15px 0;
          background-color: $gray-btn;
          transition: all 0.5s ease 0s;
          @include media767 {
            padding: 12px 0;
          }
          @include media480 {
            padding: 10px 0;
          }
          a {
            @include defaultFontStyle(500, 16, 1.2, $black-color);
            @include media767 {
              @include defaultFontStyle(500, 15, 1.2, $black-color);
            }
          }
          &:first-child {
            border-radius: 5px 0px 12px 0px;
          }
          &:nth-child(2) {
            border-radius: 0px 5px 0px 12px;
          }
          &.active {
            background-color: $blue;
            a {
              color: $white;
            }
          }
        }
      }
      .tabsfacility-view {
        border: 1px dashed $blue;
        padding: 15px;
        border-radius: 0 0 5px 5px;
        @include media575 {
          padding: 0 15px 15px;
        }
        .manage_roster {
          margin-top: 20px;
          border: 1px solid $gray-btn;
          border-radius: 5px;
          .table {
            text-align: left;
            thead {
              border-bottom: 1px solid $gray-btn;
            }
            tr {
              th {
                @include defaultFontStyle(500, 15, 1.2, $light-black);
                padding: 10px 38px;
                @include media480 {
                  font-size: 14px;
                }
                @include media991 {
                  padding: 10px 20px;
                }
                @include media767 {
                  padding: 10px 0 10px 15px;
                  min-width: 120px;
                }
                &:last-child {
                  padding-left: 10px;
                }
                &:first-child {
                  @include media767 {
                    min-width: 50px;
                  }
                }
              }
            }
            tr {
              td {
                @include defaultFontStyle(400, 15, 1.2, $black-color);
                padding: 10px 40px;
                @include media991 {
                  padding: 10px 20px;
                }
                @include media480 {
                  font-size: 14px;
                }
                @include media767 {
                  padding: 10px 0 10px 15px;
                }
              }
              .common_btn {
                padding: 10px;
                white-space: nowrap;
                &.copyBtn {
                  .btn {
                    position: relative;
                  }
                }
                @include media767 {
                  min-width: 150px;
                  padding: 10px 10px 20px;
                }
                @include media480 {
                  min-width: 120px;
                }
                .blue_btn,
                .red_btn {
                  width: 100%;
                  padding: 12px 10px;
                  @include media480 {
                    font-size: 12px;
                    padding: 12px 0px;
                  }
                }
              }
            }
          }
        }
      }
      &.add_playerinfo {
        .pleayer_info_features {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 15px;
          justify-content: space-between;
          .serach_filter {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 10px;
            @include media575 {
              display: block;
              margin-bottom: 10px;
            }
            .form-group {
              margin: 10px;
              margin-left: 0;
              input {
                height: 38px;
              }
            }
            .btn,
            .red_btn {
              margin-left: 10px;
              padding: 9px 13px;
              font-size: 14px;
              @include media480 {
                margin: 0 10px 0 0;
              }
            }
            .css-2b097c-container {
              width: 360px;
              margin: 10px;
              margin-left: 0;
              @include media991 {
                width: 270px;
              }
              @include media767 {
                width: 340px;
              }
              @include media575 {
                width: 240px;
              }
              @include media480 {
                width: 290px;
                margin: 10px 10px 10px 0;
              }
              @include media350 {
                width: 250px;
              }
              .css-xb97g8 {
                cursor: pointer;
              }
              .css-yk16xz-control,
              .css-1pahdxg-control {
                box-shadow: none;
                border: 1px solid $gray-btn;
                .css-g1d714-ValueContainer {
                  width: 100%;
                }
                &:hover {
                  border-color: $black-color !important;
                }
              }
            }
            .search-bar {
              height: 38px;
              @include media575 {
                margin-bottom: 10px;
              }
            }
          }
          .add_player {
            display: flex;
            align-items: center;
            // @include media767 {
            //     margin-top: 15px;
            // }
            .btn {
              padding: 9px 13px;
              font-size: 14px;
            }
            .download {
              width: 38px;
              height: 38px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid $gray-btn;
              border-radius: 100%;
            }
            .gmail {
              width: 38px;
              height: 38px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid $gray-btn;
              border-radius: 100%;
              margin: 0 10px;
            }
          }
        }
        // .serach_filter {
        //   display: flex;
        //   margin-bottom: 10px;
        //   margin-left: 10px;
        //   input {
        //     height: 38px;
        //   }
        //   .btn,.red_btn {
        //     margin-left: 10px;
        //     padding: 9px 13px;
        //     font-size: 14px;
        //   }
        // }
        .tabsfacility-view {
          border: none;
          padding: 0;
          .manage_roster {
            margin-top: 0;
            margin-bottom: 15px;
            .table-responsive-xl {
              display: block;
              width: 100%;
              overflow-x: auto;
              -webkit-overflow-scrolling: touch;
              .table {
                tr {
                  th {
                    padding: 10px 15px;
                    min-width: 140px;
                    &:first-child {
                      min-width: 50px;
                    }
                    &.head_coach_title {
                      min-width: 230px;
                    }
                  }
                  td {
                    padding: 10px 15px;
                    &.common_btn {
                      padding: 10px;
                      a {
                        padding: 12px 0;
                        font-size: 14px;
                        width: 100px;
                      }
                    }
                    .head_coach {
                      display: flex;
                      flex-wrap: wrap;
                      span {
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 1.2;
                        color: #333333;
                        background: #e6e6e6;
                        padding: 4px;
                        margin: 5px;
                        border-radius: 2px;
                        position: relative;
                        display: flex;
                        align-items: center;
                        .cross-btn {
                          // position: absolute;
                          // top: 10px;
                          // right: 7px;
                          margin: 0 6px;
                          cursor: pointer;
                          &:hover {
                            color: red;
                          }
                        }
                      }
                    }
                    // .cross-btn{
                    //   background: #e3e3e3c7;
                    //   border-radius: 100%;
                    //   padding: 4px 5px;
                    //   font-size: 10px;
                    //   margin: 0 3px;
                    // }
                    .edit_icon {
                      color: $blue;
                      font-size: 14px;
                      font-weight: 600;
                      display: inline-block;
                      vertical-align: middle;
                      &:hover {
                        color: $black-color;
                      }
                    }
                  }
                }
              }
            }
          }
          .pagination {
            display: flex;
            padding-left: 0;
            list-style: none;
            align-items: center;
            justify-content: flex-end;
            border-radius: 0.25rem;
            li {
              list-style: none;
              .page-link {
                position: relative;
                display: block;
                padding: 0.5rem 0.75rem;
                margin-left: -1px;
                line-height: 1.25;
                color: $black-color;
                background-color: $white;
                border: 1px solid $gray-btn;
              }
              &:first-child {
                margin-left: 0;
                border-top-left-radius: 0.25rem;
                border-bottom-left-radius: 0.25rem;
              }
              &:last-child {
                border-top-right-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
              }
              &:hover {
                a {
                  color: var(--green-color);
                }
              }
            }
          }
        }
      }
    }
    .uploadtab {
      display: flex;
      list-style: none;
      align-items: center;
      border: 1px solid $gray-light;
      border-radius: 5px;
      margin: 20px 0;
      li {
        width: 100%;
        text-align: center;
        padding: 15px 0;
        cursor: pointer;
        background-color: transparent;
        transition: all 0.5s ease 0s;
        @include media767 {
          padding: 12px 0;
        }
        @include media480 {
          padding: 10px 0;
        }
        a {
          @include defaultFontStyle(500, 16, 1.2, $black-color);
          @include media767 {
            @include defaultFontStyle(500, 15, 1.2, $black-color);
          }
        }
        &:first-child {
          border-radius: 5px 0px 0px 5px;
        }
        &:nth-child(2) {
          border-radius: 0px 5px 5px 0px;
        }
        &.active {
          background-color: $blue;
          a {
            color: $white;
          }
        }
      }
    }
    .uploadtab_view {
      border: 2px dashed $gray-light;
      border-radius: 5px;
      .table-responsive-xl {
        padding: 15px;
        table {
          margin-bottom: 20px;
          .common_btn {
            min-width: auto;
            padding: 0 15px;
            padding-right: 0;
            @include media767 {
              padding-right: 15px;
              padding-left: 0;
            }
            .blue_btn,
            .red_btn {
              padding: 0;
              width: 100px;
              height: 45px;
              display: flex;
              align-items: center;
              justify-content: center;
              @include media480 {
                width: 60px;
                font-size: 13px;
                padding: 0;
              }
            }
          }
          tr {
            border-bottom: 10px solid transparent;
            &:last-child {
              border-bottom: none;
            }
          }
          th,
          td {
            min-width: 30px;
            text-align: left;
            padding: 0;
            @include defaultFontStyle(400, 16, 1.2, $light-black);
            @include media480 {
              @include defaultFontStyle(400, 15, 1.2, $light-black);
            }
          }
          td {
            min-width: 450px;
            @include defaultFontStyle(400, 16, 1.2, $light-black);
            @include media991 {
              min-width: 350px;
            }
            @include media767 {
              min-width: 250px;
            }
            @include media480 {
              min-width: 150px;
              @include defaultFontStyle(400, 15, 1.2, $light-black);
            }
          }
        }
      }
      .gray_btn {
        width: 100%;
      }
    }
    .reassign_team {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      flex-wrap: wrap;
      @include media767 {
        margin-top: 20px;
      }
      a {
        @include defaultFontStyle(500, 15, 1.4, $blue);
        @include media767 {
          @include defaultFontStyle(500, 14, 1.4, $blue);
        }
        &:hover {
          color: var(--green-color);
        }
      }
    }
  }
  .player_list_collage {
    border: 1px dashed $gray-btn;
    border-radius: 5px;
    @include media575 {
      margin-top: 20px;
    }
    .form-group {
      padding: 15px;
      border-bottom: 1px solid $gray-btn;
      @include media767 {
        padding: 10px;
      }
    }
    .otherbrand-logo {
      display: flex;
      align-items: center;
      padding: 15px;
      flex-wrap: wrap;
      border-bottom: 1px solid $gray-btn;
      height: 182px;
      overflow: auto;
      .brand-logo-inner {
        padding: 0 10px;
        padding-left: 0;
        .brand-logo-main {
          border: 1px solid $gray-btn;
          display: flex;
          margin: 0 0 15px;
          align-items: center;
          flex-wrap: wrap;
          border-radius: 8px;
          height: 70px;
          width: 105px;
          @include media1199 {
            width: 120px;
          }
          @include media767 {
            width: 90px;
            height: 60px;
            margin: 0 0 10px;
          }
          @include media575 {
            width: 110px;
            height: 70px;
          }
          @include media480 {
            height: 60px;
            width: 90px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px $gray-btn;
      background-color: $gray-btn;
      border-radius: 5px;
    }
    ::-webkit-scrollbar {
      width: 5px;
      border-radius: 5px;
      background-color: $gray-btn;
    }
    ::-webkit-scrollbar-thumb {
      background-color: $blue;
      border: 2px solid $blue;
      border-radius: 5px;
    }
    .scrollbar_team {
      max-height: 215px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      overflow-y: scroll;
      margin: 15px;
      @include media767 {
        margin: 10px;
      }
      @include media575 {
        max-height: 355px;
      }
      @include media480 {
        max-height: 300px;
      }
      .select_team {
        min-height: 100%;
        width: 100%;
        .team-flex {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 95%;
          margin-bottom: 15px;
          @include media767 {
            width: 98%;
          }
          .team-data {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .team-img {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 60px;
              height: 60px;
              margin-right: 15px;
              border-radius: 100%;
              border: 1px solid $gray-btn;
              > img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 100%;
              }
              @include media575 {
                margin-right: 5px;
              }
              @include media480 {
                width: 65px;
                height: 65px;
              }
            }
            h4 {
              @include defaultFontStyle(500, 16, 1.4, $black-color);
              @include media767 {
                font-size: 14px;
              }
            }
          }
          .radiobox {
            position: relative;
            display: flex;
            input {
              position: absolute;
              opacity: 0;
              left: 0;
              right: 0;
              top: 0;
              height: 100%;
              width: 100%;
              z-index: 1;
              cursor: pointer;
            }
            span {
              position: relative;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              transition: all 0.5s ease 0s;
              background-color: $gray-btn;
            }
            span:after {
              content: "";
              position: absolute;
              width: 16px;
              height: 8px;
              border: 3px solid $white;
              border-top: 0;
              border-right: 0;
              transform: rotate(-40deg) scale(0.6);
              top: 30%;
              left: 7px;
              transition: all 0.5s ease 0s;
              opacity: 0;
            }
            input:checked ~ span::after {
              transform: rotate(-40deg) scale(1);
              opacity: 1;
            }
            input:checked ~ span {
              background: $blue;
            }
          }
        }
      }
    }
  }
  .linkplayer-filter {
    display: flex;
    justify-content: space-between;
    @include media575 {
      display: block;
    }
    .form-group {
      flex: 0 0 50%;
      max-width: 50%;
      @include media575 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .btn {
      white-space: nowrap;
      padding: 8px;
      margin: 0 10px;
      &:first-child {
        margin-right: 0;
      }
      @include media575 {
        margin-top: 10px;
        margin-left: 0;
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
  .linkedplayer-nodeta {
    .text-right {
      @include media575 {
        text-align: center;
        margin-top: 20px;
      }
    }
  }
  .parent-info-table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 30px;
    th {
      @include defaultFontStyle(500, 16, 1.4, $light-black);

      padding: 19px 24px;
      text-align: left;
      background-color: $light-white;
      &:nth-child(3) {
        text-align: center;
      }
    }
    td {
      padding: 20px 24px;
      .card_holder_lable {
        display: flex;
        max-width: 260px;
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}

.roster-table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $white;
  background-color: $white;
  border-radius: 5px;
}

.roster-table::-webkit-scrollbar {
  height: 7px;
  width: 100%;
  border-radius: 5px;
  background-color: $white;
}

.roster-table::-webkit-scrollbar-thumb {
  background-color: $gray-btn;
  border-bottom: 2px solid $gray-btn;
  border-radius: 5px;
  margin-top: 15px;
  &:hover {
    background-color: $light-black;
    border-bottom: 2px solid $light-black;
  }
}

/* event multiple Pay */
.event_multiple_Pay {
  padding: 60px 0;
  .event_list_info {
    background-color: $white;
    border: 1px solid #e1e3e8;
    border-radius: 0 0 5px 5px;
    max-width: 1000px;
    margin: auto;
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
    .title {
      padding: 15px 20px;
      text-align: left;
      .section_title {
        font-size: 24px;
        text-align: left;
        &::before {
          right: unset;
        }
      }
    }
    .event_list {
      .event_list_item {
        display: flex;
        align-items: center;
        border-top: 1px solid #e1e3e8;
        padding: 10px 20px;
        justify-content: center;
        .event_logo {
          img {
            width: 70px;
            height: 70px;
            border-radius: 100%;
          }
        }
        .event_detail {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          margin-left: 20px;
          width: calc(100% - 70px);
          h5 {
            @include defaultFontStyle(500, 16, 1.4, $black-color);
            margin-right: 10px;
          }
          p {
            @include defaultFontStyle(500, 16, 1.4, $black-color);
            margin: 0;
          }
        }
      }
    }
    .payment_info {
      padding: 20px 20px;
      border-top: 1px solid #e1e3e8;
      .row {
        align-items: center;
      }
      .w-85 {
        @include media991 {
          flex: 0 0 70%;
          max-width: 70%;
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
        @include media575 {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      .w-15 {
        @include media991 {
          flex: 0 0 30%;
          max-width: 30%;
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
        @include media575 {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      .form-group {
        @include media991 {
          margin-bottom: 0;
        }
        .custom-select {
          height: 48px;
        }
        .form-control {
          height: 48px;
        }
      }
    }
    .apply_btn {
      .btn {
        height: 48px;
        margin-top: 9px;
        width: 100%;
        @include media991 {
          margin-top: 23px;
        }
        @include media575 {
          margin-top: 0;
        }
      }
    }
    .join_btn {
      padding: 0 8px;
      margin-top: 10px;
      .btn {
        padding: 13px 50px;
      }
    }
  }
}
