header {
  left: 0;
  position: sticky;
  right: 0;
  top: 0;
  background-color: #31313d;
  z-index: 999;
  // padding: 18px 0;
  transition: all 0.5s;
  font-family: $secondary-font;
  box-shadow: 0px 4px 25px #35354112;

  @include media480 {
    // padding: 12px 0;
  }

  &.webglheader {
    padding: 8px 0;
    position: sticky;
    top: 0;
    background-color: #31313d;
    transition: all 0.5s;

    // &.fixed {
    //   padding: 8px 0;
    //   left: 0;
    //   position: fixed;
    //   right: 0;
    //   top: 0;
    // }
    .navbar-expand-lg {
      .container {
        max-width: 100%;
        padding: 0 30px;

        @include media991 {
          padding: 0 15px;
        }

        .navbar-brand {
          // max-width: 210px;

          // @include media991 {
          //   max-width: 200px;
          // }

          // @include media767 {
          //   max-width: 180px;
          // }

          // @include media480 {
          //   max-width: 160px;
          // }
        }
        #navbarNav {
          .navbar-nav {
            .nav-item {
              ul.sub-menu {
                top: calc(100% + 5px);

                &:before {
                  border-width: 0 8px 12px;
                  top: -10px;
                  border-color: transparent transparent $white;
                }
              }
            }
          }
        }

        .avatar-dropdown-menu {
          .login-user .avatar-image {
            width: 32px;
            height: 32px;
            border-width: 2px;
          }

          .avatar-dropdown-menu-items {
            right: 0;
            top: calc(100% + 8px);

            &:before {
              right: 15px;
              left: auto;
              border-width: 0 8px 12px;
              top: -10px;
              border-color: transparent transparent $white;
            }
          }
        }

        .header_align {
          .notification_alert {
            > a {
              width: 32px;
              height: 32px;
              font-size: 14px;
              border-width: 2px;
            }

            .notification_dropdown {
              top: calc(100% + -2px);

              &:before {
                border-width: 0 8px 12px;
                top: -8px;
                border-color: transparent transparent $white;
              }
            }
          }
        }

        > div {
          display: flex;
          align-items: center;
        }
      }
    }

    .mainNav {
      padding: 0 !important;
    }

    .menubar {
      padding: 3px !important;
    }
  }

  &.fixed {
    // padding: 18px 0;
    transition: all 0.5s;
    background-color: #31313d;
    // box-shadow: 0 0 10px 1px rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);

    @include media480 {
      // padding: 12px 0;
    }
  }

  .navbar-expand-lg {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;

    .container {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .navbar-brand {
        display: flex;
        max-width: 600px;
        align-items: center;
        flex-wrap: wrap;

        // @include media1366{
        //   max-width: 170px;
        // }
        // @include media991 {
        //   max-width: 200px;
        // }
        // @include media767 {
        //   max-width: 150px;
        // }
        // @include media480 {
        //   max-width: 110px;
        // }
        img {
          max-width: 300px;
          height: 100%;
          // width: 235px;
          // @include media1366 {
          //   max-width: 170px;
          // }
          // @include media991 {
          //   max-width: 166px;
          // }
          // @include customMedia(380) {
          //   max-width: 111px;
          // }
          // &.gob-logo {
          //   width: 100% !important;
          //   padding: 8px;
          //   @include media1366 {
          //     padding: 4px;
          //     max-width: 69px;
          //   }
          //   @include media991 {
          //     padding: 0px;
          //     max-width: 56px;
          //   }
          // }
        }
      }

      #navbarNav {
        flex-basis: 100%;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-align: center;
        align-items: center;
        display: flex;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;

        @include media991 {
          background: $white;
          box-shadow: 0 0px 0 10px #fff inset, 0 0 0 13px $green-color inset;
          padding: 50px 20px 15px;
          position: fixed;
          top: 0;
          right: 0;
          display: block !important;
          width: 60%;
          text-align: center;
          bottom: 0;
          -webkit-transition: all 0.5s ease 0s;
          -moz-transition: all 0.5s ease 0s;
          -ms-transition: all 0.5s ease 0s;
          -o-transition: all 0.5s ease 0s;
          transition: all 0.5s ease 0s;
          -webkit-transform: translateX(110%);
          -moz-transform: translateX(110%);
          -ms-transform: translateX(110%);
          -o-transform: translateX(110%);
          transform: translateX(110%);
          // height: calc(100vh -100px);
          overflow: auto;
          z-index: 2;
        }

        @include media575 {
          width: 80%;
        }

        @include media480 {
          width: 90%;
          box-shadow: 0 0px 0 10px #fff inset, 0 0 0 13px var(--green-color) inset;
        }

        .navbar-nav {
          display: flex;
          list-style: none;
          -ms-flex-direction: row;
          flex-direction: row;
          margin-left: auto;
          align-items: center;

          // height: calc(100vh - 130px);overflow: scroll;
          @include media991 {
            -ms-flex-direction: column;
            flex-direction: column;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            height: calc(100vh - 250px);
            overflow: scroll;
            align-items: initial;
          }

          .nav-item {
            margin: 0 10px;
            position: relative;

            .nav-link {
              display: block;
              @include defaultFontStyle(500, 14, 1.2, $white);

              @include media991 {
                color: $black-color;
                padding: 12px 0;
                border-bottom: 1px solid $menu-bg;
                text-align: left;
              }
            }

            .arrow {
              display: none;

              @include media991 {
                display: block;
                color: $black-color;
                position: absolute;
                right: 0;
                top: 5px;
                cursor: pointer;
                width: 90px;
                text-align: right;
                font-size: 15px;
                height: 38px;
                -webkit-transition: all 0.5s ease-in-out;
                -moz-transition: all 0.5s ease-in-out;
                -o-transition: all 0.5s ease-in-out;
                -ms-transition: all 0.5s ease-in-out;
                transition: all 0.5s ease-in-out;
                line-height: 38px;

                i {
                  font-weight: 600;
                  transition: all 0.5s ease-in-out;
                }

                &.active {
                  i {
                    transform: rotate(90deg);
                    -webkit-transition: all 0.5s ease-in-out;
                    -moz-transition: all 0.5s ease-in-out;
                    -o-transition: all 0.5s ease-in-out;
                    -ms-transition: all 0.5s ease-in-out;
                    transition: all 0.5s ease-in-out;
                  }
                }
              }
            }

            ul.sub-menu {
              padding: 0;
              background-color: $white;
              width: 180px;
              white-space: nowrap;
              position: absolute;
              top: calc(100% + 19px);
              left: -60px;
              z-index: 1;
              visibility: hidden;
              opacity: 0;
              -webkit-transform: translateY(30px);
              -ms-transform: translateY(30px);
              transform: translateY(30px);
              -webkit-transition: all 0.5s ease 0s;
              -moz-transition: all 0.5s ease 0s;
              -ms-transition: all 0.5s ease 0s;
              -o-transition: all 0.5s ease 0s;
              transition: all 0.5s ease 0s;
              border-radius: 4px;
              -webkit-border-radius: 4px;
              -moz-border-radius: 4px;
              box-sizing: border-box;
              -webkit-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.09);
              -moz-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.09);
              box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.09);
              right: 0;
              margin: 0 auto;

              @include media991 {
                position: relative;
                padding: 0;
                margin: 0 15px;
                width: 100%;
                background: transparent;
                box-shadow: none;
                top: auto;
                left: auto;
                border-radius: 0;
                -webkit-transform: inherit;
                -ms-transform: inherit;
                transform: inherit;
                visibility: 1;
                opacity: 1;
                max-height: 0;
                overflow-y: hidden;
                -webkit-transition: max-height 0.5s ease-in-out;
                -moz-transition: max-height 0.5s ease-in-out;
                -o-transition: max-height 0.5s ease-in-out;
                transition: max-height 0.5s ease-in-out;
              }

              &:before {
                position: absolute;
                top: -17px;
                left: 0;
                display: block;
                content: "";
                width: 0;
                border-style: solid;
                border-width: 0 10px 18px;
                border-color: transparent transparent $white;
                box-sizing: border-box;
                right: 0;
                margin: 0 auto;
                height: 0;

                @include media991 {
                  display: none;
                }
              }

              > li {
                display: block;
                border-radius: 4px;
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;

                a {
                  @include defaultFontStyle(500, 14, 1.2, $black-color);
                  text-align: left;
                  display: block;
                  padding: 15px 0;
                  margin: 0 15px;
                  border-bottom: 1px solid $menu-bg;
                  position: relative;
                  text-decoration: none;
                  outline: none;
                  text-transform: none;
                  white-space: nowrap;
                  -webkit-transition: all 0.5s linear;
                  -moz-transition: all 0.5s linear;
                  -o-transition: all 0.5s linear;
                  -ms-transition: all 0.5s linear;
                  transition: all 0.5s linear;

                  @include media991 {
                    margin: 0;
                    visibility: initial;
                  }

                  img {
                    margin: 0;
                  }
                }

                .product-submenu {
                  margin-left: 15px;
                  max-height: 0;
                  overflow-y: hidden;
                  -webkit-transition: max-height 0.5s ease-in-out;
                  -moz-transition: max-height 0.5s ease-in-out;
                  -o-transition: max-height 0.5s ease-in-out;
                  transition: max-height 0.5s ease-in-out;

                  @include media991 {
                    max-height: 100vh;
                    -webkit-transition: all 0.5s ease-in-out;
                    -moz-transition: all 0.5s ease-in-out;
                    -o-transition: all 0.5s ease-in-out;
                    -ms-transition: all 0.5s ease-in-out;
                    transition: all 0.5s ease-in-out;
                  }

                  &.active {
                    max-height: 100vh;
                    -webkit-transition: all 0.5s ease-in-out;
                    -moz-transition: all 0.5s ease-in-out;
                    -o-transition: all 0.5s ease-in-out;
                    -ms-transition: all 0.5s ease-in-out;
                    transition: all 0.5s ease-in-out;

                    @include media991 {
                      max-height: 0;
                      overflow-y: hidden;
                      -webkit-transition: max-height 0.5s ease-in-out;
                      -moz-transition: max-height 0.5s ease-in-out;
                      -o-transition: max-height 0.5s ease-in-out;
                      transition: max-height 0.5s ease-in-out;
                    }
                  }
                }

                p {
                  @include defaultFontStyle(500, 14, 1.2, $black-color);
                  text-align: left;
                  display: block;
                  padding: 15px 0;
                  margin: 0 15px;
                  border-bottom: 1px solid $menu-bg;
                  position: relative;
                  text-decoration: none;
                  outline: none;
                  text-transform: none;
                  white-space: nowrap;
                  -webkit-transition: all 0.5s linear;
                  -moz-transition: all 0.5s linear;
                  -o-transition: all 0.5s linear;
                  -ms-transition: all 0.5s linear;
                  transition: all 0.5s linear;
                  cursor: pointer;
                }

                &:hover,
                &.active {
                  background-color: $menu-bg;
                  -webkit-transition: all 0.5s linear;
                  -moz-transition: all 0.5s linear;
                  -o-transition: all 0.5s linear;
                  -ms-transition: all 0.5s linear;
                  transition: all 0.5s linear;

                  @include media991 {
                    background-color: transparent;
                  }

                  a {
                    color: $blue;
                    -webkit-transition: all 0.5s linear;
                    -moz-transition: all 0.5s linear;
                    -o-transition: all 0.5s linear;
                    -ms-transition: all 0.5s linear;
                    transition: all 0.5s linear;
                  }
                }

                &.inner-subemnu {
                  .product-dropdown {
                    background-color: $menu-bg;
                    position: relative;
                    margin: 0;
                    border-radius: 4px;
                    padding: 18px 20px;
                    @include defaultFontStyle(500, 14, 1.2, $blue);
                    text-align: left;
                    display: block;
                    padding: 18px 15px;
                    border-bottom: 1px solid $menu-bg;
                    position: relative;
                    text-decoration: none;
                    outline: none;
                    text-transform: none;
                    white-space: nowrap;
                    -webkit-transition: all 0.5s linear;
                    -moz-transition: all 0.5s linear;
                    -o-transition: all 0.5s linear;
                    -ms-transition: all 0.5s linear;
                    transition: all 0.5s linear;

                    @include media991 {
                      background: transparent;
                      padding: 15px 0;
                      color: $black-color;
                    }

                    &:before {
                      position: absolute;
                      content: "\f054";
                      font-family: "Font Awesome 5 Free";
                      font-weight: 600;
                      right: 15px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      height: 20px;
                      width: 20px;
                      color: $light-black;
                      transform: rotate(90deg);
                      -webkit-transition: all 0.5s ease-in-out;
                      -moz-transition: all 0.5s ease-in-out;
                      -o-transition: all 0.5s ease-in-out;
                      -ms-transition: all 0.5s ease-in-out;
                      transition: all 0.5s ease-in-out;

                      @include media991 {
                        transform: rotate(90deg);
                        right: 30px;
                        color: $black-color;
                        font-size: 15px;
                      }
                    }

                    &.active {
                      &:before {
                        transform: rotate(-90deg);

                        @include media991 {
                          transform: rotate(0deg);
                        }
                      }
                    }
                  }

                  &:hover,
                  &.active {
                    background-color: transparent;
                    color: $black-color;
                  }

                  .product-submenu {
                    li {
                      a {
                        color: $black-color;
                      }

                      &:hover {
                        background-color: $menu-bg;
                        -webkit-transition: all 0.5s ease-in-out;
                        -moz-transition: all 0.5s ease-in-out;
                        -o-transition: all 0.5s ease-in-out;
                        -ms-transition: all 0.5s ease-in-out;
                        transition: all 0.5s ease-in-out;

                        @include media991 {
                          background-color: transparent;
                        }

                        a {
                          color: $blue;
                        }
                      }
                    }
                  }
                }
              }

              &.active-submenu {
                max-height: 100vh;
                -webkit-transition: all 0.5s ease-in-out;
                -moz-transition: all 0.5s ease-in-out;
                -o-transition: all 0.5s ease-in-out;
                -ms-transition: all 0.5s ease-in-out;
                transition: all 0.5s ease-in-out;
              }

              .menu_event_list {
                display: flex;
                align-items: center;

                .event_img {
                  width: 80px;
                  height: 60px;
                  border: 1px solid $gray-light;
                  border-radius: 4px;
                  margin-right: 15px;
                  display: flex;
                  align-items: center;
                  padding: 8px;
                }

                h5 {
                  @include defaultFontStyle(400, 16, 1.2, $black-color);
                }
              }
            }

            &:hover {
              ul.sub-menu {
                -webkit-transform: translateY(10px);
                -ms-transform: translateY(10px);
                transform: translateY(10px);
                visibility: visible;
                opacity: 1;

                @include media991 {
                  -webkit-transform: none;
                  -ms-transform: none;
                  transform: none;
                  transform: none;
                  visibility: visible;
                  opacity: 1;
                  -webkit-transition: all 0.5s ease-in-out;
                  -moz-transition: all 0.5s ease-in-out;
                  -o-transition: all 0.5s ease-in-out;
                  -ms-transition: all 0.5s ease-in-out;
                  transition: all 0.5s ease-in-out;
                }
              }

              .nav-link {
                color: $green-color;
              }
            }

            &.hide-desktop {
              display: none;

              @include media991 {
                display: block;
              }
            }

            &.powered-by {
              content: "";
              position: absolute;
              width: 100%;
              left: 0;
              right: 0;
              bottom: 0;
              padding: 12px;
              margin-left: 0;

              p {
                display: flex;
                justify-content: center;

                img {
                  margin: 0;
                  margin-left: 10px;
                }
              }
            }
          }
        }

        .powered-by {
          content: "";
          position: absolute;
          width: 100%;
          bottom: 20px;
          left: 0;
          right: 0;
          padding: 12px;
          border-top: 1px solid $gray-light;
          display: none;

          @include media991 {
            display: block;
            border-top: none;
          }

          p {
            @include defaultFontStyle(500, 12, 1.2, $black-color);
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;

            img {
              margin: initial;
              margin-left: 10px;
            }
          }
        }
      }

      .header_align {
        display: flex;
        align-items: center;
        margin-left: 15px;

        // border-left: 2px solid #686871;
        // border-right: 2px solid #686871;
        // padding: 0 30px;
        // margin: 0 15px;
        @include media991 {
          margin-left: auto;
        }

        .btn {
          margin-left: 15px;
          width: max-content;

          @include media991 {
            margin-left: 10px;
          }

          @include media480 {
            margin-left: 5px;
          }

          @include media350 {
            margin-left: 3px;
          }

          background-color: $green-color;
          color: $white;
          border: 2px solid $green-color;

          &:hover {
            border: 2px solid $green-color;
            border-radius: 5px;
            color: $green-color;
            background-color: transparent;
            box-shadow: 0px 0px 17px -5px $green-color;
          }

          &.trans,
          &.trans:focus,
          &.trans:active,
          &.trans:visited {
            border: 2px solid $green-btn-light;
            color: $green-btn-light;
            background-color: transparent;
            border-radius: 5px;

            &:hover {
              background-color: $green-btn-light;
              color: $white;
              border-radius: 5px;
              border: 2px solid $green-btn-light;
              box-shadow: 0px 0px 17px -5px $green-btn;
            }
          }
        }

        .notification_alert {
          display: block;
          position: relative;
          padding-left: 30px;

          @include media575 {
            padding-left: 10px;
          }

          > a {
            width: 36px;
            height: 36px;
            border: 3px solid $white;
            border-radius: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            font-size: 12px;

            @include media1366 {
              width: 35px;
              height: 35px;
              font-size: 15px;
              border: 2px solid $white;
            }

            // @include media991 {
            //   width: 35px;
            //   height: 35px;
            //   font-size: 15px;
            //   border: 2px solid $white;
            // }
            span {
              width: 20px;
              height: 20px;
              color: $white;
              background-color: $green-color;
              border-radius: 100%;
              position: absolute;
              top: -5px;
              left: auto;
              right: -5px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 10px;

              @include media1366 {
                top: -6px;
                right: -8px;
              }

              @include media575 {
                width: 15px;
                height: 15px;
                font-size: 11px;
              }
            }
          }

          .notification_dropdown {
            // display: none;
            visibility: hidden;
            opacity: 0;
            position: absolute;
            z-index: 3;
            right: 0px;
            top: calc(100% + 8px);
            padding: 0;
            margin: 0;
            background: $white;
            padding: 0px 0;
            min-width: 420px;
            -webkit-transform: translateY(30px);
            -ms-transform: translateY(30px);
            transform: translateY(30px);
            -webkit-transition: all 0.5s ease 0s;
            -moz-transition: all 0.5s ease 0s;
            -ms-transition: all 0.5s ease 0s;
            -o-transition: all 0.5s ease 0s;
            transition: all 0.5s ease 0s;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            box-sizing: border-box;
            -webkit-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.09);
            -moz-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.09);
            box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.09);

            @include media991 {
              min-width: 380px;
            }

            @include media575 {
              min-width: 290px;
            }

            @include media480 {
              top: 100%;
              right: -100px;
            }

            &:before {
              position: absolute;
              top: -15px;
              display: block;
              content: "";
              width: 0;
              border-style: solid;
              border-width: 0 10px 18px;
              border-color: transparent transparent $white;
              box-sizing: border-box;
              left: auto;
              margin: 0 auto;
              height: 0;
              right: 10px;

              @include media480 {
                border-width: 0 8px 10px;
                top: -8px;
                right: 38%;
              }
            }

            ul {
              list-style: none;
              max-height: 235px;
              overflow-y: scroll;
              border-radius: 4px;
              -webkit-border-radius: 4px;
              -moz-border-radius: 4px;

              @include media575 {
                max-height: 190px;
              }

              li {
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                padding: 10px;
                border-bottom: 1px solid $gray-btn;

                .notification_images {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 50px;
                  height: 50px;
                  border: 1px solid $gray-btn;
                  border-radius: 100%;

                  @include media575 {
                    width: 40px;
                    height: 40px;
                  }

                  img {
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                    object-fit: cover;
                  }
                }

                .notification_dec {
                  margin-left: 10px;
                  flex: 1;
                  position: relative;

                  .notification_title {
                    margin-bottom: 5px;
                    display: inline-block;
                    vertical-align: middle;
                    padding-right: 20px;

                    p {
                      @include defaultFontStyle(500, 13, 1.4, $light-black);
                      margin-bottom: 0;
                      display: inline-block;

                      span {
                        @include defaultFontStyle(500, 14, 1.4, $black-color);
                        display: inline-block;
                        padding: 0 4px;

                        @include media575 {
                          font-size: 13px;
                        }

                        &:last-child {
                          padding-right: 0;
                        }

                        &:first-child {
                          padding-left: 0;
                        }
                      }
                    }
                  }

                  .close_notification {
                    position: absolute;
                    right: 0;
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 1;
                    top: 0;
                    cursor: pointer;
                    border-radius: 100%;
                    border: 1px solid $bg-red-light;

                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }

                &:last-child {
                  border-bottom: none;
                }

                &:first-child {
                  border-bottom: 1px solid $gray-btn;
                }

                .notification-btn {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  .notification-inner-btn {
                    > a {
                      padding: 4px 5px;
                      font-size: 13px;
                      margin: 0px 6px 0 0;
                      font-weight: 500;

                      @include media575 {
                        padding: 3px 5px;
                        font-size: 12px;
                      }
                    }
                  }

                  span {
                    @include defaultFontStyle(500, 13, 1.2, $light-black);

                    @include media575 {
                      font-size: 12px;
                    }
                  }
                }
              }
            }

            h4 {
              text-align: center;
              padding: 10px;
            }
          }

          &:hover {
            .notification_dropdown {
              -webkit-transform: translateY(10px);
              -ms-transform: translateY(10px);
              transform: translateY(10px);
              visibility: visible;
              opacity: 1;
              // display: block;
            }
          }
        }
      }

      .menubar {
        padding: 5px;
        margin-left: 30px;
        background: $green-color;
        border-color: $green-color;

        &:hover {
          background-color: $green-color;
        }

        @include media575 {
          padding: 2px;
        }
      }
      .menu_alert_sec {
        display: flex;
        align-items: center;
        .menu_alert {
          display: inline-block;
          position: relative;
          display: block;

          @include media991 {
            display: none;
          }

          .menu_dropdown {
            display: flex;
            visibility: hidden;
            opacity: 0;
            position: absolute;
            z-index: 3;
            right: 0px;
            top: calc(100% + 8px);
            padding: 0;
            margin: 0;
            background: $white;
            padding: 0px 0;
            min-width: 380px;
            -webkit-transform: translateY(30px);
            -ms-transform: translateY(30px);
            transform: translateY(30px);
            -webkit-transition: all 0.5s ease 0s;
            -moz-transition: all 0.5s ease 0s;
            -ms-transition: all 0.5s ease 0s;
            -o-transition: all 0.5s ease 0s;
            transition: all 0.5s ease 0s;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            box-sizing: border-box;
            -webkit-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.09);
            -moz-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.09);
            box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.09);

            @include media991 {
              min-width: 380px;
              display: block;
            }

            @include media575 {
              min-width: 290px;
            }

            @include media480 {
              top: 100%;
            }

            .menu_box {
              display: flex;
              width: 100%;

              @include media991 {
                max-height: 400px;
                overflow-y: auto;
                display: block;
              }
            }

            &:before {
              position: absolute;
              top: -15px;
              display: block;
              content: "";
              width: 0;
              border-style: solid;
              border-width: 0 10px 18px;
              border-color: transparent transparent $white;
              box-sizing: border-box;
              left: auto;
              margin: 0 auto;
              height: 0;
              right: 10px;

              @include media480 {
                border-width: 0 8px 10px;
                top: -8px;
              }
            }

            ul {
              list-style: none;
              // max-height: 235px;
              // overflow-y: scroll;
              border-radius: 4px;
              -webkit-border-radius: 4px;
              -moz-border-radius: 4px;

              li {
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                cursor: pointer;
              }
            }

            .menu_about {
              width: 25%;
              padding: 30px;
              border-right: 1px solid $gray-light;

              @include media991 {
                width: 100%;
                padding: 30px 30px 0;
              }

              ul {
                li {
                  padding: 10px 0;
                  @include defaultFontStyle(400, 16, 1.2, $black-color);

                  a {
                    &:hover {
                      color: $green-color !important;
                    }
                  }
                }
              }
            }

            .menu_product {
              width: 35%;
              border-right: 1px solid $gray-light;
              position: relative;

              @include media991 {
                width: 100%;
              }

              .menu_title {
                margin: 0 30px;
                margin: 30px 30px 0;
              }

              ul {
                li {
                  border-bottom: 1px solid $gray-light;
                  padding: 20px 0;
                  margin: 0 30px;
                }
              }

              .powered-by {
                content: "";
                position: absolute;
                width: 100%;
                bottom: 0;
                padding: 12px;
                border-top: 1px solid $gray-light;

                @include media991 {
                  position: initial;
                  border-top: none;
                }

                p {
                  @include defaultFontStyle(500, 12, 1.2, $black-color);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin: 0;

                  img {
                    margin: initial;
                    margin-left: 10px;
                  }
                }
              }
            }

            .menu_event {
              width: 100%;
              padding: 30px;

              @include media991 {
                width: 100%;
              }

              .menu_event_list {
                display: flex;
                align-items: center;

                .event_img {
                  width: 80px;
                  height: 60px;
                  border: 1px solid $gray-light;
                  border-radius: 4px;
                  margin-right: 15px;
                  display: flex;
                  align-items: center;
                  padding: 8px;
                }

                h5 {
                  @include defaultFontStyle(400, 16, 1.2, $black-color);
                }
              }

              ul {
                li {
                  margin-bottom: 10px;
                }
              }
            }

            .menu_title {
              border-bottom: 1px solid $gray-light;
              padding-bottom: 20px;
              margin-bottom: 20px;
              position: relative;

              h4 {
                @include defaultFontStyle(700, 16, 1.2, $green-color);

                &::before {
                  content: "";
                  position: absolute;
                  bottom: -1px;
                  left: 0;
                  border: 2px solid $green-color;
                  width: 60px;
                }
              }
            }

            &.fan_menu_dropdown {
              min-width: 225px;

              .menu_about {
                width: 100%;
              }
            }
          }

          &:hover {
            .menu_dropdown {
              -webkit-transform: translateY(10px);
              -ms-transform: translateY(10px);
              transform: translateY(10px);
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
      .avatar-dropdown-menu {
        position: relative;
        cursor: pointer;
        margin-left: 15px;
        padding-right: 30px;

        @include media575 {
          padding-right: 10px;
        }

        @include media480 {
          margin-left: 5px;
        }

        .login-user {
          display: flex;
          align-items: center;
          color: $white;

          @include media991 {
            font-size: 13px;
          }

          .avatar-image {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $light-black;
            width: 36px;
            height: 36px;
            border-radius: 100%;
            cursor: pointer;
            margin-right: 8px;
            border: 3px solid $white;

            @include media1366 {
              width: 35px;
              height: 35px;
              border: 2px solid $white;
              margin-right: 5px;
            }

            // @include media991 {
            //   width: 35px;
            //   height: 35px;
            //   border: 2px solid $white;
            //   margin-right: 5px;
            // }
            img {
              border-radius: 100%;
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }

          i {
            font-size: 12px;
            margin-left: 8px;
            -webkit-transition: all 0.2s;
            -moz-transition: all 0.2s;
            -ms-transition: all 0.2s;
            -o-transition: all 0.2s;
            transition: all 0.2s;

            @include media480 {
              margin-left: 5px;
            }
          }
        }

        .avatar-dropdown-menu-items {
          visibility: hidden;
          transition: all 0.2s;
          transform: scale(0.8) translate(0px, -30px);
          opacity: 0;
          position: absolute;
          z-index: 3;
          right: -65px;
          top: calc(100% + 18px);
          padding: 0;
          margin: 0;
          background: $white;
          padding: 0px 0;
          min-width: 200px;
          border-radius: 3px;
          box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);

          @include media1599 {
            right: 10px;
          }

          @include media991 {
            min-width: 190px;
          }

          @include media480 {
            top: calc(100% + 12px);
          }

          li {
            display: block;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            position: relative;

            a {
              border-radius: 4px;
              -webkit-border-radius: 4px;
              -moz-border-radius: 4px;
              @include defaultFontStyle(500, 14, 1.2, $black-color);
              text-align: left;
              display: block;
              padding: 18px 20px;
              border-bottom: 1px solid $menu-bg;
              position: relative;
              text-decoration: none;
              outline: none;
              text-transform: none;
              white-space: nowrap;
              -webkit-transition: all 0.5s ease-in-out;
              -moz-transition: all 0.5s ease-in-out;
              -o-transition: all 0.5s ease-in-out;
              -ms-transition: all 0.5s ease-in-out;
              transition: all 0.5s ease-in-out;

              @include media991 {
                padding: 15px 20px;
              }

              i {
                color: $green-btn;
              }

              &.user-dropdown {
                color: $black-color;
                background-color: $menu-bg;
                position: relative;

                &:before {
                  position: absolute;
                  content: "\f054";
                  font-family: "Font Awesome 5 Free";
                  font-weight: 600;
                  right: 15px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 20px;
                  width: 20px;
                  color: $light-black;
                  transform: rotate(90deg);
                  -webkit-transition: all 0.5s ease-in-out;
                  -moz-transition: all 0.5s ease-in-out;
                  -o-transition: all 0.5s ease-in-out;
                  -ms-transition: all 0.5s ease-in-out;
                  transition: all 0.5s ease-in-out;
                }

                &.active {
                  &:before {
                    transform: rotate(-90deg);
                  }
                }
              }

              &:hover,
              &.active {
                background-color: $menu-bg;
                color: $blue;
                -webkit-transition: all 0.5s ease-in-out;
                -moz-transition: all 0.5s ease-in-out;
                -o-transition: all 0.5s ease-in-out;
                -ms-transition: all 0.5s ease-in-out;
                transition: all 0.5s ease-in-out;
              }

              i {
                color: $green-btn;
              }
            }

            .user-submenu {
              margin-left: 15px;
              max-height: 0;
              overflow-y: hidden;
              -webkit-transition: max-height 0.5s ease-in-out;
              -moz-transition: max-height 0.5s ease-in-out;
              -o-transition: max-height 0.5s ease-in-out;
              transition: max-height 0.5s ease-in-out;

              &.active {
                max-height: 100vh;
                -webkit-transition: all 0.5s ease-in-out;
                -moz-transition: all 0.5s ease-in-out;
                -o-transition: all 0.5s ease-in-out;
                -ms-transition: all 0.5s ease-in-out;
                transition: all 0.5s ease-in-out;
              }
            }
          }

          &:before {
            position: absolute;
            top: -15px;
            display: block;
            content: "";
            width: 0;
            border-style: solid;
            border-width: 0 10px 18px;
            border-color: transparent transparent $white;
            box-sizing: border-box;
            left: 0;
            margin: 0 auto;
            height: 0;
            right: 0;

            @include media1599 {
              left: auto;
              right: 15px;
            }
          }
        }

        &:hover {
          .avatar-dropdown-menu-items {
            display: block;
            visibility: visible;
            opacity: 1;
            transform: scale(1) translate(0, 0);
          }
        }

        &.downloadapp-menu-mb {
          @include media991 {
            &:hover {
              .avatar-dropdown-menu-items {
                display: none;
                visibility: hidden;
                opacity: 0;
              }
            }
          }
        }
      }
    }

    .navbar-toggler {
      display: none;

      @include media991 {
        display: block;
        width: 35px;
        height: 35px;
        line-height: 35px;
        padding: 0;
        border-radius: 5px;
        background-color: transparent;
        border: 1px solid transparent;
        text-align: center;
        // z-index: 99;
        display: flex;
        transition: all 0.5s;
        margin-left: 10px;
        outline: none;
        box-shadow: none;

        @include media480 {
          margin-left: 5px;
        }

        @include media480 {
          width: 30px;
          height: 30px;
        }

        .navbar-toggler-icon {
          content: "";
          background: no-repeat center center;
          background-size: 100% 100%;
          position: relative;
          width: 90%;
          height: 3px;
          background-color: $green-color;
          display: inline-block;
          vertical-align: middle;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%);

          &:after,
          &:before {
            position: absolute;
            content: "";
            width: 75%;
            height: 3px;
            background-color: $green-color;
            right: 0;
            transition: all 0.5s;
            animation: openCloseByeSpan 300ms linear forwards;
          }

          &:before {
            top: -8px;
          }

          &:after {
            top: 8px;
          }
        }
      }
    }

    &.mainNav {
      background-color: #31313d;
      padding: 20px 0;

      @include media1366 {
        padding: 10px 0;
      }

      .container {
        .navbar_sec {
          display: flex;
          align-items: center;
          justify-content: start;
          flex-wrap: wrap;
          > img {
            max-width: 210px;
            height: 100%;
            width: 100%;
            margin: 0;
          }
          .navbar-brand {
            padding-right: 15px;
            img {
              max-width: 270px;
            }
          }

          @include customMedia(790) {
            max-width: 300px;
            justify-content: center;
            padding-right: 10px;
            .navbar-brand {
              padding-bottom: 10px;
              padding-right: 0;
            }
          }
        }
      }

      // @include media575{
      //   >.container{
      //     flex-wrap: wrap;
      //     >div{
      //     width: 45%;
      //     >.header_align{
      //       margin-left: unset;
      //     }
      //     &:nth-last-child(1){
      //       width: 100%;
      //       display: flex;
      //       justify-content: center;
      //       padding-top: 15px;
      //     }
      //   }
      //   }
      // }
      @include media575 {
        .container {
          > div {
            // width: 50%;
            .navbar-brand {
              > img {
                width: 100%;
              }
            }
            &:nth-child(1) {
              width: 40%;
            }
            &:nth-child(2) {
              width: 60%;
            }
          }
        }
      }
    }
  }

  .header-bar {
    @include media575 {
      display: none;
    }
  }
}

body {
  &.show-menu {
    overflow: hidden;
  }
}

.show-menu {
  header {
    .navbar-expand-lg {
      .container {
        #navbarNav {
          -webkit-transform: translateX(0%);
          -moz-transform: translateX(0%);
          -ms-transform: translateX(0%);
          -o-transform: translateX(0%);
          transform: translateX(0%);
        }
      }

      .navbar-toggler {
        position: fixed;
        z-index: 5;
        right: 15px;
        border-radius: 5px;
        padding: 3px;
        top: 15px;

        .navbar-toggler-icon {
          height: 0;

          &:after,
          &:before {
            left: 0;
            right: 0;
            margin: auto;
            transform: rotate(45deg);
            top: -5px;
          }

          &:after {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }

  .overlay {
    position: fixed;
    left: 0;
    z-index: 9;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
  }
}

.notification_scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $white;
  background-color: $white;
  border-radius: 5px;
}

.notification_scroll::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
  background-color: $white;
}

.notification_scroll::-webkit-scrollbar-thumb {
  background-color: $gray-text;
  border: 2px solid $gray-text;
  border-radius: 5px;

  &:hover {
    background-color: $light-black;
    border: 2px solid $light-black;
  }
}

.secondnav {
  background-color: $white;
  padding: 14px 0;

  @include media1366 {
    padding: 6px 0;
  }

  .container {
    @include media767 {
      // display: block;
      // text-align: center;
    }
  }

  .navbar-brand {
    max-width: 100% !important;

    // @include media767 {
    //   max-width: 150px !important;
    // }
    img {
      width: 100% !important;

      @include media1366 {
        max-width: 100% !important;
      }

      @include media991 {
        max-width: 100% !important;
      }

      @include customMedia(380) {
        max-width: 100% !important;
      }
    }
  }

  #companyheader {
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
    display: flex;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    min-height: 50px;

    @include media991 {
      background: $white;
      box-shadow: 0 0px 0 10px #fff inset, 0 0 0 15px var(--green-color) inset;
      padding: 50px 20px 15px;
      position: fixed;
      top: 0;
      right: 0;
      display: block !important;
      width: 60%;
      text-align: center;
      bottom: 0;
      -webkit-transition: all 0.5s ease 0s;
      -moz-transition: all 0.5s ease 0s;
      -ms-transition: all 0.5s ease 0s;
      -o-transition: all 0.5s ease 0s;
      transition: all 0.5s ease 0s;
      -webkit-transform: translateX(110%);
      -moz-transform: translateX(110%);
      -ms-transform: translateX(110%);
      -o-transform: translateX(110%);
      transform: translateX(110%);
      // height: calc(100vh -100px);
      overflow: auto;
      z-index: 2;
    }

    @include media575 {
      width: 80%;
    }

    @include media480 {
      width: 90%;
      box-shadow: 0 0px 0 10px #fff inset, 0 0 0 13px var(--green-color) inset;
    }

    @include media767 {
      display: block;
    }

    .arrow {
      display: none;

      @include media991 {
        display: block;
        color: $black-color;
        position: absolute;
        right: 0;
        top: 5px;
        cursor: pointer;
        width: 90px;
        text-align: right;
        font-size: 15px;
        height: 38px;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        line-height: 38px;

        i {
          font-weight: 600;
          transition: all 0.5s ease-in-out;
        }

        &.active {
          i {
            transform: rotate(90deg);
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            -ms-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
          }
        }
      }
    }

    ul.sub-menu {
      padding: 0;
      background-color: $white;
      width: 180px;
      white-space: nowrap;
      position: absolute;
      top: calc(100% + 19px);
      left: -60px;
      z-index: 1;
      visibility: hidden;
      opacity: 0;
      -webkit-transform: translateY(30px);
      -ms-transform: translateY(30px);
      transform: translateY(30px);
      -webkit-transition: all 0.5s ease 0s;
      -moz-transition: all 0.5s ease 0s;
      -ms-transition: all 0.5s ease 0s;
      -o-transition: all 0.5s ease 0s;
      transition: all 0.5s ease 0s;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      box-sizing: border-box;
      -webkit-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.09);
      -moz-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.09);
      box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.09);
      right: 0;
      margin: 0 auto;

      @include media991 {
        position: relative;
        padding: 0;
        margin: 0 15px;
        width: 100%;
        background: transparent;
        box-shadow: none;
        top: auto;
        left: auto;
        border-radius: 0;
        -webkit-transform: inherit;
        -ms-transform: inherit;
        transform: inherit;
        visibility: 1;
        opacity: 1;
        max-height: 0;
        overflow-y: hidden;
        -webkit-transition: max-height 0.5s ease-in-out;
        -moz-transition: max-height 0.5s ease-in-out;
        -o-transition: max-height 0.5s ease-in-out;
        transition: max-height 0.5s ease-in-out;
      }

      &:before {
        position: absolute;
        top: -17px;
        left: 0;
        display: block;
        content: "";
        width: 0;
        border-style: solid;
        border-width: 0 10px 18px;
        border-color: transparent transparent $white;
        box-sizing: border-box;
        right: 0;
        margin: 0 auto;
        height: 0;

        @include media991 {
          display: none;
        }
      }

      > li {
        display: block;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;

        a {
          @include defaultFontStyle(500, 14, 1.2, $black-color);
          text-align: left;
          display: block;
          padding: 15px 0;
          margin: 0 15px;
          border-bottom: 1px solid $menu-bg;
          position: relative;
          text-decoration: none;
          outline: none;
          text-transform: none;
          white-space: nowrap;
          -webkit-transition: all 0.5s linear;
          -moz-transition: all 0.5s linear;
          -o-transition: all 0.5s linear;
          -ms-transition: all 0.5s linear;
          transition: all 0.5s linear;

          @include media991 {
            margin: 0;
            visibility: initial;
          }

          img {
            margin: 0;
          }
        }

        .product-submenu {
          margin-left: 15px;
          max-height: 0;
          overflow-y: hidden;
          -webkit-transition: max-height 0.5s ease-in-out;
          -moz-transition: max-height 0.5s ease-in-out;
          -o-transition: max-height 0.5s ease-in-out;
          transition: max-height 0.5s ease-in-out;

          @include media991 {
            max-height: 100vh;
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            -ms-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
          }

          &.active {
            max-height: 100vh;
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            -ms-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;

            @include media991 {
              max-height: 0;
              overflow-y: hidden;
              -webkit-transition: max-height 0.5s ease-in-out;
              -moz-transition: max-height 0.5s ease-in-out;
              -o-transition: max-height 0.5s ease-in-out;
              transition: max-height 0.5s ease-in-out;
            }
          }
        }

        p {
          @include defaultFontStyle(500, 14, 1.2, $black-color);
          text-align: left;
          display: block;
          padding: 15px 0;
          margin: 0 15px;
          border-bottom: 1px solid $menu-bg;
          position: relative;
          text-decoration: none;
          outline: none;
          text-transform: none;
          white-space: nowrap;
          -webkit-transition: all 0.5s linear;
          -moz-transition: all 0.5s linear;
          -o-transition: all 0.5s linear;
          -ms-transition: all 0.5s linear;
          transition: all 0.5s linear;
          cursor: pointer;
        }

        &:hover,
        &.active {
          background-color: $menu-bg;
          -webkit-transition: all 0.5s linear;
          -moz-transition: all 0.5s linear;
          -o-transition: all 0.5s linear;
          -ms-transition: all 0.5s linear;
          transition: all 0.5s linear;

          @include media991 {
            background-color: transparent;
          }

          a {
            color: $blue;
            -webkit-transition: all 0.5s linear;
            -moz-transition: all 0.5s linear;
            -o-transition: all 0.5s linear;
            -ms-transition: all 0.5s linear;
            transition: all 0.5s linear;
          }
        }

        &.inner-subemnu {
          .product-dropdown {
            background-color: $menu-bg;
            position: relative;
            margin: 0;
            border-radius: 4px;
            padding: 18px 20px;
            @include defaultFontStyle(500, 14, 1.2, $blue);
            text-align: left;
            display: block;
            padding: 18px 15px;
            border-bottom: 1px solid $menu-bg;
            position: relative;
            text-decoration: none;
            outline: none;
            text-transform: none;
            white-space: nowrap;
            -webkit-transition: all 0.5s linear;
            -moz-transition: all 0.5s linear;
            -o-transition: all 0.5s linear;
            -ms-transition: all 0.5s linear;
            transition: all 0.5s linear;

            @include media991 {
              background: transparent;
              padding: 15px 0;
              color: $black-color;
            }

            &:before {
              position: absolute;
              content: "\f054";
              font-family: "Font Awesome 5 Free";
              font-weight: 600;
              right: 15px;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 20px;
              width: 20px;
              color: $light-black;
              transform: rotate(90deg);
              -webkit-transition: all 0.5s ease-in-out;
              -moz-transition: all 0.5s ease-in-out;
              -o-transition: all 0.5s ease-in-out;
              -ms-transition: all 0.5s ease-in-out;
              transition: all 0.5s ease-in-out;

              @include media991 {
                transform: rotate(90deg);
                right: 30px;
                color: $black-color;
                font-size: 15px;
              }
            }

            &.active {
              &:before {
                transform: rotate(-90deg);

                @include media991 {
                  transform: rotate(0deg);
                }
              }
            }
          }

          &:hover,
          &.active {
            background-color: transparent;
            color: $black-color;
          }

          .product-submenu {
            li {
              a {
                color: $black-color;
              }

              &:hover {
                background-color: $menu-bg;
                -webkit-transition: all 0.5s ease-in-out;
                -moz-transition: all 0.5s ease-in-out;
                -o-transition: all 0.5s ease-in-out;
                -ms-transition: all 0.5s ease-in-out;
                transition: all 0.5s ease-in-out;

                @include media991 {
                  background-color: transparent;
                }

                a {
                  color: $blue;
                }
              }
            }
          }
        }
      }

      &.active-submenu {
        max-height: 100vh;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
      }

      .menu_event_list {
        display: flex;
        align-items: center;

        .event_img {
          width: 80px;
          height: 60px;
          border: 1px solid $gray-light;
          border-radius: 4px;
          margin-right: 15px;
          display: flex;
          align-items: center;
          padding: 8px;
        }

        h5 {
          @include defaultFontStyle(400, 16, 1.2, $black-color);
        }
      }
    }

    .navbar-nav {
      display: flex;
      align-items: center;
      list-style: none;
      -ms-flex-direction: row;
      flex-direction: row;
      margin-left: auto;

      @include media991 {
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        height: calc(100vh - 250px);
        overflow: scroll;
        align-items: normal;
        overflow-x: hidden;
      }

      @include media350 {
        display: block;
      }

      .nav-item {
        margin: 0 10px;
        position: relative;
        text-align: center;

        &.past_champions {
          margin: 0 4px;
        }

        @include media1199 {
          margin: 0px 5px;
        }

        @include media991 {
          border-bottom: 1px solid $menu-bg;

          &:last-child {
            border-bottom: 0;
          }
        }

        &.beta-tag {
          @include media991 {
            display: flex;
            align-items: center;
          }

          .tag-text {
            @include defaultFontStyle(600, 8, 1.2, $white);
            background: #cb352f;
            padding: 3px;
            border-radius: 4px;
            letter-spacing: 0.5px;
            text-align: center;
            position: absolute;
            top: -20px;
            left: 0;
            right: 0;
            width: 35px;
            margin: auto;

            @include media1199 {
              font-size: 7px;
              top: -15px;
              width: 28px;
            }

            @include media991 {
              position: static;
              margin: unset;
              width: 33px;
              margin-left: 10px;
            }
          }
        }

        .nav-link {
          display: block;
          @include defaultFontStyle(500, 14, 1.2, $black-color);

          @include media991 {
            color: $black-color;
            padding: 12px 0;

            text-align: left;
          }
        }

        &.active {
          position: relative;

          .nav-link {
            color: var(--green-color);
          }

          &::before {
            content: "";
            position: absolute;
            width: 100%;
            left: 0;
            bottom: -31px;
            border-bottom: 3px solid var(--green-color);
            transition: all 0.5s ease;

            @include media1366 {
              bottom: -22px;
            }

            // @include media1199 {
            //   bottom: -18px;
            // }
            @include media991 {
              bottom: 0px;
              border-bottom: 1px solid var(--green-color);
            }
          }
        }

        &:hover {
          ul.sub-menu {
            -webkit-transform: translateY(10px);
            -ms-transform: translateY(10px);
            transform: translateY(10px);
            visibility: visible;
            opacity: 1;

            @include media991 {
              -webkit-transform: none;
              -ms-transform: none;
              transform: none;
              transform: none;
              visibility: visible;
              opacity: 1;
              -webkit-transition: all 0.5s ease-in-out;
              -moz-transition: all 0.5s ease-in-out;
              -o-transition: all 0.5s ease-in-out;
              -ms-transition: all 0.5s ease-in-out;
              transition: all 0.5s ease-in-out;
            }
          }

          .nav-link {
            color: $green-color;
          }
        }

        &:hover {
          .nav-link {
            color: var(--green-color);
          }

          &::before {
            content: "";
            position: absolute;
            width: 100%;
            left: 0;
            bottom: -31px;
            border-bottom: 3px solid var(--green-color);
            transition: all 0.5s ease;

            @include media1366 {
              bottom: -22px;
            }

            // @include media1199 {
            //   bottom: -18px;
            // }
            @include media991 {
              bottom: 0px;
              border-bottom: 1px solid var(--green-color);
            }

            @include media767 {
              bottom: 0px;
            }

            @include media575 {
              bottom: 0px;
            }

            @include media350 {
              bottom: -4px;
              border-bottom: 2px solid var(--green-color);
              left: 0;
              right: 0;
              width: 30%;
              margin: auto;
            }
          }

          // &:first-child {
          //   &::before {
          //     content: none;
          //   }
          // }
          &:last-child {
            &::before {
              content: none;
            }
          }
        }

        &:last-child {
          margin-right: 0;

          @include media575 {
            margin: 8px 15px;
          }

          .btn {
            margin-right: 5px;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        i {
          margin-right: 8px;
        }

        .btn {
          padding: 13px 20px;
          font-size: 14px;

          @include media1199 {
            padding: 10px 5px;
          }

          @include media991 {
            margin-top: 15px;
          }
        }

        &.header-btn {
          text-align: left;

          &.beta-tag {
            .tag-text {
              @include media991 {
                margin-top: 15px;
              }
            }
          }

          .header-dark {
            background-color: $black-color;
            border-color: $black-color;
            padding: 6px 6px;
            font-size: 14px;

            &:hover {
              color: $white;
              box-shadow: none;
            }

            @include media1199 {
              padding: 4px 6px;
            }

            @include media991 {
              padding: 6px 6px;
            }
          }

          &:hover {
            &::before {
              content: none;
            }
          }
        }
      }

      &.navbar-navs {
        margin-left: unset;
      }
    }

    // .navbar-nav {
    //   display: flex;
    //   list-style: none;
    //   -ms-flex-direction: row;
    //   flex-direction: row;
    //   margin-left: auto;
    //   align-items: center;
    //   // height: calc(100vh - 130px);overflow: scroll;
    //   @include media991 {
    //     -ms-flex-direction: column;
    //     flex-direction: column;
    //     padding-left: 0;
    //     margin-bottom: 0;
    //     list-style: none;
    //     height: calc(100vh - 250px);
    //     overflow: scroll;
    //     align-items: initial;
    //   }

    //   .nav-item {
    //     margin: 0 15px;
    //     position: relative;
    //     text-align: center;
    //     &:last-child {
    //       margin-right: 0;
    //       @include media575 {
    //         margin: 8px 15px;
    //       }
    //       .btn {
    //         margin-right: 5px;
    //         &:last-child{
    //           margin-right: 0;
    //         }
    //       }
    //     }
    //     &.beta-tag{
    //       @include media991 {
    //         display: flex;
    //         align-items: center
    //       }
    //       .tag-text{
    //         @include defaultFontStyle(600, 8, 1.2, $white);
    //         background: #cb352f;
    //         padding: 3px;
    //         border-radius: 4px;
    //         letter-spacing: 0.5px;
    //         text-align: center;
    //         position: absolute;
    //         top: -20px;
    //         left: 0;
    //         right: 0;
    //         width: 35px;
    //         margin: auto;
    //         @include media1199 {
    //           font-size: 7px;
    //           top: -15px;
    //           width: 28px;
    //         }
    //         @include media991 {
    //           position: static;
    //           margin: unset;
    //           width: 33px;
    //           margin-left: 10px;
    //         }
    //       }
    //     }
    //     .nav-link {
    //       display: block;
    //       @include defaultFontStyle(500, 14, 1.2, $black-color);
    //       @include media991 {
    //         color: $black-color;
    //         padding: 12px 0;
    //         border-bottom: 1px solid $menu-bg;
    //         text-align: left;
    //       }
    //     }
    //     .arrow {
    //       display: none;
    //       @include media991 {
    //         display: block;
    //         color: $black-color;
    //         position: absolute;
    //         right: 0;
    //         top: 5px;
    //         cursor: pointer;
    //         width: 90px;
    //         text-align: right;
    //         font-size: 15px;
    //         height: 38px;
    //         -webkit-transition: all 0.5s ease-in-out;
    //         -moz-transition: all 0.5s ease-in-out;
    //         -o-transition: all 0.5s ease-in-out;
    //         -ms-transition: all 0.5s ease-in-out;
    //         transition: all 0.5s ease-in-out;
    //         line-height: 38px;
    //         i {
    //           font-weight: 600;
    //           transition: all 0.5s ease-in-out;
    //         }
    //         &.active {
    //           i {
    //             transform: rotate(90deg);
    //             -webkit-transition: all 0.5s ease-in-out;
    //             -moz-transition: all 0.5s ease-in-out;
    //             -o-transition: all 0.5s ease-in-out;
    //             -ms-transition: all 0.5s ease-in-out;
    //             transition: all 0.5s ease-in-out;
    //           }
    //         }
    //       }
    //     }
    //     ul.sub-menu {
    //       padding: 0;
    //       background-color: $white;
    //       width: 180px;
    //       white-space: nowrap;
    //       position: absolute;
    //       top: calc(100% + 19px);
    //       left: -60px;
    //       z-index: 1;
    //       visibility: hidden;
    //       opacity: 0;
    //       -webkit-transform: translateY(30px);
    //       -ms-transform: translateY(30px);
    //       transform: translateY(30px);
    //       -webkit-transition: all 0.5s ease 0s;
    //       -moz-transition: all 0.5s ease 0s;
    //       -ms-transition: all 0.5s ease 0s;
    //       -o-transition: all 0.5s ease 0s;
    //       transition: all 0.5s ease 0s;
    //       border-radius: 4px;
    //       -webkit-border-radius: 4px;
    //       -moz-border-radius: 4px;
    //       box-sizing: border-box;
    //       -webkit-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.09);
    //       -moz-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.09);
    //       box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.09);
    //       right: 0;
    //       margin: 0 auto;
    //       @include media991 {
    //         position: relative;
    //         padding: 0;
    //         margin: 0 15px;
    //         width: 100%;
    //         background: transparent;
    //         box-shadow: none;
    //         top: auto;
    //         left: auto;
    //         border-radius: 0;
    //         -webkit-transform: inherit;
    //         -ms-transform: inherit;
    //         transform: inherit;
    //         visibility: 1;
    //         opacity: 1;
    //         max-height: 0;
    //         overflow-y: hidden;
    //         -webkit-transition: max-height 0.5s ease-in-out;
    //         -moz-transition: max-height 0.5s ease-in-out;
    //         -o-transition: max-height 0.5s ease-in-out;
    //         transition: max-height 0.5s ease-in-out;
    //       }
    //       &:before {
    //         position: absolute;
    //         top: -17px;
    //         left: 0;
    //         display: block;
    //         content: "";
    //         width: 0;
    //         border-style: solid;
    //         border-width: 0 10px 18px;
    //         border-color: transparent transparent $white;
    //         box-sizing: border-box;
    //         right: 0;
    //         margin: 0 auto;
    //         height: 0;
    //         @include media991 {
    //           display: none;
    //         }
    //       }

    //       > li {
    //         display: block;
    //         border-radius: 4px;
    //         -webkit-border-radius: 4px;
    //         -moz-border-radius: 4px;

    //         a {
    //           @include defaultFontStyle(500, 14, 1.2, $black-color);
    //           text-align: left;
    //           display: block;
    //           padding: 15px 0;
    //           margin: 0 15px;
    //           border-bottom: 1px solid $menu-bg;
    //           position: relative;
    //           text-decoration: none;
    //           outline: none;
    //           text-transform: none;
    //           white-space: nowrap;
    //           -webkit-transition: all 0.5s linear;
    //           -moz-transition: all 0.5s linear;
    //           -o-transition: all 0.5s linear;
    //           -ms-transition: all 0.5s linear;
    //           transition: all 0.5s linear;
    //           @include media991 {
    //             margin: 0;
    //             visibility: initial;
    //           }
    //           img {
    //             margin: 0;
    //           }
    //         }
    //         .product-submenu {
    //           margin-left: 15px;
    //           max-height: 0;
    //           overflow-y: hidden;
    //           -webkit-transition: max-height 0.5s ease-in-out;
    //           -moz-transition: max-height 0.5s ease-in-out;
    //           -o-transition: max-height 0.5s ease-in-out;
    //           transition: max-height 0.5s ease-in-out;
    //           @include media991 {
    //             max-height: 100vh;
    //             -webkit-transition: all 0.5s ease-in-out;
    //             -moz-transition: all 0.5s ease-in-out;
    //             -o-transition: all 0.5s ease-in-out;
    //             -ms-transition: all 0.5s ease-in-out;
    //             transition: all 0.5s ease-in-out;
    //           }
    //           &.active {
    //             max-height: 100vh;
    //             -webkit-transition: all 0.5s ease-in-out;
    //             -moz-transition: all 0.5s ease-in-out;
    //             -o-transition: all 0.5s ease-in-out;
    //             -ms-transition: all 0.5s ease-in-out;
    //             transition: all 0.5s ease-in-out;
    //             @include media991 {
    //               max-height: 0;
    //               overflow-y: hidden;
    //               -webkit-transition: max-height 0.5s ease-in-out;
    //               -moz-transition: max-height 0.5s ease-in-out;
    //               -o-transition: max-height 0.5s ease-in-out;
    //               transition: max-height 0.5s ease-in-out;
    //             }
    //           }
    //         }
    //         p {
    //           @include defaultFontStyle(500, 14, 1.2, $black-color);
    //           text-align: left;
    //           display: block;
    //           padding: 15px 0;
    //           margin: 0 15px;
    //           border-bottom: 1px solid $menu-bg;
    //           position: relative;
    //           text-decoration: none;
    //           outline: none;
    //           text-transform: none;
    //           white-space: nowrap;
    //           -webkit-transition: all 0.5s linear;
    //           -moz-transition: all 0.5s linear;
    //           -o-transition: all 0.5s linear;
    //           -ms-transition: all 0.5s linear;
    //           transition: all 0.5s linear;
    //           cursor: pointer;
    //         }

    //         &:hover,
    //         &.active {
    //           background-color: $menu-bg;
    //           -webkit-transition: all 0.5s linear;
    //           -moz-transition: all 0.5s linear;
    //           -o-transition: all 0.5s linear;
    //           -ms-transition: all 0.5s linear;
    //           transition: all 0.5s linear;
    //           @include media991 {
    //             background-color: transparent;
    //           }

    //           a {
    //             color: $blue;
    //             -webkit-transition: all 0.5s linear;
    //             -moz-transition: all 0.5s linear;
    //             -o-transition: all 0.5s linear;
    //             -ms-transition: all 0.5s linear;
    //             transition: all 0.5s linear;
    //           }
    //         }

    //         &.inner-subemnu {
    //           .product-dropdown {
    //             background-color: $menu-bg;
    //             position: relative;
    //             margin: 0;
    //             border-radius: 4px;
    //             padding: 18px 20px;
    //             @include defaultFontStyle(500, 14, 1.2, $blue);
    //             text-align: left;
    //             display: block;
    //             padding: 18px 15px;
    //             border-bottom: 1px solid $menu-bg;
    //             position: relative;
    //             text-decoration: none;
    //             outline: none;
    //             text-transform: none;
    //             white-space: nowrap;
    //             -webkit-transition: all 0.5s linear;
    //             -moz-transition: all 0.5s linear;
    //             -o-transition: all 0.5s linear;
    //             -ms-transition: all 0.5s linear;
    //             transition: all 0.5s linear;
    //             @include media991 {
    //               background: transparent;
    //               padding: 15px 0;
    //               color: $black-color;
    //             }
    //             &:before {
    //               position: absolute;
    //               content: "\f054";
    //               font-family: "Font Awesome 5 Free";
    //               font-weight: 600;
    //               right: 15px;
    //               display: flex;
    //               align-items: center;
    //               justify-content: center;
    //               height: 20px;
    //               width: 20px;
    //               color: $light-black;
    //               transform: rotate(90deg);
    //               -webkit-transition: all 0.5s ease-in-out;
    //               -moz-transition: all 0.5s ease-in-out;
    //               -o-transition: all 0.5s ease-in-out;
    //               -ms-transition: all 0.5s ease-in-out;
    //               transition: all 0.5s ease-in-out;
    //               @include media991 {
    //                 transform: rotate(90deg);
    //                 right: 30px;
    //                 color: $black-color;
    //                 font-size: 15px;
    //               }
    //             }
    //             &.active {
    //               &:before {
    //                 transform: rotate(-90deg);
    //                 @include media991 {
    //                   transform: rotate(0deg);
    //                 }
    //               }
    //             }
    //           }
    //           &:hover,
    //           &.active {
    //             background-color: transparent;
    //             color: $black-color;
    //           }
    //           .product-submenu {
    //             li {
    //               a {
    //                 color: $black-color;
    //               }
    //               &:hover {
    //                 background-color: $menu-bg;
    //                 -webkit-transition: all 0.5s ease-in-out;
    //                 -moz-transition: all 0.5s ease-in-out;
    //                 -o-transition: all 0.5s ease-in-out;
    //                 -ms-transition: all 0.5s ease-in-out;
    //                 transition: all 0.5s ease-in-out;
    //                 @include media991 {
    //                   background-color: transparent;
    //                 }
    //                 a {
    //                   color: $blue;
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       }
    //       &.active-submenu {
    //         max-height: 100vh;
    //         -webkit-transition: all 0.5s ease-in-out;
    //         -moz-transition: all 0.5s ease-in-out;
    //         -o-transition: all 0.5s ease-in-out;
    //         -ms-transition: all 0.5s ease-in-out;
    //         transition: all 0.5s ease-in-out;
    //       }
    //       .menu_event_list {
    //         display: flex;
    //         align-items: center;
    //         .event_img {
    //           width: 80px;
    //           height: 60px;
    //           border: 1px solid $gray-light;
    //           border-radius: 4px;
    //           margin-right: 15px;
    //           display: flex;
    //           align-items: center;
    //           padding: 8px;
    //         }
    //         h5 {
    //           @include defaultFontStyle(400, 16, 1.2, $black-color);
    //         }
    //       }
    //     }
    //     &:hover {
    //       ul.sub-menu {
    //         -webkit-transform: translateY(10px);
    //         -ms-transform: translateY(10px);
    //         transform: translateY(10px);
    //         visibility: visible;
    //         opacity: 1;
    //         @include media991 {
    //           -webkit-transform: none;
    //           -ms-transform: none;
    //           transform: none;
    //           transform: none;
    //           visibility: visible;
    //           opacity: 1;
    //           -webkit-transition: all 0.5s ease-in-out;
    //           -moz-transition: all 0.5s ease-in-out;
    //           -o-transition: all 0.5s ease-in-out;
    //           -ms-transition: all 0.5s ease-in-out;
    //           transition: all 0.5s ease-in-out;
    //         }
    //       }

    //       .nav-link {
    //         color: $green-color;
    //       }
    //     }
    //     &.hide-desktop {
    //       display: none;
    //       @include media991 {
    //         display: block;
    //       }
    //     }
    //     &.powered-by {
    //       content: "";
    //       position: absolute;
    //       width: 100%;
    //       left: 0;
    //       right: 0;
    //       bottom: 0;
    //       padding: 12px;
    //       margin-left: 0;
    //       p {
    //         display: flex;
    //         justify-content: center;
    //         img {
    //           margin: 0;
    //           margin-left: 10px;
    //         }
    //       }
    //     }
    //   }
    // }
  }

  .navbar-toggler-company {
    display: none;

    @include media991 {
      display: block;
      width: 35px;
      height: 35px;
      line-height: 35px;
      padding: 0;
      border-radius: 5px;
      background-color: transparent;
      border: 1px solid transparent;
      text-align: center;
      display: flex;
      transition: all 0.5s;
      margin-left: 10px;
      outline: none;
      box-shadow: none;
    }

    // @include media575 {
    //   width: 25px;
    //   width: 25px;
    // }
    .navbar-toggler-icon-company {
      content: "";
      background: no-repeat center center;
      background-size: 100% 100%;
      position: relative;
      width: 90%;
      height: 3px;
      background-color: var(--green-color);
      display: inline-block;
      vertical-align: middle;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);

      &:after,
      &:before {
        position: absolute;
        content: "";
        width: 75%;
        height: 3px;
        background-color: var(--green-color);
        right: 0;
        transition: all 0.5s;
        animation: openCloseByeSpan 300ms linear forwards;
      }

      &:before {
        top: -8px;
      }

      &:after {
        top: 8px;
      }
    }
  }
}

.show-menu2 {
  #companyheader {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }

  .navbar-toggler-company {
    position: fixed;
    z-index: 5;
    right: 15px;
    border-radius: 5px;
    padding: 3px;
    top: 15px;

    .navbar-toggler-icon-company {
      height: 0;

      &:after,
      &:before {
        left: 0;
        right: 0;
        margin: auto;
        transform: rotate(45deg);
        top: -5px;
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}

.tearm-policy {
  .ruleDraft {
    padding: 0 15px;
  }
}
