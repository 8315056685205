.billing_payment {
    .billing_paymethod {
        padding: 0;
        margin-bottom: 20px;
        .payment-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            @include media350 {
                flex-wrap: wrap;
                justify-content: center;
            }
            h2 {
                @include defaultFontStyle(500, 26, 1.4, $black-color);
                @include media575 {
                    @include defaultFontStyle(500, 20, 1.4, $black-color);
                }
                @include media480 {
                    @include defaultFontStyle(500, 16, 1.4, $black-color);
                }
            }
            .pay_add_btn {
                color: var(--green-color);
                padding: 9px 20px;
                border: 2px dashed var(--green-color);
                border-radius: 4px;
                display: inline-block;
                vertical-align: middle;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                background-color: transparent;
                @include media350 {
                    margin-top: 10px;
                }
            }
        }
        .payment_list {
            .payment_table {
                display: block;
                width: 100%;
                overflow-x: auto;
                -webkit-overflow-scrolling: touch;
                table {
                    border-collapse: collapse;
                    width: 100%;
                    th {
                        @include defaultFontStyle(500, 16, 1.4, $light-black);

                        padding: 19px 24px;
                        text-align: left;
                        background-color: $light-white;
                        &:nth-child(1) {
                            min-width: 387px;
                            @include media1366 {
                                min-width: 377px;
                            }
                        }
                        &:nth-child(2) {
                            min-width: 640px;
                            @include media1366 {
                                min-width: 270px;
                            }
                        }
                        &:nth-child(3) {
                            min-width: 141px;
                        }
                    }
                    td {
                        padding: 20px 24px;
                        .card_holder_lable {
                            display: flex;
                            max-width: 260px;
                            width: 100%;
                            justify-content: space-between;
                            a {
                                background: var(--green-color);
                                color: $white;
                                display: inline-block;
                                text-align: center;
                                vertical-align: middle;
                                padding: 5px 10px;
                                border-radius: 5px;
                                font-size: 10px;
                                font-weight: 500;
                            }
                        }

                        p {
                            @include defaultFontStyle(400, 14, 1.4, $light-black);
                            margin: 0;
                            padding: 0;
                        }
                        .action-btn {
                            white-space: nowrap;
                            a {
                                margin-right: 10px;
                                &:last-child {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
            .billing_table {
                display: block;
                width: 100%;
                overflow-x: auto;
                -webkit-overflow-scrolling: touch;
                table {
                    border-collapse: collapse;
                    width: 100%;
                    tbody {
                        tr {
                            border-bottom: 1px solid #dcdfe6;
                            &:last-child {
                                border-bottom: 0p;
                            }
                            td {
                                &:first-child {
                                    p {
                                        color: $black-color;
                                    }
                                }
                            }
                        }
                    }
                    th {
                        @include defaultFontStyle(500, 16, 1.4, $light-black);
                        padding: 19px 24px;
                        text-align: left;
                        background-color: $light-white;
                        &:nth-child(1) {
                            min-width: 425px;
                        }
                        &:nth-child(2) {
                            min-width: 244px;
                        }
                        &:nth-child(3) {
                            min-width: 244px;
                        }
                        &:nth-child(4) {
                            min-width: 244px;
                        }
                        &:nth-child(5) {
                            min-width: 128px;
                        }
                    }
                    td {
                        padding: 20px 24px;
                        .card_holder_lable {
                            display: flex;
                            max-width: 260px;
                            width: 100%;
                            justify-content: space-between;
                            a {
                                background: var(--green-color);
                                color: $white;
                                display: inline-block;
                                text-align: center;
                                vertical-align: middle;
                                padding: 4px 10px;
                                border-radius: 5px;
                                font-size: 8px;
                                font-weight: 500;
                            }
                        }
                        .delete_icon {
                            background: $white;
                            color: $text-red;
                            display: inline-block;
                            text-align: center;
                            vertical-align: middle;
                            padding: 4px 14px;
                            border-radius: 24px;
                            font-size: 14px;
                            font-weight: 500;
                        }
                        p {
                            @include defaultFontStyle(400, 14, 1.4, $light-black);
                            margin: 0;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}
