.account_creation {
    padding: 40px 0;
    background: #ffffff;
    margin-bottom: 100px;
    position: relative;
    height: 860px;
    .left_section {
        width: 100%;
        // border-right: 1px solid #0000001a;
        // padding-right: 15px;
    }
    .right_section {
        max-width: 690px;
        background: $white;
        width: 100%;
        height: 100%;
        overflow-x: auto;
        position: absolute;
        right: -100%;
        top: 0;
        padding: 22px;
        z-index: 2;
        border: 1px solid #e8eaf2;
        box-shadow: -11px 0 30px #1e25380f;
        transition: all 0.5s ease 0s;
        z-index: 99;
        &.active {
            right: 0;
            transition: all 0.5s ease 0s;
        }
        .account_tab {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 20px;
            .btn {
                width: 31.33%;
                @include media575 {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 15px;
                }
                &.active {
                    background-color: var(--green-color);
                    color: $white;
                }
            }
        }
    }
    .cross-btn {
        background: #8080803b;
        color: #000000b5;
        border-radius: 100%;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 0;
        margin-left: auto;
        cursor: pointer;
        margin-bottom: 20px;
        i {
            font-size: 20px;
            color: #0000009e;
        }
    }
    .team_info {
        display: flex;
        align-items: center;
        img {
            width: 100px;
            height: 100px;
            margin: 0;
            border-radius: 8px;
        }
        .team_desc {
            margin-left: 15px;
            p {
                b {
                    color: $black-light;
                }
                @include defaultFontStyle(500, 16, 1.2, $light-black);
                margin: 0px;
                padding-bottom: 5px;
            }
        }
    }
    .account_creation_table {
        margin-top: 30px;
        border: 1px solid #e5e9f2;
        border-radius: 5px;
        .table-responsive-xl {
            // @include media1599{
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            // }
            max-height: 590px;
            overflow-y: auto;
            // overflow-x: hidden;
        }
        .table {
            width: 100%;
            table-layout: unset !important;
            th {
                @include defaultFontStyle(500, 15, 1.4, $black-light);
                padding: 18px 24px;
                text-align: left;
                background-color: $light-white;
                vertical-align: middle;
                white-space: nowrap;
            }
            td {
                padding: 6px 24px;
                white-space: nowrap;
                color: $light-black;

                &.text-green {
                    color: var(--green-color);
                }
            }
            .active {
                background: #7cbd4230;
            }
            .grey {
                background: #00000021;
            }
        }
    }
    .form_section {
        border: 1px solid #9e9e9eb5;
        padding: 15px;
        margin-bottom: 20px;
        border-radius: 5px;
        &.disable {
            background: #80808047;
            opacity: 0.6;
            // cursor: not-allowed;
        }
        .player_name {
            p {
                @include defaultFontStyle(500, 16, 1.4, $black-color);
            }
        }
        .btn {
            padding: 8px 28px;
        }
        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 14px 0;
            h3 {
                @include defaultFontStyle(500, 22, 1.4, $black-color);
                padding-right: 10px;
            }
            .btn {
                padding: 7px 22px;
            }
        }
        .common-lable {
            margin: 14px 0 8px;
        }
        .serch-box {
            position: relative;
            z-index: 111;
            .serch-btn {
                position: absolute;
                height: 45px;
                width: 65px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #4d8aae;
                color: #fff;
                top: 0;
                right: 0;
                border-radius: 0 4px 4px 0;
            }
        }
    }
    .serch-box {
        .geosuggest {
            .geosuggest__suggests-wrapper {
                z-index: 1;
            }
        }
    }
}

.checkbox {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 50px;
    padding: 10px 0;

    &:last-child {
        margin-right: 0;
    }
    input {
        position: absolute;
        opacity: 0;
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        cursor: pointer;
        &.disabled {
            cursor: not-allowed;
        }
    }
    .square {
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        transition: all 0.5s ease 0s;
        background-color: $white;
        border: 1px solid rgba(53, 53, 65, 0.5);
        margin-right: 12px;
        margin: auto;
        &.disabled {
            background: $gray-btn;
            border: 1px solid transparent;
            cursor: not-allowed;
            &:after {
                border: solid #e5e9f2;
            }
        }
    }

    .square:after {
        content: "";
        display: block;
        position: absolute;
        top: 4px;
        left: 7px;
        width: 5px;
        height: 8px;
        border: solid $white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
    .circle {
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        transition: all 0.5s ease 0s;
        background-color: $white;
        border: 1px solid rgba(53, 53, 65, 0.5);
        margin-right: 12px;
    }
    .circle:after {
        content: "";
        position: absolute;
        left: 3px;
        top: 3px;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background: $green-btn-light;
        opacity: 0;
    }
    label {
        @include defaultFontStyle(400, 14, 16, $black-light);
    }
    input:checked ~ .square::after,
    input:checked ~ .circle::after {
        opacity: 1;
    }
    input:checked ~ .circle {
        border: 1px solid $green-btn-light;
    }
    input:checked ~ .square {
        background-color: $green-btn-light;
        border: 1px solid $green-btn-light;
    }
}

.position-relative {
    position: relative;
}
.overlay_div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0.6;
    transition: 0.5s ease;
    background-color: #353541;
    cursor: pointer;
    z-index: 99;
    // &:active{
    //     opacity: 0.6;
    // }
}

::-webkit-scrollbar {
    width: 5px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #babeca;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #6c7b95;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #b2b9c6;
}

.account_creation_error {
    text-align: center;
    color: #ff0000b0;
    padding-top: 20px;
    font-size: 16px;
}
