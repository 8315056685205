/* for common fonts */
@mixin defaultFontStyle($fontWeight: null, $fontSize: null, $lineHeight: null, $color: null) {
  font-family: $secondary-font;
  font-style: normal;
  font-weight: $fontWeight;
  font-size: ($fontSize) + px;
  line-height: $lineHeight;
  color: $color;
}

// custom devices
@mixin customMedia($custom) {
  @media (max-width: $custom+"px") {
    @content;
  }
}
// Small smartphones
@mixin media350 {
  @media (max-width: #{$screen-350}) {
    @content;
  }
}
// Small devices
@mixin media480 {
  @media (max-width: #{$screen-480}) {
    @content;
  }
}
@mixin media675 {
  @media (max-width: #{$screen-675}) {
    @content;
  }
}
// Small smartphones
@mixin media575 {
  @media (max-width: #{$screen-575}) {
    @content;
  }
}
// Medium devices

@mixin media767 {
  @media (max-width: #{$screen-767}) {
    @content;
  }
}
// tablet devices
@mixin media991 {
  @media (max-width: #{$screen-991}) {
    @content;
  }
}
// large devices
@mixin media1199 {
  @media (max-width: #{$screen-1199}) {
    @content;
  }
}
// Extra large devices
@mixin media1366 {
  @media (max-width: #{$screen-1366}) {
    @content;
  }
}
// extra Large tablets and desktops
@mixin media1429 {
  @media (max-width: #{$screen-1429}) {
    @content;
  }
}
// Extra large devices
@mixin media1599 {
  @media (max-width: #{$screen-1599}) {
    @content;
  }
}

// Paddding and Margin

@for $i from 0 through 100 {
  .m-#{$i} {
    $margin: $i * 1px;
    margin: $margin;
  }
  .mt-#{$i} {
    $margin: $i * 1px;
    margin-top: $margin;
  }
  .mr-#{$i} {
    $margin: $i * 1px;
    margin-right: $margin;
  }
  .mb-#{$i} {
    $margin: $i * 1px;
    margin-bottom: $margin;
  }
  .ml-#{$i} {
    $margin: $i * 1px;
    margin-left: $margin;
  }
  .p-#{$i} {
    $padding: $i * 1px;
    padding: $padding;
  }
  .pt-#{$i} {
    $padding: $i * 1px;
    padding-top: $padding;
  }
  .pr-#{$i} {
    $padding: $i * 1px;
    padding-right: $padding;
  }
  .pb-#{$i} {
    $padding: $i * 1px;
    padding-bottom: $padding;
  }
  .pl-#{$i} {
    $padding: $i * 1px;
    padding-left: $padding;
  }
}
