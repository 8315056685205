.main-login {
    margin-top: -70px;
    padding-top: 0;
    position: relative;
    z-index: 1;
    .common-login {
        display: flex;
        background-color: $white;
        border-radius: 5px;
        box-shadow: 0px 10px 5px -7px rgba(0, 0, 0, 0.09);
        @include media767 {
            max-height: 100%;
            flex-wrap: wrap;
        }
        .commong-img {
            max-width: 700px;
            border-radius: 5px;
            position: relative;
            border: 2px solid $white;
            @include media1429 {
                max-width: 580px;
            }
            @include media1199 {
                max-width: 500px;
            }
            @include media991 {
                max-width: 380px;
            }
            @include media767 {
                max-width: 100%;
                width: 100%;
            }
            .logo-common {
                position: absolute;
                bottom: 25px;
                max-width: 180px;
                left: 25px;
                @include media480 {
                    bottom: 15px;
                    max-width: 100px;
                    left: 15px;
                }
            }
            > img {
                width: 100%;
                height: 100%;
                border-radius: 5px;
                object-fit: none;
                @include media767 {
                    object-fit: cover;
                }
            }
        }
        .common-dec {
            display: flex;
            width: calc(100% - 700px);
            padding: 40px;
            flex-direction: column;
            border-radius: 0 5px 5px 0;
            @include media1429 {
                width: calc(100% - 580px);
            }
            @include media1199 {
                width: calc(100% - 500px);
                padding: 25px;
            }
            @include media991 {
                width: calc(100% - 380px);
                padding: 20px;
            }
            @include media767 {
                width: 100%;
                border-radius: 0px 0px 5px 5px;
            }
            @include media575 {
                padding: 20px 15px;
            }
            .login-btn {
                width: 420px;
                margin: 10px auto;
                @include media1429 {
                    width: 100%;
                }
                @include media991 {
                    margin: 0;
                }
            }
            .header_align {
                display: flex;
                align-items: center;
                width: 100%;
                .btn {
                    width: 100%;
                    margin: 0 10px;
                    text-align: center;
                    @include media480 {
                        margin: 0 5px;
                    }
                }
                &.social-with {
                    justify-content: space-between;
                    @include media1199 {
                        justify-content: center;
                        flex-wrap: wrap;
                    }
                    > .social_btn {
                        width: 80%;
                        padding-left: 20px;
                        margin: 0 auto;

                        > img {
                            padding: 20px;
                            left: 5px;
                            padding-left: 0;
                        }
                    }
                }
            }
            .form-group {
                margin-bottom: 20px;
            }
            .signup_button {
                margin-top: 30px;
            }
            .select_team {
                margin: 120px 0;
                @include media1429 {
                    margin: 30px 0;
                }
                @include media991 {
                    margin: 20px 0;
                }
                .blue_btn {
                    margin-top: 30px;
                    @include media1429 {
                        margin-top: 20px;
                    }
                }
                .radiobox {
                    width: 100%;
                    cursor: pointer;
                    position: relative;
                    margin-bottom: 15px;
                    label {
                        width: 100%;
                        border-radius: 4px;
                        text-align: center;
                        height: 55px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include defaultFontStyle(500, 16, 1.4, $blue);
                        border: 2px solid transparent;
                        background-color: $gray-btn;
                        border-radius: 5px;
                        @include media480 {
                            @include defaultFontStyle(500, 14, 1.4, $blue);
                        }
                        @include media480 {
                            height: 45px;
                        }
                    }
                    input[type="radio"],
                    input[type="checkbox"] {
                        opacity: 0;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        cursor: pointer;
                        &:checked ~ label {
                            background-color: $blue;
                            color: $white;
                            border: 2px solid $blue;
                        }
                    }
                }
            }
            .verification_code {
                margin-top: 25px;
                @include media767 {
                    margin-top: 10px;
                }
                .form-group {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .form-control,
                    .form-control:active,
                    .form-control:focus {
                        @include defaultFontStyle(600, 18, 1.2, $black-color);
                        width: 50px !important;
                        margin: 0 5px;
                        padding: 5px;
                        text-align: center !important;
                        height: 100%;
                        border: none;
                        border-bottom: 2px solid #ededed;
                        border-radius: 0;
                    }
                }
                .validMsg {
                    text-align: center;
                    display: block;
                    margin: 0 0 15px;
                }
                .resend {
                    text-align: center;
                    display: block;
                    a {
                        @include defaultFontStyle(500, 16, 1.2, $blue);
                        margin: 0 0 20px;
                        display: inline-block;
                        @include media575 {
                            margin-bottom: 15px;
                            @include defaultFontStyle(500, 14, 1.2, $blue);
                        }
                    }
                    p {
                        margin: 0 0 20px;
                        @include defaultFontStyle(500, 16, 1.2, $black-color);
                        @include media575 {
                            margin-bottom: 15px;
                            @include defaultFontStyle(500, 14, 1.2, $black-color);
                        }
                    }
                }
            }
            .login_alert_text {
                margin: 15px 0;
                text-align: left;
                color: $text-red;
                display: block;
                font-size: 15px;
            }
            &.socialmedia-login {
                justify-content: space-between;
            }
        }
    }
}
