footer {
    background-color: $white;

    .footer_before {
        padding: 60px 0 0px;
        @include media575 {
            padding: 40px 0 0;
        }

        .flex-footer {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
            .flex-footer-logo_sec {
                @include customMedia(678) {
                    width: 100%;
                    padding-bottom: 20px;
                }
                .footer-logo {
                    display: inline-block;
                    // @include media1199 {
                    //     max-width: 220px;
                    // }
                    @include media575 {
                        width: 100%;
                        max-width: inherit;
                        margin-bottom: 30px;
                        padding-top: unset;
                        img {
                            max-width: 220px;
                            width: 100%;
                        }
                    }
                    > img {
                        min-width: 300px;
                        max-width: 300px;
                        @include media575 {
                            min-width: 275px;
                        }
                    }
                }
                @include media575 {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                }
            }
            .allfooter_url {
                @include customMedia(678) {
                    margin-bottom: 20px;
                }
                .footer-title {
                    position: relative;
                    padding-bottom: 10px;
                    margin-bottom: 20px;
                    @include defaultFontStyle(500, 20, 1.2, var(--green-color));
                    @include media350 {
                        @include defaultFontStyle(500, 18, 1.2, var(--green-color));
                    }
                    &:before {
                        position: absolute;
                        left: 0;
                        right: auto;
                        content: "";
                        height: 2px;
                        background-color: var(--green-color);
                        width: 35px;
                        bottom: 0;
                    }
                }
                .footer-url {
                    li {
                        display: block;
                        margin-bottom: 15px;
                        a {
                            @include defaultFontStyle(400, 17, 1.2, $light-black);
                            @include media1199 {
                                @include defaultFontStyle(400, 14, 1.2, $light-black);
                            }
                            &:hover {
                                color: $black-color;
                            }
                        }
                    }
                }
            }
        }
        form.footer_form {
            @include media991 {
                margin: 30px auto 0;
                @include media480 {
                    margin: 20px auto 0;
                }
                @include media350 {
                    margin: 0 auto;
                }
            }
            .form-group {
                margin-bottom: 1rem;
                .form-control,
                .form-control:active,
                .form-control:focus {
                    box-shadow: none;
                    height: 45px;
                    width: 100%;
                    padding: 10px;
                    border: 1px solid $gray-btn;
                    border-radius: 4px;
                    min-width: 300px;
                    @include defaultFontStyle(400, 16, 1.2, $light-black);
                    @include media1199 {
                        min-width: 250px;
                    }
                    @include media991 {
                        min-width: 500px;
                    }
                    @include media575 {
                        min-width: 400px;
                    }
                    @include media480 {
                        min-width: 280px;
                        height: 40px;
                        @include defaultFontStyle(400, 14, 1.2, $light-black);
                    }
                    @include media350 {
                        min-width: 220px;
                    }

                    &:hover,
                    &:visited,
                    &:active,
                    &:focus {
                        border: 1px solid $light-black;
                        color: $light-black;
                    }
                }
            }
        }
    }
    .footoe-logo {
        padding: 20px 0;
        // border-bottom: 1px solid #e8e8e8;
        img {
            width: 200px;
            &.gob_footer_logo {
                width: auto;
                max-width: 100%;
            }
            &.utb_footer_logo {
                width: 57px;
                max-width: 100%;
            }
            &.pioneer_footer_logo {
                width: 57px;
                max-width: 100%;
            }
        }
    }
    .footer_middle {
        text-align: center;
        padding: 20px 0;
        .social-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            li {
                margin: 0 15px;
                @include defaultFontStyle(500, 16, 1.2, var(--green-color));
                a {
                    @include defaultFontStyle(500, 20, 1.2, var(--green-color));
                    @include media480 {
                        @include defaultFontStyle(500, 18, 1.2, var(--green-color));
                    }
                    &:hover {
                        color: $black-color;
                    }
                }
            }
        }
    }
    .footer_after {
        padding: 20px 0;
        border-top: 1px solid $gray-bg;
        text-align: center;
        p {
            color: $black-color;
            font-size: 15px;
            @include media480 {
                font-size: 13px;
            }
        }
    }
}

.footer-company {
    .social-icon {
        li {
            margin: 0 10px !important;
        }
    }
}
.footer_download_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    a {
        width: 50%;
        margin-right: 5px;
        &.android_link {
            img {
                margin-right: 0;
                width: 180px;
            }
        }
        &.ios_link {
            img {
                margin-left: 0;
                width: 180px;
            }
        }
    }
}
